export interface ButtonBarButton {
  id?: string | null;
  label: string;
  routerLink?: string | null;
  visible: boolean;
  disabled: boolean;
  action?: ButtonAction | null;
  class?: string | null;
  icon?: string;
  iconPos?: ButtonIconPosition;
  isSaveButton?: boolean;
  isCancelButton?: boolean;
  function?: Function;
  tooltip?: string;
}

export enum ButtonAction {
  Save,
  Cancel,
  Delete,
  Start,
  Decline,
  Approve,
  Add,
  Reset,
  Close,
  Certify,
  Export,
  Open,
}

export enum ButtonIconPosition {
  Left = 'left',
  Right = 'right',
}
