import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { HealthzService } from '@mentor-one-ui/healthz/healthz.service';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-healthz',
  standalone: true,
  imports: [
    CommonModule,
  ],
  template: `<p>{{statusString$ | async}}</p>`,
  styleUrl: './healthz.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HealthzComponent implements OnInit {
  
  public statusString$: Observable<string>;

  constructor(public healthzService: HealthzService) { }

  ngOnInit(): void {
    this.statusString$ = 
    this.healthzService.getServiceHealth().pipe(
      map(res => {
        return "HealthStatus=" + res;
      })
    );
  }
}
