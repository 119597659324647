import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MainHeaderComponent } from '@mentor-one-ui/core/components/main-header/main-header.component';
import { MainNavigationModule } from '@mentor-one-ui/core/components/main-navigation/main-navigation.module';

import { AppRoutingModule } from '@mentor-one-ui/app-routing.module';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

import { DropdownModule } from 'primeng/dropdown';
import { SidebarModule } from 'primeng/sidebar';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button'; // Hvorfor må denne importeres her også for å få ikoner?
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { SlideMenuModule } from 'primeng/slidemenu';
import { PipesModule } from '../../pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { ThemeService } from '@mentor-one-ui/core/services/theme.service';

@NgModule({
  declarations: [MainHeaderComponent],
  imports: [
    AppRoutingModule,
    CommonModule,
    StoreRouterConnectingModule,
    DropdownModule,
    CommonModule,
    ButtonModule,
    OverlayPanelModule,
    FormsModule,
    SidebarModule,
    PipesModule,
    MainNavigationModule,
    MenuModule,
    TieredMenuModule,
    SlideMenuModule,
  ],
  exports: [MainHeaderComponent],
})
export class MainHeaderModule {}
