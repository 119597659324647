import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainFooterComponent } from './main-footer.component';
import { PipesModule } from "../../pipes/pipes.module";

@NgModule({
    declarations: [MainFooterComponent],
    exports: [MainFooterComponent],
    imports: [CommonModule, PipesModule]
})
export class MainFooterModule {}
