
// Denne konfigurasjonen benyttes ved Azure DevOps Pipelines. Variablene erstattes under bygging med riktige verdier ut fra kjøremiljø.
// Variablene defineres i Azure DevOps Library og er case sensitive. Verdiene hentes fra KeyVault - Test: kv-mentor-test, Prod: kv-mentor-prod

const baseUrl = '#{mentor-core-api-endpointurl}#';
const siteUrl = '#{mentor-one-siteuri}#';
const identityUrl = '#{mentor-identityserver-endpointurl}#';

export const environment = {
  production: true,
  API_URL: baseUrl,
  SITE_URL: siteUrl,
  Identity_URL: identityUrl,
  APPLICATION_INSIGHTS_INSTRUMENTATION_KEY: '763ad321-97ad-46d7-9761-f5f44820b751',
  SUPPORT_EMAIL: 'support@mentorpluss.no'
};
