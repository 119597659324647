import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BalanceModel, BalanceTypeEnum } from '../models/balance.model';

@Injectable({
  providedIn: 'root',
})
export class BalanceService {
  constructor(public http: HttpClient) {}

  getBalance(employeeId: number): Observable<BalanceModel[]> {
    return this.http.get<BalanceModel[]>(environment.API_URL + `/balance/${employeeId}/current`);
  }
}
