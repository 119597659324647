import { NgModule } from '@angular/core';
import { EmployeeFullNamePipe, EmployeeWithJobTitlePipe, EmployeeFullNameFromIdPipe, EmployeeFirstNameFromIdPipe } from './employee-fullname.pipe';
import { EmployeeDepartment, EmployeeDepartments } from './employee-departments.pipe';


@NgModule({
  declarations: [EmployeeFullNamePipe, EmployeeFullNameFromIdPipe, EmployeeFirstNameFromIdPipe, EmployeeWithJobTitlePipe, EmployeeDepartments, EmployeeDepartment],
  imports: [],
  exports: [EmployeeFullNamePipe, EmployeeFullNameFromIdPipe, EmployeeFirstNameFromIdPipe, EmployeeWithJobTitlePipe, EmployeeDepartments, EmployeeDepartment],
})
export class EmployeePipesModule { }

