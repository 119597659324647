import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@mentor-one-ui/core/pipes/pipes.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { MenubarModule } from 'primeng/menubar';

import { HttpClientModule } from '@angular/common/http';
import { CalendarViewComponent } from './calendar-page.component';
import { RouterModule, Routes } from '@angular/router';
import { MbscModule } from '@mobiscroll/angular';
import { EffectsModule } from '@ngrx/effects';
import { CalendarEffects } from './state/calendar.effects';
import { LetDirective } from '@ngrx/component';
import { SkeletonModule } from 'primeng/skeleton';
import { SidebarModule } from 'primeng/sidebar';
import { LeaveTypePipesModule } from '../pipes/leave-type-pipes.module';
import { EmployeePipesModule } from '../../employees/pipes/employee-pipes.module';
import { EventDetailsMobileComponent } from './components/event-details-mobile/event-details-mobile.component';
import { ButtonBarModule } from '../../core/components/button-bar/button-bar.module';
import { TooltipModule } from 'primeng/tooltip';
import { TimespanElementModule } from '../shared/timespan-element/timespan-element.module';
import { EventDetailsDesktopComponent } from './components/event-details-desktop/event-details-desktop.component';
import { MenuModule } from 'primeng/menu';
import { CreateNewAbsenceModalComponent } from './components/create-new-absence-modal/create-new-absence-modal.component';
import { InputTextModule } from 'primeng/inputtext';
import { ErrorMessageComponent } from '@mentor-one-ui/core/components/error-message/error-message.component';
import { AbsenceStatusTagComponent } from '../my-time/absence/components/absence-status-tag/absence-status-tag.component';
import { EmojiComponent } from '@mentor-one-ui/core/components/emoji/emoji.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConfirmPopupModule } from 'primeng/confirmpopup';

const routes: Routes = [
  {
    path: '',
    component: CalendarViewComponent,
  },
];

@NgModule({
  declarations: [
    CalendarViewComponent,
    EventDetailsMobileComponent,
    EventDetailsDesktopComponent,
    CreateNewAbsenceModalComponent,
  ],
  exports: [CalendarViewComponent],
  imports: [
    // ng
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    LetDirective,
    // primeng
    ButtonModule,
    OverlayPanelModule,
    CalendarModule,
    FormsModule,
    CheckboxModule,
    SkeletonModule,
    RouterModule.forChild(routes),
    OverlayPanelModule,
    SidebarModule,
    TooltipModule,
    MenubarModule,
    MenuModule,
    InputTextModule,
    ProgressSpinnerModule,
    ConfirmPopupModule,
    // mo
    PipesModule,
    EmployeePipesModule,
    TimespanElementModule,
    ErrorMessageComponent,
    // custom
    MbscModule,
    EffectsModule.forFeature([CalendarEffects]),
    LeaveTypePipesModule,
    AbsenceStatusTagComponent,
    EmployeePipesModule,
    ButtonBarModule,
    EmojiComponent,
  ],
})
export class CalendarPageModule {}
