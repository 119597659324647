import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContactModel } from '@mentor-one-ui/core/models/user-organization/contact/ContactModel';
import { State } from '@mentor-one-ui/user-organization/components/contacts/state/contacts.reducer';
import * as _ from 'underscore';
import { selectUserOrganizationState } from '@mentor-one-ui/user-organization/state';

const selectContactFeatureState = createSelector(selectUserOrganizationState, (state) => state['contacts']);

const selectContactList = createSelector(selectUserOrganizationState, (state) => state?.contacts.contactList);

const selectContactListFilteredByTag = createSelector(selectContactFeatureState, (state) => {
  if (state?.filterTag === '') {
    return state?.contactList;
  }
  const contactListFilteredByTag = state?.contactList.filter((c) =>
    c.ContactTags.map((t) => t.TagValue).includes(state?.filterTag)
  );
  return contactListFilteredByTag;
});

const selectContact = createSelector(selectContactFeatureState, (state) => {
  if (state?.selectedContactId === null) {
    return new ContactModel(-1, '', '', '', '', '', '', [], false);
  }
  return state?.contactList.find((c) => c.ContactId === state?.selectedContactId);
});

const getContactId = createSelector(selectContactFeatureState, (state) => state?.selectedContactId);

const selectContactTags = createSelector(selectContactFeatureState, (state) => {
  return _.flatten(state?.contactList.map((c) => c.ContactTags))
    .map((tag) => tag.TagValue)
    .filter((value, index, self) => self.indexOf(value) === index);
});

const selectFilterTag = createSelector(selectContactFeatureState, (state) => state?.filterTag);

const selectHasLoadError = createSelector(selectContactFeatureState, (state: State) => state.loadError);
const selectIsLoading = createSelector(selectContactFeatureState, (state: State) => state.isLoading);

export const contactsSelectors = {
  selectContactList,
  selectContactListFilteredByTag,
  selectContact,
  getContactId,
  selectContactTags,
  selectFilterTag,
  selectHasLoadError,
  selectIsLoading
};
