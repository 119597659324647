import * as fromOrganizationDepartments from '../components/departments/state/departments.reducer';
import * as fromOrganizationDetails from '../components/about/state/organization-details.reducer';
import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';
import * as fromOrganizationContacts from '../components/contacts/state/contacts.reducer';
import * as fromOrganizationSettings from '../components/settings/state/settings.reducer';

export const userOrganizationFeatureKey = 'organization';

export interface UserOrganizationState {
  [fromOrganizationDetails.featureKey]: fromOrganizationDetails.State;
  [fromOrganizationDepartments.featureKey]: fromOrganizationDepartments.State;
  [fromOrganizationContacts.featureKey]: fromOrganizationContacts.State;  
  [fromOrganizationSettings.featureKey]: fromOrganizationSettings.State;
}

export function reducers(state: UserOrganizationState | undefined, action: Action) {
  return combineReducers({
    [fromOrganizationDetails.featureKey]: fromOrganizationDetails.reducer,
    [fromOrganizationDepartments.featureKey]: fromOrganizationDepartments.reducer,
    [fromOrganizationContacts.featureKey]: fromOrganizationContacts.reducer,    
    [fromOrganizationSettings.featureKey]: fromOrganizationSettings.reducer,
  })(state, action);
}

export const selectUserOrganizationState = createFeatureSelector<UserOrganizationState>(userOrganizationFeatureKey);
