import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  styleUrls: ['./page-not-found.component.scss'],
  template: `
    <div class="error-container panel">
      <div class="text-icon">
        <h1>404</h1>
        <div class="icon">🧐</div>
      </div>
      <h2>{{ 'page-not-found-header' | translate | async }}</h2>
      <p>{{ 'page-not-found-description' | translate | async }}</p>
      <p *ngIf="url">
        {{ 'you-tried-to-access' | translate | async }} <strong>{{ url }}</strong>
      </p>
      <button pButton routerLink="/" class="btn btn-primary">{{ 'go-to-front-page' | translate | async }}</button>
    </div>
  `,
})
export class PageNotFoundComponent implements OnInit {
  url: string;
  constructor(private router: Router) {
    this.url = this.router.getCurrentNavigation()?.extras?.state?.url;
  }

  ngOnInit(): void {}
}
