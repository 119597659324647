import { createAction, props } from '@ngrx/store';
import { PayrollModel } from '../../models/payroll.model';
import { EmployeePayrollModel } from '../../models/employee-payroll.model';
import { TimeManagementType } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementType';
import { PayrollExportSettingModel } from '../../models/payroll-settings.model';
import { PayrollValidationResultModel } from '../../models/payroll-valiation-result.model';
import {
  PayrollExportHistoryDetailModel,
  PayrollExportHistoryResponseModel,
} from '../../models/payroll-export-history.model';
import { AbsenceModel } from '@mentor-one-ui/time/my-time/absence/models/absence.model';

export const PayrollApiActions = {
  validatePayrollSettingsSuccess: createAction(
    '[Payroll API] Validate Payroll Settings Success',
    props<{ validationResult: PayrollValidationResultModel }>()
  ),
  validatePayrollSettingsFailure: createAction(
    '[Payroll API] Validate Payroll Settings Failure',
    props<{ error: string }>()
  ),
  loadPayrollOverviewSuccess: createAction(
    '[Payroll API] Load Payroll Overview Success',
    props<{ payrollList: PayrollModel[] }>()
  ),
  loadPayrollOverviewFailure: createAction('[Payroll API] Load Payroll Overview Failure', props<{ error: string }>()),
  loadEmployeePayrollSuccess: createAction(
    '[Payroll API] Load Employee Payroll Success',
    props<{ employeeReport: EmployeePayrollModel }>()
  ),
  loadEmployeePayrollFailure: createAction('[Payroll API] Load Employee Payroll Failure', props<{ error: string }>()),
  certifyEmployeePayrollSuccess: createAction('[Payroll API] Certify Employee Payroll Success'),
  certifyEmployeePayrollFailure: createAction(
    '[Payroll API] Certify Employee Payroll Failure',
    props<{ error: string }>()
  ),
  ApproveLeaveSuccess: createAction(
    '[Payroll API] Approve Absence Success',
    props<{ id: number; leaveType: TimeManagementType }>()
  ),
  ApproveLeaveError: createAction('[Payroll API] Approve Absence Error', props<{ error: string }>()),
  RejectAbsenceSuccess: createAction(
    '[Payroll API] Reject Absence Success',
    props<{ id: number; leaveType: TimeManagementType }>()
  ),
  RejectAbsenceError: createAction('[Payroll API] Reject Absence Error', props<{ error: string }>()),
  exportPayrollSuccess: createAction('[Payroll API] Export Payroll Success'),
  exportPayrollFailure: createAction('[Payroll API] Export Payroll Failure', props<{ error: string }>()),
  loadPayrollAbsenceSuccess: createAction(
    '[Payroll API] Load Payroll Absence Success',
    props<{ absence: AbsenceModel }>()
  ),
  loadPayrollAbsenceFailure: createAction('[Payroll API] Load Payroll Absence Failure', props<{ error: string }>()),
};

export const PayrollSettingsApiActions = {
  loadPayrollSettingsSuccess: createAction(
    '[Payroll Settings API] Load Payroll Settings Success',
    props<{ settings: PayrollExportSettingModel }>()
  ),
  loadPayrollSettingsFailure: createAction(
    '[Payroll Settings API] Load Payroll Settings Failure',
    props<{ error: string }>()
  ),
  updatePayrollSettingsSuccess: createAction(
    '[Payroll Settings API] Update Payroll Settings Success',
    props<{ settings: PayrollExportSettingModel }>()
  ),
  updatePayrollSettingsFailure: createAction(
    '[Payroll Settings API] Update Payroll Settings Failure',
    props<{ error: string }>()
  ),
};

export const PayrollExportHistoryApiActions = {
  LoadExportListSuccess: createAction(
    '[Payroll Export History API] Export List Success',
    props<{ response: PayrollExportHistoryResponseModel }>()
  ),
  LoadExportListFailure: createAction(
    '[Payroll Export History API] Export List Failure',
    props<{ code: string; error: string }>()
  ),
  LoadExportDetailsSuccess: createAction(
    '[Payroll Export History API] Export Details Success',
    props<{ payrollExportId: number; response: PayrollExportHistoryDetailModel }>()
  ),
  LoadExportDetailsFailure: createAction(
    '[Payroll Export History API] Export Details Failure',
    props<{ code: string; error: string }>()
  ),
  DownloadHistoryFile: createAction(
    '[Payroll Export History API] Download History File',
    props<{ payrollExportId: number; payrollExportFileId: number; filename: string }>()
  ),
  DownloadHistoryFileSuccess: createAction('[Payroll Export History API] Download History File Success'),
  DownloadHistoryFileError: createAction(
    '[Payroll Export History API] Download History File Failure',
    props<{ code: string; error: string }>()
  ),
};
