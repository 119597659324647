import { createReducer, on } from "@ngrx/store";
import { SetPasswordActions } from "./set-password.action";

export interface State {
  showPasswordForm: boolean;
  confirmbox: boolean;
  showServerErrorMessage: boolean;
  tokenStateIsLoading: boolean;
  isTokenUsed: boolean;
}

export const initialState: State = {
  showPasswordForm: false,
  confirmbox: false,
  showServerErrorMessage: false,
  tokenStateIsLoading: true ,
  isTokenUsed: false
};

export const reducers = createReducer<State>(initialState,
  on(SetPasswordActions.EnterSetPasswordPage, (state, action): State => {
    return initialState;
  }),
  on(SetPasswordActions.SetPasswordPostPasswordFailure, (state, action): State => {
    return {
      ...state,
      showServerErrorMessage: true
    };
  }),

  on(SetPasswordActions.SetPasswordPostPasswordSuccess, (state, action): State => {
    return {
      ...state,
      showPasswordForm: false,
      confirmbox: true
    };
  }),

  on(SetPasswordActions.CheckIfTokenIsCompletedSuccess, (state, action): State => {
    return {
      ...state,
      showPasswordForm: !action.status,
      tokenStateIsLoading: false,
      isTokenUsed: action.status
    };
  }
  ),
);




