import { createAction, props } from "@ngrx/store";
import { UpdateEmployeeBalanceRequestModel } from "../models/admin-balance.model";

export const AdminBalancePageActions = {
  Enter: createAction('[Admin Balance Page] Enter'),
  updateEmployeeBalance: createAction('[Admin Balance Page] Update Employee Balance', props<{ employeeId: number, updatemodel: UpdateEmployeeBalanceRequestModel }>()),
  filterByEmployee: createAction('[Admin Balance Page] Filter By Employee', props<{employeeId: number}>()),
  filterByDepartment: createAction('[Admin Balance Page] Filter By Department', props<{departmentId: number}>()),
  filterByTimeType: createAction('[Admin Balance Page] Filter By Time Type', props<{timeTypeId: string}>()),
  filterByYear: createAction('[Admin Balance Page] Filter By Year', props<{year: number}>()),
  OpenEmployeeModal: createAction('[Admin Balance Page] Open Employee Modal', props<{ employeeId: number }>()),
  CloseEmployeeModal: createAction('[Admin Balance Page] Close Employee Modal'),
  resetFilter: createAction('[Admin Balance Page] Reset Filter')
};
