import { TimeManagementType } from "@mentor-one-ui/time/my-time/absence/models/TimeManagementType";
import { createAction, props } from "@ngrx/store";
import { TimeManagementTemplate } from "../../../models/time-management-template.model";

export const TimeManagementTypesModalActions = {
    cancel: createAction('[Settings | MODAL] Cancel time management types'),
    save: createAction('[Settings | MODAL] Save time management types', 
        props<{ 
            timeManagementTypes: TimeManagementTemplate[],
            timeManagementType: TimeManagementType
         }>()),

};