import { SystemMessageModel } from "@mentor-one-ui/core/models/system-messages.model";
import { SickLeaveFrontpageStatusModel } from "@mentor-one-ui/sick-leave/follow-up/models/sick-leave-follow-up.model";
import { CalendarCurrentDayDepartmentModel } from "@mentor-one-ui/time/calendar/models/calendar.models";
import { AbsenceModel } from "@mentor-one-ui/time/my-time/absence/models/absence.model";
import { TimeManagementListModel } from "@mentor-one-ui/time/my-time/absence/models/TimeManagementModel";
import { createAction, props } from "@ngrx/store";

export const FrontpageActions = {
  Enter: createAction('[Frontpage] Enter'),
  SetSystemMessageAsClosed: createAction('[Frontpage] Set System Message As Closed', props<{ id: number }>()),
  OpenNewRegistrationModal: createAction('[Frontpage] Open New Registration Modal'),
  CloseRegistrationModal: createAction('[Frontpage] Close Registration Modal'),
  LoadManageListFrontpage: createAction('[Frontpage] Load Manage List Front Page'),
  ManageTimeItemClicked: createAction('[Frontpage] Manage Time Item Clicked', props<{ eventId: number }>()),
  HideManageItem: createAction('[Frontpage] Hide Manage Item'),
  ApproveLeaveRequest: createAction('[Frontpage] Approve Leave Request', props<{ absenceId: number }>()),
  LoadProcessedTimeBox: createAction('[Frontpage] Load Processed Time Box'),

  ApproveOvertimeRequest: createAction('[Frontpage] Approve Overtime Request', props<{ overtimeId: number }>()),

  ShowRejectAbsenceDialog: createAction('[Frontpage] Show Reject Absence Dialog', props<{ model: any }>()),
  RejectLeaveRequest: createAction('[Frontpage] Reject Leave Request', props<{ leaveId: number, leaveTypeId: number, comment: string }>()),
  CancelRejectAbsenceRequest: createAction('[Frontpage] Cancel Reject Absence Success'),

  RejectOvertimeRequest: createAction('[Frontpage] Reject Overtime Request', props<{overtimeId: number, comment: string}>()),

  LoadDailySummarySuccess: createAction('[Frontpage] Load Daily Summary Success', props<{ summary: CalendarCurrentDayDepartmentModel[] }>()),
  LoadDailySummaryError: createAction('[Frontpage] Load Daily Summary Error', props<{ error: string }>()),
  CloseStartupSupportBox: createAction('[Frontpage] Close Startup Support Box'),
  CloseEmployeeSupportBox: createAction('[Frontpage] Close Employee Support Box'),
  LoadFollowUpListFrontpage: createAction('[Frontpage] Load follow up list frontpage'),
  SetLastBalanceTransitionToNull: createAction('[Frontpage] Set Last Balance Transition To Null'),
};

export const FrontPageApiActions = {
  LoadSystemMessageListSuccess: createAction('[Frontpage API] Load System Message List Success', props<{ systemMessageList: SystemMessageModel[] }>()),
  LoadSystemMessageListError: createAction('[Frontpage API] Load System Message List Error', props<{ error: string }>()),
  ManageTimeItemLoadedSuccess: createAction('[Frontpage API] Manage Time Item Loaded Success', props<{ absence: AbsenceModel }>()),
  ManageTimeItemLoadedError: createAction('[Frontpage API] Manage Time Item Loaded Error', props<{ error: string }>()),
  LoadManageListFrontpageSuccess: createAction('[Frontpage API API] Load Manage List Front Page Success', props<{ manageList: TimeManagementListModel }>()),
  LoadManageListFrontpageError: createAction('[Frontpage API API] Load Manage List Front Page Error', props<{ error: string }>()),
  ApproveAbsenceSuccess: createAction('[Frontpage API] Approve Absence Success'),
  ApproveAbsenceError: createAction('[Frontpage API] Approve Absence Error', props<{ error: string }>()),
  ApproveOvertimeSuccess: createAction('[Frontpage API] Approve Overtime Success'),
  ApproveOvertimeError: createAction('[Frontpage API] Approve Overtime Error', props<{ error: string }>()),
  RejectAbsenceSuccess: createAction('[Frontpage API] Reject Absence Success'),
  RejectAbsenceError: createAction('[Frontpage API] Reject Absence Error', props<{ error: string }>()),
  RejectOvertimeSuccess:  createAction('[Frontpage API] Reject Overtime Success'),
  RejectOvertimeError: createAction('[Frontpage API] Reject Overtime Error', props<{ error: string }>()),
  CloseStartupSupportBoxSuccess: createAction('[Frontpage API] Close Startup Support Box Success'),
  CloseStartupSupportBoxError: createAction('[Frontpage API] Close Startup Support Box Error', props<{ error: string }>()),
  CloseEmployeeSupportBoxSuccess: createAction('[Frontpage API] Close Employee Support Box Success'),
  CloseEmployeeSupportBoxError: createAction('[Frontpage API] Close Employee Support Box Error', props<{ error: string }>()),
  LoadFollowUpListFrontpageSuccess: createAction('[Frontpage API API] Load Follow Up List Front Page Success', props<{ result: SickLeaveFrontpageStatusModel }>()),
  LoadFollowUpListFrontpageError: createAction('[Frontpage API API] Load Follow Up List Front Page Error', props<{ code: string, message: string }>()),
}
