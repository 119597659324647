import { createAction, props } from "@ngrx/store";

export const UnmanagedLeavesPageActions = {    
    Enter: createAction(
        '[Unmanaged Leaves page] Enter'
      ),
    OpenFilterModal: createAction(
        '[Unmanaged Leaves page] Open Filter Modal'
      ),
      OpenSortModal: createAction(
        '[Unmanaged Leaves page] Open Sort Modal'
      ),
      resetFilter: createAction(
        '[Unmanaged Leaves page] Reset Filter'
    ),
    setSortOption: createAction(
        '[Unmanaged Leaves page] Set Sort Option',
        props<{ sortOptionId: number }>()
    ),
    optionDialogCanceled: createAction(
        '[Unmanaged Leaves page] Option Dialog Canceled'
    ),
    setEmployeeFilter: createAction(
        '[Unmanaged Leaves page] Set Employee Filter',
        props<{ employeeId: number }>()
    ),
    Paginate: createAction(
        '[Unmanaged Leaves page] Paginate',
        props<{ page: number }>()
    ),
    ShowRejectAbsenceDialog: createAction(
        '[Unmanaged Leaves page] Show Reject Absence Dialog',
        props<{ model: any }>()
    ),
    ApproveLeaveRequest: createAction(
        '[Unmanaged Leaves page] Approve Leave Request',
        props<{ leaveType: any, leaveId: number }>()
    ),


}