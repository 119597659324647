import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CalendarCurrentDayDepartmentModel } from "@mentor-one-ui/time/calendar/models/calendar.models";
import { Observable } from "rxjs";
import { UserModel } from "src/app/core/state/user/user.model";
import { environment } from "src/environments/environment";
import { AccessControlSystemEditModel } from "../core/models/frontpage.model";

@Injectable({
  providedIn: 'root'
})
export class FrontpageService {

  constructor(
    public http: HttpClient) {

    }

  GetModuleAccess(): Observable<AccessControlSystemEditModel[]> {
    return this.http.get<AccessControlSystemEditModel[]>(environment.API_URL + '/Organization/moduleaccess');
  }

  GetTodaySummary(): Observable<CalendarCurrentDayDepartmentModel[]> {
    return this.http.get<CalendarCurrentDayDepartmentModel[]>(environment.API_URL + '/Calendar/today');
  }
}
