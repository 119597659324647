import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { PipesModule } from "@mentor-one-ui/core/pipes/pipes.module";
import { FrontpageActions } from "@mentor-one-ui/core/state/frontpage/frontpage.actions";
import { selectSystemMessageList } from "@mentor-one-ui/core/state/frontpage/frontpage.selectors";
import { Store } from "@ngrx/store";
import { Message } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { MessageModule } from "primeng/message";
import { MessagesModule } from "primeng/messages";
import { ToastModule } from "primeng/toast";



@Component({
  selector: 'mo-system-message-list',
  styleUrls: ['./system-message-list.component.scss'],
  standalone: true,
  template: `
    <div *ngFor="let message of systemMessageList" class="message-list">
      <p-messages [severity]="message.severity" [closable]="true" [key]="message.key">
        <ng-template pTemplate>
            <div class="message-item">
              <i class="info {{message.icon}}"></i>
              <div class="message-content">
               <div class="message-title">
                  <h2 class="h4">{{message.summary}}</h2>
                  <button pButton 
                  icon="fa fa-chevron-down"
                  (click)="toggleInfo()"
                  class="info-toggler p-button-sm p-button-text mobile-only"
                  [ngClass]="{'upside-down': infoToggled}">
                  </button>
                </div>
                <p [ngClass]="{'mobile-hidden': !infoToggled}" 
                [innerHTML]="message.detail | safeHtml"></p>
              </div>
              <div class="actions">
                <button type="button" class="p-message-close p-link" (click)="close(message)">
                  <i class="fal fa-times"></i>
                </button>
              </div>
            </div>
        </ng-template>
      </p-messages>
    </div>
    `,
  imports: [
    CommonModule,
    MessageModule,
    MessagesModule,
    ToastModule,
    PipesModule,
    ButtonModule
  ],
})
export class SystemMessageListComponent {


  systemMessageList: Message[];
  infoToggled: boolean = false;

  constructor(private store: Store) {
    this.store.select(selectSystemMessageList).subscribe((sm: Message[]) => {
      this.systemMessageList = sm;
    });
  }

  toggleInfo(){
    this.infoToggled = !this.infoToggled;
  }
  close(message: Message) {
    this.store.dispatch(FrontpageActions.SetSystemMessageAsClosed({ id: parseInt(message.key!) }));
  }


}
