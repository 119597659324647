

import { createSelector } from "@ngrx/store";
import { selectMyTimeState } from "../../state/selectors";
import * as fromMyTime from "../../state";

export const selectBalanceState = createSelector(
  selectMyTimeState,
  (state: fromMyTime.State) => state?.balance
);

export const selectBalance = createSelector(
  selectBalanceState,
  (state) => state?.balance
);

export const selectIsLoadingBalance = createSelector(
  selectBalanceState,
  (state) => state?.isLoading
);

export const selectBalanceLoadError = createSelector(
  selectBalanceState,
  (state) => state?.balanceLoadError
);



