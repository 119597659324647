import { createReducer, on } from "@ngrx/store";
import { BalancePageActions } from "../balance/actions/balance-page.actions";
import { BalanceModel } from "../balance/models/balance.model";
import { BalanceApiActions } from "../balance/actions/balance-api.actions";

export const featureKey = 'balance';

export interface State {
  isSaving: boolean;
  isLoading: boolean;
  balance: BalanceModel[];
  balancesLoaded: boolean;
  balanceLoadError: boolean;
}

export const initialState: State = {
  isSaving: false,
  isLoading: true,
  balance: [],
  balancesLoaded: false,
  balanceLoadError: false,
};

export const reducer = createReducer(
  initialState,
  on(BalancePageActions.Enter, (state) => {
    return {
      ...state,
      balanceLoadError: false
    }
  }),
  on(BalanceApiActions.loadBalanceSuccess, (state, { balance }) => {
    return {
      ...state,
      isLoading: false,
      balanceLoaded: true,
      balanceLoadError: false,
      balance: balance
    }
  }),
  on(BalanceApiActions.loadBalanceError, (state) => {
    return {
      ...state,
      balanceLoadError: true
    }
  }),

);
