import { createReducer, on } from '@ngrx/store';
import { EmployeePageActions } from '../actions/employee-page.actions';
import { EmployeeNextOfKinApiActions, EmployeeNextOfKinTabActions } from '../actions/employee-nextofkin.actions';
import { EmployeeModalActions } from '../actions/employee-details.actions';

export const featureKey = 'nextOfKin';

export interface State {
  selectedNextOfKinId: number;
  isSaving: boolean;
  loaded: boolean;
  error: boolean;
}

export const initialState: State = {
  selectedNextOfKinId: -1,
  isSaving: false,
  loaded: false,
  error: false,
};

export const reducer = createReducer(
  initialState,
  on(EmployeePageActions.Enter, (state) => initialState),
  on(EmployeeNextOfKinTabActions.OpenModal, (state, { nextOfKinId }): State => {
    return {
      ...state,
      selectedNextOfKinId: nextOfKinId,
    };
  }),
  on(EmployeeModalActions.CloseDetailsModal, (state): State => {
    return {
      ...state,
      selectedNextOfKinId: -1
    };
  }),
  on(EmployeeNextOfKinTabActions.ModalWasClosed, (state): State => {
    return {
      ...state,
      selectedNextOfKinId: -1,
    };
  }),
  on(EmployeeNextOfKinTabActions.SaveNextOfKin, (state): State => {
    return {
      ...state,
      isSaving: true,
    };
  }),
  on(EmployeeNextOfKinApiActions.SaveNextOfKinSuccess, (state): State => {
    return {
      ...state,
      isSaving: false
    };
  }),
  on(EmployeeNextOfKinApiActions.LoadNextOfKinListSuccess, (state): State => {
    return {
      ...state,
      loaded: true,
      error: false,
    };
  }),
  on(EmployeeNextOfKinApiActions.LoadNextOfKinListError, (state): State => {
    return {
      ...state,
      loaded: false,
      error: true,
    };
  }),
  on(EmployeeNextOfKinApiActions.SaveNextOfKinError, (state): State => {
    return {
      ...state,
      isSaving: false,
    };
  })
);
