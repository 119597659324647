import { createAction, props } from '@ngrx/store';
import { ContactModel } from '@mentor-one-ui/core/models/user-organization/contact/ContactModel';

// Get/Set data from API
export const LoadContacts = createAction('[Contact API] Load contacts');
const LoadContactsSuccess = createAction('[Contact API] Load contacts success', props<{ contact: ContactModel[] }>());
const postContact = createAction('[Contact API] Post contact', props<{ contact: ContactModel }>());
export const postContactSuccess = createAction('[Contact API] Post contact success', props<{ payload: any }>());
export const postContactFailure = createAction('[Contact API] Post contact failure', props<{ payload: any }>());

const deleteContact = createAction('[Contact List] Delete contact', props<{ contact: ContactModel }>());
const deleteContactSuccess = createAction('[Contact List] Delete contact success', props<{ contactId: number }>());
const deleteContactFailure = createAction('[Contact List] Delete contact faliure', props<{ error: string }>());

export const contactPageActions = {
  
}

export const contactApiActions = {

  LoadContacts,
  LoadContactsSuccess,
  LoadContactsError: createAction('[Contact API] Load contacts error', props<{ error: string }>()),

  postContact,
  saveContactSuccess: createAction('[Contact API] Save contact success', props<{ contact: ContactModel; id: number }>()),
  saveContactError: createAction('[Contact API] Save contact error', props<{ error: string }>()),
  deleteContact,
  deleteContactSuccess,
  deleteContactFailure,
};
