import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserSelectors } from '@mentor-one-ui/core/state/user/user.selector';

@Injectable()
export class CurrentOrganizationInterceptor implements HttpInterceptor {
  constructor(private store: Store) { 
    this.store.select(UserSelectors.selectCurrentOrganizationId).subscribe((organizationId) => {
        this.organizationId = organizationId;
    });
  }

  private organizationId: number | undefined;

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {

    const myReq = req.clone({
        headers: req.headers.set('x-organization-id', this.organizationId?.toString() ?? '')
    });

    return next.handle(myReq);
  }
}