import { MbscModule } from '@mobiscroll/angular';
import { TranslationActions } from 'src/app/core/state/translations/translation.actions';
import { TranslationSelectors } from 'src/app/core/state/translations/translation.selectors';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { ButtonModule } from 'primeng/button';
import { TagModule } from 'primeng/tag';
import { MainHeaderModule } from '@mentor-one-ui/core/components/main-header/main-header.module';
import { MainFooterModule } from '@mentor-one-ui/core/components/main-footer/main-footer.module';
import { MainNavigationModule } from '@mentor-one-ui/core/components/main-navigation/main-navigation.module';
import { Store, StoreModule } from '@ngrx/store';
import { ROOT_REDUCERS, metaReducers } from 'src/app/core/state';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { ApplicationEffects } from 'src/app/core/state/application/application.effects';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { UserEffects } from 'src/app/core/state/user/user.effects';
import { TranslationEffects } from 'src/app/core/state/translations/translation.effects';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, registerLocaleData } from '@angular/common';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { ErrorIntercept } from './core/interceptors/error.interceptor';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessageService, ConfirmationService } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { filter } from 'rxjs';
import { ContactEffects } from './user-organization/components/contacts/state/contacts.effects';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { DepartmentsEffects } from './user-organization/components/departments/state/departments.effects';
import { OrganizationDetailsEffects } from './user-organization/components/about/state/organization-details.effects';
import { DirectivesModule } from './core/directives/directives.module';
import { EmployeesEffects } from './employees/state/effects/employees.effect';
import { LOCALE_ID } from '@angular/core';
import localeNo from '@angular/common/locales/no';
import { AuthConfigModule } from './auth/auth-config.module';
import { AuthInterceptor, AuthModule } from 'angular-auth-oidc-client';
import { AuthEffects } from './core/state/auth/auth.effects';
import { EmployeesListEffects } from './employees/state/effects/employees-list.effects';
import { SystemMessageListComponent } from './core/components/system-message-list/system-message-list.component';
import { FrontpageEffects } from './core/state/frontpage/frontpage.effects';
import { TimeEffects } from './time/state/time.effects';
import { GlobalErrorHandler } from '@mentor-one-ui/core/services/global-error.service';
import { ErrorMessageComponent } from './core/components/error-message/error-message.component';
import { TopNavigationModule } from './core/components/top-navigation/top-navigation.module';
import { CurrentOrganizationInterceptor } from '@mentor-one-ui/core/interceptors/current-organization.interceptor';
import { AbsenceEffects } from './time/my-time/state/effects/absence.effects';
import { OvertimeEffects } from './time/my-time/state/effects/overtime.effects';
import { ReportEffects } from './core/state/report/report.effects';
import { LeaveManagementsEffects } from './time/leave-management/state/effects/leave-management.effects';
import { SettingsEffects } from '@mentor-one-ui/user-organization/components/settings/state/settings.effects';

registerLocaleData(localeNo);

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent],
  imports: [
    //ng
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    // primeng
    ButtonModule,
    CardModule,
    ConfirmDialogModule,
    DynamicDialogModule,
    TagModule,
    ToastModule,
    // mo
    AppRoutingModule,
    DirectivesModule,
    MainHeaderModule,
    MainFooterModule,
    MainNavigationModule,
    TopNavigationModule,
    SystemMessageListComponent,
    ErrorMessageComponent,
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: false,
        // strictStateSerializability: true,
        // strictActionSerializability: true,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
    EffectsModule.forRoot([
      AuthEffects,
      ApplicationEffects,
      FrontpageEffects,
      UserEffects,
      TranslationEffects,
      ContactEffects,
      OrganizationDetailsEffects,
      DepartmentsEffects,
      ContactEffects,
      TranslationEffects,
      EmployeesEffects,
      EmployeesListEffects,
      TimeEffects,
      AbsenceEffects,
      OvertimeEffects,
      ReportEffects,
      LeaveManagementsEffects,
      SettingsEffects,
    ]),
    StoreRouterConnectingModule.forRoot(),
    PipesModule,
    // other
    AuthConfigModule,
  ],
  providers: [
    DialogService,
    MessageService,
    ConfirmationService,
    { provide: LOCALE_ID, useValue: 'no' },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [Store],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CurrentOrganizationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorIntercept,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function appInitializerFactory(store: Store): Function {
  return () =>
    new Promise((resolve) => {
      store
        .select(TranslationSelectors.selectIsLoaded)
        .pipe(filter((languageLoaded) => languageLoaded))
        .subscribe(() => resolve(true));
      store.dispatch(TranslationActions.load());
    });
}
