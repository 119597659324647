import {
  EmployeeContactInformationModel,
  EmployeeModel,
  EmployeeFormalityModel,
  EmployeePersonalDetailModel,
} from '@mentor-one-ui/core/models/employee/EmployeeModel';
import { createAction, props } from '@ngrx/store';
import { EmployeeDetailsModalTypes } from '@mentor-one-ui/core/models/employee/EmployeeDetailsModalTypes';
import { PersonnelManagerModel } from '@mentor-one-ui/core/models/employee/EmploymentModel';
import { BalanceModel } from '@mentor-one-ui/time/my-time/balance/models/balance.model';

export const EmployeeDetailsTabActions = {
  OpenDetailsModal: createAction(
    '[Employee - Details Tab] Contact Details Modal Open',
    props<{ modalType: EmployeeDetailsModalTypes }>()
  ),
};

export const EmployeeFormalityActions = {
  SaveFormality: createAction('[Employee] Save Formality', props<{ formality: EmployeeFormalityModel }>()),
};

export const EmployeePersonaliaActions = {
  SaveEmployeePersonalia: createAction(
    '[Employee] Save Personalia',
    props<{ personalia: EmployeePersonalDetailModel }>()
  ),
};

export const EmployeeContactInformationActions = {
  SaveContactInformation: createAction(
    '[Employee] Save Contact Information',
    props<{ contactInformation: EmployeeContactInformationModel }>()
  ),
};

//
export const EmployeeDetailsApiActions = {
  LoadEmployeeSuccess: createAction('[Employee - Details API] Load success', props<{ employee: EmployeeModel }>()),
  LoadEmployeeError: createAction('[Employee - Details API] Load error', props<{ error: string }>()),
  ActivateUserAccessSuccess: createAction(
    '[Employee - Details API] Activate User Access Success',
    props<{ employeeId: number }>()
  ),
  ActivateUserAccessError: createAction(
    '[Employee - Details API] Activate User Access Error',
    props<{ error: string }>()
  ),
  DeactivateUserAccessSuccess: createAction(
    '[Employee - Details API] Deactivate User Access Success',
    props<{ employeeId: number }>()
  ),
  DeactivateUserAccessError: createAction(
    '[Employee - Details API] Deactivate User Access Error',
    props<{ error: string }>()
  ),
  SaveEmployeeFormalitySuccess: createAction(
    '[Employee - Details API] Save Formality Success',
    props<{ employeeId: number; formality: EmployeeFormalityModel }>()
  ),
  SaveEmployeeFormalityError: createAction('[Employee - Details API] Save Formality Error', props<{ error: string }>()),
  SaveEmployeePersonaliaSuccess: createAction(
    '[Employee - Details API] Save Personalia Success',
    props<{ employeeId: number; personalia: EmployeePersonalDetailModel }>()
  ),
  SaveEmployeePersonaliaError: createAction(
    '[Employee - Details API] Save Personalia Error',
    props<{ error: string }>()
  ),
  SaveEmployeeContactInformationSuccess: createAction(
    '[Employee - Details API] Save Contact Information Success',
    props<{ employeeId: number; contactInformation: EmployeeContactInformationModel }>()
  ),
  SaveEmployeeContactInformationError: createAction(
    '[Employee - Details API] Save Contact Information Error',
    props<{ error: string }>()
  ),
  getEmployeePersonnelManagersSuccess: createAction(
    '[Employee - Details API] Get Employee Personnel Managers Success',
    props<{ personnelManagers: PersonnelManagerModel[] }>()
  ),
  getEmployeePersonnelManagersError: createAction(
    '[Employee - Details API] Get Employee Personnel Managers Error',
    props<{ error: string }>()
  ),
  DeleteMyProfilePicture: createAction(
    '[Employee - Details API] Delete MyProfile picture',
    props<{ employeeId: number }>()
  ),
  DeleteMyProfilePictureSuccess: createAction(
    '[Employee - Details API] Delete MyProfile picture success',
    props<{ employeeId: number }>()
  ),
  DeleteMyProfilePictureError: createAction(
    '[Employee - Details API] Delete MyProfile picture error',
    props<{ error: string }>()
  ),
  getBalanceForSingleEmployeeSuccess: createAction(
    '[Employee - Details API] Get balance for single employee',
    props<{ balance: BalanceModel[] }>()
  ),
  getBalanceForSingleEmployeeError: createAction(
    '[Employee - Details API] Get balance for single employee error',
    props<{ error: string }>()
  ),
};

export const EmployeeModalActions = {
  CancelModalEdit: createAction('[Employee] Modal Cancel Click'),
  CloseDetailsModal: createAction('[Employee] Modal Close'),
  CloseModalRejected: createAction('[Employee] Modal Reject Close'),
  CloseModalConfirmed: createAction('[Employee] Modal Confirm Close'),
  LoadEmployeePictureEditorModal: createAction('[Employee - Details API] Load employee picture editor modal'),
  CloseEmployeePictureEditorModal: createAction('[Employee - Details API] Close employee picture editor modal'),
};
