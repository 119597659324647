export enum SystemEnum {
  MentorAdmin = -1,
  MentorPluss = 0,
  MentorPersonal = 1,
  MentorHms = 2,
  MentorKidplan = 3,
}


export enum GroupOrganizationTypeEnum {
  /// <summary>
  ///     Ikke satt. Reservert for evt fremtidig PBL Admin bruk
  /// </summary>
  NotSet = 0,

  /// <summary>
  ///     Konsern. Kan ha underenheter under seg
  /// </summary>
  GroupOrganization = 1,

  /// <summary>
  ///     Tjenesteleverandør. Kan ha konsern og underenheter under seg
  /// </summary>
  ServiceProvider = 2,

  /// <summary>
  ///     Frittstående organisasjon, eller underenhet under ett konsern eller tjenesteleverandør
  /// </summary>
  Organization = 3
}
