import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DepartmentLightModel } from '@mentor-one-ui/core/models/DepartmentModel';
import { selectJobTitles } from '@mentor-one-ui/core/state/user/user.selector';
import { AbsenceType } from '@mentor-one-ui/time/my-time/absence/models/absence-type.model';
import { OvertimeType } from '@mentor-one-ui/time/my-time/overtime/models/overtime-type.model';
import {
  selectAbsenceTypes,
  selectOvertimeTypes,
} from '@mentor-one-ui/time/state/time-common/selectors/time-common.selectors';
import { departmentSelectors } from '@mentor-one-ui/user-organization/components/departments/state/departments.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectCalendarFilter, selectCalendarFilterCount } from '../../state/selectors';
import { CalendarPageFilterActions } from '../../state/actions/calendar-page.actions';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { TimeManagementStatusEnum } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementStatusEnum';
import { LeaveFilterOptionsModule } from '@mentor-one-ui/time/shared/time-filter-options/time-filter-options.module';
import { TimeFilter } from '@mentor-one-ui/time/shared/time-filter.model';

@Component({
  selector: 'app-calendar-filter',
  standalone: true,
  imports: [CommonModule, LeaveFilterOptionsModule],
  styleUrls: ['./calendar-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <mo-time-filter-options
      [filter]="(filter$ | async)!"
      [departments]="(departments$ | async)!"
      [absenceTypes]="(absenceTypes$ | async)!"
      [overtimeTypes]="(overtimeTypes$ | async)!"
      [positionTitles]="(positionTitles$ | async)!"
      [showStatusFilter]="true"
      [selectedFilterCount]="selectedFilterCount$ | async"
      [showOvertimeTypes]="false"
      [statusTypes]="statusTypes"
      (setFilterStatus)="setFilterStatus($event)"
      (togglePosition)="positionsChanged($event)"
      (toggleDepartment)="departmentsChanged($event)"
      (filterByOvertimeType)="overtimeTypeChanged($event)"
      (filterByAbsenceType)="absenceTypeChanged($event)"
      (closeFilter)="closeFilter()"
      (resetFilter)="resetFilter()"
      (setFilter)="setFilter()"
    >
    </mo-time-filter-options>
  `,
})
export class CalendarFilterComponent {
  filter$: Observable<TimeFilter> = this.store.select(selectCalendarFilter);
  departments$: Observable<DepartmentLightModel[]> = this.store.select(
    departmentSelectors.selectDepartmentsForDropDown
  );
  absenceTypes$: Observable<AbsenceType[]> = this.store.select(selectAbsenceTypes);
  overtimeTypes$: Observable<OvertimeType[]> = this.store.select(selectOvertimeTypes);
  positionTitles$: Observable<string[]> = this.store.select(selectJobTitles);
  selectedFilterCount$: Observable<number> = this.store.select(selectCalendarFilterCount);
  statusTypes: { name: string; value: number }[] = [];

  constructor(private store: Store, private translationService: TranslationDataService) {
    this.statusTypes = [
      { name: this.translationService.translate('all'), value: -1 },
      { name: this.translationService.translate('approved'), value: TimeManagementStatusEnum.Approved },
      { name: this.translationService.translate('unprocessed'), value: TimeManagementStatusEnum.Pending },
    ];
  }

  positionsChanged(position: string) {
    this.store.dispatch(CalendarPageFilterActions.togglePosition({ position: position }));
  }

  departmentsChanged(departmentId: number) {
    this.store.dispatch(CalendarPageFilterActions.toggleDepartment({ departmentId: departmentId }));
  }

  overtimeTypeChanged(overtimeTypeIds: number[]) {
    this.store.dispatch(CalendarPageFilterActions.filterByOvertimeType({ overtimeTypeIds }));
  }

  absenceTypeChanged(absenceTypeIds: number[]) {
    this.store.dispatch(CalendarPageFilterActions.filterByAbsenceType({ absenceTypeIds }));
  }

  setFilterStatus(statuses: number[]) {
    let selectedStatuses = [];

    if (statuses.length === 1 && statuses[0] === -1) {
      selectedStatuses = [-1];
    } else {
      selectedStatuses = statuses;
    }

    this.store.dispatch(CalendarPageFilterActions.setFilterStatus({ statuses: selectedStatuses }));
  }

  closeFilter() {
    this.store.dispatch(CalendarPageFilterActions.closeFilter());
  }

  resetFilter() {
    this.store.dispatch(CalendarPageFilterActions.resetFilter());
  }

  setFilter() {
    this.store.dispatch(CalendarPageFilterActions.setFilter());
  }
}
