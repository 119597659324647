import { Component, ViewEncapsulation } from '@angular/core';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { FrontpageActions } from '@mentor-one-ui/core/state/frontpage/frontpage.actions';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { CalendarPageActions } from '../../state/actions/calendar-page.actions';

@Component({
  selector: 'app-create-new-absence-modal',
  styleUrls: ['./create-new-absence-modal.component.scss'],
  template: `
    <div class="new-absence-content">
      <p>{{'register-on-behalf-of' | translate | async}}</p>
      <button icon="fal fa-users" pButton type="button" routerLink="/time/manage/new" (click)="this.close()" label="{{'my-employees' | translate | async}}" class="p-button-secondary p-button-secondary"></button>
      <button icon="fal fa-user" pButton type="button" (click)="menu.toggle($event)" label="{{'myself' | translate | async}}" class="p-button-secondary p-button-secondary"></button>
      <p-menu appendTo="body" #menu [model]="buttonItems" [popup]="true"></p-menu>
    </div>
      `
})
export class CreateNewAbsenceModalComponent {
  buttonItems: MenuItem[] = [
    { routerLink: '/time/my/absence/new', command: () => this.close(), label: this.translationService.translate('new-absence') },
    { routerLink: '/time/my/overtime/new', command: () => this.close(), label: this.translationService.translate('new-overtime') }
  ];

  constructor(
    private store: Store,
    private translationService: TranslationDataService) {
  }

  close() {
    this.store.dispatch(CalendarPageActions.closeCreateNewAbsenceModal());
    this.store.dispatch(FrontpageActions.CloseRegistrationModal());
  }
}
