import { MbscCalendarEvent, MbscEventcalendarView, MbscResource } from "@mobiscroll/angular";
import { createSelector } from "@ngrx/store";
import { selectCalendarFilter, selectCalendarState, selectCalendarViewType, selectHasLoadError, selectLastNavigationDate } from ".";
import { AbsenceType } from "@mentor-one-ui/time/my-time/absence/models/absence-type.model";
import { selectAbsenceTypes } from "@mentor-one-ui/time/state/time-common/selectors/time-common.selectors";
import { CalendarItemModel } from "../../models/calendar.models";
import { TimeManagementStatusEnum } from "@mentor-one-ui/time/my-time/absence/models/TimeManagementStatusEnum";
import { EmployeeModel } from "@mentor-one-ui/core/models/employee/EmployeeModel";
import { selectAllemployees } from "@mentor-one-ui/employees/state/selectors/employee.selectors";
import { UserSelectors, selectManageableEmployeeIds } from "@mentor-one-ui/core/state/user/user.selector";
import { selectAbsenceVisibleToAllEmployees } from "@mentor-one-ui/user-organization/components/about/state/organization-details.selectors";

export const selectCalendarViewForMobi = createSelector(
    selectCalendarState,
    (state) => {
      return  {
        timeline: {
          type: state.calendarView.type,
          size: state.calendarView.size,
          eventList: state.calendarView.eventList,
          weekNumbers: state.calendarView.weekNumbers,
          resolutionHorizontal: state.calendarView.resolutionHorizontal,
          startTime: '06:00', 
          endTime: '19:00'
        }
      } as MbscEventcalendarView;
    }
  );

  export const selectFilteredEmployees = createSelector(
    selectAllemployees,
    selectCalendarFilter,
    (employees, filter) => {
      let filteredEmployees = employees;
      if(filter.selectedDepartmentIds.length > 0) {
        filteredEmployees = employees.filter((employee: EmployeeModel) => filter.selectedDepartmentIds?.some((filterById: number) => employee.ContactInformation.DepartmentIds?.some(employeeDeptId => employeeDeptId ===  filterById)));
      }
      if(filter.selectedPositions.length > 0) {
        filteredEmployees =  filteredEmployees.filter(e => filter.selectedPositions?.some(pos => e.JobTitle?.split(',').some(p => p.trim().toLowerCase() === pos.trim().toLowerCase())));
      }
      if(filter.employeeSearch.length > 0) {
        filteredEmployees = filteredEmployees.filter((employee: EmployeeModel) => employee.ContactInformation.FirstName.toLowerCase().includes(filter.employeeSearch.toLowerCase()) || employee.ContactInformation.LastName.toLowerCase().includes(filter.employeeSearch.toLowerCase()));
      }

      return filteredEmployees;
    }
  );

  export const selectEmployeesAsMobiResources = createSelector(
    selectFilteredEmployees,
    (employees) => employees
    .sort((a, b) => a.ContactInformation.FirstName.localeCompare(b.ContactInformation.FirstName) || b.ContactInformation.LastName.localeCompare(a.ContactInformation.LastName))
    .map((employee: any): MbscResource => {
      return {
        id: employee.EmployeeId,
        name: employee.ContactInformation.FirstName + ' ' + employee.ContactInformation.LastName,
      };
    })
  );

  export const selectDefaultEventTitle = createSelector(
    selectCalendarState,
    (state) => {
      return state.defaultTitle;
    }
  );

  export const selectCalendarEvents = createSelector(
    selectCalendarState,
    selectCalendarFilter,
    selectAbsenceTypes,
    selectDefaultEventTitle,
    (state, filter, absenceTypes, defaultTitle) => state.calendarEvents
      .filter(event => {
        if(filter.absenceMapIds.length > 0) {
          if(!filter.absenceMapIds.some(id => id === event.OrganizationAbsenceMapId)) {
            return false;
          }
        }
        if(filter.statuses.length > 0 && filter.statuses[0] !== -1) {
          if(!filter.statuses.some(id => id === event.Status)) {
            return false;
          }
        }
        return true;
      })

    .map((m) => convertFromTimeManagementModelToMbscCalendarEvent(m, absenceTypes, defaultTitle))
  );

  function convertFromTimeManagementModelToMbscCalendarEvent(calenderEvent: CalendarItemModel, absenceTypes: AbsenceType[], defaultTitle: string): MbscCalendarEvent {
    let title = defaultTitle;

    if (calenderEvent.OrganizationAbsenceMapId != null) {
      let absenceType = absenceTypes?.find(f => f.OrganizationAbsenceMapId === calenderEvent.OrganizationAbsenceMapId);
      title = `${absenceType?.EmojiCode} ${absenceType?.Title}`;
    }

    let cssClass = '';
    let start = calenderEvent.FromDate;
    let end = calenderEvent.ToDate == null ? calenderEvent.FromDate : calenderEvent.ToDate;

    if (calenderEvent.FromTime != null) { start += 'T' + calenderEvent.FromTime }
    if (calenderEvent.ToTime != null) { end += 'T' + calenderEvent.ToTime; }

    if(calenderEvent.Status == TimeManagementStatusEnum.Approved) { cssClass = 'absence-approved'; }
    if(calenderEvent.Status == TimeManagementStatusEnum.Pending) { cssClass = 'absence-pending'; }

    let event: MbscCalendarEvent = {
      id: calenderEvent.AbsenceId,
      start: start,
      end: end,
      title: title,
      resource: calenderEvent.EmployeeId,
      allDay: calenderEvent.FromTime != null && calenderEvent.ToTime != null ? false : true,
      cssClass: cssClass,
    };

    return event;
  }

  export const selectEmployeesForCalendar = createSelector(
    selectManageableEmployeeIds,
    selectEmployeesAsMobiResources,
    selectAbsenceVisibleToAllEmployees,
    UserSelectors.selectSelectedUserId,
    (manageableEmployeeIds, employees, absenceVisibleToAllEmployees, selectedUserId) => {
      if(absenceVisibleToAllEmployees) {
        return employees;
      }

      let manageEmployeesWithCurrentUser = [...manageableEmployeeIds];
      manageEmployeesWithCurrentUser.push(selectedUserId!);
      return employees.filter(e => manageEmployeesWithCurrentUser?.some(id => id === e.id));
    });

  export const selectCalendarViewModel = createSelector(
    selectCalendarViewForMobi,
    selectCalendarViewType,
    selectCalendarEvents,
    selectLastNavigationDate,
    selectEmployeesForCalendar,
    selectHasLoadError,
    (calendarView, viewType, calendarEvents, lastNavigationDate, employees, loadError) => {
      return {
        calendarView: calendarView,
        viewType: viewType,
        calendarEvents: calendarEvents,
        lastNavigationDate: lastNavigationDate,
        employees: employees,
        loadError: loadError
      }
    });
