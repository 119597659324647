import { selectAllemployees } from '@mentor-one-ui/employees/state/selectors/employee.selectors';
import { SickLeaveState } from '@mentor-one-ui/sick-leave/state';
import { selectSickLeaveState } from '@mentor-one-ui/sick-leave/state/selectors';
import { createSelector } from '@ngrx/store';
import { SickLeaveFollowUpModel } from '../../models/sick-leave-follow-up.model';
import { selectRoutedFollowupId } from '@mentor-one-ui/core/state/router/router.selectors';
import { adapter } from '..';

export const selectFollowUpState = createSelector(selectSickLeaveState, (state: SickLeaveState) => state.followup);

export const {
  selectIds: selectFollowUpIds,
  selectEntities: selectFollowUpEntities,
  selectAll: selectAllCurrentFollowUp,
  selectTotal: selectTotalFollowUp,
} = adapter.getSelectors(selectFollowUpState);

export const selectSickLeaveFollowUpLoaded = createSelector(selectFollowUpState, (state) => state.followUpListLoaded);
export const selectSickLeavePastFollowUpLoaded = createSelector(
  selectFollowUpState,
  (state) => state.followUpListPastLoaded
);

export const sickLeaveFollowUpError = createSelector(selectFollowUpState, (state) => state.followUpError);
export const selectSickLeaveFollowUpListType = createSelector(selectFollowUpState, (state) => state.listType);
export const selectSickLeaveFollowUpFilter = createSelector(selectFollowUpState, (state) => state.followUpFilter);
export const selectSickLeaveFollowUpCurrentPageCount = createSelector(
  selectFollowUpState,
  (state) => state.followUpCurrentPageCount
);

export const selectCurrentFollowUps = createSelector(
  selectAllCurrentFollowUp,
  selectAllemployees,
  (followUps, employees) =>
    followUps
      .filter((x: SickLeaveFollowUpModel) => !x.IsCompleted)
      .map((followUp: SickLeaveFollowUpModel) => {
        let employee = employees.find((e) => e.EmployeeId === followUp.EmployeeId);
        return {
          ...followUp,
          EmployeeName: `${employee?.ContactInformation?.FirstName} ${employee?.ContactInformation?.LastName}`,
        };
      })
);

export const selectFilteredCurrentFollowUps = createSelector(
  selectCurrentFollowUps,
  selectSickLeaveFollowUpFilter,
  (allFollowups, filter) => {
    return allFollowups.filter((x: SickLeaveFollowUpModel) => {
      let match = true;
      if (filter.EmployeeId && filter.EmployeeId > -1) {
        match = x.EmployeeId == filter.EmployeeId;
      }

      if (filter.FollowUpLength && filter.FollowUpLength > -1) {
        match = match && x.Length == filter.FollowUpLength;
      }

      return match;
    });
  }
);

export const selectFilteredSickLeaveFollowUpsCurrent = createSelector(
  selectFilteredCurrentFollowUps,
  selectAllemployees,
  (allFollowups, employees) => {
    let followUps = allFollowups
      .filter((x: SickLeaveFollowUpModel) => !x.IsCompleted)
      .map((followUp: SickLeaveFollowUpModel) => {
        let employee = employees.find((e) => e.EmployeeId === followUp.EmployeeId);
        return {
          ...followUp,
          EmployeeName: `${employee?.ContactInformation?.FirstName} ${employee?.ContactInformation?.LastName}`,
        };
      });

    return followUps;
  }
);

export const selectSickLeaveFollowUpErrorCode = createSelector(selectFollowUpState, (state) => state.followUpErrorCode);

export const selectSelectedFollowUpId = createSelector(selectFollowUpState, (state) => state.selectedFollowUpId);

export const selectSickLeaveFollowUpErrorMessage = createSelector(
  selectFollowUpState,
  (state) => state.followUpErrorMessage
);

export const selectSickLeavePastFollowUps = createSelector(
  selectFollowUpState,
  selectSickLeaveFollowUpFilter,
  selectAllemployees,
  (state, filter, employees) => {
    let followUps = state.followUpListPast.map((followUp: SickLeaveFollowUpModel) => {
      let employee = employees.find((e) => e.EmployeeId === followUp.EmployeeId);
      return {
        ...followUp,
        EmployeeName: `${employee?.ContactInformation?.FirstName} ${employee?.ContactInformation?.LastName}`,
      };
    });

    return followUps;
  }
);

export const selectSelectedFollowUpForAbsence = createSelector(
  selectSickLeavePastFollowUps,
  selectCurrentFollowUps,
  selectSelectedFollowUpId,
  (allPastFollowUps, allCurrentFollowUps, selectedFollowUpId) => {
    if (selectedFollowUpId == null) return null;
    let isPastFollowUp = allPastFollowUps.some(
      (followUp: SickLeaveFollowUpModel) => followUp.SickLeaveFollowUpId === +selectedFollowUpId!
    );

    if (isPastFollowUp) {
      return allPastFollowUps.find(
        (followUp: SickLeaveFollowUpModel) => followUp.SickLeaveFollowUpId === +selectedFollowUpId!
      );
    } else {
      return allCurrentFollowUps.find(
        (followUp: SickLeaveFollowUpModel) => followUp.SickLeaveFollowUpId === +selectedFollowUpId!
      );
    }
  }
);

export const selectSelectedFollowUp = createSelector(
  selectSickLeavePastFollowUps,
  selectCurrentFollowUps,
  selectRoutedFollowupId,
  (allPastFollowUps, allCurrentFollowUps, routedFollowUpId) => {

    let followUp = allCurrentFollowUps.find(
      (followUp: SickLeaveFollowUpModel) => followUp.SickLeaveFollowUpId === +routedFollowUpId!
    );
    if (followUp) {
      return followUp;
    }

    followUp = allPastFollowUps.find(
        (followUp: SickLeaveFollowUpModel) => followUp.SickLeaveFollowUpId === +routedFollowUpId!
      );
    if (followUp) {
      return followUp;
    }
  
    return null;
  }
);

export const selectSelectedFollowUpIsCompleted = createSelector(selectSelectedFollowUp, (allPastFollowUps) => {
  return allPastFollowUps?.IsCompleted == true;
});

export const selectTotalFollowUpCount = createSelector(selectFollowUpState, (state) => state.followUpPastTotalCount);

export const selectSickLeavePastFollowUpsViewModel = createSelector(
  selectFollowUpState,
  selectSickLeavePastFollowUps,
  (state, followUps) => {
    return {
      followUps: followUps,
      totalFollowUps: state.followUpPastTotalCount,
    };
  }
);

export const selectFollowUpFileIdBeingDeleted = createSelector(selectFollowUpState, (state) => state?.idBeingDeleted);

export const selectIsDownloadingNote = (noteId: number) =>
  createSelector(selectFollowUpState, (state) => state.downloadingNote.some((n) => n == noteId));

export const selectIsDownloadingFollowUp = createSelector(selectFollowUpState, (state) => state.downloadingFollowUp);
export const selectTimelinePanelOpen = createSelector(selectFollowUpState, (state) => state.timelinePanelOpen);

export const selectFollowUpFileIsSaving = createSelector(selectFollowUpState, (state) => state.isSavingFiles);
export const selectNoteIsSaving = createSelector(selectFollowUpState, (state) => state.isSavingNote);
export const selectFilesForUpload = createSelector(selectFollowUpState, (state) => state.filesForUpload);

export const selectFollowupItemsStatustBeeingUpdated = createSelector(
  selectFollowUpState,
  (state) => state.followUpItemsStatusBeingUpdated
);
