import { createReducer, on } from '@ngrx/store';
import { EmployeeChildrenApiActions, EmployeeChildrenTabActions } from '../actions/employee-children.actions';
import { EmployeePageActions } from '../actions/employee-page.actions';
import { EmployeeModalActions } from '../actions/employee-details.actions';

export const featureKey = 'children';

export interface State {
  selectedChildId: number;
  isSaving: boolean;
  childrenLoaded: boolean;
  error: boolean;
}

export const initialState: State = {
  selectedChildId: -1,
  isSaving: false,
  childrenLoaded: false,
  error: false,
};

export const reducer = createReducer(
  initialState,
  on(EmployeePageActions.Enter, (state) => initialState),
  on(EmployeeChildrenApiActions.LoadChildListSuccess, (state): State => {
    return {
      ...state,
      childrenLoaded: true,
      error: false,
    };
  }),
  on(EmployeeChildrenApiActions.LoadChildListError, (state): State => {
    return {
      ...state,
      error: true,
    };
  }),
  on(EmployeeChildrenTabActions.OpenChildModal, (state, { childId }): State => {
    return {
      ...state,
      selectedChildId: childId,
    };
  }),
  on(EmployeeModalActions.CloseDetailsModal, (state): State => {
    return {
      ...state,
      selectedChildId: -1
    };
  }),
  on(EmployeeChildrenTabActions.SaveChild, (state): State => {
    return {
      ...state,
      isSaving: true,
    };
  }),
  on(EmployeeChildrenApiActions.SaveChildSuccess, (state): State => {
    return {
      ...state,
      isSaving: false,
    };
  }),
  on(EmployeeChildrenApiActions.SaveChildError, (state): State => {
    return {
      ...state,
      isSaving: false,
    };
  })
);
