import { selectRoutedAbsenceId } from '@mentor-one-ui/core/state/router/router.selectors';
import { createSelector } from '@ngrx/store';
import { adapter } from '../../state/absence.reducer';
import { selectAbsenceTypes } from '@mentor-one-ui/time/state/time-common/selectors/time-common.selectors';
import { selectMyTimeState } from '../../state/selectors';
import * as fromMyTime from '../../state';
import { selectBalance } from '../../balance/state/balance.selectors';

export const selectAbsenceState = createSelector(selectMyTimeState, (state: fromMyTime.State) => state?.absence);

export const {
  selectIds: selectAbsenceIds,
  selectEntities: selectAbsenceEntities,
  selectAll: selectAllAbsences,
  selectTotal: selectTotalAbsences,
} = adapter.getSelectors(selectAbsenceState);

export const selectSelectedAbsence = createSelector(selectRoutedAbsenceId, selectAllAbsences, (absenceId, absences) => {
  if (!absenceId) return null;
  return absences?.find((absence) => absence.AbsenceId === +absenceId) ?? null;
});

export const selectedRoutedAbsenceViewModel = createSelector(
  selectSelectedAbsence,
  selectAbsenceTypes,
  selectBalance,
  (absence, absenceTypes, balance) => {
    if (!absence) return null;
    return {
      absence,
      absenceType:
        absenceTypes?.find(
          (absenceType) => absenceType.OrganizationAbsenceMapId === absence.OrganizationAbsenceMapId
        ) ?? null,
      balance,
    };
  }
);

export const selectIsSaving = createSelector(selectAbsenceState, (state) => state?.isSaving ?? false);

export const selectTotalAbsenceCount = createSelector(selectAbsenceState, (state) => state?.totalItems);

export const selectPageSize = createSelector(selectAbsenceState, (state) => state?.pageSize);

export const selectPage = createSelector(selectAbsenceState, (state) => state?.page);

export const selectFilterByAbsenceType = createSelector(selectAbsenceState, (state) => state?.filteredAbsenceTypes);

export const selectFilterByStatus = createSelector(selectAbsenceState, (state) => state?.filterByStatus);

const selectSelectedAbsenceTypeId = createSelector(selectAbsenceState, (state) => state?.selectedAbsenceTypeId);

export const selectAbsenceValidationResult = createSelector(selectAbsenceState, (state) => state?.validationResult);

export const selectSelectedAbsenceType = createSelector(
  selectAbsenceTypes,
  selectSelectedAbsenceTypeId,
  (absenceTypes, organizationAbsenceMapId) => {
    if (!organizationAbsenceMapId) return null;
    return (
      absenceTypes?.find((absenceType) => absenceType.OrganizationAbsenceMapId === organizationAbsenceMapId) ?? null
    );
  }
);

export const selectHasAbsenceFormChanges = createSelector(
  selectAbsenceState,
  (state) => state?.hasFormChanges ?? false
);

export const selectAbsencesLoaded = createSelector(selectAbsenceState, (state) => state?.absencesLoaded ?? false);

export const selectAbsenceLoadError = createSelector(selectAbsenceState, (state) => state?.absenceLoadError ?? false);
