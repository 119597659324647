import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserOrganizationModel } from '@mentor-one-ui/core/models/user-organization/user-organization.model';
import { selectIsDarkMode } from '@mentor-one-ui/core/state/application/application.selectors';
import {
  locale,
  MbscCalendarColor,
  MbscCalendarEvent,
  MbscEventcalendarOptions,
  setOptions,
} from '@mobiscroll/angular';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CalendarPageActions, CalendarPageFilterActions } from './state/actions/calendar-page.actions';
import {
  selectCalendarFilterCount,
  selectHolidays,
  selectIsSaving,
  selectSelectedCalendarEvent,
  selectShowCalendarEventDetails,
} from './state/selectors';
import { DateOnlyConverter } from '@mentor-one-ui/core/misc/dateonly.converter';
import { OverlayPanel } from 'primeng/overlaypanel';
import { CalendarItemModel } from './models/calendar.models';
import { ButtonAction, ButtonBarButton } from '@mentor-one-ui/core/models/ButtonBar';
import { TimeManagementType } from '../my-time/absence/models/TimeManagementType';
import { TimeManagementModel } from '../my-time/absence/models/TimeManagementModel';
import { MediaQuery } from '@mentor-one-ui/core/services/media-queries';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import {
  AccessSelector,
  selectIsPersonnelManager,
  selectIsAdministratorPersonal,
} from '@mentor-one-ui/core/state/access/access.selector';
import { EmployeeListFilter } from '@mentor-one-ui/employees/state/reducers/employees.reducer';
import { EmployeeListSelectors } from '@mentor-one-ui/employees/state/selectors/employee-list.selectors';
import { CalendarApiActions } from './state/actions/calendar-api.actions';
import { selectManageableEmployeeIds, UserSelectors } from '@mentor-one-ui/core/state/user/user.selector';
import { selectCalendarViewModel } from './state/selectors/mobi-calendar.selectors';
import { Router } from '@angular/router';
import { TimeManagementStatusEnum } from '../my-time/absence/models/TimeManagementStatusEnum';
import { selectAbsenceVisibleToAllEmployees } from '@mentor-one-ui/user-organization/components/about/state/organization-details.selectors';

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

@Component({
  selector: 'app-absence',
  styleUrls: ['./calendar-page.component.scss'],
  template: `
    <section class="calendar-page panel">
      <header class="page-header">
        <h1>{{ 'calendar' | translate | async }}</h1>
        <button
          (click)="showFullscreen = true"
          pButton
          class="p-button p-button-secondary"
          icon="pi pi-window-maximize"
        ></button>
        <p-menu #menu [model]="buttonItems" [popup]="true"></p-menu>
        <button
          *ngIf="!isAdmin()"
          pButton
          type="button"
          class="add-button p-button-secondary p-button-secondary"
          (click)="menu.toggle($event)"
          icon="pi pi-plus"
          label="{{ 'new-registration' | translate | async }}"
        ></button>
        <button
          *ngIf="isAdmin()"
          pButton
          type="button"
          class="add-button p-button-secondary p-button-secondary"
          (click)="showNewAbsenceModal()"
          icon="pi pi-plus"
          label="{{ 'new-registration' | translate | async }}"
        ></button>
      </header>
      <div class="calendar-page__filter" *ngIf="(selectAbsenceVisibleToAllEmployees$ | async) || isAdmin()">
        <div class="calendar-page__filter__inner" *ngrxLet="filter$ | async as filter">
          <span class="p-input-icon-right ml-auto">
            <i class="pi pi-search"></i>
            <input
              #searchInput
              pInputText
              type="text"
              [ngModel]="filter?.search"
              class="{{ filter?.search?.length! > 0 ? 'active-filter' : '' }}"
              (input)="search($event)"
              placeholder="{{ 'find-person' | translate | async }}"
            />
          </span>
          <button
            pButton
            (click)="openFilterModal()"
            type="button"
            class="p-button-secondary {{ (selectCalendarFilterCount$ | async)! > 0 ? 'active-filter' : '' }}"
            icon="fal fa-filter"
            label="{{ 'filter' | translate | async }}  ({{ selectCalendarFilterCount$ | async }})"
          ></button>
          <ng-container *ngrxLet="selectCalendarFilterCount$ | async as selectedFilterCount">
            <button
              *ngIf="(selectedFilterCount && selectedFilterCount > 0) || searchInput.value.length > 0"
              pButton
              (click)="clearFilter(); searchInput.value = ''"
              class="p-button-secondary"
              label="{{ 'clearfilter' | translate | async }}"
            ></button>
          </ng-container>
        </div>
      </div>
      <div>
        <ng-container *ngIf="calendarVm$ | async as vm; else loading">
          <ng-container *ngIf="!vm?.loadError; else error">
            <div class="calendar-page__component">
              <mbsc-eventcalendar
                tabindex="-1"
                #pageCalendar
                [headerTemplate]="myHeaderTemplate"
                [clickToCreate]="false"
                [view]="vm.calendarView"
                [options]="calendarOptions"
                [data]="vm.calendarEvents"
                [locale]="localeAll[localeStr]"
                [themeVariant]="themeMode"
                [class]="showFullscreen ? 'fullscreen' : ''"
                [resources]="vm.employees"
              >
                <ng-template #myHeaderTemplate>
                  <ng-container>
                    <mbsc-calendar-nav class="md-event-listing-nav"></mbsc-calendar-nav>
                  </ng-container>
                  <div class="md-event-listing-picker">
                    <mbsc-segmented-group [ngModel]="vm.viewType" (valueChange)="changeView($event)">
                      <mbsc-segmented value="day">{{ 'day' | translate | async }}</mbsc-segmented>
                      <mbsc-segmented value="week">{{ 'week' | translate | async }}</mbsc-segmented>
                      <mbsc-segmented value="month">{{ 'month' | translate | async }}</mbsc-segmented>
                    </mbsc-segmented-group>
                  </div>
                  <ng-container *ngIf="vm.lastNavigationDate; else calendarNav">
                    <mbsc-calendar-prev class="md-event-listing-prev"></mbsc-calendar-prev>
                    <mbsc-calendar-today class="md-event-listing-today"></mbsc-calendar-today>
                    <mbsc-calendar-next class="md-event-listing-next"></mbsc-calendar-next>
                  </ng-container>
                </ng-template>
              </mbsc-eventcalendar>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #error>
          <mo-error-message> </mo-error-message>
        </ng-template>
        <ng-template #loading>
          <p-skeleton width="100%" height="3rem"></p-skeleton>
        </ng-template>
        <ng-template #selectedDate>
          <p-skeleton width="8rem" height="3rem"></p-skeleton>
        </ng-template>
        <ng-template #calendarNav>
          <p-skeleton width="8rem" height="3rem"></p-skeleton>
        </ng-template>
        <ng-container *ngIf="!isMobile() && (selectedCalenderEvent$ | async) as selectedCalenderEvent">
          <p-overlayPanel #panel (onHide)="closeOverlayPanel()">
            <ng-template pTemplate="content">
              <div class="eventdetail-overlay-content">
                <div class="justify-sides">
                  <span>{{ selectedCalenderEvent?.EmployeeId! | employeeFullNameFromId | async }}</span>
                  <mo-absence-status-tag [status]="selectedCalenderEvent?.Status!"></mo-absence-status-tag>
                </div>

                <p>
                  <mo-timespan-element
                    [absence]="selectedCalenderEvent!"
                    [showCalculatedHours]="true"
                  ></mo-timespan-element>
                </p>
                <ng-container
                  *ngIf="
                    (selectedCalenderEvent?.Status == TimeManagementStatusEnum.Pending &&
                      selectedCalenderEvent?.HasValidationErrors) == null;
                    else showButtons
                  "
                >
                  <p-progressSpinner class="spinner"></p-progressSpinner>
                </ng-container>
                <ng-template #showButtons>
                  <div class="absence-type">
                    <div>
                      <mo-emoji [absenceId]="selectedCalenderEvent?.OrganizationAbsenceMapId!"></mo-emoji>
                      <span>{{ selectedCalenderEvent?.OrganizationAbsenceMapId! | absenceTypeName }}</span>
                    </div>
                    <span class="absence-icons">
                      <i
                        class="fal fa-comment"
                        *ngIf="selectedCalenderEvent?.Comment?.length! > 0"
                        [pTooltip]="selectedCalenderEvent?.Comment!"
                      ></i>
                      <i
                        class="fal fa-exclamation-triangle warning"
                        *ngIf="selectedCalenderEvent?.HasValidationErrors"
                        pTooltip="{{ 'absence-has-warning' | translate | async }}"
                      ></i>
                    </span>
                  </div>
                  <mo-button-bar
                    class="absence-details-page__button-bar"
                    (buttonBarClick)="handleButtonClick($event, selectedCalenderEvent)"
                    [isSaving]="(isSaving$ | async)!"
                    [buttonList]="popupButtons!"
                  >
                  </mo-button-bar>
                </ng-template>
              </div>
            </ng-template>
          </p-overlayPanel>
        </ng-container>
        <mo-event-details-mobile
          *ngIf="isMobile() && (selectedCalenderEvent$ | async) as selectedCalenderEvent"
          [selectedCalenderEvent]="selectedCalenderEvent"
          [showCalendarEventDetails]="(showCalendarEventDetails$ | async)!"
          [isSaving]="(isSaving$ | async)!"
          [buttons]="popupButtons"
          (handleButtonClick)="handleButtonClick($event, selectedCalenderEvent)"
          (closePanel)="closePanel()"
        ></mo-event-details-mobile>
      </div>
    </section>
    <p-sidebar [(visible)]="showFullscreen" [fullScreen]="true">
      <ng-container *ngIf="calendarVm$ | async as vm; else loading">
        <ng-container *ngIf="!vm?.loadError; else error">
          <div class="calendar-page__component">
            <mbsc-eventcalendar
              [headerTemplate]="myHeaderTemplate"
              [clickToCreate]="false"
              [view]="vm.calendarView"
              [options]="calendarOptions"
              [data]="vm.calendarEvents"
              [locale]="localeAll[localeStr]"
              [themeVariant]="themeMode"
              class="fullscreen"
              [resources]="vm.employees"
            >
              <ng-template #myHeaderTemplate>
                <ng-container>
                  <mbsc-calendar-nav class="md-event-listing-nav"></mbsc-calendar-nav>
                </ng-container>
                <div class="md-event-listing-picker">
                  <mbsc-segmented-group [ngModel]="vm.viewType" (valueChange)="changeView($event)">
                    <mbsc-segmented value="day">{{ 'day' | translate | async }}</mbsc-segmented>
                    <mbsc-segmented value="week">{{ 'week' | translate | async }}</mbsc-segmented>
                    <mbsc-segmented value="month">{{ 'month' | translate | async }}</mbsc-segmented>
                  </mbsc-segmented-group>
                </div>
                <ng-container *ngIf="vm.lastNavigationDate; else calendarNav">
                  <mbsc-calendar-prev class="md-event-listing-prev"></mbsc-calendar-prev>
                  <mbsc-calendar-today class="md-event-listing-today"></mbsc-calendar-today>
                  <mbsc-calendar-next class="md-event-listing-next"></mbsc-calendar-next>
                </ng-container>
              </ng-template>
            </mbsc-eventcalendar>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #error>
        <mo-error-message> </mo-error-message>
      </ng-template>
      <ng-template #loading>
        <p-skeleton width="100%" height="3rem"></p-skeleton>
      </ng-template>
      <ng-template #selectedDate>
        <p-skeleton width="8rem" height="3rem"></p-skeleton>
      </ng-template>
      <ng-template #calendarNav>
        <p-skeleton width="8rem" height="3rem"></p-skeleton>
      </ng-template>
      <ng-container *ngIf="!isMobile() && (selectedCalenderEvent$ | async) as selectedCalenderEvent">
        <p-overlayPanel #panel (onHide)="closeOverlayPanel()">
          <ng-template pTemplate="content">
            <div class="eventdetail-overlay-content">
              <div class="justify-sides">
                <span>{{ selectedCalenderEvent?.EmployeeId! | employeeFullNameFromId | async }}</span>
                <mo-absence-status-tag [status]="selectedCalenderEvent?.Status!"></mo-absence-status-tag>
              </div>
              <p>
                <mo-timespan-element
                  [absence]="selectedCalenderEvent!"
                  [showCalculatedHours]="true"
                ></mo-timespan-element>
              </p>
              <ng-container
                *ngIf="
                  (selectedCalenderEvent?.Status == TimeManagementStatusEnum.Pending &&
                    selectedCalenderEvent?.HasValidationErrors) == null;
                  else showButtons
                "
              >
                <p-progressSpinner class="spinner"></p-progressSpinner>
              </ng-container>
              <ng-template #showButtons>
                <div class="absence-type">
                  <mo-emoji [absenceId]="selectedCalenderEvent?.OrganizationAbsenceMapId!"></mo-emoji>
                  <span>{{ selectedCalenderEvent?.OrganizationAbsenceMapId! | absenceTypeName }}</span>
                  <i
                    class="fal fa-comment"
                    *ngIf="selectedCalenderEvent?.Comment?.length! > 0"
                    [pTooltip]="selectedCalenderEvent?.Comment!"
                  ></i>
                </div>
                <mo-button-bar
                  class="absence-details-page__button-bar"
                  (buttonBarClick)="handleButtonClick($event, selectedCalenderEvent)"
                  [isSaving]="(isSaving$ | async)!"
                  [buttonList]="popupButtons!"
                >
                </mo-button-bar>
              </ng-template>
            </div>
          </ng-template>
        </p-overlayPanel>
      </ng-container>
      <mo-event-details-mobile
        *ngIf="isMobile() && (selectedCalenderEvent$ | async) as selectedCalenderEvent"
        [selectedCalenderEvent]="selectedCalenderEvent"
        [showCalendarEventDetails]="(showCalendarEventDetails$ | async)!"
        [isSaving]="(isSaving$ | async)!"
        [buttons]="popupButtons"
        (handleButtonClick)="handleButtonClick($event, selectedCalenderEvent)"
        (closePanel)="closePanel()"
      ></mo-event-details-mobile>
    </p-sidebar>
    <p-confirmPopup key="approveAbsencePopupCalendar"></p-confirmPopup>
  `,
})
export class CalendarViewComponent implements OnInit {
  @ViewChild('panel') panel: OverlayPanel;
  @ViewChild('pageCalendar') pageCalendar: ElementRef;
  calendarVm$ = this.store.select(selectCalendarViewModel);
  filter$: Observable<EmployeeListFilter> = this.store.select(EmployeeListSelectors.selectFilter);
  selectCalendarFilterCount$: Observable<number> = this.store.select(selectCalendarFilterCount);
  selectedCalenderEvent$: Observable<CalendarItemModel | undefined> = this.store
    .select(selectSelectedCalendarEvent)
    .pipe(
      tap((s) => {
        this.selectedEvent = s;
        if (this.selectedEvent?.HasValidationErrors) {
          this.popupButtons = this.popupButtons.map((m) => {
            if (m.id === 'approveButton') {
              m.icon += ' fal fa-exclamation-triangle';
              m.class += ' p-button-warning';
            }
            return m;
          });
        }
      })
    );
  isSaving$: Observable<boolean | null> = this.store.select(selectIsSaving);
  orgModelImage$: Observable<string | null>;
  showCalendarEventDetails$: Observable<boolean> = this.store.select(selectShowCalendarEventDetails);
  selectAbsenceVisibleToAllEmployees$: Observable<boolean | undefined> = this.store.select(
    selectAbsenceVisibleToAllEmployees
  );

  TimeManagementStatusEnum = TimeManagementStatusEnum;
  inputOrgModel: UserOrganizationModel;
  events: MbscCalendarEvent[];
  popupButtons: ButtonBarButton[];
  selectedEvent: CalendarItemModel | undefined;
  themeMode: 'light' | 'dark';
  buttonItems: MenuItem[] = [
    { routerLink: '/time/my/absence/new', label: this.translationService.translate('new-absence') },
    { routerLink: '/time/my/overtime/new', label: this.translationService.translate('new-overtime') },
  ];

  toggleOverlay = ({ originalEvent }: { originalEvent: any }) => {
    setTimeout(() => {
      this.panel.toggle(originalEvent);
    }, 100);
  };

  calendarOptions: MbscEventcalendarOptions = {
    dragToCreate: false,
    dragToResize: false,
    dragToMove: false,
    clickToCreate: true,
    onSelectedDateChange: (args: any) => {
      this.store.dispatch(
        CalendarPageActions.dateChanged({ date: DateOnlyConverter.convertJsDateToDateString(args.date) })
      );
    },
    onEventClick: (args: any) => {
      if (!this.isAdmin()) {
        return;
      }

      if (this.isPersonellManager) {
        let employeeId = args.event.resource;
        if (this.manageableEmployeeIds?.find((empId) => empId === employeeId) === undefined) {
          return;
        }
      }

      if (!this.isMobile()) {
        this.toggleOverlay({ originalEvent: args.domEvent });
      }
      this.store.dispatch(CalendarPageActions.calendarEventClicked({ eventId: args.event.id }));
      this.createButtonList();
    },
    colors: [
      {
        start: '00:00',
        end: '23:59',
        recurring: {
          repeat: 'weekly',
          weekDays: 'SA,SU',
        },
        cssClass: 'md-weekend-bg',
      },
    ],
  };
  public ngDestroyed$ = new Subject();
  hasMentorAdminAccess: boolean;
  isPersonellManager: boolean | undefined;
  isAdministratorPersonal: boolean | undefined;
  manageableEmployeeIds: number[] | undefined;
  localeStr = 'no';
  localeAll = locale;
  showFullscreen: boolean = false;
  copyCalendar: any;

  constructor(
    private store: Store,
    private translationService: TranslationDataService,
    private router: Router,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(CalendarPageActions.Enter());

    combineLatest([
      this.store.select(AccessSelector.selectIsSystemAdmin),
      this.store.select(selectIsPersonnelManager),
      this.store.select(selectIsAdministratorPersonal),
      this.store.select(selectManageableEmployeeIds),
    ])
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe(([hasAdminAccess, isPersonellManager, isAdministratorPersonal, manageableEmployeeIds]) => {
        this.hasMentorAdminAccess = hasAdminAccess;
        this.isPersonellManager = isPersonellManager;
        this.isAdministratorPersonal = isAdministratorPersonal;
        this.manageableEmployeeIds = manageableEmployeeIds;
      });

    this.store
      .select(selectHolidays)
      .pipe(
        takeUntil(this.ngDestroyed$),
        tap((h: any) => {
          let holidays: MbscCalendarColor[] = [];

          holidays = h.map((holiday: any) => {
            return {
              date: new Date(holiday.Date),
              title: holiday.LocalName,
              cssClass: 'md-holiday-bg',
              allDay: true,
            };
          });

          if (holidays.length > 0) {
            holidays.forEach((element) => {
              let exists = this.calendarOptions.colors?.find((c: any) => c.title === element.title);

              if (!exists) {
                this.calendarOptions.colors?.push(element);
              }
            });
          }
        })
      )
      .subscribe();

    this.store
      .select(selectIsDarkMode)
      .pipe(
        takeUntil(this.ngDestroyed$),
        tap((d: any) => {
          if (d) {
            this.themeMode = 'dark';
          } else {
            this.themeMode = 'light';
          }
        })
      )
      .subscribe();

    this.store.select(UserSelectors.selectUserCultureCode).subscribe((code) => {
      switch (code) {
        case 'en-US':
          this.localeStr = 'en';
          break;
        case 'nb-NO':
          this.localeStr = 'no';
          break;
        default:
          this.localeStr = 'no';
          break;
      }
    });
  }

  clearFilter() {
    this.store.dispatch(CalendarPageFilterActions.SearchEmployees({ search: '' }));
    this.store.dispatch(CalendarPageFilterActions.resetFilter());
  }

  search(event: any) {
    this.store.dispatch(CalendarPageFilterActions.SearchEmployees({ search: event.target.value }));
  }

  closePanel() {
    this.store.dispatch(CalendarPageActions.calendarEventHide());
  }

  closeOverlayPanel() {
    this.pageCalendar.nativeElement.focus();
    this.store.dispatch(CalendarPageActions.calendarEventHide());
  }

  isAdmin() {
    return this.hasMentorAdminAccess || this.isAdministratorPersonal || this.isPersonellManager;
  }

  ngOnDestroy(): void {
    this.ngDestroyed$.next(null);
    this.ngDestroyed$.complete();
  }

  showNewAbsenceModal() {
    this.store.dispatch(CalendarPageActions.openCreateNewAbsenceModal());
  }

  openFilterModal() {
    this.store.dispatch(CalendarPageActions.OpenFilterDialog());
  }

  changeView(view: any) {
    this.store.dispatch(CalendarPageActions.setCalendarView({ calendarView: view }));
  }

  isMobile() {
    return MediaQuery.screenSize === 'small' || MediaQuery.screenSize === 'xsmall';
  }

  handleButtonClick(button: ButtonBarButton, selectedCalenderEvent: CalendarItemModel) {
    if (button.action === ButtonAction.Cancel) {
      this.store.dispatch(CalendarPageActions.calendarEventHide());
    }
    if (button.action === ButtonAction.Approve) {
      if (selectedCalenderEvent.HasValidationErrors) {
        this.confirmAbsence(selectedCalenderEvent);
        return;
      }
      this.store.dispatch(
        CalendarApiActions.ApproveLeaveRequest({
          absenceId: selectedCalenderEvent?.AbsenceId!,
        })
      );
    }
    if (button.action === ButtonAction.Decline) {
      let model: TimeManagementModel = {
        Id: selectedCalenderEvent.AbsenceId,
        Type: TimeManagementType.Absence,
      } as TimeManagementModel;

      this.store.dispatch(CalendarPageActions.ShowRejectAbsenceDialog({ model }));
    }
    if (button.action === ButtonAction.Open) {
      this.router.navigate(['/time/manage/absence/' + selectedCalenderEvent.AbsenceId]);
    }
  }

  confirmAbsence(calendarEvent: CalendarItemModel) {
    let acceptLabel = this.translationService.translate('approve-anyway');
    let rejectLabel = this.translationService.translate('show-details');
    let message = this.translationService.translate('absence-has-warning');

    var element = document.getElementById('approveButton');

    this.confirmationService.confirm({
      target: element as EventTarget,
      message: message,
      icon: 'pi pi-exclamation-triangle',
      key: 'approveAbsencePopupCalendar',
      acceptLabel: acceptLabel,
      rejectLabel: rejectLabel,
      accept: () => {
        this.store.dispatch(
          CalendarApiActions.ApproveLeaveRequest({
            absenceId: calendarEvent?.AbsenceId!,
          })
        );
      },
      reject: () => {
        if (calendarEvent.HasValidationErrors) {
          this.router.navigate([`/time/manage/absence/${calendarEvent.AbsenceId}`]);
        }
      },
    });
  }

  createButtonList() {
    this.popupButtons = [
      {
        label: this.translationService.translate('details'),
        action: ButtonAction.Open,
        routerLink: null,
        visible: true,
        isSaveButton: false,
        class: 'p-button p-button-sm p-button-secondary',
      } as ButtonBarButton,
      {
        label: this.translationService.translate('reject'),
        routerLink: null,
        action: ButtonAction.Decline,
        visible: this.selectedEvent?.Status == TimeManagementStatusEnum.Pending,
        isSaveButton: false,
        class: 'p-button p-button-sm p-button-danger',
      } as ButtonBarButton,
      {
        id: 'approveButton',
        label: this.translationService.translate('approve'),
        routerLink: null,
        action: ButtonAction.Approve,
        isSaveButton: true,
        visible: this.selectedEvent?.Status == TimeManagementStatusEnum.Pending,
        class: 'p-button p-button-sm',
      } as ButtonBarButton,
    ];
  }
}
