export interface SystemMessageModel {
  Id: number;
  MessageType: SystemMessageTypeEnum;
  Title: string;
  Description: string;
  IsClosed: boolean;
}

export enum SystemMessageTypeEnum
{
    Information = 1,
    ErrorMessage = 2,
    PlannedMaintenance = 3
}
