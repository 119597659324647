import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import {
  EmployeeContactInformationModel,
  EmployeeModel,
  EmployeeFormalityModel,
  EmployeePersonalDetailModel,
} from '@mentor-one-ui/core/models/employee/EmployeeModel';
import { NextOfKinModel } from '@mentor-one-ui/core/models/employee/NextOfKinModel';
import { ChildModel } from '@mentor-one-ui/core/models/employee/ChildModel';
import {
  PersonnelManagerModel,
  EmploymentModel,
  TerminateEmploymentModel,
  EmploymentChangeDepartmentRequestModel,
} from '@mentor-one-ui/core/models/employee/EmploymentModel';
import { MentorAdminEmployeeListModel } from '@mentor-one-ui/core/models/employee/MentorAdminEmployeeListModel';
import { NewEmployeeScenario } from '@mentor-one-ui/core/models/employee/NewEmployeeScenario';
import { PersonnelFileModel } from '@mentor-one-ui/core/models/employee/PersonnelFileModel';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  constructor(public http: HttpClient) {}

  getEmployees(): Observable<EmployeeModel[]> {
    return this.http.get<EmployeeModel[]>(environment.API_URL + '/employee/list/');
  }

  getArchivedEmployees(): Observable<EmployeeModel[]> {
    return this.http.get<EmployeeModel[]>(environment.API_URL + '/employee/achivedlist/');
  }

  createEmployee(
    employee: EmployeeModel,
    employments: EmploymentModel[],
    roleIds: number[] = [],
    createUser: boolean
  ): Observable<any> {
    const payload = {
      ContactInformation: employee.ContactInformation,
      PersonalDetails: employee.PersonalDetails,
      Formality: employee.Formality,
      RoleIds: roleIds,
      Employments: employments,
      CreateUser: createUser,
    };
    return this.http.post(environment.API_URL + '/employee/create', payload);
  }

  terminateEmployeement(model: TerminateEmploymentModel) {
    return this.http.post(environment.API_URL + '/employee/terminateEmployment', model);
  }

  abortTerminationOfEmployeement(employeeId: number) {
    return this.http.post(environment.API_URL + '/employee/' + employeeId + '/cancelTerminateEmployment', null);
  }

  updateEmployee(employee: EmployeeModel): Observable<EmployeeModel> {
    return this.http.post<EmployeeModel>(environment.API_URL + '/employee/update', employee);
  }

  updateEmployeePersonalDetails(
    employeeId: number,
    personalDetails: EmployeePersonalDetailModel
  ): Observable<EmployeePersonalDetailModel> {
    return this.http.post<EmployeePersonalDetailModel>(
      environment.API_URL + '/employee/' + employeeId + '/personalDetails/update',
      personalDetails
    );
  }

  updateEmployeeFormality(employeeId: number, formality: EmployeeFormalityModel): Observable<EmployeeFormalityModel> {
    return this.http.post<EmployeeFormalityModel>(
      environment.API_URL + '/employee/' + employeeId + '/formality/update',
      formality
    );
  }

  updateEmployeeContactInformation(
    employeeId: number,
    contactInformation: EmployeeContactInformationModel
  ): Observable<EmployeeContactInformationModel> {
    return this.http.post<EmployeeContactInformationModel>(
      environment.API_URL + '/employee/' + employeeId + '/contactInformation/update',
      contactInformation
    );
  }

  getEmployee(id: number): Observable<EmployeeModel> {
    return this.http.get<EmployeeModel>(environment.API_URL + '/employee/' + id);
  }

  hideSupportBoxForEmployee(employeeId: number) {
    return this.http.post(environment.API_URL + '/employee/' + employeeId + '/hidesupportbox', null);
  }

  saveEmployeeProfilePicture(employeeId: number, image: any): Observable<any> {
    return this.http.post<File>(environment.API_URL + '/employee/' + employeeId + '/profilepicture/save', image);
  }

  getEmployeeProfilePicture(employeeId: number): Observable<any> {
    return this.http.get<File>(environment.API_URL + '/employee/' + employeeId + '/profilepicture/get', {
      responseType: 'blob' as 'json',
    });
  }

  deleteEmployeeProfilePicture(employeeId: number) {
    return this.http.delete(environment.API_URL + '/employee/' + employeeId + '/profilepicture/delete');
  }

  archiveEmployee(employeeId: number): Observable<any> {
    return this.http.post(environment.API_URL + '/employee/' + employeeId + '/archive', employeeId);
  }

  unarchiveEmployee(employeeId: number): Observable<any> {
    return this.http.post(environment.API_URL + '/employee/' + employeeId + '/unarchive', employeeId);
  }

  deleteEmployee(employeeId: number): Observable<any> {
    return this.http.delete(environment.API_URL + '/employee/' + employeeId, {
      body: employeeId,
    });
  }

  updateEmployeeHaveNoChildren(employeeId: number, haveNoChildren: boolean): Observable<any> {
    const url = environment.API_URL + '/employee/' + employeeId + '/haveNoChildren/update';
    return this.http.post(url, haveNoChildren);
  }

  // Org dependent data

  // Next of kin

  getNextOfKinList(employeeId: number): Observable<NextOfKinModel[]> {
    return this.http.get<NextOfKinModel[]>(environment.API_URL + '/employee/' + employeeId + '/nextOfKin/list/');
  }
  saveNextOfKin(employeeId: number, nextOfKin: NextOfKinModel): Observable<NextOfKinModel> {
    return this.http.post<NextOfKinModel>(
      environment.API_URL + '/employee/' + employeeId + '/nextOfKin/update/',
      nextOfKin
    );
  }

  deleteNextOfKin(employeeId: number, nextOfKinId: number): Observable<NextOfKinModel[]> {
    return this.http.delete<NextOfKinModel[]>(
      environment.API_URL + '/employee/' + employeeId + '/nextOfKin/' + nextOfKinId
    );
  }

  // Children

  getChildList(employeeId: number): Observable<ChildModel[]> {
    return this.http.get<ChildModel[]>(environment.API_URL + '/employee/' + employeeId + '/children/list/');
  }
  saveChild(employeeId: number, child: ChildModel): Observable<ChildModel> {
    return this.http.post<ChildModel>(environment.API_URL + '/employee/' + employeeId + '/children/update/', child);
  }

  deleteChild(employeeId: number, childId: number): Observable<ChildModel[]> {
    return this.http.delete<ChildModel[]>(environment.API_URL + '/employee/' + employeeId + '/children/' + childId);
  }

  getEmployeePersonalManagers(employeeId: number): Observable<PersonnelManagerModel[]> {
    return this.http.get<PersonnelManagerModel[]>(
      environment.API_URL + '/employee/' + employeeId + '/personnelmanagers'
    );
  }

  // Employment

  getEmploymentHistory(employeeId: number): Observable<EmploymentModel[]> {
    return this.http.get<EmploymentModel[]>(environment.API_URL + '/employee/' + employeeId + '/employment/list');
  }
  saveEmployment(employeeId: number, employment: EmploymentModel): Observable<EmploymentModel> {
    return this.http.post<EmploymentModel>(
      environment.API_URL + '/employee/' + employeeId + '/employment/update/',
      employment
    );
  }
  deleteEmployment(employeeId: number, employmentId: number): Observable<any> {
    return this.http.delete(environment.API_URL + '/employee/' + employeeId + '/employment/' + employmentId);
  }

  changeDepartmentOnEmployment(
    employeeId: number,
    employmentId: number,
    newDepartmentId: number,
    fromDate: string
  ): Observable<EmploymentModel> {
    let request: EmploymentChangeDepartmentRequestModel = {
      ToDepartmentId: newDepartmentId,
      ChangeDate: fromDate,
    };
    return this.http.post<EmploymentModel>(
      environment.API_URL + '/employee/' + employeeId + '/employment/' + employmentId + '/changedepartment/',
      request
    );
  }

  deleteDepartmentOnEmployment(
    employeeId: number,
    employmentId: number,
    employmentDepartmentMapId: number
  ): Observable<EmploymentModel> {
    return this.http.delete<EmploymentModel>(
      environment.API_URL +
        '/employee/' +
        employeeId +
        '/employment/' +
        employmentId +
        '/department/' +
        employmentDepartmentMapId +
        '/delete'
    );
  }
  transferPersonnel(currentManagerId: number, newManagerId: number) {
    return this.http.post<any>(environment.API_URL + `/employee/${currentManagerId}/transferPersonnel?newPersonnelManagerId=${newManagerId}`, {})
  }
  // Mentor admin
  searchEmployee(search: string): Observable<MentorAdminEmployeeListModel[]> {
    return this.http.get<MentorAdminEmployeeListModel[]>(environment.API_URL + '/MentorAdminEmployee/search/' + search);
  }

  getEmployeesInOrganization(organizationId: number): Observable<MentorAdminEmployeeListModel[]> {
    return this.http.get<MentorAdminEmployeeListModel[]>(
      environment.API_URL + '/MentorAdminEmployee/' + organizationId + '/list/'
    );
  }

  resetTwoFactor(employeeId: number): Observable<boolean> {
    return this.http.post<boolean>(
      environment.API_URL + '/MentorAdminEmployee/' + employeeId + '/resettwofactorauthenticator',
      employeeId
    );
  }

  // New Employee steps

  validatePersonalDetailsStep(model: EmployeeModel, scenario: NewEmployeeScenario): Observable<any> {
    return this.http.post<any>(
      environment.API_URL + '/employee/' + model.EmployeeId + '/personalDetailsStep/validate',
      { model, scenario }
    );
  }

  ValidateIsEmployeeNumberAlreadyRegistered(
    currentEmployeeId: Number | null,
    employeeNumber: string
  ): Observable<boolean> {
    const body = {
      CurrentEmployeeNumber: currentEmployeeId,
      EmployeeNumber: employeeNumber,
    };

    return this.http.post<boolean>(environment.API_URL + '/employee/validate/EmployeeNumberAlreadyRegistered', body);
  }

  ValidateIsEmailAlreadyRegistered(email: string): Observable<boolean> {
    const body = {
      Email: email,
    };

    return this.http.post<boolean>(environment.API_URL + '/employee/validate/EmailAlreadyRegistered', body);
  }

  updateEmployeePersonalDetailsStep(model: EmployeeModel): Observable<any> {
    return this.http.post<any>(
      environment.API_URL + '/employee/' + model.EmployeeId + '/personalDetailsStep/update',
      model
    );
  }

  // Personnel file

  getPersonnelFileList(employeeId: number): Observable<PersonnelFileModel[]> {
    return this.http.get<PersonnelFileModel[]>(
      environment.API_URL + '/employee/' + employeeId + '/personnelFile/list/'
    );
  }

  savePersonnelFile(employeeId: number, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<File>(environment.API_URL + '/employee/' + employeeId + '/personnelFile/update/', formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  deletePersonnelFile(employeeId: number, personnelFileId: number): Observable<PersonnelFileModel[]> {
    return this.http.delete<PersonnelFileModel[]>(
      environment.API_URL + '/employee/' + employeeId + '/personnelFile/' + personnelFileId
    );
  }

  downloadPersonnelFile(employeeId: number, personnelFileId: number): Observable<any> {
    return this.http.post<any>(
      `${environment.API_URL}/employee/${employeeId}/personnelFile/download?personnelFileId=${personnelFileId}`,
      {},
      { responseType: 'blob' as 'json' }
    );
  }
}
