import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'mo-info-box',
  standalone: true,
  styleUrls: ['./info-box.component.scss'],
  imports: [
    CommonModule
  ],
  template: `
    <div class="info-box">
      <i class="fal fa-info-circle"></i>
      <p *ngIf="info">{{ info }}</p>
      <p *ngIf="htmlText" [innerHtml]="htmlText"></p>
      <ng-content></ng-content>
    </div>
  `,
})
export class InfoBoxComponent {
  @Input() info: string | null;
  @Input() htmlText: SafeHtml;
}
