import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { OrganizationService } from "@mentor-one-ui/core/services/api/organization.service";
import { OrganizationsApiActions } from "@mentor-one-ui/mentor-admin/admin-organizations/state/actions/organization-list-api.actions";
import { switchMap, map, catchError, of, filter } from "rxjs";
import { AbsenceSettingsPageActions } from "../absence-settings/absence-settings-page/actions/absence-settings-page.actions";
import { selectAbsenceTemplates, selectOvertimeTemplates } from "./settings.selectors";
import { DialogService } from "primeng/dynamicdialog";
import { TimeManagementTypesModalComponent } from "../absence-settings/time-management-types-modal/time-management-types-modal.component";
import { TimeManagementType } from "@mentor-one-ui/time/my-time/absence/models/TimeManagementType";
import { TimeManagementTemplate } from "../models/time-management-template.model";
import { TimeManagementTypesModalActions } from "../absence-settings/time-management-types-modal/actions/time-management-types-modal.actions";
import { TranslationDataService } from "@mentor-one-ui/core/services/translation-data.service";
import { ApplicationActions } from "@mentor-one-ui/core/state/application/application.actions";
import { AdminBalancePageActions } from "@mentor-one-ui/time/admin-balance/actions/admin-balance-page.actions";

@Injectable()
export class SettingsEffects {

    constructor(
    private actions$: Actions,
    private organizationService: OrganizationService,
    private store: Store,
    private dialogService: DialogService,
    private translationService: TranslationDataService

  ) { }

  loadTimeManagementTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbsenceSettingsPageActions.Enter, AdminBalancePageActions.Enter),
      switchMap((action) => {
        return this.organizationService.getTimeManagementTypes().pipe(
          map((result: TimeManagementTemplate[]) => {
            return OrganizationsApiActions.loadTimeManagementTypesSuccess({
              timeManagementTypes: result,
            });
          }),
          catchError((e) => of(OrganizationsApiActions.loadTimeManagementTypesError({ error: this.translationService.translate('could-not-load-absence-types') })))
        )
      })
    )
  );

  saveAbsenceTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimeManagementTypesModalActions.save),
      filter((action) => action.timeManagementType === TimeManagementType.Absence),
      switchMap((action) => {
        return this.organizationService.updateAbsenceTypes(action.timeManagementTypes).pipe(
          map((result: TimeManagementTemplate[]) => {
            return OrganizationsApiActions.updateTimeManagementTypesSuccess({
              timeManagementTypes: result,
            });            
          }),
          catchError((e) => of(OrganizationsApiActions.updateTimeManagementTypesError({ error: this.translationService.translate('could-not-update-overtime-codes') })))
        )
      })
    )
  );

  saveOvertimeTypes$ = createEffect(() =>
  this.actions$.pipe(
    ofType(TimeManagementTypesModalActions.save),
    filter((action) => action.timeManagementType === TimeManagementType.Overtime),
    switchMap((action) => {
      return this.organizationService.updateOvertimeTypes(action.timeManagementTypes).pipe(
        map((result: TimeManagementTemplate[]) => {
          return OrganizationsApiActions.updateTimeManagementTypesSuccess({
            timeManagementTypes: result,
          });
        }),
        catchError((e) => of(OrganizationsApiActions.updateTimeManagementTypesError({ error: this.translationService.translate('could-not-update-absence-codes') })))
      )
    })
  )
);


displayErrorToUser$ = createEffect(() => {
  return this.actions$.pipe(
    ofType(
      OrganizationsApiActions.updateTimeManagementTypesError
    ),
    map((action) => {
      return ApplicationActions.ErrorMessage({
        title: this.translationService.translate('something-went-wrong'),
        message: action.error,
      });
    })
  );
});


  dialogRef: any;

  closeDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        TimeManagementTypesModalActions.cancel,
        OrganizationsApiActions.updateTimeManagementTypesSuccess),
      map((data) => {
        this.dialogRef.close();

      })
    ), { dispatch: false }
  );

  showSuccessToastOnSaveSettingSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OrganizationsApiActions.updateTimeManagementTypesSuccess),
        map((action) => {
          return ApplicationActions.SuccessMessage({
            title: this.translationService.translate('success'),
            message: this.translationService.translate('setting-saved')
          });
        })
      );
    }
  );


  showAbsenceTemplatesDetailDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbsenceSettingsPageActions.openAbsenceCodeModal),
      concatLatestFrom(() => this.store.select(selectAbsenceTemplates)),
      filter(([action, absenceTemplates]) => action.timeManagementType === TimeManagementType.Absence),
      switchMap(([action, timeManagementTypes]) => {

        this.dialogRef = this.dialogService.open(TimeManagementTypesModalComponent, {
          header: this.translationService.translate("absence-codes"),
          data: {
            type: action.timeManagementType,
            timeManagementTypes: [...timeManagementTypes],
          },
        });

        return this.dialogRef.onClose;
      }),
      map((data) => {
        return AbsenceSettingsPageActions.ModalWasClosed();
      })
    )
  );

  showOvertimeTemplatesDetailDialog$ = createEffect(() =>
  this.actions$.pipe(
    ofType(AbsenceSettingsPageActions.openAbsenceCodeModal),
    concatLatestFrom(() => this.store.select(selectOvertimeTemplates)),
    filter(([action, absenceTemplates]) => action.timeManagementType === TimeManagementType.Overtime),
    switchMap(([action, timeManagementTypes]) => {

      this.dialogRef = this.dialogService.open(TimeManagementTypesModalComponent, {
        header: this.translationService.translate("overtime-codes"),
        data: {

          type: action.timeManagementType,
          timeManagementTypes: [...timeManagementTypes],
        },
      });

      return this.dialogRef.onClose;
    }),
    map((data) => {
      return AbsenceSettingsPageActions.ModalWasClosed();
    })
  )
);

}
