import { NextOfKinModel } from '@mentor-one-ui/core/models/employee/NextOfKinModel';
import { createAction, props } from '@ngrx/store';

export const EmployeeNextOfKinTabActions = {
  EnterNextOfKinTab: createAction('[Employee - NextOfKin Tab] Enter'),
  OpenModal: createAction('[Employee - NextOfKin Tab] Next Of Kin Modal Open', props<{ nextOfKinId: number }>()),
  SaveNextOfKin: createAction('[Employee - NextOfKin Tab] Save NextOfKin', props<{ nextOfKin: NextOfKinModel }>()),
  DeleteNextOfKin: createAction('[Employee - NextOfKin Tab] Delete NextOfKin', props<{ nokId: number }>()),
  // TODO: remove one action ?
  CloseModal: createAction('[Employee - NextOfKin Tab] Modal Close'),
  ModalWasClosed: createAction('[Employee - NextOfKin Tab] Modal Closed'),
};

//
export const EmployeeNextOfKinApiActions = {
  LoadNextOfKinListSuccess: createAction(
    '[Employee - NextOfKin API] Load NextOfKin Success',
    props<{ employeeId: number; nextOfKinList: NextOfKinModel[] }>()
  ),
  SaveNextOfKinSuccess: createAction(
    '[Employee - NextOfKin API] Save NextOfKin Success',
    props<{ employeeId: number; nextOfKin: NextOfKinModel }>()
  ),
  DeleteNextOfKinSuccess: createAction(
    '[Employee - NextOfKin API] Delete NextOfKin Success',
    props<{ employeeId: number; noks: NextOfKinModel[] }>()
  ),
  LoadNextOfKinListError: createAction('[Employee - NextOfKin API] Load NextOfKin Error', props<{ error: string }>()),
  SaveNextOfKinError: createAction('[Employee - NextOfKin API] Save NextOfKin Error', props<{ error: string }>()),
  DeleteNextOfKinError: createAction('[Employee - NextOfKin API] Delete NextOfKin Error', props<{ error: string }>()),
};
