import { createAction, props } from '@ngrx/store';
import { PayrollFilter } from '../../models/payroll-filter.model';
import { TimeManagementType } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementType';
import { PayrollExportSettingModel } from '../../models/payroll-settings.model';

export const PayrollPageActions = {
  Enter: createAction('[Payroll Page] Enter'),
  FilterChanged: createAction('[Payroll Page] Filter Changed', props<{ filter: PayrollFilter }>()),
  OpenEmployeeReport: createAction('[Payroll Page] Open Employee Report', props<{ employeeId: number }>()),
  ExportPayrollModal: createAction('[Payroll Page] Export Payroll Modal'),
  ExportPayroll: createAction('[Payroll Page] Export Payroll', props<{ exportType: number }>()),
  ExportDialogClosed: createAction('[Payroll Page] Export Dialog Closed'),
  ValidatePayrollSettings: createAction('[Payroll Page] Validate Payroll Settings', props<{ exportType: number }>()),
};

export const EmployeePayrollReportActions = {
  CloseEmployeePayrollReport: createAction('[Employee Payroll Report] Close Employee Report'),
  CertifyEmployeePayroll: createAction(
    '[Employee Payroll Report] Certify Employee Payroll',
    props<{ employeeId: number }>()
  ),
  ApproveLeaveRequest: createAction(
    '[Employee Payroll Report] Approve Leave Request',
    props<{ leaveType: TimeManagementType; leaveId: number }>()
  ),
  ShowRejectAbsenceDialog: createAction(
    '[Employee Payroll Report] Show Reject Absence Dialog',
    props<{ model: any }>()
  ),
  RejectLeaveRequest: createAction(
    '[Employee Payroll Report] Reject Leave Request',
    props<{ leaveType: TimeManagementType; leaveId: number; reason: string }>()
  ),
  CancelRejectAbsenceRequest: createAction('[Employee Payroll Report] Cancel Reject Absence Request'),
  NavigateToDetails: createAction(
    '[Employee Payroll Report] Navigate To Details',
    props<{ id: number; leaveType: TimeManagementType }>()
  ),

  SelectPayrollAbsence: createAction(
    '[Employee Payroll Report] Select Payroll Absence',
    props<{ absenceId: number }>()
  ),
  HideManagePayrollAbsence: createAction('[Employee Payroll Report] Hide Manage Payroll Absence'),
};

export const PayrollSettingsPageActions = {
  Enter: createAction('[Employee Payroll Settings] Enter'),
  UpdatePayrollSettings: createAction(
    '[Payroll Settings Page] Update Payroll Settings',
    props<{ settings: PayrollExportSettingModel }>()
  ),
  HasFormChanges: createAction('[Payroll Settings Page] Has Form Changes', props<{ hasChanges: boolean }>()),
};

export const PayrollExportHistoryPageActions = {
  Enter: createAction('[Payroll Export History Page] Enter'),
  OpenPayrollExportHistoryDetailsModal: createAction(
    '[Payroll Export History Page] Open Payroll Export History Details Modal',
    props<{ exportId: number }>()
  ),
  ClosePayrollExportHistoryDetailsModal: createAction(
    '[Payroll Export History Page] Close Payroll Export History Details Modal'
  ),
  LoadMorePayrollExportHistory: createAction(
    '[Payroll Export History Page] Load More Payroll Export History',
    props<{ skip: number; take: number }>()
  ),
};
