import { EmployeeModel } from '@mentor-one-ui/core/models/employee/EmployeeModel';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { EmployeeListApiActions } from '@mentor-one-ui/employees/state/actions/employee-list-api.actions';
import { EmployeeListPageActions } from '@mentor-one-ui/employees/state/actions/employee-list-page.actions';
import { NewEmployeeApiActions } from '@mentor-one-ui/employees/new-employee/state/actions/new-employee-api-actions';
import { EmployeeChildrenApiActions } from '@mentor-one-ui/employees/state/actions/employee-children.actions';
import { EmployeeEmploymentApiActions } from '@mentor-one-ui/employees/state/actions/employee-employment.actions';
import { EmployeeNextOfKinApiActions } from '@mentor-one-ui/employees/state/actions/employee-nextofkin.actions';
import { EmployeeDetailsApiActions } from '@mentor-one-ui/employees/state/actions/employee-details.actions';
import {
  EmployeeSystemAccessApiActions,
  EmployeeSystemAccessTabActions,
} from '../actions/employee-system-access.actions';
import { EmployeeTerminateEmploymentApiActions } from '../actions/employee-terminate-employment.actions';
import { EmployeePersonnelFileApiActions } from '../actions/employee-personnel-file.actions';
import { EmployeePageActions } from '@mentor-one-ui/employees/state/actions/employee-page.actions';
import { EmployeesColumn } from '@mentor-one-ui/employees/employee-list-page/models/employees-column.enum';

export const employeesFeatureKey = 'employees';

export interface EmployeeListFilter {
  departments: number[];
  isActive: boolean;
  search: string;
  columns: string[];
  selectedColumns: string[];
}
export interface State extends EntityState<EmployeeModel> {
  filter: EmployeeListFilter;
  loaded: boolean;
  selectedEmployeeId: number | null;
  loadError: boolean;
  loadErrorCode: string | null;
  loadErrorMessage: string | null;
  isLoading: boolean;
}

export const adapter: EntityAdapter<EmployeeModel> = createEntityAdapter<EmployeeModel>({
  sortComparer: false,
  selectId: (s) => s.EmployeeId,
});

export const initialState: State = adapter.getInitialState({
  filter: {
    departments: [],
    isActive: false,
    search: '',
    columns: [
      EmployeesColumn.Phone,
      EmployeesColumn.Email,
      EmployeesColumn.Deparment,
      EmployeesColumn.LoggedIn,
      EmployeesColumn.Profile,
      EmployeesColumn.Roles,
    ],
    selectedColumns: [EmployeesColumn.Phone, EmployeesColumn.Email],
  },
  loaded: false,
  loadError: false,
  loadErrorCode: null,
  loadErrorMessage: null,
  selectedEmployeeId: -1,
  isLoading: false,
});

export const employeesReducer = createReducer(
  initialState,
  on(
    EmployeeListApiActions.LoadEmployeesSuccess,
    EmployeeListApiActions.LoadArchivedEmployeesSuccess,
    (state, { employees }): State => {
      return {
        ...adapter.upsertMany(employees, state),
        loaded: true,
        loadError: false,
        isLoading: false,
      };
    }
  ),
  on(EmployeeListApiActions.LoadEmployeesError, (state, { error, code }): State => {
    return {
      ...state,
      loaded: true,
      loadError: true,
      loadErrorCode: code,
      loadErrorMessage: error,
      isLoading: false,
    };
  }),
  on(EmployeeListPageActions.Enter, (state): State => {
    return {
      ...state,
      loadError: false,
      isLoading: true,
    };
  }),
  //
  // load employee details
  on(EmployeeDetailsApiActions.LoadEmployeeSuccess, (state, { employee }): State => {
    return adapter.upsertOne(employee, state);
  }),
  on(EmployeeDetailsApiActions.LoadEmployeeError, (state): State => {
    return {
      ...state,
      loadError: true,
      isLoading: false,
    };
  }),
  on(EmployeeTerminateEmploymentApiActions.TerminateEmploymentSuccess, (state, { employeeId }): State => {
    let employee = { ...state.entities[employeeId] } as EmployeeModel;
    employee.IsEmploymentTerminationStarted = true;
    return adapter.upsertOne(employee, state);
  }),
  on(EmployeeTerminateEmploymentApiActions.AbortTerminateEmploymentSuccess, (state, { employeeId }): State => {
    let employee = { ...state.entities[employeeId] } as EmployeeModel;
    employee.IsEmploymentTerminationStarted = false;
    return adapter.upsertOne(employee, state);
  }),
  on(
    NewEmployeeApiActions.CreateNewEmployeeSuccess,
    EmployeeChildrenApiActions.CheckedHaveNoChildrenSuccess,
    (state, { employee }): State => {
      return adapter.upsertOne(employee, state);
    }
  ),
  on(EmployeeDetailsApiActions.SaveEmployeeFormalitySuccess, (state, { employeeId, formality }): State => {
    let employee = { ...state.entities[employeeId] } as EmployeeModel;
    employee.Formality = formality;
    return adapter.upsertOne(employee, state);
  }),
  on(EmployeeDetailsApiActions.SaveEmployeePersonaliaSuccess, (state, { employeeId, personalia }): State => {
    let employee = { ...state.entities[employeeId] } as EmployeeModel;
    employee.PersonalDetails = personalia;
    return adapter.upsertOne(employee, state);
  }),
  on(EmployeeSystemAccessApiActions.CreateUserAndSystemAccessSuccess, (state, { userLoginId, employeeId }): State => {
    let employee = { ...state.entities[employeeId] } as EmployeeModel;
    employee.IsUser = userLoginId != null;
    return adapter.upsertOne(employee, state);
  }),
  on(EmployeeSystemAccessTabActions.DeleteUserAccessSuccess, (state, { employeeId }): State => {
    let employee = { ...state.entities[employeeId] } as EmployeeModel;
    employee.IsUser = false;
    return adapter.upsertOne(employee, state);
  }),
  on(EmployeeDetailsApiActions.DeleteMyProfilePictureSuccess, (state, { employeeId }): State => {
    let employee = { ...state.entities[employeeId] } as EmployeeModel;
    employee.HasProfilePicture = false;
    return adapter.upsertOne(employee, state);
  }),
  on(EmployeePageActions.SetProfilePicture, (state, { employeeId }): State => {
    let employee = { ...state.entities[employeeId] } as EmployeeModel;
    employee.HasProfilePicture = true;
    return adapter.upsertOne(employee, state);
  }),
  on(
    EmployeeDetailsApiActions.SaveEmployeeContactInformationSuccess,
    (state, { employeeId, contactInformation }): State => {
      let employee = { ...state.entities[employeeId] } as EmployeeModel;
      employee.ContactInformation = contactInformation;
      return adapter.upsertOne(employee, state);
    }
  ),
  on(EmployeeDetailsApiActions.ActivateUserAccessSuccess, (state, { employeeId }): State => {
    let employee = { ...state.entities[employeeId] } as EmployeeModel;
    employee.ContactInformation = { ...employee.ContactInformation, IsActive: true };
    return adapter.upsertOne(employee, state);
  }),
  on(EmployeeDetailsApiActions.DeactivateUserAccessSuccess, (state, { employeeId }): State => {
    let employee = { ...state.entities[employeeId] } as EmployeeModel;
    employee.ContactInformation = { ...employee.ContactInformation, IsActive: false };
    return adapter.upsertOne(employee, state);
  }),
  on(EmployeeChildrenApiActions.LoadChildListSuccess, (state, { employeeId, children }): State => {
    let employee = { ...state.entities[employeeId] } as EmployeeModel;
    employee.Children = children;
    return adapter.upsertOne(employee, state);
  }),
  on(EmployeeEmploymentApiActions.LoadEmploymentHistorySuccess, (state, { employeeId, employmentHistory }): State => {
    let employee = { ...state.entities[employeeId] } as EmployeeModel;
    employee.EmploymentHistory = employmentHistory;
    return adapter.upsertOne(employee, state);
  }),
  on(EmployeeNextOfKinApiActions.LoadNextOfKinListSuccess, (state, { employeeId, nextOfKinList }): State => {
    let employee = { ...state.entities[employeeId] } as EmployeeModel;
    employee.NextOfKins = nextOfKinList;
    return adapter.upsertOne(employee, state);
  }),
  on(
    EmployeePersonnelFileApiActions.LoadPersonnelFileListSuccess,
    (state, { employeeId, personnelFileList }): State => {
      let employee = { ...state.entities[employeeId] } as EmployeeModel;
      employee.PersonnelFiles = personnelFileList;
      return adapter.upsertOne(employee, state);
    }
  ),
  //
  // update employee details
  on(EmployeeChildrenApiActions.SaveChildSuccess, (state, action): State => {
    let employee = { ...state.entities[action.employeeId]! };
    let children = [...employee.Children];
    if (children.find((f) => f.Id === action.child.Id) === undefined) {
      children.push(action.child);
    } else {
      children = children.map((m) => (m.Id === action.child.Id ? action.child : m));
    }
    employee.Children = children;
    employee.ChildrenCompleted = children.length != 0 || employee.HaveNoChildren;
    return adapter.upsertOne(employee, state);
  }),
  on(EmployeeChildrenApiActions.DeleteChildSuccess, (state, action): State => {
    let employee = { ...state.entities[action.employeeId]! };
    employee.Children = action.children;
    employee.ChildrenCompleted = action.children.length != 0 || employee.HaveNoChildren;
    return adapter.upsertOne(employee, state);
  }),
  on(EmployeeNextOfKinApiActions.SaveNextOfKinSuccess, (state, action): State => {
    let employee = { ...state.entities[action.employeeId]! };
    let noks = [...employee.NextOfKins];
    if (noks.find((f) => f.Id === action.nextOfKin.Id) === undefined) {
      noks.push(action.nextOfKin);
    } else {
      noks = noks.map((m) => (m.Id === action.nextOfKin.Id ? action.nextOfKin : m));
    }
    employee.NextOfKins = noks;
    employee.NextOfKinCompleted = noks.length != 0;
    return adapter.upsertOne(employee, state);
  }),
  on(EmployeeNextOfKinApiActions.DeleteNextOfKinSuccess, (state, action): State => {
    let employee = { ...state.entities[action.employeeId]! };
    employee.NextOfKins = action.noks;
    employee.NextOfKinCompleted = action.noks.length != 0;
    return adapter.upsertOne(employee, state);
  }),
  on(
    EmployeeEmploymentApiActions.SaveEmploymentSuccess,
    EmployeeEmploymentApiActions.ChangeDepartmentOnEmploymentSuccess,
    EmployeeEmploymentApiActions.DeleteEmploymentDepartmentSuccess,
    (state, action): State => {
      let employee = { ...state.entities[action.employeeId]! };
      let employmentHistory = [...employee.EmploymentHistory];
      if (employmentHistory.find((f) => f.Id === action.employment.Id) === undefined) {
        employmentHistory.push(action.employment);
      } else {
        employmentHistory = employmentHistory.map((m) => (m.Id === action.employment.Id ? action.employment : m));
      }
      employee.EmploymentHistory = employmentHistory;

      let now = new Date();
      let updatedActiveEmployments = employmentHistory.filter(
        (e) => new Date(e.StartDate) <= now && (e.EndDate == null || new Date(e.EndDate) >= now)
      );
      employee.HasActiveEmployments = updatedActiveEmployments.length > 0;

      if (updatedActiveEmployments.length > 0) {
        employee.ActiveEmploymentsPercentage = updatedActiveEmployments
          .map((empl) => empl.PositionPercentage)
          .reduce((p1, p2) => p1 + p2);
      }

      return adapter.upsertOne(employee, state);
    }
  ),
  on(EmployeeEmploymentApiActions.DeleteEmploymentSuccess, (state, action): State => {
    let employee = { ...state.entities[action.employeeId]! };
    employee.EmploymentHistory = employee.EmploymentHistory.filter((f) => f.Id !== action.employmentId);
    let now = new Date();
    let updatedActiveEmployments = employee.EmploymentHistory.filter(
      (e) => new Date(e.StartDate) <= now && (e.EndDate == null || new Date(e.EndDate) >= now)
    );
    employee.HasActiveEmployments = updatedActiveEmployments.length > 0;
    employee.ActiveEmploymentsPercentage = 0;

    if(updatedActiveEmployments.length > 0) {
      employee.ActiveEmploymentsPercentage = updatedActiveEmployments
      .map((empl) => empl.PositionPercentage)
      .reduce((p1, p2) => p1 + p2);
    }
    return adapter.upsertOne(employee, state);
  }),
  on(EmployeePersonnelFileApiActions.DeletePersonnelFileSuccess, (state, action): State => {
    let employee = { ...state.entities[action.employeeId]! };
    employee.PersonnelFiles = action.files;
    return adapter.upsertOne(employee, state);
  }),
  //
  // employee-list filter
  on(EmployeeListPageActions.SetFilterDepartments, (state, { departments }): State => {
    return {
      ...state,
      filter: {
        ...state.filter,
        departments: departments,
      },
    };
  }),
  on(EmployeeListPageActions.SetFilterIsActive, (state, { isActive }): State => {
    return {
      ...state,
      filter: {
        ...state.filter,
        isActive: isActive,
      },
    };
  }),
  on(EmployeeListPageActions.SetFilterSearch, (state, { search }): State => {
    return {
      ...state,
      filter: {
        ...state.filter,
        search: search,
      },
    };
  }),
  on(EmployeeListPageActions.SetSelectedColumns, (state, { columns }): State => {
    return {
      ...state,
      filter: {
        ...state.filter,
        selectedColumns: columns,
      },
    };
  }),
  on(EmployeeListPageActions.ArchiveEmployee, (state, { employee }): State => {
    return {
      ...state,
      selectedEmployeeId: employee.EmployeeId,
    };
  }),
  on(
    EmployeeListApiActions.ArchiveEmployeeListSuccess,
    EmployeeListPageActions.RejectArchiveEmployee,
    (state): State => {
      return {
        ...state,
        selectedEmployeeId: -1,
      };
    }
  ),
  //
  // employee-list actions
  on(EmployeeListApiActions.ArchiveEmployeeSuccess, (state, { employeeId }): State => {
    let employee = { ...state.entities[employeeId.valueOf()] } as EmployeeModel;
    employee.IsArchived = true;
    return adapter.upsertOne(employee, state);
  }),
  on(EmployeeListApiActions.UnarchiveEmployeeSuccess, (state, { id: employeeId }): State => {
    let employee = { ...state.entities[employeeId.valueOf()] } as EmployeeModel;
    employee.IsArchived = false;
    return adapter.upsertOne(employee, state);
  }),
  on(EmployeeListApiActions.DeleteEmployeeSuccess, (state, { employeeId }): State => {
    return adapter.removeOne(employeeId.valueOf(), state);
  }),
  on(EmployeeListApiActions.ActivateToggleEmployeeSuccess, (state, { employeeId, isActive }): State => {
    let employee = JSON.parse(JSON.stringify(state.entities[employeeId]));
    employee.ContactInformation.IsActive = isActive;
    return adapter.upsertOne(employee, state);
  })
);
