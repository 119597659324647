import { EmployeeModel } from '@mentor-one-ui/core/models/employee/EmployeeModel';
import { EmploymentModel } from '@mentor-one-ui/core/models/employee/EmploymentModel';
import { createAction, props } from '@ngrx/store';
import { NewEmployeeScenario } from '@mentor-one-ui/core/models/employee/NewEmployeeScenario';

export const NewEmployeePageActions = {
  Enter: createAction('[New Employee Page] Enter'),

  StartWizard: createAction('[New Employee Page] Start Wizard'),
  ResetWizard: createAction('[New Employee Page] Reset Wizard'),
  CancelWizard: createAction('[New Employee Page] Cancel Wizard'),
  CompleteWizard: createAction('[New Employee Page] Complete Wizard'),
  ToggleCreateUser: createAction('[New Employee Page] Toggle Create User', props<{ createUser: boolean }>()),
  PreviousStep: createAction('[New Employee Page] Previous Step'),
  NextStep: createAction('[New Employee Page] Next Step'),

  LoadUserEmployee: createAction('[New Employee Page] Load User Employee'),
  CreateNewEmployee: createAction(
    '[New Employee Page] Create New Employee',
    props<{
      employee: EmployeeModel;
      roleIds: number[];
      createUser: boolean;
    }>()
  ),

  ValidatePersonalDetailsStep: createAction(
    '[New Employee Page] Validate Personal Details',
    props<{ employee: EmployeeModel; scenario: NewEmployeeScenario }>()
  ),
  ShowEmploymentModal: createAction('[New Employee Page] Show Add Employment Modal', props<{ employmentId: number }>()),
  EmployeeModalClosed: createAction('[New Employee Page] Employee Modal Closed'),
  AddEmployment: createAction('[New Employee Page] Add Employment', props<{ employment: EmploymentModel }>()),
  EditEmployment: createAction('[New Employee Page] Edit Employment', props<{ employment: EmploymentModel }>()),
  DeleteEmployment: createAction('[New Employee Page] Delete Employment', props<{ employmentId: number }>()),
};
