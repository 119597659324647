import { ApplicationActions } from '@mentor-one-ui/core/state/application/application.actions';
import { createReducer, on } from '@ngrx/store';
import { EmployeeChildrenApiActions } from '../actions/employee-children.actions';
import { EmployeeDetailsApiActions } from '../actions/employee-details.actions';
import { EmployeeEmploymentApiActions } from '../actions/employee-employment.actions';
import { EmployeeNextOfKinApiActions } from '../actions/employee-nextofkin.actions';
import { EmployeePageActions } from '../actions/employee-page.actions';
import { EmployeeSystemAccessApiActions } from '../actions/employee-system-access.actions';

export const featureKey = 'employeePage';
export interface State {
  hasFormChanges: boolean;
  error: boolean;
}
export const initialState: State = {
  hasFormChanges: false,
  error: false,
};

export const reducer = createReducer(
  initialState,
  on(EmployeeDetailsApiActions.LoadEmployeeSuccess, (state) => ({
    ...state,
    error: false,
  })),
  on(EmployeeDetailsApiActions.LoadEmployeeError, (state) => ({
    ...state,
    error: true,
  })),
  on(ApplicationActions.DiscardUnsavedChanges, (state) => ({
    ...state,
    hasFormChanges: false,
  })),
  on(EmployeePageActions.HasFormChanges, (state, { hasChanges }) => ({
    ...state,
    hasFormChanges: hasChanges,
  })),
  on(
    EmployeeDetailsApiActions.SaveEmployeeFormalitySuccess,
    EmployeeDetailsApiActions.SaveEmployeePersonaliaSuccess,
    EmployeeDetailsApiActions.SaveEmployeeContactInformationSuccess,
    EmployeeChildrenApiActions.SaveChildSuccess,
    EmployeeEmploymentApiActions.SaveEmploymentSuccess,
    EmployeeEmploymentApiActions.ChangeDepartmentOnEmploymentSuccess,
    EmployeeEmploymentApiActions.DeleteEmploymentSuccess,
    EmployeeNextOfKinApiActions.SaveNextOfKinSuccess,
    EmployeeSystemAccessApiActions.SaveSystemAccessSuccess,
    (state) => ({
      ...state,
      hasFormChanges: false,
    })
  )
);
