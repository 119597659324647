import {
  AdminBalanceModel,
} from '@mentor-one-ui/time/admin-balance/models/admin-balance.model';
import { createAction, props } from '@ngrx/store';

export const EmployeeBalanceDetailApiActions = {
  updateEmployeeBalanceSuccess: createAction(
    '[Admin Balance/API] Update Employee Balance Success',
    props<{ balances: AdminBalanceModel[] }>()
  ),
  updateEmployeeBalanceError: createAction(
    '[Admin Balance/API] Update Employee Balance Error',
    props<{ error: string }>()
  ),
  ChangeYearSuccess: createAction(
    '[Employee Balance Detail] Change Year Success',
    props<{ balances: AdminBalanceModel[] }>()
  ),
  ChangeYearError: createAction('[Employee Balance Detail] Change Year Error', props<{ error: string }>()),
};
