import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { TimeFilter } from '../time-filter.model';
import { DepartmentLightModel } from '@mentor-one-ui/core/models/DepartmentModel';
import { AbsenceType } from '@mentor-one-ui/time/my-time/absence/models/absence-type.model';
import { OvertimeType } from '@mentor-one-ui/time/my-time/overtime/models/overtime-type.model';
import { ButtonBarButton } from '@mentor-one-ui/core/models/ButtonBar';
import { TimeManagementType } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementType';
import { CheckboxChangeEvent } from 'primeng/checkbox';

@Component({
  selector: 'mo-time-filter-mobile',
  styleUrls: ['./time-filter-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="filter-mobile">
      <div *ngIf="showFirstPage()">

        <div class="filter-mobile__filters">
          <div  class="filter-desktop__status" *ngIf="showStatusFilter">
            <label>{{ 'status' | translate | async }}</label>
            <p-selectButton
              (onChange)="onStatusChange($event)"
              [options]="statusTypes"
              [ngModel]="selectedStatus"
              optionLabel="name"
              optionValue="value">
            </p-selectButton>
          </div>
          <button icon="pi pi-chevron-right" iconPos="right" (click)="showDepartments()" pButton label="{{getDepartmentHeaderText(filter)}}" class="p-button p-button-secondary"></button>
          <button icon="pi pi-chevron-right" iconPos="right" (click)="showTypes()" pButton label="{{getTypesHeaderText(filter)}}" class="p-button p-button-secondary"></button>
          <button icon="pi pi-chevron-right" iconPos="right" (click)="showPositions()" pButton label="{{getPositionsHeaderText(filter)}}" class="p-button p-button-secondary"></button>
          <mo-button-bar
            [buttonList]="buttons"
            (buttonBarClick)="handleButtonClick.emit($event)">
          </mo-button-bar>
        </div>
      </div>
      <div *ngIf="showDepartmentFilters">
        <div class="filter-mobile__header">
          <button
            pButton
            (click)="goBack()"
            label="{{ 'back' | translate | async }}"
            icon="fal fa-chevron-left"
            class="p-button-text"
          ></button>
          </div>
          <div class="filter-mobile__filter-detail">
            <p-checkbox *ngFor="let dept of departments"
              (onChange)="toggleDepartment.emit(dept.DepartmentId)"
              [ngModel]="filter.selectedDepartmentIds.includes(dept.DepartmentId)"
              label="{{dept.Name}}"
              [binary]="true"></p-checkbox>
          </div>
      </div>
      <div *ngIf="showTypeFilters">
        <div class="filter-mobile__header">
        <button
          pButton
          (click)="goBack()"
          label="{{ 'back' | translate | async }}"
          icon="fal fa-chevron-left"
          class="p-button-text"
        ></button>
        </div>
        <div class="filter-mobile__filter-detail">
          <p-checkbox
            label="{{ 'absence' | translate | async }}"
            [binary]="true"
            (onChange)="onTypeGroupChecked($event, TimeManagementType.Absence)"
          ></p-checkbox>
          <p-checkbox
            class="checkbox-group__item"
            [ngModel]="filter.absenceMapIds.includes(absence.OrganizationAbsenceMapId)"
            *ngFor="let absence of absenceTypes"
            (onChange)="onAbsenceSelected($event, absence)"
            label="{{absence.Title}}"
            [binary]="true">
          </p-checkbox>
          <ng-container *ngIf="showOvertimeTypes">
            <p-checkbox
              label="{{ 'overtime' | translate | async }}"
              [binary]="true"
              (onChange)="onTypeGroupChecked($event, TimeManagementType.Overtime)"
            ></p-checkbox>
            <p-checkbox
              class="checkbox-group__item"
              [ngModel]="filter.overtimeMapdIds.includes(overtime.OrganizationOvertimeMapId)"
              *ngFor="let overtime of overtimeTypes"
              (onChange)="onOvertimeSelected($event, overtime)"
              label="{{overtime.Title}}"
              [binary]="true">
            </p-checkbox>
          </ng-container>
        </div>
      </div>
      <div *ngIf="showPositionFilters">
        <div class="filter-mobile__header">
        <button
          pButton
          (click)="goBack()"
          label="{{ 'back' | translate | async }}"
          icon="fal fa-chevron-left"
          class="p-button-text"
        ></button>
        </div>
        <div class="filter-mobile__filter-detail">
          <p-checkbox
            *ngFor="let position of positionTitles"
            [ngModel]="filter.selectedPositions.includes(position)"
            (onChange)="togglePosition.emit(position)"
            label="{{position}}"
            [binary]="true">
          </p-checkbox>
        </div>
      </div>
    </div>
    `,
})
export class TimeFilterMobileComponent {
  @Input() filter: TimeFilter;
  @Input() departments: DepartmentLightModel[];
  @Input() absenceTypes: AbsenceType[];
  @Input() overtimeTypes: OvertimeType[];
  @Input() positionTitles: string[];
  @Input() showStatusFilter: boolean;
  @Input() selectedLeaveTypes: string[];
  @Input() selectedStatus: number;
  @Input() selectedFilterCount: number | null;
  @Input() statusTypes: { name: string; value: number; }[];
  @Input() buttons: ButtonBarButton[];
  @Input() showOvertimeTypes: boolean;

  @Output() filterByOvertimeType = new EventEmitter<number[]>();
  @Output() filterByAbsenceType = new EventEmitter<number[]>();
  @Output() resetFilter = new EventEmitter();
  @Output() setFilterStatus = new EventEmitter<number[]>();

  @Output() toggleDepartment = new EventEmitter<number>();
  @Output() togglePosition = new EventEmitter<string>();
  @Output() handleButtonClick = new EventEmitter<ButtonBarButton>();


  TimeManagementType = TimeManagementType;
  showDepartmentFilters: boolean;
  showTypeFilters: boolean;
  showPositionFilters: boolean;

  constructor(private translationService: TranslationDataService) { }

  onAbsenceSelected(event: any, absence: AbsenceType) {
    if(event.checked) {
      this.filterByAbsenceType.emit([...this.filter.absenceMapIds, absence.OrganizationAbsenceMapId]);
    } else {
      this.filterByAbsenceType.emit(this.filter.absenceMapIds.filter(f => f != absence.OrganizationAbsenceMapId));
    }
  }

  onOvertimeSelected(event: any, overtime: OvertimeType) {
    if(event.checked) {
      this.filterByOvertimeType.emit([...this.filter.overtimeMapdIds, overtime.OrganizationOvertimeMapId]);
    } else {
      this.filterByOvertimeType.emit(this.filter.overtimeMapdIds.filter(f => f != overtime.OrganizationOvertimeMapId));
    }
  }

  onTypeGroupChecked(event: CheckboxChangeEvent, type: TimeManagementType) {
    if(event.checked) {
      if(type == TimeManagementType.Absence) {
        this.filterByAbsenceType.emit(this.absenceTypes.map((f:AbsenceType) => f.OrganizationAbsenceMapId));
      }
      else {
        this.filterByOvertimeType.emit(this.overtimeTypes.map((f:OvertimeType) => f.OrganizationOvertimeMapId));
      }
    } else {
      if(type == TimeManagementType.Absence) {
        this.filterByAbsenceType.emit([]);
      }
      else {
        this.filterByOvertimeType.emit([]);
      }
    }
  }


  onStatusChange(event: any) {
    this.setFilterStatus.emit([event.value]);
  }

  showFirstPage() {
    return !this.showDepartmentFilters && !this.showTypeFilters && !this.showPositionFilters;
  }

  showDepartments() {
    this.showDepartmentFilters = true;
  }

  showTypes() {
    this.showTypeFilters = true;
  }

  showPositions() {
    this.showPositionFilters = true;
  }

  getDepartmentHeaderText(filter: TimeFilter) {
    return this.translationService.translate('department') + ' (' + filter?.selectedDepartmentIds?.length + ')';
  }

  getTypesHeaderText(filter: TimeFilter) {
    return this.translationService.translate('type') + ' (' + (filter?.absenceMapIds.length + filter?.overtimeMapdIds.length)  + ')';
  }

  getPositionsHeaderText(filter: TimeFilter) {
    return this.translationService.translate('job-title') + ' (' + filter?.selectedPositions.length + ')';
  }

  goBack() {
    this.showDepartmentFilters = false;
    this.showTypeFilters = false
    this.showPositionFilters = false;
  }
}
