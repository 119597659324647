import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { PipesModule } from '@mentor-one-ui/core/pipes/pipes.module';

@Component({
  selector: 'app-forbidden',
  standalone: true,
  imports: [CommonModule, PipesModule],
  styleUrl: './forbidden.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="panel unauthorized-page">
      <div class="title-emoji">⛔</div>
      <h2>{{ 'forbidden-page-title' | translate | async }}</h2>
      <p *ngIf="url">
        {{ 'you-tried-to-access' | translate | async }} <strong>{{ url }}</strong>
      </p>
      <p>{{ 'forbidden-contact-admin' | translate | async }}</p>
      <a class="p-button" href="/">{{ 'go-to-front-page' | translate | async }}</a>
    </div>
  `,
})
export class ForbiddenComponent {
  url: string;
  constructor(private router: Router) {
    this.url = this.router.getCurrentNavigation()?.extras?.state?.url;
  }
}
