import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { CalendarService } from "../services/calender.service";
import { CalendarPageActions, CalendarPageFilterActions } from "./actions/calendar-page.actions";
import { switchMap, map, catchError, of } from "rxjs";
import { CalendarApiActions } from "./actions/calendar-api.actions";
import { CalendarModel } from "../models/calendar.models";
import { Location } from '@angular/common';
import { selectCalendarViewType, selectLastNavigationDate } from "./selectors";
import { AbsenceService } from "@mentor-one-ui/time/my-time/absence/absence.service";
import { AbsenceModel } from "@mentor-one-ui/time/my-time/absence/models/absence.model";
import { AbsenceManageService } from "@mentor-one-ui/time/my-time/absence/absence-manage.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { CreateNewAbsenceModalComponent } from "../components/create-new-absence-modal/create-new-absence-modal.component";
import { TranslationDataService } from "@mentor-one-ui/core/services/translation-data.service";
import { CalendarFilterComponent } from "../components/calendar-filter/calendar-filter.component";
import { RejectLeaveDialogComponent } from "@mentor-one-ui/time/leave-management/components/reject-leave-dialog/reject-leave-dialog.component";
import { ApplicationActions } from "@mentor-one-ui/core/state/application/application.actions";
import { MessageService } from "primeng/api";

@Injectable()
export class CalendarEffects {
  constructor(
    private calendarService: CalendarService,
    private store: Store,
    private actions$: Actions,
    private location: Location,
    private absenceService: AbsenceService,
    private absenceManageService: AbsenceManageService,
    private translationService: TranslationDataService,
    private dialogService: DialogService
  ) { }

  loadCalendarData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarPageActions.Enter),
      concatLatestFrom(() => [this.store.select(selectLastNavigationDate)]),
      switchMap(([action, date]) => {
        var selectedDate: Date = date ? new Date(date) : new Date();
        const firstDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
        const lastDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);
        return this.calendarService.getCalenderEvents(firstDay, lastDay).pipe(
          map((result: CalendarModel) => {
            return CalendarApiActions.loadCalendarsSuccess({ calendarData: result, date: date! })
          }),
          catchError((e) => of(CalendarApiActions.loadCalendarDataError(e.message)))
        )
      })
    )
  );

  calendarNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarPageActions.dateChanged),
      concatLatestFrom(() => [this.store.select(selectLastNavigationDate), this.store.select(selectCalendarViewType)]),
      switchMap(([action, date, viewType]) => {

        const selectedDate = new Date(date);
        let firstDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
        let lastDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);

        if(viewType === 'week') {
          firstDay = getMonday(selectedDate);
          lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate() + 6);
        }

        return this.calendarService.getCalenderEvents(firstDay, lastDay).pipe(
          map((result: CalendarModel) => {
            return CalendarApiActions.loadCalendarsSuccess({ calendarData: result, date: date! })
          }),
          catchError((e) => of(CalendarApiActions.loadCalendarDataError(e.message)))
        )
      })
    )
  );

  setDefaultTitle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarPageActions.Enter),
      map((action) => {
        return CalendarPageActions.setDefaultTitle({ title: '👤' + this.translationService.translate('absence') });
      })
    )
  );

  calendarEventClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarPageActions.calendarEventClicked),
      switchMap((action) => {
        return this.absenceService.getAbsence(action.eventId).pipe(
          map((result: AbsenceModel) => {

            return CalendarApiActions.loadCalendarEventDetailsSuccess({ model: result })
          }),
          catchError((e) => of(CalendarApiActions.loadCalendarEventDetailsError(e.message)))
        )
      })
    )
  );

  dialogRef: DynamicDialogRef;

  openRoleOverviewModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarPageActions.openCreateNewAbsenceModal),
      switchMap((action) => {
        this.dialogRef = this.dialogService.open(CreateNewAbsenceModalComponent, {
          header: this.translationService.translate('new-registration'),
          width: '20rem',
          styleClass: 'create-new-absence-modal',
          closable: true,

        });

        return this.dialogRef.onClose;
      })
    ),
    { dispatch: false }
  );


  dialogClosed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CalendarPageActions.closeCreateNewAbsenceModal,
        ),
        map((payload) => {
          if (this.dialogRef) {
            this.dialogRef.close();
          }
        })
      ),
    { dispatch: false }
  );


  openFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarPageActions.OpenFilterDialog),
      switchMap((action) => {
        this.dialogRef = this.dialogService.open(CalendarFilterComponent, {
          data: {
            model: {}
          },
          closeOnEscape: false,
          closable: false,
          height: 'auto',
        });

        return this.dialogRef.onClose;
      })
    ),
    { dispatch: false }
  );

  filterModalClosed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CalendarPageFilterActions.closeFilter,
          CalendarPageFilterActions.setFilter,
        ),
        map((payload) => {
          if (this.dialogRef) {
            this.dialogRef.close();
          }
        })
      ),
    { dispatch: false }
  );


  approveAbsenceFromCalendar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarApiActions.ApproveLeaveRequest),
      switchMap((action) => this.absenceManageService.approveAbsence(action.absenceId).pipe(
        map((absence: AbsenceModel) => {
          return CalendarApiActions.ApproveAbsenceSuccess({ absence: absence });
        }),
        catchError((e) => of(CalendarApiActions.ApproveAbsenceError({ error: this.translationService.translate('could-not-approve-registration') })))
      )
      ))
  );

  showSuccessToastOnAbsenceApproveSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CalendarApiActions.ApproveAbsenceSuccess),
        map((action) => {
          return ApplicationActions.SuccessMessage({
            title: this.translationService.translate('success'),
            message: this.translationService.translate('registration-approved')
          });
        })
      );
    }
  );

  showSuccessToastOnAbsenceRejectSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CalendarApiActions.RejectAbsenceSuccess),
        map((action) => {
          return ApplicationActions.SuccessMessage({
            title: this.translationService.translate('success'),
            message: this.translationService.translate('registration-rejected')
          });
        })
      );
    }
  );

  showErrorMessageToUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        CalendarApiActions.ApproveAbsenceError,
        CalendarApiActions.RejectAbsenceError
      ),
      map((action) => {
        return ApplicationActions.ErrorMessage({
          title: this.translationService.translate('something-went-wrong'),
          message: action.error,
        });
      })
    );
  });

  showRejectModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarPageActions.ShowRejectAbsenceDialog),
      switchMap((action) => {

        this.dialogRef = this.dialogService.open(RejectLeaveDialogComponent, {
          data: {
            model: action.model
          },
          closeOnEscape: false,
          closable: false,
        });

        return this.dialogRef.onClose;
      }),
      map((data) => {
        if (data && data.save) {
          return CalendarApiActions.RejectLeaveRequest({ absenceId: data.leaveId, comment: data.comment });
        }
        else {
          return CalendarPageActions.CancelRejectAbsenceRequest();
        }
      })
    ),
  );

  confirmRejectAbsence$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarApiActions.RejectLeaveRequest),
      switchMap((action) => this.absenceManageService.rejectAbsence(action.absenceId, action.comment).pipe(
        map((absence: AbsenceModel) => {
          return CalendarApiActions.RejectAbsenceSuccess({ id: absence.AbsenceId });
        }),
        catchError((e) => of(CalendarApiActions.RejectAbsenceError({ error: this.translationService.translate('could-not-reject-registration') })))
      )))
  );
}

function isDateInThisWeek(date: Date) {
  const todayObj = new Date();
  const todayDate = todayObj.getDate();
  const todayDay = todayObj.getDay();

  // get first date of week
  const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

  // get last date of week
  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

  // if date is equal or within the first and last dates of the week
  return date >= firstDayOfWeek && date <= lastDayOfWeek;
}

function getMonday(d: Date) {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

function isDateInThisMonth(date: Date) {
  if (new Date().getFullYear() === date.getFullYear() && new Date().getMonth() === date.getMonth()) {
    return true;
  }
  return false;
}

