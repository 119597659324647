import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SystemAccessRole } from '@mentor-one-ui/core/models/employee/SystemAccessRole';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(public http: HttpClient) {}

  getAvailiableRoles(): Observable<SystemAccessRole[]> {
    return this.http.get<SystemAccessRole[]>(environment.API_URL + '/role/availableroles');
  }

  updateEmployeeRoles(employeeId: number, roleIds: number[]): Observable<number[]> {
    return this.http.post<number[]>(environment.API_URL + '/Employee/' + employeeId + '/roles/update', roleIds);
  }
}
