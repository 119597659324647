import { environment } from 'src/environments/environment';
import { Injectable, OnInit } from '@angular/core';
import { ApplicationInsights, Exception, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserSelectors } from '@mentor-one-ui/core/state/user/user.selector';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightsService {
  private routerSubscription: Subscription;

  private appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
      enableAutoRouteTracking: true, // option to log all route changes
    },
  });

  constructor(private router: Router, private store: Store) {}

  /// <summary>
  /// Initializes the Application Insights service
  /// </summary>
  public Init() {
    this.appInsights.loadAppInsights();

    this.store.select(UserSelectors.selectUser).subscribe((user) => {
      if (user?.EmployeeId != null && user?.OrganizationId != null)
        this.appInsights.setAuthenticatedUserContext(
          user?.EmployeeId?.toString(),
          user?.OrganizationId.toString(),
          true
        );

      var telemetryInitializer = (envelope: ITelemetryItem) => {
        if (envelope.data) {
          envelope.data['EmployeeId'] = user?.EmployeeId?.toString();
          envelope.data['EmployeeName'] = user?.EmployeeFullName;
          envelope.data['OrganizationId'] = user?.OrganizationId.toString();
          envelope.data['OrganizationName'] = user?.OrganizationName;
        }
      };
      this.appInsights.addTelemetryInitializer(telemetryInitializer);

      this.appInsights.setAuthenticatedUserContext(
        user?.EmployeeId?.toString() ?? 'unknown employee id',
        user?.OrganizationName ?? 'unknown organization name',
        true
      );
    });

    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof ResolveEnd))
      .subscribe((event: any) => {
        //ResolveEnd
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent) {
          this.logPageView(
            `${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`,
            event.urlAfterRedirects
          );
        }
      });
  }

  public logHttpErrorResponse(ex: HttpErrorResponse) {
    this.appInsights.trackException({ error: ex });
  }

  public GetSessionId(): string {
    return this.appInsights.context.getSessionId();
  }

  public logError(errorMessage: string) {
    this.appInsights.trackException({ error: new Error(errorMessage) });
  }

  public logException(exception: Error) {
    this.appInsights.trackException({ error: exception });
  }

  public trackEvent(event: string) {
    this.appInsights.trackEvent({ name: event });
  }

  private logPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({ name, uri });
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }
}
