import { createReducer, on } from "@ngrx/store";
import { ManageApiActions } from "../actions/manage-api.actions";
import { LeaveManagementPageActions, LeaveManagementDetailsPageActions } from "../../containers/leave-management-page/actions/manage-absence.actions";
import { UnmanagedLeavesPageActions } from "../../containers/unmanaged-leaves-page/actions/unmanaged-leaves-page.actions";

export const featureKey = 'approvalPage';
  export interface State {

  isSaving: boolean;
  selectedId: number;  

}

export const initialState: State = {  
  isSaving: false,
  selectedId: -1,  
};

export const reducer = createReducer(
  initialState,
  on(UnmanagedLeavesPageActions.ApproveLeaveRequest, LeaveManagementDetailsPageActions.ApproveLeaveRequest, (state, action): State => {
    return {
      ...state,
      isSaving: true,
      selectedId: action.leaveId

    }
  }),
  on(ManageApiActions.ApproveAbsenceSuccess, (state, { id }): State => {
    return {

      ...state,
      isSaving: false,
      selectedId: -1
    }
  }),

  on(LeaveManagementPageActions.RejectLeaveRequest, (state): State => {
    return {
      ...state,
      isSaving: true
    }
  }),

  on(ManageApiActions.RejectAbsenceSuccess, (state, { id }): State => {
    return {
      ...state,
      isSaving: false
    }
  }),
  on(ManageApiActions.RejectAbsenceError, ManageApiActions.ApproveAbsenceError, (state): State => {
    return {
      ...state,
      isSaving: false,
      selectedId: -1
    }
  }),
  

);


