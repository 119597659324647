import { FormControl, FormGroup } from '@angular/forms';
import { GroupOrganizationTypeEnum } from '../enums';
import { SystemEnum } from '@mentor-one-ui/core/models/enums';

export interface UserOrganizationModel {
  HasChildren: boolean;
  // userorganizationmodel
  OrganizationId: number;
  DisplayName: string;
  GroupOrganizationType: GroupOrganizationTypeEnum;
  ChildOrganizations: UserOrganizationModel[];
  IsCurrentOrganization: boolean;
  IsSupportLogin: boolean;
  Expires: string;
  Sid?: number;

  // UserOrganization - About Page - Form - Model
  Name: string;
  OrganizationNumber: string;
  BusinessNumber: string;
  PhoneNumber: string;
  PostalAddress: string;
  PostalZipCode: string;
  PostalCity: string;
  VisitAddress: string;
  VisitZipCode: string;
  VisitCity: string;
  Vision: string;
  HideSupportBox: boolean;
  ShowLastBalanceTransition: number | null;

  // UserOrganization - About Page - Image - Model
  Map: string;

  HrSettings: UserOrganizationHrSettings;

  SystemAccess: SystemAccess[];
}

export interface SystemAccess {
  System: SystemEnum;
  ModuleCodes: string[];
}

export interface UserOrganizationHrSettings {
  PoliceCertificateIsRequired: boolean;
  ConfidentialityDeclaration: boolean;

  SelfCertificationLimitNumberOfOccurrences: boolean;
  SelfCertificationOccurencesPer12Months: number | null;
  SelfCertificationMaxDaysPerOccurence: number | null;
  SelfCertificationMaxDaysPerPeriod: number | null;
  SelfCertificationMaxDaysPerYear: number;

  NumberOfVacationDays: Number;
  SaturdaysIsWorkTime: boolean;
  SundayIsWorkingTime: boolean;
  AllowHalfDayCareDayAbsence: boolean;
  AbsenceAreVisibleToAllEmployees: boolean;
}

// todo use this everywhere and expand it to include all fields (?)
export function createHrSettingsForm(orgModel: UserOrganizationModel): FormGroup {
  return new FormGroup({
    PoliceCertificateIsRequired: new FormControl(orgModel.HrSettings.PoliceCertificateIsRequired),
    ConfidentialityDeclaration: new FormControl(orgModel.HrSettings.ConfidentialityDeclaration),
    NumberOfVacationDays: new FormControl(orgModel.HrSettings.NumberOfVacationDays),
    AllowHalfDayCareDayAbsence: new FormControl(orgModel.HrSettings.AllowHalfDayCareDayAbsence),
    RegisterSeriesSaturday: new FormControl(orgModel.HrSettings.SaturdaysIsWorkTime),
    RegisterSeriesSunday: new FormControl(orgModel.HrSettings.SundayIsWorkingTime),

    // TODO: add these fields in BE
    SeniorPolicy: new FormControl(false),
    SeniorPolicyAge: new FormControl(),
    SeniorDaysFixed: new FormControl(false),
    SeniorDaysNumberOfDaysTotal: new FormControl(''),
    SeniorDaysNumberOfDaysPerYear: new FormControl(''),
    Comment: new FormControl(''),
  });
}

export function combineHrSettingsAndOrgModel(form: FormGroup, orgModel: UserOrganizationModel): UserOrganizationModel {
  return {
    ...orgModel,
    HrSettings: {
      PoliceCertificateIsRequired: form.value.PoliceCertificateIsRequired,
      ConfidentialityDeclaration: form.value.ConfidentialityDeclaration,
      NumberOfVacationDays: form.value.NumberOfVacationDays,
      AllowHalfDayCareDayAbsence: form.value.AllowHalfDayCareDayAbsence,
      SaturdaysIsWorkTime: orgModel?.HrSettings?.SaturdaysIsWorkTime,
      SundayIsWorkingTime: orgModel?.HrSettings?.SundayIsWorkingTime,
      AbsenceAreVisibleToAllEmployees: form.value.AbsenceAreVisibleToAllEmployees,
    } as UserOrganizationHrSettings,
  };
}
