import { NgModule } from '@angular/core';
import { TranslatePipe } from './translate.pipe';
import { CultureCodePipe } from './culturecode.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { DepartmentPipes } from './department.pipe';
import { TimespanPipes } from './timespan.pipe';
import { HoursFormatterPipe } from '@mentor-one-ui/time/pipes/hours-formatter';
import { DaysFormatterPipe } from '@mentor-one-ui/time/pipes/days-formatter';
import { DateOnlyToStringPipe } from './dateonly.pipe';
import { DaysFormaterPipe } from './days-formater.pipe';


@NgModule({
  declarations: [TranslatePipe, CultureCodePipe, SafeHtmlPipe, DepartmentPipes, TimespanPipes, HoursFormatterPipe, DaysFormatterPipe, DateOnlyToStringPipe, DaysFormaterPipe],
  imports: [],
  exports: [TranslatePipe, CultureCodePipe, SafeHtmlPipe, DepartmentPipes, TimespanPipes, HoursFormatterPipe, DaysFormatterPipe, DateOnlyToStringPipe, DaysFormaterPipe],
})
export class PipesModule {}
