import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TimeFilter } from '../time-filter.model';
import { TimeManagementType } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementType';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { SelectItemGroup } from 'primeng/api';
import { DepartmentLightModel } from '@mentor-one-ui/core/models/DepartmentModel';
import { ButtonBarButton } from '@mentor-one-ui/core/models/ButtonBar';
import { AppPermissionEnum } from '@mentor-one-ui/core/permissions/app-permissions';

@Component({
  selector: 'mo-time-filter-desktop',
  styleUrls: ['./time-filter-desktop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="filter-desktop">
      <div *hasPermission="AppPermissionEnum.IsMentorPersonalAdminOrPersonnelManager">
        <div class="filter-desktop__status" *ngIf="showStatusFilter">
          <label>{{ 'status' | translate | async }}</label>
          <p-selectButton
            (onChange)="onStatusChange($event)"
            [options]="statusTypes"
            [ngModel]="selectedStatus"
            optionLabel="name"
            optionValue="value">
          </p-selectButton>
        </div>
      </div>
      <div class="filter-desktop__selectors">
        <div>
          <label>{{ 'department' | translate | async }}</label>
          <p-listbox
            [ngModel]="filter.selectedDepartmentIds"
            (onClick)="toggleDepartment.emit($event.option.DepartmentId)"
            [listStyle]="{'max-height': '400px', 'min-height': '400px'}"
            [options]="departments"
            optionValue="DepartmentId"
            optionLabel="Name"
            [multiple]="true"
            [checkbox]="true"
            [showToggleAll]="false">
          </p-listbox>
        </div>
        <div *hasPermission="AppPermissionEnum.IsMentorPersonalAdminOrPersonnelManager">
          <label>{{ 'type' | translate | async }}</label>
          <p-listbox class="indented-group-list"
            [ngModel]="selectedLeaveTypes"
            (onClick)="selectLeaveType($event)"
            [listStyle]="{'max-height': '400px', 'min-height': '400px'}"
            [options]="leaveTypesGrouped"
            [multiple]="true"
            [checkbox]="true"
            [group]="true"
            [showToggleAll]="false">
            <ng-template let-group pTemplate="group">
                <p-checkbox (onChange)="onTypeGroupChecked($event, group)" [label]="group.label" [binary]="true"></p-checkbox>
            </ng-template>
          </p-listbox>
        </div>
        <div>
          <label>{{ 'job-title' | translate | async }}</label>
          <p-listbox
            [ngModel]="filter.selectedPositions"
            (onClick)="togglePosition.emit($event.option)"
            [listStyle]="{'max-height': '400px', 'min-height': '400px'}"
            [options]="positionTitles"
            [multiple]="true"
            [checkbox]="true"
            [showToggleAll]="false">
          </p-listbox>
        </div>
      </div>
      <div class="" >
      <!-- <div class="filter-desktop__header">
          <button [disabled]="selectedFilterCount == 0" (click)="resetFilter.emit()" pButton label="Nullstill" class="p-button-secondary p-button-sm"></button>
      </div>    -->
        <mo-button-bar
          [buttonList]="buttons"
          (buttonBarClick)="handleButtonClick.emit($event)">
        </mo-button-bar>
      </div>
    </div>
    `,
})
export class TimeFilterDesktopComponent {
  @Input() filter: TimeFilter;
  @Input() selectedFilterCount: number | null;
  @Input() departments: DepartmentLightModel[];
  @Input() positionTitles: string[];
  @Input() showStatusFilter: boolean;
  @Input() selectedLeaveTypes: string[];
  @Input() selectedStatus: number;
  @Input() leaveTypesGrouped: SelectItemGroup[];
  @Input() statusTypes: { name: string; value: number; }[];
  @Input() buttons: ButtonBarButton[];

  @Output() resetFilter = new EventEmitter();
  @Output() setFilterStatus = new EventEmitter<number[]>();
  @Output() togglePosition = new EventEmitter<string>();
  @Output() filterByOvertimeType = new EventEmitter<number[]>();
  @Output() filterByAbsenceType = new EventEmitter<number[]>();
  @Output() toggleDepartment = new EventEmitter<number>();
  @Output() handleButtonClick = new EventEmitter<ButtonBarButton>();

  AppPermissionEnum = AppPermissionEnum;

  onStatusChange(event: any) {
    this.setFilterStatus.emit([event.value]);
  }

  onTypeGroupChecked(event: CheckboxChangeEvent, group: any) {
    if (event.checked) {
      if (group.value == TimeManagementType.Absence) {
        this.filterByAbsenceType.emit(group.items.map((f: any) => f.id));
      }
      else {
        this.filterByOvertimeType.emit(group.items.map((f: any) => f.id));
      }
    } else {
      if (group.value == TimeManagementType.Absence) {
        this.filterByAbsenceType.emit([]);
      }
      else {
        this.filterByOvertimeType.emit([]);
      }
    }
  }

  selectLeaveType(event: any) {
    if (event.option.type === TimeManagementType.Absence) {
      if (this.selectedLeaveTypes.includes(event.option.value)) {
        this.filterByAbsenceType.emit(this.filter.absenceMapIds.filter(f => f != event.option.id));
      } else {
        this.filterByAbsenceType.emit([...this.filter.absenceMapIds, event.option.id]);
      }
    } else {
      if (this.selectedLeaveTypes.includes(event.option.value)) {
        this.filterByOvertimeType.emit(this.filter.overtimeMapdIds.filter(f => f != event.option.id));
      } else {
        this.filterByOvertimeType.emit([...this.filter.overtimeMapdIds, event.option.id]);
      }
    }
  }

}
