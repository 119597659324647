import { AccessControlSystemEditModel } from '@mentor-one-ui/core/models/module-access';
import { createAction, props } from '@ngrx/store';
import { MentorAdminCreateInternalUser, MentorAdminOrganizationModel } from '@mentor-one-ui/core/models/admin-organization/admin-organization.model';
import { SupportLoginRequestModel } from '@mentor-one-ui/core/models/admin-organization/support-login.model';

const LoadOrganizationSuccess = createAction(
  '[OrganizationDetailsApi] Load success',
  props<{ organization: MentorAdminOrganizationModel }>()
);

const LoadOrganizationError = createAction('[OrganizationDetailsApi] Load error', props<{ error: string }>());

const LoadModuleAccessDefaults = createAction('[OrganizationDetailsApi] Load module access defaults');

const LoadModuleAccessDefaultsSuccess = createAction(
  '[OrganizationDetailsApi] Load module access defaults success',
  props<{ moduleaccessdefaults: AccessControlSystemEditModel[] }>()
);

const SaveOrganizationSuccess = createAction('[OrganizationDetailsApi] Save success', props<{ organization: MentorAdminOrganizationModel }>());

const DeleteOrganization = createAction('[OrganizationDetailsApi] Delete organization', props<{ organizationId: number }>());
const DeleteOrganizationSuccess = createAction('[OrganizationDetailsApi] Delete organization success', props<{ organizationId: number }>());
const DeleteOrganizationError = createAction('[OrganizationDetailsApi] Delete organization error', props<{ error: string }>());

  const SupportLoginRequest = createAction('[OrganizationDetailsApi] Support Login Request', props<{ supportRequest: SupportLoginRequestModel }>());
  const SupportLoginRequestSuccess = createAction('[OrganizationDetailsApi] Support Login Request Success');
  const SupportLoginRequestError = createAction('[OrganizationDetailsApi] Support Login Request Error', props<{ error: string }>());

  const SupportLogoutRequest = createAction('[OrganizationDetailsApi] Support Logout request', props<{ sid: number }>());
  const SupportLogoutRequestSuccess = createAction('[OrganizationDetailsApi] Support Logout request success');
  const SupportLogoutRequestError = createAction('[OrganizationDetailsApi] Support Logout request error', props<{ error: string }>());

const AddInternalAdministrator = createAction('[OrganizationDetailsApi] Add Internal Administrator', props<{ employee: MentorAdminCreateInternalUser }>());
const AddInternalAdministratorSuccess = createAction('[OrganizationDetailsApi] Add Internal Administrator Success', props<{ employeeId: number }>());
const AddInternalAdministratorError = createAction('[OrganizationDetailsApi] Add Internal Administrator Error', props<{ error: any }>());

const LoadModuleAccessDefaultsError = createAction(
  '[OrganizationDetailsApi] Load module access defaults error',
  props<{ error: string }>()
);

const SaveOrganizationError = createAction('[OrganizationDetailsApi] Save error', props<{ error: string }>());

export const OrganizationDetailsApiActions = {
  LoadOrganizationError,
  LoadOrganizationSuccess,
  LoadModuleAccessDefaults,
  LoadModuleAccessDefaultsSuccess,
  LoadModuleAccessDefaultsError,
  SaveOrganizationSuccess,
  SaveOrganizationError,
  SupportLoginRequest,
  SupportLoginRequestSuccess,
  SupportLoginRequestError,
  SupportLogoutRequest,
  SupportLogoutRequestSuccess,
  SupportLogoutRequestError,
  DeleteOrganization,
  DeleteOrganizationSuccess,
  DeleteOrganizationError,
  AddInternalAdministrator,
  AddInternalAdministratorSuccess,
  AddInternalAdministratorError
  };
