export const Absence = '2A783F99-4D76-4A90-AA6B-054F560D2BAC';
export const ManagePersonnelFolder = '6B8A3434-5D2F-4F7F-99A2-9D26CF778E7F';
export const OrganizationDetails = '77FEB9D1-CD3B-4C69-A3D7-018A555C9098';
export const OrganizationContacts = '9B2040E0-DED8-4F40-8A52-5210C69A5B5C';
export const EmployeeList = '7AAF19CB-E5D4-48BF-94B6-1077B6934951';

export const EmployeeDetails = '7AC54581-CE24-4E54-8931-332EC53C5EDE';
export const EmployeeDetailsEmployeeEditableFields = 'F2F0B0A0-5F0B-4F9A-9E0B-4F9B9F0B4F9A';

export const AppPermissions = {
  Absence,
  ManagePersonnelFolder,
  OrganizationDetails,
  OrganizationContacts,
  EmployeeList,
  EmployeeDetails,
  EmployeeDetailsEmployeeEditableFields,
};

export enum AppPermissionEnum {
  IsSystemAdmin,
  IsMentorPersonalAdmin,
  IsMentorPersonalAdminOrPersonnelManager,
}
