
export interface SickLeaveReportAggregationModel {
  Title: string;
  Genders: SickLeaveReportGenderModel[];
  TotalManDays: number;
  TotalAbsenceDays: number;
  TotalOccurrences: number;
  TotalAbsencePercentage: number;
}

export class SickLeaveReportGenderModel {
  ManDays: number;
  AbsenceDays: number;
  AbsenceOccurrences: number;
  AbsencePercentage: number;
  Title: string;
}

export class SickLeaveReportViewModelModel {
  SortOrder: number;
  ManDays: number;
  AbsenceDays: number;
  AbsenceOccurrences: number;
  AbsencePercentage: number;
  Title: string;
  GroupTitle: string;
  TotalManDays: number;
  TotalAbsenceDays: number;
  TotalOccurrences: number;
  TotalAbsencePercentage: number;
}

export interface SickLeaveReportPartModel {
  SickLeaveReportLength: SickLeaveReportLengthEnum;
  Title: string;
  Aggregations: SickLeaveReportAggregationModel[];
}

export interface SickLeaveReportModel {
  Parts: SickLeaveReportPartModel[];
  Year: number;
}

export interface SickLeaveReportRequestModel {
  Year: number;
  AggregationType: AggregationTypeEnum;
  AbsenceReportLengths: SickLeaveReportLengthEnum[];
}

export interface SickLeaveReportFilterModel {
  Year: number;
  AggregationType: AggregationTypeEnum;
  AbsenceReportLengths: SickLeaveReportLengthEnum[];
}

export enum SickLeaveReportLengthEnum {
  TotalAllLengths = 0,
  SelfCertified = 10,
  ShortMedicalCertificate = 20,
  MediumMedicalCertificate = 30,
  LongerMedicalCertificate = 40,
  ProlongedMedicalCertificate = 50
}

export enum AggregationTypeEnum {
  ByYear = 0,
  ByQuarter = 1,
  ByMonth = 2
}
