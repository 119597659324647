import { AbsenceModel } from '@mentor-one-ui/time/my-time/absence/models/absence.model';

export interface SickLeaveFollowUpModel {
  SickLeaveFollowUpId: number;
  EmployeeId?: number;
  EmployeeName?: string | null;
  TaskCount?: number;
  DurationInDays?: number;
  FromDate?: Date;
  ToDate?: Date;
  Items?: SickLeaveFollowUpItemModel[];
  Length?: SickLeaveFollowUpLengthEnum;
  Absences: AbsenceModel[];
  ExpiredCount: number;
  DeadlineTodayCount: number;
  DeadlineApproachingCount: number;
  IsClosed: boolean;
  IsCompleted: boolean;
}

export interface SickLeaveFollowUpItemModel {
  SickLeaveFollowUpItemId: number;
  Title: string;
  Date: Date;
  OrganizationAbsenceMapId?: number;
  Guidance?: string;
  ItemType?: SickLeaveFollowUpItemTypeEnum;
  Icon?: string;
  IconType?: IconTypeEnum;
  Completed?: boolean;
  CanBeCompleted?: boolean;
  Status: SickLeaveFollowUpItemStatusEnum;
  Notes: SickLeaveFollowUpItemNoteModel[];
  Files: SickLeaveFollowUpItemFileModel[];
  AbsenseId?: number;
}

export enum SickLeaveFollowUpItemTypeEnum {
  Today = 1,
  Absence = 10,
  LastAbsenceEndDate = 11,
  FollowUpClosed = 12,
  Guidance = 20,
  Note = 30,
  File = 40,
}

export enum IconTypeEnum {
  None = 0,
  Emoji = 1,
  FontAwesome = 2,
}

export enum SickLeaveFollowUpItemStatusEnum {
  UpToDate = 0,
  DeadlineApproaching = 1,
  DeadlineToday = 2,
  ExpiredDeadline = 3,
  Completed = 4,
}

export interface SickLeaveFollowUpFilterModel {
  EmployeeId?: number | null;
  FollowUpLength: SickLeaveFollowUpLengthEnum;
  Take: number;
  Skip: number;
}

export enum SickLeaveFollowUpLengthEnum {
  TotalAllLengths = 0,
  Short = 1,
  Medium = 2,
  Longer = 3,
  Prolonged = 4,
}

export interface SickLeaveFollowUpRequestModel {
  Length: SickLeaveFollowUpLengthEnum | null;
  Skip: number;
  Take: number;
  EmployeeId: number | null;
}

export interface SickLeaveFollowUpResponseModel {
  Count: number;
  Items: SickLeaveFollowUpModel[];
}

export interface SickLeaveFrontpageStatusModel {
  ExpiredCount: number;
  DeadlineTodayCount: number;
  DeadlineApproachingCount: number;
  HasNoFollowUps: boolean;
}

export interface SickLeaveFollowUpItemNoteModel {
  SickLeaveFollowUpItemId?: number;
  Id?: number;
  Note?: string;
  Date?: string;
  CreatedByEmployeeId?: number;
  Title?: string;
}

export interface SickLeaveFollowUpItemNoteRequestModel {
  Note?: string;
  Date?: string;
  Title?: string;
}

export interface SickLeaveFollowUpItemFileModel {
  Id: number;
  Date: string;
  CreatedByEmployeeId: number;
  FileName: string;
  ContentType: string;
}

export class FileForUpload {
  fileName: string;
  uploaded: boolean;
  progress: number;
  error: boolean;
  file: File;
}

export interface SickLeaveFollowUpPdfRequestModel {
  IncludeGuidance: boolean;
  IncludeNotes: boolean;
  IncludeFiles: boolean;
}
