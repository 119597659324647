import { PersonnelFileModel } from '@mentor-one-ui/core/models/employee/PersonnelFileModel';
import { createAction, props } from '@ngrx/store';
import { FileForUpload } from '../reducers/personnel-file.reducer';

export const EmployeePersonnelFileModalActions = {
  SavePersonnelFiles: createAction(
    '[Employee - PersonnelFile Tab] Save PersonnelFiles',
    props<{ files: FileForUpload[] }>()
  ),
  SavePersonnelFile: createAction(
    '[Employee - PersonnelFile Tab] Save PersonnelFile',
    props<{ file: FileForUpload }>()
  ),
  DeletePersonnelFile: createAction(
    '[Employee - PersonnelFile Tab] Delete PersonnelFile',
    props<{ personnelFile: PersonnelFileModel }>()
  ),
  CloseModal: createAction('[Employee - PersonnelFile Tab] Modal Close'),
};
export const EmployeePersonnelFileTabActions = {
  AddPersonnelFileForUpload: createAction(
    '[Employee - PersonnelFile Tab] Add PersonnelFile For Upload',
    props<{ files: FileForUpload[] }>()
  ),
  RemovePersonnelFileForUpload: createAction(
    '[Employee - PersonnelFile Tab] Remove PersonnelFile For Upload',
    props<{ fileName: string }>()
  ),
  EnterPersonnelFileTab: createAction('[Employee - PersonnelFile Tab] Enter'),
  OpenModal: createAction(
    '[Employee - PersonnelFile Tab] PersonnelFile Modal Open',
    props<{ personnelFileId: number }>()
  ),
  DownloadPersonnelFile: createAction(
    '[Employee - PersonnelFile Tab] Download PersonnelFile',
    props<{ personnelFile: PersonnelFileModel }>()
  ),
};

export const EmployeePersonnelFileApiActions = {
  LoadPersonnelFileListSuccess: createAction(
    '[Employee - PersonnelFile API] Load PersonnelFile Success',
    props<{ employeeId: number; personnelFileList: PersonnelFileModel[] }>()
  ),
  SavePersonnelFileProgress: createAction(
    '[Employee - PersonnelFile API] Save PersonnelFile Progress',
    props<{ progress: number; fileName: string }>()
  ),
  SavePersonnelFileSuccess: createAction(
    '[Employee - PersonnelFile API] Save PersonnelFile Success',
    props<{ employeeId: number; fileName: string }>()
  ),
  UploadFilesComplete: createAction('[Employee - PersonnelFile API] Upload Files Complete'),
  DeletePersonnelFileSuccess: createAction(
    '[Employee - PersonnelFile API] Delete PersonnelFile Success',
    props<{ employeeId: number; files: PersonnelFileModel[] }>()
  ),
  LoadPersonnelFileListError: createAction(
    '[Employee - PersonnelFile API] Load PersonnelFile Error',
    props<{ error: string }>()
  ),
  SavePersonnelFileError: createAction(
    '[Employee - PersonnelFile API] Save PersonnelFile Error',
    props<{ error: string; fileName: string }>()
  ),
  DeletePersonnelFileError: createAction(
    '[Employee - PersonnelFile API] Delete PersonnelFile Error',
    props<{ error: string }>()
  ),
  DownloadPersonnelFileSuccess: createAction('[Employee - PersonnelFile API] Download PersonnelFile Success'),
  DownloadPersonnelFileError: createAction(
    '[Employee - PersonnelFile API] Download PersonnelFile Error',
    props<{ error: string }>()
  ),
};
