import { ContactTagModel as ContactTagModel } from '@mentor-one-ui/core/models/user-organization/contact/ContactTagModel';
import { MapTypeKeys } from '../../Model.Utils.MapTypeKeys';

interface ContactFields {
  FirstName: string;
  LastName: string;
  TelephoneNumber: string;
  Email: string;
  PostalAddress: string;
}
interface ContactInterface {
  ContactId: number;
  UsefulInformation: string;
  ContactTags: ContactTagModel[];
}

export class ContactModel implements ContactInterface, ContactFields {
  constructor(
    public ContactId: number,
    public FirstName: string,
    public LastName: string,
    public TelephoneNumber: string,
    public Email: string,
    public PostalAddress: string,

    public UsefulInformation: string,
    public ContactTags: ContactTagModel[],
    public showInformation: boolean
  ) {
    showInformation = false;
  }
}

export type SortContact = MapTypeKeys<ContactFields, boolean | undefined>;
export const sortContact: SortContact = {
  FirstName: undefined,
  LastName: undefined,
  TelephoneNumber: undefined,
  Email: undefined,
  PostalAddress: undefined,
};
