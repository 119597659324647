import { createReducer, on } from '@ngrx/store';
import { PersonnelManagerModel } from '../../../core/models/employee/EmploymentModel';
import { EmployeePageActions } from '../actions/employee-page.actions';
import { EmployeeEmploymentTabActions, EmployeeEmploymentApiActions } from '../actions/employee-employment.actions';
import { EmployeeModalActions } from '../actions/employee-details.actions';

export const featureKey = 'employment';

export interface State {
  selectedEmploymentId: number;
  isSaving: boolean;
  error: boolean;
}

export const initialState: State = {
  selectedEmploymentId: -1,
  isSaving: false,
  error: false,
};

export const reducer = createReducer(
  initialState,
  on(EmployeePageActions.Enter, (state) => initialState),
  on(EmployeeEmploymentApiActions.LoadEmploymentHistorySuccess, (state): State => { 
    return {
      ...state,
      error: false,
    };
  }),
  on(EmployeeEmploymentApiActions.LoadEmploymentHistoryError, (state): State => { 
    return {
      ...state,
      error: true,
    };
  }),
  on(EmployeeEmploymentTabActions.OpenEmploymentModal, (state, { employmentId }): State => {
    return {
      ...state,
      selectedEmploymentId: employmentId,
      isSaving: false,
    };
  }),
  on(EmployeeModalActions.CloseDetailsModal, (state): State => {
    return {
      ...state,
      selectedEmploymentId: -1
    };
  }),
  on(EmployeeEmploymentTabActions.SaveEmployment, (state): State => {
    return {
      ...state,
      isSaving: true,
    };
  }),
  on(
    EmployeeEmploymentApiActions.SaveEmploymentSuccess,
    EmployeeEmploymentApiActions.DeleteEmploymentSuccess,
    (state, action): State => {
      return {
        ...state,
        isSaving: false,
      };
    }
  ),
  on(EmployeeEmploymentApiActions.SaveEmploymentError, (state, action): State => {
    return {
      ...state,
      isSaving: false,
    };
  }),
);
