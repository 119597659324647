import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ButtonBarButton, ButtonAction } from '@mentor-one-ui/core/models/ButtonBar';
import { DepartmentLightModel } from '@mentor-one-ui/core/models/DepartmentModel';
import { TimeFilter } from '../time-filter.model';
import { AbsenceType } from '@mentor-one-ui/time/my-time/absence/models/absence-type.model';
import { OvertimeType } from '@mentor-one-ui/time/my-time/overtime/models/overtime-type.model';
import { SelectItemGroup } from 'primeng/api';
import { TimeManagementType } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementType';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';

@Component({
  selector: 'mo-time-filter-options',
  styleUrls: ['./time-filter-options.component.scss'],
  template: `
    <mo-time-filter-mobile
      [filter]="filter"
      [departments]="departments"
      [absenceTypes]="absenceTypes"
      [overtimeTypes]="overtimeTypes"
      [positionTitles]="positionTitles"
      [selectedFilterCount]="selectedFilterCount"
      [statusTypes]="statusTypes"
      [selectedStatus]="selectedStatus"
      [showStatusFilter]="showStatusFilter"
      [buttons]="buttons"
      [showOvertimeTypes]="showOvertimeTypes"
      (filterByOvertimeType)="filterByOvertimeType.emit($event)"
      (filterByAbsenceType)="filterByAbsenceType.emit($event)"
      (setFilterStatus)="setFilterStatus.emit($event)"
      (resetFilter)="resetFilter.emit()"
      (toggleDepartment)="toggleDepartment.emit($event)"
      (togglePosition)="togglePosition.emit($event)"
      (handleButtonClick)="handleButtonClick($event)"
    >
    </mo-time-filter-mobile>
    <mo-time-filter-desktop
      [filter]="filter"
      [selectedFilterCount]="selectedFilterCount"
      [departments]="departments"
      [positionTitles]="positionTitles"
      [showStatusFilter]="showStatusFilter"
      [selectedLeaveTypes]="selectedLeaveTypes"
      [selectedStatus]="selectedStatus"
      [leaveTypesGrouped]="leaveTypesGrouped"
      [statusTypes]="statusTypes"
      [buttons]="buttons"
      (resetFilter)="resetFilter.emit()"
      (setFilterStatus)="setFilterStatus.emit($event)"
      (togglePosition)="togglePosition.emit($event)"
      (filterByOvertimeType)="filterByOvertimeType.emit($event)"
      (filterByAbsenceType)="filterByAbsenceType.emit($event)"
      (toggleDepartment)="toggleDepartment.emit($event)"
      (handleButtonClick)="handleButtonClick($event)"
    >
    </mo-time-filter-desktop>
  `,
})
export class TimeFilterOptionsComponent implements OnInit, OnChanges {
  @Input() filter: TimeFilter;
  @Input() departments: DepartmentLightModel[];
  @Input() absenceTypes: AbsenceType[];
  @Input() overtimeTypes: OvertimeType[];
  @Input() positionTitles: string[];
  @Input() selectedFilterCount: number | null;
  @Input() showOvertimeTypes: boolean;
  @Input() showStatusFilter: boolean;
  @Input() statusTypes: { name: string; value: number }[];
  @Output() toggleDepartment = new EventEmitter<number>();
  @Output() filterByOvertimeType = new EventEmitter<number[]>();
  @Output() filterByAbsenceType = new EventEmitter<number[]>();
  @Output() togglePosition = new EventEmitter<string>();
  @Output() setFilterStatus = new EventEmitter<number[]>();
  @Output() closeFilter = new EventEmitter();
  @Output() setFilter = new EventEmitter();
  @Output() resetFilter = new EventEmitter();

  buttons: ButtonBarButton[];
  selectedLeaveTypes: string[];
  selectedStatus: number;
  leaveTypesGrouped: SelectItemGroup[];

  constructor(private translationService: TranslationDataService) {
    this.buttons = [
      {
        label: this.translationService.translate('clearfilter'),
        routerLink: null,
        action: ButtonAction.Reset,
        visible: true,
        disabled: true,
        class: 'p-button p-button-secondary p-button-sm',
      } as ButtonBarButton,

      {
        label: this.translationService.translate('cancel'),
        routerLink: null,
        action: ButtonAction.Cancel,
        visible: true,
        disabled: false,
        class: 'p-button p-button-secondary p-button-sm',
      } as ButtonBarButton,
      {
        label: this.translationService.translate('save'),
        action: ButtonAction.Add,
        disabled: false,
        visible: true,
        isSaveButton: true,
        class: 'p-button p-button-sm',
      } as ButtonBarButton,
    ];
  }
  ngOnInit(): void {
    this.leaveTypesGrouped = [
      {
        label: this.translationService.translate('absence'),
        value: TimeManagementType.Absence,
        items: this.absenceTypes?.map((f) => ({
          label: f.Title,
          value: 'a' + f.OrganizationAbsenceMapId,
          type: TimeManagementType.Absence,
          id: f.OrganizationAbsenceMapId,
        })),
      },
    ];

    if (this.showOvertimeTypes) {
      this.leaveTypesGrouped.push({
        label: this.translationService.translate('overtime'),
        value: TimeManagementType.Overtime,
        items: this.overtimeTypes?.map((f) => ({
          label: f.Title,
          value: 'o' + f.OrganizationOvertimeMapId,
          type: TimeManagementType.Overtime,
          id: f.OrganizationOvertimeMapId,
        })),
      });
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filter) {
      const filter = changes.filter.currentValue as TimeFilter;
      this.selectedLeaveTypes = filter.absenceMapIds
        .map((f) => 'a' + f)
        .concat(filter.overtimeMapdIds.map((f) => 'o' + f));
    }
    if (changes.selectedFilterCount) {
      const count = changes.selectedFilterCount.currentValue;
      if (count == 0) {
        this.buttons.find((f) => f.action == ButtonAction.Reset)!.disabled = true;
      } else {
        this.buttons.find((f) => f.action == ButtonAction.Reset)!.disabled = false;
      }

      this.selectedStatus = this.filter.statuses[0];
    }
  }

  handleButtonClick(button: ButtonBarButton) {
    if (button.action == ButtonAction.Cancel) {
      this.closeFilter.emit();
    } else if (button.action == ButtonAction.Add) {
      this.setFilter.emit();
    } else if (button.action == ButtonAction.Reset) {
      this.resetFilter.emit();
    }
  }
}
