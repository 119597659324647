import { createAction, props } from '@ngrx/store';
import {
  SickLeaveFollowUpItemModel,
  SickLeaveFollowUpResponseModel,
  SickLeaveFollowUpItemNoteModel,
  SickLeaveFollowUpItemFileModel,
  FileForUpload,
  SickLeaveFollowUpModel,
  SickLeaveFollowUpPdfRequestModel,
} from '../models/sick-leave-follow-up.model';

export const SickLeaveFollowUpPageActions = {
  Enter: createAction('[SickLeaveFollowUp Page] Enter'),
  IsLoading: createAction('[SickLeaveFollowUp Page] Is Loading', props<{ isLoading: boolean }>()),
  FilterChanged: createAction('[SickLeaveFollowUp] Filter Changed'),
  SetSelectedFollowUp: createAction('[SickLeaveFollowUp Page] Set Selected Follow Up', props<{ followUpId: number }>()),
  ExtendAbsence: createAction(
    '[SickLeaveFollowUp Page] Extend Absence',
    props<{ followUpId: number; absenceTypeId: number; employeeId: number }>()
  ),
  EnterDetails: createAction('[SickLeaveFollowUp Page] Enter Details'),
  EnterPast: createAction('[SickLeaveFollowUp Page] Enter Past'),
  OpenNoteModal: createAction(
    '[SickLeaveFollowUp Page] Open New Document Modal',
    props<{ followUpItemId: number; noteId: number }>()
  ),
  UpdateStatus: createAction('[SickLeaveFollowUp Page] Update Status', props<{ itemId: number; completed: boolean }>()),
  CloseFollowUp: createAction('[SickLeaveFollowUp Page] Close Follow Up'),
  DownloadFollowUpNote: createAction(
    '[SickLeaveFollowUp Page] Download Follow Up Note',
    props<{ followUpId: number; itemId: number; noteId: number }>()
  ),
  DownloadFollowUp: createAction(
    '[SickLeaveFollowUp Page] Download Follow Up',
    props<{ exportOptions: SickLeaveFollowUpPdfRequestModel }>()
  ),
  OpenFileModal: createAction('[SickLeaveFollowUp Page] Open File Modal', props<{ followUpItemId: number }>()),
  OpenExportModal: createAction('[SickLeaveFollowUp Page] Open Export Modal'),
  CloseExportModal: createAction('[SickLeaveFollowUp Page] Close Export Modal'),
  TogglePanelCollapsed: createAction('[SickLeaveFollowUp Page] Open Detail Panel', props<{ followUpItemId: number }>()),
};

export const SickLeaveFollowUpNoteActions = {
  addFollowUpItemNote: createAction(
    '[SickLeaveFollowUp Notes] Add Follow Up Item Note',
    props<{ note: SickLeaveFollowUpItemNoteModel }>()
  ),
  addFollowUpNote: createAction(
    '[SickLeaveFollowUp Notes] Add Follow Up Note',
    props<{ note: SickLeaveFollowUpItemNoteModel }>()
  ),
  editFollowUpNote: createAction(
    '[SickLeaveFollowUp Notes] Edit Follow Up Note',
    props<{ note: SickLeaveFollowUpItemNoteModel }>()
  ),
  deleteNote: createAction(
    '[SickLeaveFollowUp Notes] Delete Note',
    props<{ followUpItemId: number; noteId: number }>()
  ),
};

export const PastSickLeaveActions = {
  SetFilterSearch: createAction('[Past Sick Leave Followup] Set Filter Search', props<{ employeeId: number }>()),
  SetFilterLength: createAction('[Past Sick Leave Followup] Set Filter Length', props<{ duration: number }>()),
  PageChanged: createAction('[Past Sick Leave Followup] Page Changed', props<{ page: number }>()),
};

export const ActiveSickLeaveActions = {
  filterByEmployee: createAction('[Current Sick Leave Followup] Filter By Employee', props<{ employeeId: number }>()),
  filterByDuration: createAction('[Current Sick Leave Followup] Filter By Duration', props<{ duration: number }>()),
};

export const SickLeaveFollowUpApiActions = {
  loadSickLeaveFollowUpsSuccess: createAction(
    '[SickLeaveFollowUp API] Load Sick Leave Follow Up Success',
    props<{ response: SickLeaveFollowUpResponseModel }>()
  ),
  loadSickLeaveFollowUpsError: createAction(
    '[SickLeaveFollowUp API] Load Sick Leave Follow Up Error',
    props<{ code: string; message: string }>()
  ),
  loadFollowUpDetailsSuccess: createAction(
    '[SickLeaveFollowUp API] Load Follow Up Current Details Success',
    props<{ followUp: SickLeaveFollowUpModel }>()
  ),
  loadFollowUpDetailsError: createAction(
    '[SickLeaveFollowUp API] Load Follow Up Details Error',
    props<{ code: string; message: string }>()
  ),
  loadSickLeavePastFollowUpsSuccess: createAction(
    '[SickLeaveFollowUp API] Load Sick Leave Past Follow Up Success',
    props<{ response: SickLeaveFollowUpResponseModel }>()
  ),
  loadSickLeavePastFollowUpsError: createAction(
    '[SickLeaveFollowUp API] Load Sick Leave Past Follow Up Error',
    props<{ code: string; message: string }>()
  ),
  loadPastFollowUpDetailsSuccess: createAction(
    '[SickLeaveFollowUp API] Load Past Follow Up Details Success',
    props<{ followUpId: number; items: SickLeaveFollowUpItemModel[] }>()
  ),
  loadPastFollowUpDetailsError: createAction(
    '[SickLeaveFollowUp API] Load Past Follow Up Details Error',
    props<{ code: string; message: string }>()
  ),

  editFollowUpNoteSuccess: createAction(
    '[SickLeaveFollowUp API] Edit Follow Up Note Success',
    props<{ followUp: SickLeaveFollowUpModel }>()
  ),
  editFollowUpNoteError: createAction(
    '[SickLeaveFollowUp API] Edit Follow Up Note Error',
    props<{ code: string; message: string }>()
  ),
  saveFollowUpStatusSuccess: createAction('[SickLeaveFollowUp API] Save Follow Up Status Success'),
  saveFollowUpStatusError: createAction(
    '[SickLeaveFollowUp API] Save Follow Up Status Error',
    props<{ code: string; message: string }>()
  ),
  closeFollowUpSuccess: createAction('[SickLeaveFollowUp] Close Follow Up Success', props<{ followUpId: number }>()),
  closeFollowUpError: createAction(
    '[SickLeaveFollowUp API] Close Follow Up Error',
    props<{ code: string; message: string }>()
  ),
  downloadFollowUpNoteSuccess: createAction(
    '[SickLeaveFollowUp API] Download Follow Up Note Success',
    props<{ noteId: number }>()
  ),
  downloadFollowUpNoteError: createAction(
    '[SickLeaveFollowUp API] Download Follow Up Note Error',
    props<{ noteId: number }>()
  ),
  deleteNoteSuccess: createAction('[SickLeaveFollowUp API] Delete Note Success'),
  deleteNoteError: createAction(
    '[SickLeaveFollowUp API] Delete Note Error',
    props<{ code: string; message: string }>()
  ),
  downloadFollowUpSuccess: createAction('[SickLeaveFollowUp API] Download Follow Up Success'),
  downloadFollowUpError: createAction('[SickLeaveFollowUp API] Download Follow Up Error'),
  deleteFollowUp: createAction('[SickLeaveFollowUp API] Delete Follow Up'),
  deleteFollowUpSuccessEnterPast: createAction(
    '[SickLeaveFollowUp API] Delete Follow Up Success Enter Past',
    props<{ followUpItemId: number }>()
  ),
  deleteFollowUpSuccessEnterCurrent: createAction(
    '[SickLeaveFollowUp API] Delete Follow Up Success Enter Current',
    props<{ followUpItemId: number }>()
  ),
  deleteFollowUpError: createAction(
    '[SickLeaveFollowUp API] Delete Follow Up Error',
    props<{ code: string; message: string }>()
  ),
  UpdateStatusSuccess: createAction(
    '[SickLeaveFollowUp Page] Update Status Success',
    props<{ followUp: SickLeaveFollowUpModel; itemId: number }>()
  ),
};

export const SickLeaveFollowUpItemFileActions = {
  SaveFollowUpFiles: createAction(
    '[SickLeave - FollowUpItemFile] Save PersonnelFiles',
    props<{ date: string; followUpItemId: number; files: FileForUpload[] }>()
  ),
  SaveFollowUpFile: createAction(
    '[SickLeave - FollowUpItemFile] Save PersonnelFile',
    props<{ date: string; followUpItemId: number; file: FileForUpload }>()
  ),
  SaveFollowUpFileSuccess: createAction(
    '[SickLeave - FollowUpItemFile] Save FollowUp File Success',
    props<{ followUpId: number; fileName: string }>()
  ),
  SaveFollowUpFileProgress: createAction(
    '[SickLeave - FollowUpItemFile] Save FollowUp File Progress',
    props<{ progress: number; fileName: string }>()
  ),
  SaveFollowUpFileError: createAction(
    '[SickLeave - FollowUpItemFile] Save FollowUp File Error',
    props<{ error: string; fileName: string }>()
  ),
  DeleteFollowUpFile: createAction(
    '[SickLeave - FollowUpItemFile] Delete PersonnelFile',
    props<{ followUpItemId: number; fileId: number }>()
  ),
  DeleteFollowUpFileSuccess: createAction('[SickLeave - FollowUpItemFile] Delete FollowUpFile Success'),
  DeleteFollowUpFileError: createAction(
    '[SickLeave - FollowUpItemFile] Delete FollowUpFile Error',
    props<{ code: string; message: string }>()
  ),
  CloseFileModal: createAction('[SickLeaveFollowUp Page] Close File Modal'),
  AddFollowUpFileForUpload: createAction(
    '[SickLeave - FollowUpItemFile] Add FollowUp File For Upload',
    props<{ files: FileForUpload[] }>()
  ),
  UploadFilesComplete: createAction('[SickLeaveFollowUp API] Upload Files Complete', props<{ fileCount: number }>()),
  RemoveFollowUpFileForUpload: createAction(
    '[SickLeave - FollowUpItemFile] Remove FollowUpFile For Upload',
    props<{ fileName: string }>()
  ),
  EnterFollowUpFileTab: createAction('[SickLeave - FollowUpItemFile] Enter'),
  OpenModal: createAction(
    '[SickLeave - FollowUpItemFile] FollowUpFile Modal Open',
    props<{ FollowUpFileId: number }>()
  ),
  DownloadFollowUpFile: createAction(
    '[SickLeave - FollowUpItemFile] Download FollowUpFile',
    props<{ followUpItemId: number; file: SickLeaveFollowUpItemFileModel }>()
  ),
  LoadFollowUpFileListError: createAction(
    '[SickLeave - FollowUpItemFile] Load FollowUpFile Error',
    props<{ error: string }>()
  ),
  DownloadFollowUpFileSuccess: createAction('[SickLeave - FollowUpItemFile] Download FollowUpFile Success'),
  DownloadFollowUpFileError: createAction(
    '[SickLeave - FollowUpItemFile] Download FollowUpFile Error',
    props<{ error: string }>()
  ),
};
