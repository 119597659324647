import { ChildModel } from '@mentor-one-ui/core/models/employee/ChildModel';
import { EmployeeModel } from '@mentor-one-ui/core/models/employee/EmployeeModel';
import { createAction, props } from '@ngrx/store';

export const EmployeeChildrenTabActions = {
  EnterChildrenTab: createAction('[Employee - Children Tab] Enter'),
  OpenChildModal: createAction('[Employee - Children Tab] View Child Details', props<{ childId: number }>()),
  SaveChild: createAction('[Employee - Children Tab] Save Child', props<{ child: ChildModel }>()),
  DeleteChild: createAction('[Employee - Children Tab] Delete Child', props<{ childId: number }>()),
  CheckedHaveNoChildren: createAction(
    '[Employee - Children Tab] Checked Have No Children',
    props<{ checked: boolean }>()
  ),
};

export const EmployeeChildrenApiActions = {
  LoadChildListSuccess: createAction(
    '[Employee - Children API] Load Children Success',
    props<{ employeeId: number; children: ChildModel[] }>()
  ),
  SaveChildSuccess: createAction(
    '[Employee - Children API] Save Child Success',
    props<{ employeeId: number; child: ChildModel }>()
  ),
  DeleteChildSuccess: createAction(
    '[Employee - Children API] Delete Child Success',
    props<{ employeeId: number; children: ChildModel[] }>()
  ),
  CheckedHaveNoChildrenSuccess: createAction(
    '[Employee - Children API] Checked Have No Children Success',
    props<{ employee: EmployeeModel }>()
  ),

  SaveChildError: createAction('[Employee - Children API] Save Child Error', props<{ error: string }>()),
  LoadChildListError: createAction('[Employee - Children API] Load Children Error', props<{ error: string }>()),
  DeleteChildError: createAction('[Employee - Children API] Delete Child Error', props<{ error: string }>()),
  CheckedHaveNoChildrenError: createAction(
    '[Employee - Children API] Checked Have No Children Error',
    props<{ error: string }>()
  ),
};
