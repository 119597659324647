import {
  EmployeeContactInformationActions,
  EmployeeDetailsApiActions,
  EmployeeFormalityActions,
  EmployeePersonaliaActions,
} from '@mentor-one-ui/employees/state/actions/employee-details.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { EmployeeService } from '@mentor-one-ui/core/services/api/employee.service';
import { mergeMap, map, catchError, skipWhile, filter } from 'rxjs/operators';
import { of, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectIsMyProfile, selectRoutedEmployeeId } from '@mentor-one-ui/core/state/router/router.selectors';
import { EmployeePageActions } from '@mentor-one-ui/employees/state/actions/employee-page.actions';
import { UserSelectors } from '@mentor-one-ui/core/state/user/user.selector';
import { MyProfilePageActions } from '@mentor-one-ui/my-profile-page/actions/my-profile-page.actions';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { ApplicationActions } from '@mentor-one-ui/core/state/application/application.actions';
import { EmployeeSelectors } from '../selectors/employee.selectors';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Injectable()
export class EmployeesEffects {
  constructor(
    private actions$: Actions,
    private employeeService: EmployeeService,
    private store: Store,
    private translationService: TranslationDataService
  ) {}

  loadMyProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MyProfilePageActions.Enter),
      concatLatestFrom(() => [
        this.store.select(UserSelectors.selectSelectedUserId),
        this.store.select(selectIsMyProfile),
      ]),
      filter(([action, userId, isMyProfile]) => {
        return !!isMyProfile && userId != null && userId > 0;
      }),
      switchMap(([action, userId, isMyProfile]) =>
        this.employeeService.getEmployee(userId!).pipe(
          map((result) => {
            return EmployeeDetailsApiActions.LoadEmployeeSuccess({ employee: result });
          }),
          catchError((e) => of(EmployeeDetailsApiActions.LoadEmployeeError(e.message)))
        )
      )
    );
  });

  dialogRef: DynamicDialogRef;

  loadEmployee$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EmployeePageActions.Enter),
      concatLatestFrom(() => [this.store.select(selectRoutedEmployeeId), this.store.select(selectIsMyProfile)]),
      filter(([action, employeeId, isMyProfile]) => employeeId != null && !isMyProfile),
      switchMap(([action, employeeId]) =>
        this.employeeService.getEmployee(+employeeId!).pipe(
          map((result) => {
            return EmployeeDetailsApiActions.LoadEmployeeSuccess({ employee: result });
          }),
          catchError((e) => of(EmployeeDetailsApiActions.LoadEmployeeError(e.message)))
        )
      )
    );
  });

  saveFormality$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeFormalityActions.SaveFormality),
      concatLatestFrom(() => this.store.select(EmployeeSelectors.selectSelectedEmployee)),
      mergeMap(([action, employee]) =>
        this.employeeService.updateEmployeeFormality(employee.EmployeeId, action.formality).pipe(
          map((result) => {
            return EmployeeDetailsApiActions.SaveEmployeeFormalitySuccess({
              employeeId: employee.EmployeeId,
              formality: result,
            });
          }),
          catchError((e) => of(EmployeeDetailsApiActions.SaveEmployeeFormalityError(e.message)))
        )
      )
    )
  );

  savePersonalia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeePersonaliaActions.SaveEmployeePersonalia),
      concatLatestFrom(() => this.store.select(EmployeeSelectors.selectSelectedEmployee)),
      mergeMap(([action, employee]) =>
        this.employeeService.updateEmployeePersonalDetails(employee.EmployeeId, action.personalia).pipe(
          map((result) => {
            return EmployeeDetailsApiActions.SaveEmployeePersonaliaSuccess({
              employeeId: employee.EmployeeId,
              personalia: result,
            });
          }),
          catchError((e) => of(EmployeeDetailsApiActions.SaveEmployeePersonaliaError(e.message)))
        )
      )
    )
  );

  saveContactInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeContactInformationActions.SaveContactInformation),
      concatLatestFrom(() => this.store.select(EmployeeSelectors.selectSelectedEmployee)),
      mergeMap(([action, employee]) =>
        this.employeeService.updateEmployeeContactInformation(employee.EmployeeId, action.contactInformation).pipe(
          map((result) => {
            return EmployeeDetailsApiActions.SaveEmployeeContactInformationSuccess({
              employeeId: employee.EmployeeId,
              contactInformation: result,
            });
          }),
          catchError((e) => of(EmployeeDetailsApiActions.SaveEmployeeContactInformationError(e.message)))
        )
      )
    )
  );

  showErrorMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        EmployeeDetailsApiActions.SaveEmployeeFormalityError,
        EmployeeDetailsApiActions.SaveEmployeePersonaliaError,
        EmployeeDetailsApiActions.SaveEmployeeContactInformationError
      ),
      map((action) => {
        return ApplicationActions.ErrorMessage({
          title: this.translationService.translate('something-went-wrong'),
          message: action.error,
        });
      })
    );
  });
}
