import { createReducer, on } from "@ngrx/store";
import { ReportApiActions } from "./report.actions";
import { ReportModel } from "@mentor-one-ui/core/models/ReportModel";

export interface State {
  report: ReportModel | null;
  error: string;
}

export const initialState: State = {
    report: null,
    error: '',
};

export const reportReducer = createReducer<State>(initialState,
  on(ReportApiActions.LoadReportSuccess, (state, action): State => {
    return {
      ...state,
      report: action.report,
    };
  }),
  on(ReportApiActions.LoadReportError, (state, action): State => {
    return {
      ...state,
      error: action.error,
    };
  }),
);
