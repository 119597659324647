import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, type OnInit } from '@angular/core';
import { EmployeeModel } from '@mentor-one-ui/core/models/employee/EmployeeModel';
import { UserTokenModel } from '@mentor-one-ui/core/state/user/user.model';
import { UserSelectors } from '@mentor-one-ui/core/state/user/user.selector';
import { selectEmployeeById } from '@mentor-one-ui/employees/state/selectors/employee.selectors';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { AvatarModule } from 'primeng/avatar';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'mo-employee-avatar',
  standalone: true,
  imports: [
    CommonModule,
    AvatarModule,
    LetDirective
  ],
  styleUrls: ['./employee-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
  <ng-container *ngrxLet="(employee$ | async) as employee">
    <p-avatar *ngIf="employee?.HasProfilePicture" styleClass="mr-2" [size]="size" shape="circle"
    [image]="this.profilePicture(employee)"></p-avatar>
    <p-avatar *ngIf="!employee?.HasProfilePicture" styleClass="mr-2" [size]="size" shape="circle"
    [label]="employee?.ContactInformation?.FirstName?.charAt(0)"></p-avatar>
  </ng-container>
  `

})
export class EmployeeAvatarComponent implements OnInit, OnChanges {
  @Input() employeeId: number;
  @Input() size: 'normal' | 'large' | 'xlarge' | undefined = 'normal';
  imageUri: any;
  employee$: Observable<EmployeeModel | undefined>;
  userToken: UserTokenModel | undefined;

  constructor(private store: Store) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.employeeId) {
      this.employee$ = this.store.select(selectEmployeeById(this.employeeId));
    }
  }

  ngOnInit(): void {
    this.employee$ = this.store.select(selectEmployeeById(this.employeeId));

    this.store.select(UserSelectors.selectUserToken).subscribe(token => {
      this.userToken = token;
    });
  }

  getToken(){  
    return this.userToken?.Token;
  }

  profilePicture(employee: EmployeeModel | null | undefined) {
    var token = this.getToken();
    if (employee?.EmployeeId != null) {
      return environment.API_URL + '/image/'+employee.EmployeeId+'/EmployeeProfilePicture?token=' + token;
    }

    return '';
  }
}
