import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { TimeManagementModel } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementModel';
import { TimeManagementStatusEnum } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementStatusEnum';
import { TimeManagementType } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementType';
import { Store } from '@ngrx/store';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { ManageAddAbsencePageActions } from '../../containers/manage-add-absence-page/actions/manage-add-absence-page.actions';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'mo-employees-registrations-modal',
  styleUrl: './employees-registrations-modal.component.scss',
  template: `
    <div>
      <p>
        {{ 'concurrent-registrations-info-text' | translate | async }}
      </p>
      <ul class="concurrent-time-list absence">
        <ng-container *ngIf="times && times.length > 0">
          <li
            *ngFor="let time of times"
            class="concurrent-time-item event-list-item"
            (click)="openConcurrentRegistration(time)"
          >
            <ng-container *ngIf="time.Type === TimeManagementType.Absence">
              <div class="time-element">
                <mo-emoji [absenceId]="time.OrganizationAbsenceMapId!" [rounded]="false"></mo-emoji>
                <div class="middle">
                  <strong>{{ time.OrganizationAbsenceMapId | absenceTypeName }}</strong>
                  <mo-timespan-element [absence]="time" [showCalculatedHours]="false"></mo-timespan-element>
                </div>
              </div>
              <div class="status-element">
                <mo-absence-status-tag [status]="time.Status"></mo-absence-status-tag>
              </div>
            </ng-container>
            <ng-container *ngIf="time.Type === TimeManagementType.Overtime">
              <div class="time-element">
                <mo-emoji [overtimeId]="time.OrganizationAbsenceMapId!" [rounded]="false"></mo-emoji>
                <div class="middle">
                  <strong>{{ time.OrganizationAbsenceMapId | overtimeTypeName }}</strong>
                  <mo-timespan-element [absence]="time" [showCalculatedHours]="false"></mo-timespan-element>
                </div>
              </div>
              <div class="status-element">
                <mo-absence-status-tag [status]="time.Status"></mo-absence-status-tag>
              </div>
            </ng-container>
          </li>
        </ng-container>
      </ul>

      <div class="p-dialog-footer p-dialog-footer--sticky">
        <mo-default-buttons
          [showDelete]="false"
          [isSaving]="false"
          saveLabel="{{ 'concurrent-registrations-continue-label' | translate | async }}"
          (cancel)="close()"
          (save)="continue()"
        ></mo-default-buttons>
      </div>
    </div>
  `,
})
export class EmployeesRegistrationsModalComponent implements OnInit, OnDestroy {
  public ngDestroyed$ = new Subject();

  times: TimeManagementModel[];
  continueAction: any;
  TimeManagementType = TimeManagementType;
  TimeManagementStatusEnum = TimeManagementStatusEnum;

  constructor(
    private store: Store,
    public dialogService: DialogService,
    public config: DynamicDialogConfig,
    public confirmationService: ConfirmationService,
    public translationService: TranslationDataService,
    public router: Router
  ) {
    this.times = this.config.data.absences;
    this.continueAction = this.config.data.continueAction;
  }
  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.ngDestroyed$.next(null);
  }

  continue() {
    this.store.dispatch(this.continueAction);
    this.close();
  }

  close() {
    this.store.dispatch(ManageAddAbsencePageActions.closeEmployeesRegistrationsModal());
  }
  openConcurrentRegistration(time: TimeManagementModel) {
    this.confirmationService.confirm({
      message: this.translationService.translate('current-registration-will-be-cancelled'),
      header: this.translationService.translate('proceed-to-registration'),
      rejectButtonStyleClass: 'p-button-secondary',
      acceptLabel: this.translationService.translate('proceed-to-registration-continue-label'),
      rejectLabel: this.translationService.translate('cancel'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.router.navigate(this.getLink(time));
        this.close();
      },
      reject: () => {
        this.close();
      },
    });
  }
  getLink(time: TimeManagementModel) {
    var typeName = time.Type === TimeManagementType.Absence ? 'absence' : 'overtime';
    return [`/time/my/${typeName}`, time.Id];
  }
}
