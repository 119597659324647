export type Language = 'no' | 'en';
export type CultureCode = 'nb-NO' | 'en-US';

export interface TranslationState {
  loaded: boolean;
  selectedCultureCode: CultureCode;
}

export const initialState: TranslationState = {
  loaded : false,
  selectedCultureCode: 'nb-NO'
};
