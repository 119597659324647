import { NgModule } from '@angular/core';
import { RouterModule, Routes, TitleStrategy } from '@angular/router';
import { MentorAdminGuard } from './mentor-admin/mentor-admin.guard';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { ConcatTitleStrategy } from './core/misc/app-prefix-title-strategy';
import { TranslationTitleResolver } from './core/resolvers/translation-title.resolver';
import { UnauthorizedComponent } from '@mentor-one-ui/auth/unauthorized/unauthorized.component';
import { SigninOidcComponent } from './auth/signin-oidc/signin-oidc.component';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { HealthzComponent } from '@mentor-one-ui/healthz/healthz.component';
import { ForbiddenComponent } from './auth/forbidden/forbidden.component';
import { NoAccessComponent } from '@mentor-one-ui/auth/no-access/no-access.component';

const routes: Routes = [
  {
    path: '',
    // component: FrontpageModule,
    loadChildren: () => import('./frontpage/frontpage.module').then((m) => m.FrontpageModule),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'mentor-admin',
    loadChildren: () => import('./mentor-admin/mentor-admin.module').then((m) => m.MentorAdminModule),
    canActivate: [AutoLoginPartialRoutesGuard, MentorAdminGuard],
  },
  {
    path: 'my-profile',
    loadChildren: () => import('./my-profile-page/my-profile-page.module').then((m) => m.MyProfileModule),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'organization',
    loadChildren: () => import('./user-organization/user-organization.module').then((m) => m.UserOrganizationModule),
    data: { activeMenuModule: 'organization', translateTitle: 'the-organization' },
    canActivate: [AutoLoginPartialRoutesGuard],
    title: TranslationTitleResolver,
  },
  {
    path: 'employees',
    loadChildren: () => import('./employees/employees.module').then((m) => m.EmployeesModule),
    canActivate: [AutoLoginPartialRoutesGuard],
    title: TranslationTitleResolver,
    data: {
      translateTitle: 'employees',
      activeMenuModule: 'employees',
    },
  },
  {
    path: 'time',
    loadChildren: () => import('./time/time.module').then((m) => m.TimeModule),
    data: {
      activeMenuModule: 'time',
      translateTitle: 'time',
    },
    title: TranslationTitleResolver,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'sick-leave',
    loadChildren: () => import('./sick-leave/sick-leave.module').then((m) => m.SickLeaveModule),
    data: {
      activeMenuModule: 'sick-leave',
      translateTitle: 'sick-leave',
    },
    title: TranslationTitleResolver,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'set-password',
    loadChildren: () => import('./set-password/set-password.module').then((m) => m.SetPasswordModule),
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'no-access', component: NoAccessComponent },
  { path: 'signin-oidc', component: SigninOidcComponent },
  { path: 'forbidden', component: ForbiddenComponent },
  { path: 'healthz', component: HealthzComponent },
  { path: 'not-found', component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled'
    }),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: ConcatTitleStrategy,
    },
  ],
})
export class AppRoutingModule {}
