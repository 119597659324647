import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';
import * as fromEmployees from './reducers/employees.reducer';
import * as fromNewEmployee from '@mentor-one-ui/employees/new-employee/state/new-employee.reducer';
import * as fromNextOfKin from './reducers/next-of-kin.reducer';
import * as fromEmployment from './reducers/employment.reducer';
import * as fromChildren from './reducers/children.reducer';
import * as fromEmployeeDetails from './reducers/employee-details.reducer';
import * as fromSystemAccess from './reducers/system-access.reducer';
import * as fromEmployeePage from './reducers/employee-page.reducer';
import * as fromPersonnelFile from './reducers/personnel-file.reducer';
import * as fromTerminateEmployment from './reducers/terminate-employment.reducer';

export const employeesFeatureKey = 'employees';
export const employeeFeatureKey = 'employee';
interface EmployeeState {
  [fromEmployeePage.featureKey]: fromEmployeePage.State;
  [fromEmployeeDetails.featureKey]: fromEmployeeDetails.State;
  [fromNextOfKin.featureKey]: fromNextOfKin.State;
  [fromChildren.featureKey]: fromChildren.State;
  [fromEmployment.featureKey]: fromEmployment.State;
  [fromSystemAccess.featureKey]: fromSystemAccess.State;
  [fromPersonnelFile.featureKey]: fromPersonnelFile.State;
  [fromTerminateEmployment.featureKey]: fromTerminateEmployment.State;
}
export interface EmployeesState {
  [employeesFeatureKey]: fromEmployees.State;
  [employeeFeatureKey]: EmployeeState;
  [fromNewEmployee.featureKey]: fromNewEmployee.State;
}


/** Provide reducer in AoT-compilation happy way */
export function reducers(state: EmployeesState | undefined, action: Action) {
  return combineReducers({
    [fromEmployees.employeesFeatureKey]: fromEmployees.employeesReducer,
    [employeeFeatureKey]: employeeReducer,
    [fromNewEmployee.featureKey]: fromNewEmployee.reducer,
  })(state, action);
}

export function employeeReducer(state: EmployeeState | undefined, action: Action) {
  return combineReducers({
    [fromEmployeePage.featureKey]: fromEmployeePage.reducer,
    [fromEmployeeDetails.featureKey]: fromEmployeeDetails.reducer,
    [fromNextOfKin.featureKey]: fromNextOfKin.reducer,
    [fromChildren.featureKey]: fromChildren.reducer,
    [fromEmployment.featureKey]: fromEmployment.reducer,
    [fromSystemAccess.featureKey]: fromSystemAccess.reducer,
    [fromPersonnelFile.featureKey]: fromPersonnelFile.reducer,
    [fromTerminateEmployment.featureKey]: fromTerminateEmployment.reducer,
  })(state, action);
}

export const selectEmployeesState = createFeatureSelector<EmployeesState>(employeesFeatureKey);
