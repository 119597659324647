import { PersonnelManagerModel } from '@mentor-one-ui/core/models/employee/EmploymentModel';
import { CultureCode } from '@mentor-one-ui/core/state/translations/translation.state';

export interface UserModel {
  EmployeeId: number;
  UserLoginId: number;
  EmployeeFullName: string;
  OrganizationId: number;
  OrganizationName: string;
  CultureCode: CultureCode | undefined;
  UserRoles: number[];
  PersonnelManagers: PersonnelManagerModel[];
  HideSupportBox: boolean;
  UserToken: UserTokenModel;
}

export enum UserRole {
  SystemAdmin = -1,
  AdministratorMentorPersonal = 7,
  PersonnelManager = 2,
  Employee = 3,
}

export interface UserTokenModel {
  Expires: Date;
  Token: string;
}

export interface MissingUserInfoModel {
  MissingGender: boolean;
  MissingEmployment: boolean;
}