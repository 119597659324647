import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DialogService } from 'primeng/dynamicdialog';
import { catchError, map, mergeMap, of } from 'rxjs';
import { OrganizationService } from '@mentor-one-ui/core/services/api/organization.service';
import { ContactModalComponent } from '@mentor-one-ui/user-organization/components/contacts/contact-modal/contact-modal.component';
import { contactPageActions } from '@mentor-one-ui/user-organization/components/contacts/state/actions/contact-page.actions';
import { contactApiActions } from '@mentor-one-ui/user-organization/components/contacts/state/actions/contact-api.actions';
import { MessageService } from 'primeng/api';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { ApplicationActions } from '@mentor-one-ui/core/state/application/application.actions';

@Injectable()
export class ContactEffects {
  constructor(
    private actions$: Actions,
    private dialogService: DialogService,
    private messageService: MessageService,
    private translationService: TranslationDataService,
    private organizationService: OrganizationService
  ) {}

  dialogRef: any;
  openContactModal = createEffect(() => {
    return this.actions$.pipe(
      ofType(contactPageActions.openContactModal),
      mergeMap(() => {
        this.dialogRef = this.dialogService.open(ContactModalComponent, {
          header: this.translationService.translate('contact-person'),
        });
        return this.dialogRef.onClose;
      }),
      map(() => {
        return contactPageActions.contactModalClosed();
      })
    );
  });
  
  loadContacts = createEffect(() => {
    return this.actions$.pipe(
      ofType(contactApiActions.LoadContacts),
      mergeMap((action) =>
        this.organizationService.getContacts().pipe(
          map((data) => {
            return contactApiActions.LoadContactsSuccess({ contact: data });
          }),
          catchError((e) => of(contactApiActions.LoadContactsError(e.message)))

        )
      )
    );
  });

  saveContact$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(contactApiActions.postContact),
      mergeMap((action) =>
        this.organizationService.postContact(action.contact).pipe(
          map((data) => {
            return contactApiActions.saveContactSuccess({ contact: action.contact, id: data });
          }),
          catchError((e) => of(contactApiActions.saveContactError( { error: this.translationService.translate('could-not-save-contact')})))
        )
      )
    );
  });

  closeContactModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(contactApiActions.saveContactSuccess),
        map((payload) => {
          if (this.dialogRef) {
            this.dialogRef?.close();
          }
        })
      ),
    { dispatch: false }
  );

  errorMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(contactApiActions.saveContactError),
      map((action) => {
        return ApplicationActions.ErrorMessage({
          title: this.translationService.translate('something-went-wrong'),
          message: action.error,
        });
      })
    );
  });

  contactSavedMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(contactApiActions.saveContactSuccess),
      map((action) => {
        return ApplicationActions.SuccessMessage({          
          title: this.translationService.translate('success'),
          message: this.translationService.translate('contact-saved-successfully'),
        });
      })
    );
  });
  deleteContact = createEffect(() => {
    return this.actions$.pipe(
      ofType(contactApiActions.deleteContact),
      mergeMap((action) =>
        this.organizationService.deleteContact(action.contact).pipe(
          map(() => {
            this.messageService.add({
              severity: 'success',
              summary: `${this.translationService.translate('contact-person')} ${this.translationService.translate(
                'deleted'
              )}`,
            });
            return contactApiActions.deleteContactSuccess({ contactId: action.contact.ContactId });
          }),
          catchError((e) => {
            this.messageService.add({
              severity: 'error',
              summary: this.translationService.translate('an-has-error-occured'),
            });
            return of(contactApiActions.deleteContactFailure({ error: e.message }));
          })
        )
      )
    );
  });
}
