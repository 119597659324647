import { TimeManagementModel } from "@mentor-one-ui/time/my-time/absence/models/TimeManagementModel";
import { EntityState, createEntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { getDefaultFilter } from ".";
import { UnmanagedFilterDialogActions } from "../../components/unmanaged-filter/actions/unmanaged-filter-dialog.actions";
import { ManageApiActions } from "../actions/manage-api.actions";
import { TimeFilter } from "../../../shared/time-filter.model";
import { UnmanagedLeavesPageActions } from "../../containers/unmanaged-leaves-page/actions/unmanaged-leaves-page.actions";

export const featureKey = 'unmanagedLeaves';

export interface State extends EntityState<TimeManagementModel> {
    dataLoaded: boolean;
    loadError: boolean;
    totalAbsences: number;
    filter: TimeFilter;  
    page: number;  
}

export const adapter = createEntityAdapter<TimeManagementModel>({
    selectId: (a) => a.Id,
    sortComparer: false,    
  });
  
  
  export const initialState: State = adapter.getInitialState({
    dataLoaded: false,  
    loadError: false,
    isSaving: false,
    selectedId: -1,  
    page: 0,
    totalAbsences: 0,
    filter: getDefaultFilter(),
  }) ;
  
  export const reducer = createReducer(
    initialState,

    on(ManageApiActions.loadUnmanagedAbsencesSuccess, (state, { unmanagedAbsences, totalAbsences }): State => {
      return {
        ...adapter.setAll(unmanagedAbsences, state),
        dataLoaded: true,
        totalAbsences: totalAbsences,
        loadError:false
      }
    }),
    on(ManageApiActions.loadUnmanagedAbsencesError, (state, action): State => {
      return {
        ...state,
        dataLoaded: false,
        loadError: true
      }
    }),
    on(ManageApiActions.ApproveAbsenceSuccess, (state, { id }): State => {
      return {
        ...adapter.removeOne(id, state),
        totalAbsences: state.totalAbsences - 1
      }
    }),
    on(ManageApiActions.RejectAbsenceSuccess, (state, { id }): State => {
      return {
        ...adapter.removeOne(id, state),
        totalAbsences: state.totalAbsences - 1
      }
    }),
    on(UnmanagedLeavesPageActions.resetFilter, (state, action): State => {
      return {
        ...state,
        filter: getDefaultFilter(),
      }
    }),
    on(UnmanagedFilterDialogActions.toggleDepartment, (state, action): State => {
  
      var departments = [...state.filter.selectedDepartmentIds];
      var index = departments.indexOf(action.departmentId);
      if(index > -1) {
        departments.splice(index, 1);
      } else {
        departments.push(action.departmentId);
      }
  
      return {
        ...state,
        filter: {
          ...state.filter,
          selectedDepartmentIds: departments
        }
      }
    }),
    on(UnmanagedFilterDialogActions.filterByOvertimeType, (state, action): State => {        
      return {
        ...state,
        filter: {
          ...state.filter,
          overtimeMapdIds: action.overtimeTypeIds
        }
      }
    }),
    on(UnmanagedFilterDialogActions.filterByAbsenceType, (state, action): State => {
  
  
      return {
        ...state,
        filter: {
          ...state.filter,
          absenceMapIds: action.absenceTypeIds
        }
      }
    }),
  
    on(UnmanagedFilterDialogActions.togglePosition, (state, action): State => {
      var positions = [...state.filter.selectedPositions];
      var index = positions.indexOf(action.position);
      if(index > -1) {
        positions.splice(index, 1);
      } else {
        positions.push(action.position);
      }
  
      return {
        ...state,
        filter: {
          ...state.filter,
          selectedPositions: positions
        }
      }
    }),
    on(UnmanagedFilterDialogActions.setFilter,
      UnmanagedLeavesPageActions.resetFilter,    
      UnmanagedLeavesPageActions.setEmployeeFilter,
      (state, action): State => {
      return {
        ...state,
        page: 0,
      } 
    } ),
  
    on(UnmanagedFilterDialogActions.setFilter,
      UnmanagedLeavesPageActions.resetFilter,
      UnmanagedLeavesPageActions.Paginate,
      UnmanagedLeavesPageActions.setSortOption,
      UnmanagedLeavesPageActions.setEmployeeFilter,
      (state, action): State => {
      return {
        ...state,
        dataLoaded: false,
      } 
    } ),
  
    on(UnmanagedLeavesPageActions.Paginate, (state, action): State => {
      return {
        ...state,
        page: action.page
      }
    }),
    on(UnmanagedLeavesPageActions.setSortOption, (state, action): State => {
      return {
        ...state,
        filter: {
          ...state.filter,
          selectedSortOptionId: action.sortOptionId
        }
      }
    }),
    on(UnmanagedLeavesPageActions.setEmployeeFilter, (state, action): State => {
      return {
        ...state,
        filter: {
          ...state.filter,
          selectedEmployeeId: action.employeeId
        }
      }
    })
);
  