import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SafeHtml } from '@angular/platform-browser';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'mo-info-mini',
  styleUrls: ['./info-mini.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TooltipModule,
    OverlayPanelModule,
    ButtonModule
  ],
  template: `
    <ng-container>
      <button pButton [text]="true" class="icon-only" icon="fa fa-info-circle" (click)="op.toggle($event)"></button>
      <p-overlayPanel styleClass="mo-tooltip-overlay" #op>
        <p *ngIf="infoText">{{infoText}}</p>
        <div *ngIf="htmlText" [innerHtml]="htmlText"></div>
        <ng-content></ng-content>
      </p-overlayPanel>
  </ng-container>
  `,
})
export class InfoMiniComponent {
  @Input() infoText: string;
  @Input() htmlText: SafeHtml;
  @Input() type: 'overlay' | 'tooltip' = 'tooltip';
}
