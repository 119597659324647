import { createReducer, on } from '@ngrx/store';
import { UserApiActions as UserApiActions, UserActions } from './user.actions';
import { initialState, UserState } from './user.state';
import { EmployeeSystemAccessApiActions } from '@mentor-one-ui/employees/state/actions/employee-system-access.actions';
import { NewEmployeeApiActions } from '@mentor-one-ui/employees/new-employee/state/actions/new-employee-api-actions';
import { FrontPageApiActions } from '../frontpage/frontpage.actions';
import { UserModel } from '@mentor-one-ui/core/state/user/user.model';
import { TranslationActions } from '@mentor-one-ui/core/state/translations/translation.actions';

export const userReducer = createReducer<UserState>(
  initialState,
  on(UserActions.loadUserDataSuccess, (state, action): UserState => {
    return {
      ...state,
      user: action.payload,
      cultureCode: action.payload?.CultureCode!,
      employeeId: action.payload.EmployeeId,
    };
  }),
  on(FrontPageApiActions.CloseEmployeeSupportBoxSuccess, (state): UserState => {
    let entity = JSON.parse(JSON.stringify(state.user));
    entity.HideSupportBox = true;

    return {
      ...state,
      user: entity,
    };
  }),
  on(UserActions.loadAvailableOrganzationSuccess, (state, action): UserState => {
    return {
      ...state,
      availableOrganizations: action.payload,
    };
  }),
  on(UserActions.loadAccessControlSuccess, (state, action): UserState => {
    return {
      ...state,
      moduleAccess: action.payload,
    };
  }),
  on(UserActions.loadSectorDataSuccess, (state, action): UserState => {
    return {
      ...state,
      sectors: action.sectors,
    };
  }),
  on(UserActions.loadManageableEmployeesSuccess, (state, action): UserState => {
    return {
      ...state,
      manageableEmployees: action.employeeIds,
      manageableEmployeesLoaded: true,
    };
  }),
  on(NewEmployeeApiActions.CreateNewEmployeeSuccess, (state, action): UserState => {
    return {
      ...state,
      manageableEmployees: [...state.manageableEmployees, action.employee.EmployeeId],
    };
  }),
  on(UserActions.loadJobTitlesSuccess, (state, action): UserState => {
    return {
      ...state,
      jobTitles: action.jobTitles,
    };
  }),
  on(EmployeeSystemAccessApiActions.LoadSystemAccessesForOrganizationSuccess, (state, { roles }) => {
    return {
      ...state,
      availableSystemAccessRoles: roles,
    };
  }),
  on(UserActions.LoadPersonnelManagersSuccess, (state, { managers }) => {
    return {
      ...state,
      personnelManagers: managers,
    };
  }),
  on(UserApiActions.loadUserTokenSuccess, (state, action): UserState => {
    let updateState = { ...state.user, UserToken: action.userToken } as UserModel;
    return {
      ...state,
      user: updateState,
    };
  }),
  on(TranslationActions.selectLanguage, (state, action): UserState => {
    return {
      ...state,
      cultureCode: action.cultureCode,
    };
  }),
  on(UserActions.LoadMissingUserInfoSuccess, (state, { info }) => {
    return {
      ...state,
      missingUserInfo: info
    }
  })
);
