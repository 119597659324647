import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class HealthzService {

  constructor(public httpClient: HttpClient) {}

  getServiceHealth(): Observable<any> {
    return this.httpClient.get<any>(environment.API_URL + '/healthz', {responseType: 'text' as 'json'});
  }
}
