import { createAction, props } from "@ngrx/store";
import { BalanceModel } from "../models/balance.model";

export const BalanceApiActions = {

    loadBalanceSuccess: createAction(
        '[Balance/API] Load Balance Success',
        props<{ balance: BalanceModel[] }>()
    ),
    loadBalanceError: createAction(
        '[Balance/API] Load Balance Error',
        props<{ error: string }>()
    ),
};
