import { DepartmentModel } from '@mentor-one-ui/core/models/DepartmentModel';
import { PersonnelManagerModel } from '@mentor-one-ui/core/models/employee/EmploymentModel';
import { createAction, props } from '@ngrx/store';

const Enter = createAction('[Organization - Departments | Page] Enter');

const OpenNewDepartmentModal = createAction('[Organization - Departments | Page] Open New Department Modal');

const OpenEditDepartmentModal = createAction(
  '[Organization - Departments | Page] Open Edit Department Modal',
  props<{ id: number }>()
);

const CloseDepartmentModal = createAction('[User Organization - Departments Page] Close Department Modal');

// Load Departments
const loadDepartmentsForOrganization = createAction(
  '[Organization - Departments | API] Load department list for organization started'
);
const loadDepartmentsForOrganizationSuccess = createAction(
  '[Organization - Departments | API] Load department list for organization success',
  props<{ departments: DepartmentModel[] }>()
);
const loadDepartmentsForOrganizationFailure = createAction(
  '[Organization - Departments | API] Load department list for organization failure',
  props<{ error: any }>()
);

// Load Personell Managers
const loadPersonellManagersForOrganization = createAction(
  '[Organization - Departments | API] Load personell managers for organization started'
);
const loadPersonellManagersForOrganizationSuccess = createAction(
  '[Organization - Departments | API] Load personell managers for organization success',
  props<{ personellManagers: PersonnelManagerModel[] }>()
);
const loadPersonellManagersForOrganizationFailure = createAction(
  '[Organization - Departments | API] Load personell managers for organization failure',
  props<{ error: any }>()
);

// Save Department
const updateDepartment = createAction(
  '[Organization - Departments | API] Save department',
  props<{ department: DepartmentModel }>()
);
const updateDepartmentSuccess = createAction(
  '[Organization - Departments | API] Save department success',
  props<{ department: DepartmentModel }>()
);
const updateDepartmentFailure = createAction(
  '[Organization - Departments | API] Save department failure',
  props<{ error: any }>()
);

// Delete Department
const deleteDepartment = createAction(
  '[Organization - Departments Modal] Delete department',
  props<{ departmentId: number }>()
);
const deleteDepartmentSuccess = createAction('[Organization - Departments | API] Delete department success');
const deleteDepartmentFailure = createAction(
  '[Organization - Departments | API] Delete department failure',
  props<{ error: any }>()
);

export const DepartmentsTabActions = {
  Enter,
  OpenNewDepartmentModal,
  OpenEditDepartmentModal,
  CloseDepartmentModal,

  //
  loadDepartmentsForOrganization,
  loadPersonellManagersForOrganization,
  updateDepartment,
  deleteDepartment,
};
export const DepartmentsApiActions = {
  loadDepartmentsForOrganizationSuccess,
  loadDepartmentsForOrganizationFailure,

  loadPersonellManagersForOrganizationSuccess,
  loadPersonellManagersForOrganizationFailure,

  updateDepartmentSuccess,
  updateDepartmentFailure,

  deleteDepartmentSuccess,
  deleteDepartmentFailure,
};
