import { createReducer, on } from '@ngrx/store';
import {
  FileForUpload,
  SickLeaveFollowUpFilterModel,
  SickLeaveFollowUpLengthEnum,
  SickLeaveFollowUpModel,
} from '../models/sick-leave-follow-up.model';
import {
  ActiveSickLeaveActions,
  SickLeaveFollowUpNoteActions,
  PastSickLeaveActions,
  SickLeaveFollowUpApiActions,
  SickLeaveFollowUpItemFileActions,
  SickLeaveFollowUpPageActions,
} from './follow-up.actions';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { ManageAddAbsencePageActions } from '@mentor-one-ui/time/leave-management/containers/manage-add-absence-page/actions/manage-add-absence-page.actions';
import { AbsenceApiActions } from '@mentor-one-ui/time/my-time/absence/actions/absence-api.actions';

export const featureKey = 'followup';

export const adapter: EntityAdapter<SickLeaveFollowUpModel> = createEntityAdapter<SickLeaveFollowUpModel>({
  sortComparer: false,
  selectId: (s) => s.SickLeaveFollowUpId,
});

export interface State extends EntityState<SickLeaveFollowUpModel> {
  followUpListLoaded: boolean;
  followUpListPastLoaded: boolean;
  followUpListPast: SickLeaveFollowUpModel[];
  followUpPastTotalCount: number;
  followUpError: boolean;
  followUpErrorCode: string | null;
  followUpErrorMessage: string | null;
  followUpFilter: SickLeaveFollowUpFilterModel;
  followUpCurrentPageCount: number;
  listType: 'current' | 'past';
  selectedFollowUpId: number | null;
  downloadingNote: number[];
  downloadingFollowUp: boolean;
  isSavingFiles: boolean;
  isSavingNote: boolean;
  idBeingDeleted: number;
  filesForUpload: FileForUpload[];
  timelinePanelOpen: number[];
  followUpItemsStatusBeingUpdated: number[];
}

export const initialState: State = adapter.getInitialState({
  followUpListLoaded: false,
  followUpListPastLoaded: false,
  followUpPastTotalCount: 0,
  followUpListPast: [],
  followUpError: false,
  followUpErrorCode: null,
  followUpErrorMessage: null,
  followUpCurrentPageCount: 0,
  followUpFilter: {
    EmployeeId: null,
    Take: 10,
    Skip: 0,
    FollowUpLength: SickLeaveFollowUpLengthEnum.TotalAllLengths,
  },
  selectedFollowUpId: null,
  listType: 'current',
  downloadingNote: [],
  downloadingFollowUp: false,
  isSavingFiles: false,
  isSavingNote: false,
  idBeingDeleted: -1,
  filesForUpload: [],
  timelinePanelOpen: [],
  followUpItemsStatusBeingUpdated: [],
});

export const reducer = createReducer<State>(
  initialState,
  on(PastSickLeaveActions.SetFilterSearch, ActiveSickLeaveActions.filterByEmployee, (state, { employeeId }): State => {
    return {
      ...state,
      followUpFilter: {
        ...state.followUpFilter,
        EmployeeId: employeeId,
      },
    };
  }),
  on(PastSickLeaveActions.SetFilterLength, ActiveSickLeaveActions.filterByDuration, (state, { duration }): State => {
    return {
      ...state,
      followUpFilter: {
        ...state.followUpFilter,
        FollowUpLength: duration,
      },
    };
  }),
  on(PastSickLeaveActions.SetFilterLength, PastSickLeaveActions.SetFilterSearch, (state): State => {
    return {
      ...state,
      followUpFilter: {
        ...state.followUpFilter,
        Skip: 0,
      },
    };
  }),
  on(PastSickLeaveActions.PageChanged, (state, { page }): State => {
    return {
      ...state,
      followUpFilter: {
        ...state.followUpFilter,
        Skip: page * state.followUpFilter.Take,
      },
    };
  }),
  on(SickLeaveFollowUpPageActions.FilterChanged, (state): State => {
    return {
      ...state,
      followUpListPastLoaded: false,
    };
  }),
  on(SickLeaveFollowUpPageActions.Enter, (state): State => {
    return {
      ...state,
      listType: 'current',
      followUpListLoaded: false,
      followUpListPastLoaded: false,
    };
  }),
  on(SickLeaveFollowUpPageActions.EnterPast, (state): State => {
    return {
      ...state,
      listType: 'past',
    };
  }),
  on(SickLeaveFollowUpApiActions.loadSickLeaveFollowUpsSuccess, (state, action) => {
    return {
      ...adapter.setAll(action.response.Items, state),
      followUpCurrentPageCount: action.response.Count,
    };
  }),
  on(SickLeaveFollowUpApiActions.loadSickLeaveFollowUpsSuccess, (state, action): State => {
    return {
      ...state,
      followUpListLoaded: true,
    };
  }),
  on(SickLeaveFollowUpApiActions.loadSickLeavePastFollowUpsSuccess, (state, action): State => {
    return {
      ...state,
      followUpListPastLoaded: true,
      followUpListPast: action.response.Items,
      followUpPastTotalCount: action.response.Count,
    };
  }),
  on(SickLeaveFollowUpApiActions.loadSickLeaveFollowUpsError, (state, { code, message }): State => {
    return {
      ...state,
      followUpError: true,
      followUpErrorCode: code,
      followUpErrorMessage: message,
    };
  }),
  on(
    SickLeaveFollowUpNoteActions.addFollowUpNote,
    SickLeaveFollowUpNoteActions.addFollowUpItemNote,
    SickLeaveFollowUpNoteActions.editFollowUpNote,
    (state): State => {
      return {
        ...state,
        isSavingNote: true,
      };
    }
  ),
  on(
    SickLeaveFollowUpApiActions.editFollowUpNoteSuccess,
    SickLeaveFollowUpApiActions.editFollowUpNoteError,
    (state): State => {
      return {
        ...state,
        isSavingNote: false,
      };
    }
  ),
  on(SickLeaveFollowUpApiActions.UpdateStatusSuccess, (state, { followUp }): State => {
    let item = state.entities[followUp.SickLeaveFollowUpId];

    if (!followUp.IsCompleted && item) {
      return {
        ...adapter.upsertOne(followUp, state),
        followUpListPast: state.followUpListPast.filter((f) => f.SickLeaveFollowUpId !== followUp.SickLeaveFollowUpId),
      };      
    } else {
      let temp = [...state.followUpListPast].map((f) => {
        if (f.SickLeaveFollowUpId === followUp.SickLeaveFollowUpId) {
          return followUp;
        }
        return f;
      });

      if (temp.length === 0) {
        temp.push(followUp);
      }

      return {
        ...adapter.removeOne(followUp.SickLeaveFollowUpId, state),
        followUpListPast: temp,
      };
    }
  }),
  on(
    SickLeaveFollowUpApiActions.editFollowUpNoteSuccess,
    SickLeaveFollowUpApiActions.loadFollowUpDetailsSuccess,
    (state, { followUp }): State => {
      if (!followUp.IsCompleted) {
        return adapter.upsertOne(followUp, state);
      } else {
        let temp = [...state.followUpListPast].map((f) => {
          if (f.SickLeaveFollowUpId === followUp.SickLeaveFollowUpId) {
            return followUp;
          }
          return f;
        });

        if (temp.length === 0) {
          temp.push(followUp);
        }

        return {
          ...state,
          followUpListPast: temp,
        };
      }
    }
  ),
  on(SickLeaveFollowUpApiActions.loadSickLeavePastFollowUpsError, (state, { code, message }): State => {
    return {
      ...state,
      followUpError: true,
      followUpErrorCode: code,
      followUpErrorMessage: message,
    };
  }),
  on(SickLeaveFollowUpPageActions.ExtendAbsence, (state, { followUpId }): State => {
    return {
      ...state,
      selectedFollowUpId: followUpId,
    };
  }),
  on(
    ManageAddAbsencePageActions.leave,
    SickLeaveFollowUpPageActions.Enter,
    AbsenceApiActions.ExtendAbsenceeSuccess,
    (state): State => {
      return {
        ...state,
        selectedFollowUpId: null,
      };
    }
  ),
  on(SickLeaveFollowUpPageActions.DownloadFollowUpNote, (state, action): State => {
    return {
      ...state,
      downloadingNote: [...state.downloadingNote, action.noteId],
    };
  }),
  on(
    SickLeaveFollowUpApiActions.downloadFollowUpNoteSuccess,
    SickLeaveFollowUpApiActions.downloadFollowUpNoteError,
    (state, action): State => {
      return {
        ...state,
        downloadingNote: state.downloadingNote.filter((r) => r != action.noteId),
      };
    }
  ),
  on(SickLeaveFollowUpPageActions.DownloadFollowUp, (state, action): State => {
    return {
      ...state,
      downloadingFollowUp: true,
    };
  }),
  on(
    SickLeaveFollowUpApiActions.downloadFollowUpSuccess,
    SickLeaveFollowUpApiActions.downloadFollowUpError,
    (state, action): State => {
      return {
        ...state,
        downloadingFollowUp: false,
      };
    }
  ),
  on(SickLeaveFollowUpItemFileActions.AddFollowUpFileForUpload, (state, { files }): State => {
    return {
      ...state,
      filesForUpload: Array.from(
        [...files, ...state.filesForUpload].reduce((m, o) => m.set(o.fileName, o), new Map()).values()
      ),
    };
  }),
  on(SickLeaveFollowUpItemFileActions.RemoveFollowUpFileForUpload, (state, { fileName }): State => {
    return {
      ...state,
      filesForUpload: state.filesForUpload.filter((file) => file.fileName !== fileName),
    };
  }),
  on(SickLeaveFollowUpItemFileActions.SaveFollowUpFiles, (state): State => {
    return {
      ...state,
      isSavingFiles: true,
    };
  }),
  on(SickLeaveFollowUpItemFileActions.SaveFollowUpFileProgress, (state, { fileName, progress }): State => {
    return {
      ...state,
      filesForUpload: state.filesForUpload.map((file) => {
        if (file.fileName === fileName) {
          return { ...file, progress: progress };
        }
        return file;
      }),
    };
  }),
  on(SickLeaveFollowUpItemFileActions.SaveFollowUpFileSuccess, (state, { followUpId, fileName }): State => {
    return {
      ...state,
      filesForUpload: state.filesForUpload.map((file) => {
        if (file.fileName === fileName) {
          return { ...file, uploaded: true };
        }
        return file;
      }),
    };
  }),
  on(SickLeaveFollowUpItemFileActions.SaveFollowUpFileError, (state, { fileName }): State => {
    return {
      ...state,
      isSavingFiles: false,
      filesForUpload: state.filesForUpload.map((file) => {
        if (file.fileName === fileName) {
          return { ...file, uploaded: false };
        }
        return file;
      }),
    };
  }),
  on(SickLeaveFollowUpItemFileActions.CloseFileModal, (state): State => {
    return {
      ...state,
      isSavingFiles: false,
      filesForUpload: [],
    };
  }),
  on(SickLeaveFollowUpItemFileActions.UploadFilesComplete, (state): State => {
    return {
      ...state,
      isSavingFiles: false,
      filesForUpload: [],
    };
  }),
  on(SickLeaveFollowUpItemFileActions.DeleteFollowUpFile, (state, { fileId }): State => {
    return {
      ...state,
      idBeingDeleted: fileId,
    };
  }),
  on(SickLeaveFollowUpItemFileActions.DeleteFollowUpFileSuccess, (state): State => {
    return {
      ...state,
      idBeingDeleted: -1,
    };
  }),
  on(SickLeaveFollowUpItemFileActions.DeleteFollowUpFileError, (state): State => {
    return {
      ...state,
      idBeingDeleted: -1,
    };
  }),
  on(SickLeaveFollowUpApiActions.deleteFollowUpSuccessEnterCurrent, (state, { followUpItemId }): State => {
    return adapter.removeOne(followUpItemId, state);
  }),
  on(SickLeaveFollowUpApiActions.deleteFollowUpSuccessEnterPast, (state, { followUpItemId }): State => {
    return {
      ...state,
      followUpListPast: state.followUpListPast.filter((f) => f.SickLeaveFollowUpId !== followUpItemId),
    };
  }),
  on(SickLeaveFollowUpApiActions.closeFollowUpSuccess, (state, { followUpId }): State => {
    let followUp = state.entities[followUpId];

    if (followUp?.IsCompleted) {
      return {
        ...state,
        followUpListPast: [...state.followUpListPast, followUp],
      };
    }

    return { ...state };
  }),
  on(SickLeaveFollowUpApiActions.closeFollowUpSuccess, (state, { followUpId }): State => {
    return adapter.updateOne(
      {
        id: followUpId,
        changes: {
          IsCompleted: true,
        },
      },
      state
    );
  }),
  on(SickLeaveFollowUpPageActions.TogglePanelCollapsed, (state, { followUpItemId }): State => {
    return {
      ...state,
      timelinePanelOpen: state.timelinePanelOpen.includes(followUpItemId)
        ? state.timelinePanelOpen.filter((id) => id !== followUpItemId)
        : [...state.timelinePanelOpen, followUpItemId],
    };
  }),
  on(SickLeaveFollowUpPageActions.UpdateStatus, (state, { itemId }): State => {
    return {
      ...state,
      followUpItemsStatusBeingUpdated: [...state.followUpItemsStatusBeingUpdated, itemId],
    };
  }),
  on(SickLeaveFollowUpApiActions.UpdateStatusSuccess, (state, { itemId }): State => {
    return {
      ...state,
      followUpItemsStatusBeingUpdated: state.followUpItemsStatusBeingUpdated.includes(itemId)
        ? state.followUpItemsStatusBeingUpdated.filter((id) => id !== itemId)
        : [...state.followUpItemsStatusBeingUpdated, itemId],
    };
  })
);
