import { createReducer, on } from '@ngrx/store';
import { EmployeePageActions } from '../actions/employee-page.actions';
import {
  EmployeePersonnelFileApiActions,
  EmployeePersonnelFileModalActions,
  EmployeePersonnelFileTabActions,
} from '../actions/employee-personnel-file.actions';
import { EmployeeModalActions } from '../actions/employee-details.actions';

export const featureKey = 'personnelFile';

export class FileForUpload {
  fileName: string;
  uploaded: boolean;
  progress: number;
  error: boolean;
  file: File;
}
export interface State {
  selectedPersonnelFileId: number;
  filesForUpload: FileForUpload[];
  isSaving: boolean;
  idBeingDeleted: number;
  loaded: boolean;
  error: boolean;
}

export const initialState: State = {
  selectedPersonnelFileId: -1,
  filesForUpload: [],
  isSaving: false,
  idBeingDeleted: -1,
  loaded: false,
  error: false,
};

export const reducer = createReducer(
  initialState,
  on(EmployeePageActions.Enter, (state) => initialState),
  on(EmployeePersonnelFileTabActions.AddPersonnelFileForUpload, (state, { files }): State => {
    return {
      ...state,
      filesForUpload: Array.from(
        [...files, ...state.filesForUpload].reduce((m, o) => m.set(o.fileName, o), new Map()).values()
      ),
    };
  }),
  on(EmployeePersonnelFileTabActions.RemovePersonnelFileForUpload, (state, { fileName }): State => {
    return {
      ...state,
      filesForUpload: state.filesForUpload.filter((file) => file.fileName !== fileName),
    };
  }),
  on(EmployeePersonnelFileTabActions.OpenModal, (state, { personnelFileId }): State => {
    return {
      ...state,
      selectedPersonnelFileId: personnelFileId,
      filesForUpload: [],
    };
  }),
  on(EmployeeModalActions.CloseDetailsModal, (state): State => {
    return {
      ...state,
      selectedPersonnelFileId: -1,
      filesForUpload: [],
    };
  }),
  on(EmployeePersonnelFileModalActions.SavePersonnelFile, (state): State => {
    return {
      ...state,
      isSaving: true,
    };
  }),
  on(EmployeePersonnelFileApiActions.SavePersonnelFileProgress, (state, { fileName, progress }): State => {
    return {
      ...state,
      filesForUpload: state.filesForUpload.map((file) => {
        if (file.fileName === fileName) {
          return { ...file, progress: progress };
        }
        return file;
      }),
    };
  }),
  on(EmployeePersonnelFileApiActions.SavePersonnelFileSuccess, (state, { fileName }): State => {
    return {
      ...state,
      filesForUpload: state.filesForUpload.map((file) => {
        if (file.fileName === fileName) {
          return { ...file, uploaded: true };
        }
        return file;
      }),
    };
  }),
  on(EmployeePersonnelFileApiActions.SavePersonnelFileError, (state, { fileName }): State => {
    return {
      ...state,
      isSaving: false,
      filesForUpload: state.filesForUpload.map((file) => {
        if (file.fileName === fileName) {
          return { ...file, error: true };
        }
        return file;
      }),
    };
  }),
  on(EmployeePersonnelFileApiActions.UploadFilesComplete, (state): State => {
    return {
      ...state,
      isSaving: false,
    };
  }),
  on(EmployeePersonnelFileModalActions.DeletePersonnelFile, (state, { personnelFile }): State => {
    return {
      ...state,
      idBeingDeleted: personnelFile.PersonnelFileId,
    };
  }),
  on(
    EmployeePersonnelFileApiActions.DeletePersonnelFileError,
    EmployeePersonnelFileApiActions.DeletePersonnelFileSuccess,
    (state): State => {
      return {
        ...state,
        idBeingDeleted: -1,
      };
    }
  ),
  on(EmployeePersonnelFileApiActions.LoadPersonnelFileListSuccess, (state): State => {
    return {
      ...state,
      loaded: true,
      error: false,
    };
  }),
  on(EmployeePersonnelFileApiActions.LoadPersonnelFileListError, (state): State => {
    return {
      ...state,
      error: true,
    };
  })
);
