import { Component, Input } from '@angular/core';
import { AbsenceType } from '@mentor-one-ui/time/my-time/absence/models/absence-type.model';
import { OvertimeType } from '@mentor-one-ui/time/my-time/overtime/models/overtime-type.model';
import {
  selectAbsenceTypes,
  selectOvertimeTypes,
} from '@mentor-one-ui/time/state/time-common/selectors/time-common.selectors';
import { Store } from '@ngrx/store';

type size = 'big' | 'small';
@Component({
  selector: 'mo-emoji',
  standalone: true,
  template: `<span class="pbl-emoji {{ size }} {{ rounded ? 'rounded' : 'square' }}">{{ getEmojiCode() }}</span> `,
  styleUrls: ['./emoji.component.scss'],
})
export class EmojiComponent {
  @Input() emojiCode?: string;
  @Input() absenceId?: number;
  @Input() overtimeId?: number;
  @Input() size?: size = 'small';
  @Input() rounded?: boolean = true;
  absenceTypes: AbsenceType[];
  overtimeTypes: OvertimeType[];

  constructor(private store: Store) {
    this.store.select(selectAbsenceTypes).subscribe((absenceTypes) => {
      this.absenceTypes = absenceTypes;
    });

    this.store.select(selectOvertimeTypes).subscribe((overtimes) => {
      this.overtimeTypes = overtimes;
    });
  }

  getEmojiCode(): string {
    if (this.emojiCode) {
      return this.emojiCode;
    }
    if (this.absenceId) {
      const absenceType = this.absenceTypes?.find((f) => f.OrganizationAbsenceMapId === this.absenceId);
      return absenceType?.EmojiCode ?? '';
    }
    if (this.overtimeId) {
      const overtimeType = this.overtimeTypes?.find((f) => f.OrganizationOvertimeMapId === this.overtimeId);
      return overtimeType?.EmojiCode ?? '';
    }

    return '';
  }
}
