import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeValidatorService {

  constructor() { }

  removeSecondsFromTimeString(time: string | null) {
    return time?.slice(0, 5) || '';
  }

  convertDateToDateOnlyString(date: Date) {
    const offset = date.getTimezoneOffset();
    const timeZonedDate = new Date(date.getTime() - (offset*60*1000));
    return timeZonedDate.toISOString().split('T')[0];
  }
}
