
import { Action, combineReducers } from '@ngrx/store';
import * as fromLeaveApproval from './reducers/leave-approval.reducer';
import * as fromLeaveAdministration from './reducers/leave-administration.reducer';
import * as fromUnmanagedAbsences from './reducers/unmanaged-leaves.reducer';
import * as frommanagedAbsences from './reducers/managed-leaves.reducer';

export const leaveManagementFeatureKey = 'leaveManagement';
export const leaveApprovalFeatureKey = 'leaveApproval';

interface LeaveApprovalState {
  [fromLeaveApproval.featureKey]: fromLeaveApproval.State;
  [fromUnmanagedAbsences.featureKey]: fromUnmanagedAbsences.State;
  [frommanagedAbsences.featureKey]: frommanagedAbsences.State;
}

export interface State {
  [leaveApprovalFeatureKey]: LeaveApprovalState
  [fromLeaveAdministration.featureKey]: fromLeaveAdministration.State;
}

export function reducers(state: State | undefined, action: Action) {
  return combineReducers({
    [leaveApprovalFeatureKey]: leaveApprovalReducer,
    [fromLeaveAdministration.featureKey]: fromLeaveAdministration.reducer,
  })(state, action);
}

export function leaveApprovalReducer(state: LeaveApprovalState | undefined, action: Action) {
  return combineReducers({
    [fromLeaveApproval.featureKey]: fromLeaveApproval.reducer,
    [fromUnmanagedAbsences.featureKey]: fromUnmanagedAbsences.reducer,
    [frommanagedAbsences.featureKey]: frommanagedAbsences.reducer,
  })(state, action);
}