import { AbsenceModel } from "@mentor-one-ui/time/my-time/absence/models/absence.model";
import { TimeManagementModel } from "@mentor-one-ui/time/my-time/absence/models/TimeManagementModel";
import { createAction, props } from "@ngrx/store";
import { CalendarItemModel, CalendarModel } from "../../models/calendar.models";

export const CalendarApiActions = {
    loadCalendarsSuccess: createAction('[Calendar API] Load Calendars Success', props<{ calendarData: CalendarModel, date: string }>()),
    loadCalendarDataError: createAction('[Calendar API] Load Calendars Failure', props<{ error: any }>()),
    loadCalendarEventDetailsSuccess: createAction('[Calendar API] Load Calendar Event Details Success', props<{ model: AbsenceModel }>()),
    loadCalendarEventDetailsError: createAction('[Calendar API] Load Calendar Event Details Error', props<{ error: string }>()),
    ApproveLeaveRequest: createAction('[Calendar API] Approve Leave Request', props<{ absenceId: number }>()),
    ApproveAbsenceSuccess: createAction('[Calendar API] Approve Absence Success', props<{ absence: AbsenceModel }>()),
    ApproveAbsenceError: createAction('[Calendar API] Approve Absence Error', props<{ error: string }>()),
    RejectLeaveRequest: createAction('[Calendar API] Reject Leave Request', props<{ absenceId: number, comment: string }>()),
    RejectAbsenceSuccess: createAction('[Calendar API] Reject Absence Success', props<{ id: number; }>()),
    RejectAbsenceError: createAction('[Calendar API] Reject Absence Error', props<{ error: string; }>()),
};
