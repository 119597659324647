import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { organizationDetailsSelectors } from '../about/state/organization-details.selectors';
import {
  UserOrganizationModel,
  combineHrSettingsAndOrgModel,
} from '@mentor-one-ui/core/models/user-organization/user-organization.model';
import { SettingsModalActions, SettingsPageActions } from '../settings/state/settings.actions';
import { Subject, takeUntil } from 'rxjs';
import { OrganizationSettingsSelectors } from '../settings/state/settings.selectors';
interface AbsenceDay {
  name: string;
  value: Number;
}

@Component({
  selector: 'mo-absence-management-modal',
  template: `<div class="absence-management-module" scrollshadow>
    <div *ngIf="orgModel">
      <form [formGroup]="form">
        <fieldset class="pbl-grid">
          <div class="pbl-grid-largest ">
            <legend>
              {{ 'care-days' | translate | async
              }}<mo-info-mini infoText="{{ 'care-days-info-text' | translate | async }}"></mo-info-mini>
            </legend>
            <p-checkbox
              id="AllowHalfDayCareDayAbsence"
              formControlName="AllowHalfDayCareDayAbsence"
              [binary]="true"
              label="{{ 'care-days-description' | translate | async }}"
            ></p-checkbox>
          </div>
        </fieldset>

        <fieldset class="pbl-grid">
          <div class="pbl-grid-largest ">
            <legend>{{ 'vacation-days' | translate | async }}</legend>
            <p>{{ 'vacation-days-description' | translate | async }}</p>
            <p-inputNumber [min]="0" [max]="100" formControlName="NumberOfVacationDays"></p-inputNumber>
          </div>
        </fieldset>

        <fieldset class="pbl-grid">
          <div class="pbl-grid-largest ">
            <legend>
              {{ 'show-absence' | translate | async
              }}<mo-info-mini infoText="{{ 'show-absence-info-text' | translate | async }}"></mo-info-mini>
            </legend>

            <p-checkbox
              id="AbsenceAreVisibleToAllEmployees"
              formControlName="AbsenceAreVisibleToAllEmployees"
              [binary]="true"
              label="{{ 'show-absence-checklabel' | translate | async }}"
            ></p-checkbox>
          </div>
        </fieldset>
        <div class="p-dialog-footer p-dialog-footer--sticky">
          <mo-default-buttons
            [showDelete]="false"
            [canSave]="hasChanges"
            [isSaving]="(isSaving$ | async)!"
            cancelLabel="{{ 'close' | translate | async }}"
            (cancel)="cancel()"
            (save)="save()"
          ></mo-default-buttons>
        </div>
      </form>
    </div>
  </div>`,
  styleUrls: ['./absence-management-modal.component.scss'],
})
export class AbsenceManagementModalComponent implements OnInit {
  private readonly destroy$ = new Subject();

  form: FormGroup = new FormGroup({});
  orgModel: UserOrganizationModel | null;
  orgModelWithoutChanges: UserOrganizationModel | null;
  days: AbsenceDay[];
  userOrganizationDataLoaded = false;

  hasChanges = false;
  isSaving$ = this.store.select(OrganizationSettingsSelectors.selectSettingsPageIsSaving);
  initialFormValues: string;

  constructor(private store: Store) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.days = [
      { name: '3 dager', value: 3 },
      { name: '8 dager', value: 8 },
    ];

    this.form = this.createHrSettingsForm();

    this.store
      .select(organizationDetailsSelectors.selectSelectedUserOrganization)
      .pipe(takeUntil(this.destroy$))
      .subscribe((org) => {
        if (org != null) {
          this.userOrganizationDataLoaded = true;
          this.orgModel = org;
          this.orgModelWithoutChanges = org;
          this.setFormValues(this.orgModel);
          this.initialFormValues = JSON.stringify(this.form.getRawValue());
        }
      });

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      if (this.initialFormValues !== JSON.stringify(this.form.getRawValue())) {
        this.hasChanges = true;
      } else {
        this.hasChanges = false;
      }
    });
  }

  createHrSettingsForm(): FormGroup {
    return new FormGroup({
      PoliceCertificateIsRequired: new FormControl(),
      ConfidentialityDeclaration: new FormControl(),
      NumberOfVacationDays: new FormControl(),
      AllowHalfDayCareDayAbsence: new FormControl(),
      RegisterSeriesSaturday: new FormControl(),
      RegisterSeriesSunday: new FormControl(),
      AbsenceAreVisibleToAllEmployees: new FormControl(),

      // TODO: add these fields in BE
      SeniorPolicy: new FormControl(false),
      SeniorPolicyAge: new FormControl(),
      SeniorDaysFixed: new FormControl(false),
      SeniorDaysNumberOfDaysTotal: new FormControl(''),
      SeniorDaysNumberOfDaysPerYear: new FormControl(''),
      Comment: new FormControl(''),
    });
  }

  setFormValues(organization: UserOrganizationModel) {
    this.form.get('PoliceCertificateIsRequired')?.setValue(organization.HrSettings.PoliceCertificateIsRequired);
    this.form.get('ConfidentialityDeclaration')?.setValue(organization.HrSettings.ConfidentialityDeclaration);
    this.form.get('NumberOfVacationDays')?.setValue(organization.HrSettings.NumberOfVacationDays);
    this.form.get('AllowHalfDayCareDayAbsence')?.setValue(organization.HrSettings.AllowHalfDayCareDayAbsence);
    this.form.get('AbsenceAreVisibleToAllEmployees')?.setValue(organization.HrSettings.AbsenceAreVisibleToAllEmployees);
  }

  hasSeniorPolicy() {
    return this.form?.controls?.SeniorPolicy?.value;
  }

  save() {
    const dto = combineHrSettingsAndOrgModel(this.form, this.orgModel!);
    this.store.dispatch(SettingsPageActions.saveSettings({ organization: dto }));
  }

  cancel() {
    this.store.dispatch(SettingsModalActions.CancelModal({ hasChanges: this.hasChanges }));
  }
}
