import { NgModule } from '@angular/core';
import { TimespanElementComponent } from './timespan-element.component';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@mentor-one-ui/core/pipes/pipes.module';

@NgModule({
  declarations: [TimespanElementComponent],
  imports: [CommonModule, PipesModule],
  exports: [TimespanElementComponent],
})
export class TimespanElementModule {}
