import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CalendarItemModel } from '../../models/calendar.models';
import { ButtonBarButton } from '@mentor-one-ui/core/models/ButtonBar';
import { Observable } from 'rxjs';
import { selectActiveCalendarEventActiveStatusForButtons } from '../../state/selectors';
import { Store } from '@ngrx/store';
import { TimeManagementStatusEnum } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementStatusEnum';

@Component({
  selector: 'mo-event-details-mobile',
  styleUrls: ['./event-details-mobile.component.scss'],
  template: `
    <p-sidebar
      [(visible)]="showCalendarEventDetails"
      (onHide)="closeDetails()"
      position="bottom"
      styleClass="sidebar-details p-sidebar-lg"
    >
      <ng-template pTemplate="header">{{ 'absence' | translate | async }}</ng-template>
      <ng-template pTemplate="content">
        <div>
          <div class="name-status justify-sides">
            <span>{{ selectedCalenderEvent?.EmployeeId! | employeeFullNameFromId | async }}</span>
            <mo-absence-status-tag [status]="selectedCalenderEvent?.Status!"></mo-absence-status-tag>
          </div>
          <p>
            <mo-timespan-element [absence]="selectedCalenderEvent!" [showCalculatedHours]="true"></mo-timespan-element>
          </p>
          <div class="justify-sides">
            <span>{{ selectedCalenderEvent?.OrganizationAbsenceMapId! | absenceType }}</span>
            <i
              class="fal fa-comment"
              *ngIf="selectedCalenderEvent?.Comment?.length! > 0"
              [pTooltip]="selectedCalenderEvent?.Comment!"
            ></i>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <ng-container
          *ngIf="
            (selectedCalenderEvent?.Status == TimeManagementStatusEnum.Pending &&
              selectedCalenderEvent?.HasValidationErrors) == null;
            else showButtons">
          <p-progressSpinner class="spinner"></p-progressSpinner>
        </ng-container>
        <ng-template #showButtons>
          <div class="sidebar-footer-details">
            <mo-button-bar
              class="absence-details-page__button-bar"
              (buttonBarClick)="buttonClick($event)"
              [isSaving]="isSaving"
              [buttonList]="buttons!"
            >
            </mo-button-bar>
          </div>
        </ng-template>
      </ng-template>
    </p-sidebar>
  `,
})
export class EventDetailsMobileComponent {
  @Input() selectedCalenderEvent: CalendarItemModel | undefined;
  @Input() showCalendarEventDetails: boolean = false;
  @Input() isSaving: boolean = false;
  @Input() buttons: ButtonBarButton[];
  @Output() handleButtonClick = new EventEmitter<any>();
  @Output() closePanel = new EventEmitter();

  TimeManagementStatusEnum = TimeManagementStatusEnum;
  constructor(private store: Store) {}

  buttonClick($event: ButtonBarButton) {
    this.handleButtonClick.emit($event);
  }

  closeDetails() {
    this.closePanel.emit();
  }
}
