import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserOrganizationModel } from '@mentor-one-ui/core/models/user-organization/user-organization.model';
import { Store } from '@ngrx/store';
import { Observable, Subject, tap } from 'rxjs';
import { UserOrganizationPageActions } from '../../about/state/actions/about-page.action';
import { organizationDetailsSelectors } from '../../about/state/organization-details.selectors';

@Component({
  selector: 'mo-profile-modal',
  template: `
  <mo-profile-form
      *ngIf="selectedUserOrganization$ | async as userOrganization"
      [form]="form"
      [isSaving]="(isSaving$ | async)!"
      (SaveForm)="saveUserOrganization(userOrganization)"
      (Cancel)="cancel()">
  </mo-profile-form>
  `,
  styleUrls: ['./profile-modal.component.scss']
})

export class ProfileModalComponent implements OnDestroy {
  form: FormGroup;
  selectedUserOrganization$: Observable<UserOrganizationModel | null>;

  public ngDestroyed$ = new Subject();
  isSaving$: Observable<boolean>;

  constructor(private store: Store) {
    this.isSaving$ = this.store.select(organizationDetailsSelectors.selectIsSaving);

    this.selectedUserOrganization$ = this.store
      .select(organizationDetailsSelectors.selectSelectedUserOrganization)
      .pipe(
        tap((userOrganization: UserOrganizationModel | null) => {
          if (userOrganization != null) {
            this.form = new FormGroup({
              Vision: new FormControl(userOrganization.Vision, [Validators.required]),
            });
          }
        })
      );
  }

  ngOnDestroy(): void {
    this.ngDestroyed$.next(null);
  }

  saveUserOrganization(organization: UserOrganizationModel) {
    const org = this.getOrganizationFromForm(organization);
    this.store.dispatch(UserOrganizationPageActions.saveUserOrganization({ organization: org }));
  }

  getOrganizationFromForm(organzation: UserOrganizationModel): UserOrganizationModel {
    const org = {
      ...organzation,
      Vision: this.form?.value.Vision,
    };
    return org;
  }

  cancel() {
    this.store.dispatch(UserOrganizationPageActions.HideProfileModal());
  }
}
