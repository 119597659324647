import { Injectable } from "@angular/core";
import { ReportModel } from "@mentor-one-ui/core/models/ReportModel";
import { ReportService } from "@mentor-one-ui/core/services/api/report.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of } from "rxjs";
import { ReportApiActions, ReportPageActions } from "./report.actions";
import { ApplicationActions } from "../application/application.actions";
import { TranslationDataService } from "@mentor-one-ui/core/services/translation-data.service";

@Injectable()
export class ReportEffects {
  constructor(
    private actions$: Actions,
    private reportService: ReportService,
    private translationService: TranslationDataService
  ) { }

  loadPoliceCertificate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportPageActions.LoadPoliceCertificate),
      mergeMap(() =>
        this.reportService.getPoliceCertificates().pipe(
          map((data: ReportModel) => {
            return ReportApiActions.LoadReportSuccess({ report: data });
          }),
          catchError((err) => of(ReportApiActions.LoadReportError({ error: this.translationService.translate('could-not-load-police-certificate') })))
        )
      )
    );
  });

  loadConfidentialityDeclarations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportPageActions.LoadConfidentialityDeclarations),
      mergeMap(() =>
        this.reportService.getConfidentialityDeclarations().pipe(
          map((data: ReportModel) => {
            return ReportApiActions.LoadReportSuccess({ report: data });
          }),
          catchError((err) => of(ReportApiActions.LoadReportError({ error: this.translationService.translate('could-not-load-confidentiality-declaration') })))
        )
      )
    );
  });

  loadBirthdays$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportPageActions.LoadBirthdays),
      mergeMap(() =>
        this.reportService.getBirthdays().pipe(
          map((data: ReportModel) => {
            return ReportApiActions.LoadReportSuccess({ report: data });
          }),
          catchError((err) => of(ReportApiActions.LoadReportError({ error: this.translationService.translate('could-not-load-birthdays') })))
        )
      )
    );
  });

  loadChildrensBirthdays$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportPageActions.LoadChildrensBirthdays),
      mergeMap(() =>
        this.reportService.getChildrensBirthdays().pipe(
          map((data: ReportModel) => {
            return ReportApiActions.LoadReportSuccess({ report: data });
          }),
          catchError((err) => of(ReportApiActions.LoadReportError({ error: this.translationService.translate('could-not-load-childrens-birthdays') })))
        )
      )
    );
  });

  loadNextOfKins$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportPageActions.LoadNextOfKins),
      mergeMap(() =>
        this.reportService.getNextOfKins().pipe(
          map((data: ReportModel) => {
            return ReportApiActions.LoadReportSuccess({ report: data });
          }),
          catchError((err) => of(ReportApiActions.LoadReportError({ error: this.translationService.translate('could-not-load-next-of-kins') })))
        )
      )
    );
  });

  loadPersonnelManagers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportPageActions.LoadPersonnelManagers),
      mergeMap(() =>
        this.reportService.getPersonnelManagers().pipe(
          map((data: ReportModel) => {
            return ReportApiActions.LoadReportSuccess({ report: data });
          }),
          catchError((err) => of(ReportApiActions.LoadReportError({ error: this.translationService.translate('could-not-load-personnel-managers') })))
        )
      )
    );
  });

  loadReportError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportApiActions.LoadReportError,     
      ),
      map((action) => {
        return ApplicationActions.ErrorMessage({
          title: action.error,
          message: '',
        });
      })
    );
  });
}