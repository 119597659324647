import { SystemMessageModel, SystemMessageTypeEnum } from '@mentor-one-ui/core/models/system-messages.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Message } from 'primeng/api';
import * as fromApplication from './application.reducer';

export const selectApplicationState = createFeatureSelector<fromApplication.State>('application');

export const selectActiveMenuModule = createSelector(
  selectApplicationState,
  state => state.activeMenuModule
);

export const selectIsDarkMode = createSelector(
  selectApplicationState,
  state => state.darkmode
);

export const selectIsBootstrapped = createSelector(
  selectApplicationState,
  state => state.bootstrapped
);
