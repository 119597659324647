import { createSelector } from '@ngrx/store';
import { adapter } from './departments.reducer';
import { selectUserOrganizationState } from '@mentor-one-ui/user-organization/state';
import { DepartmentLightModel } from '@mentor-one-ui/core/models/DepartmentModel';

const selectDepartmentEntitiesState = createSelector(selectUserOrganizationState, (state) => state?.departments);

const selectIsSaving = createSelector(selectDepartmentEntitiesState, (state) => state?.isSaving);

export const {
  selectIds: selectDepartmentIds,
  selectEntities: selectDepartmentEntities,
  selectAll: selectAllDepartments,
  selectTotal: selectTotalDepartments,
} = adapter.getSelectors(selectDepartmentEntitiesState);

const selectPersonellManagers = createSelector(selectDepartmentEntitiesState, (state) => state?.PersonellManagers);

const selectDepartmentsForDropDown = createSelector(selectAllDepartments, (departments) => {
  return departments.map((department) => {
    return {
      DepartmentId: department.DepartmentId,
      Name: department.Name,
    } as DepartmentLightModel;
  });
});

const selectSelectedId = createSelector(selectDepartmentEntitiesState, (state) => state?.SelectedId);

const selectEntityById = createSelector(selectAllDepartments, selectSelectedId, (entities, selectSelectedId) => {
  return selectSelectedId ? entities.find((x) => x.DepartmentId === selectSelectedId) : null;
});



const selectSelectedDepartment = createSelector(
  selectAllDepartments,
  selectSelectedId,
  (departments, selectedId) => departments.find((x) => x.DepartmentId === selectedId)
);

const selectedDepartmentHasEmployees = createSelector(
  selectSelectedDepartment, 
  (department) => department && department?.EmployeeIds?.length > 0  
);

const selectLoadError = createSelector(selectDepartmentEntitiesState, (state) => state?.loadError);

export const departmentSelectors = {
  selectDepartmentEntitiesState,
  selectIsSaving,
  selectPersonellManagers,  
  selectDepartmentsForDropDown,
  selectSelectedId,
  selectEntityById,
  selectedDepartmentHasEmployees,
  selectAllDepartments,  
  selectSelectedDepartment,
  selectLoadError
};
