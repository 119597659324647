import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { departmentSelectors } from '@mentor-one-ui/user-organization/components/departments/state/departments.selectors';
import { Store } from '@ngrx/store';
import { ConfirmationService } from 'primeng/api';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { DepartmentsTabActions } from '@mentor-one-ui/user-organization/components/departments/state/actions/departments.actions';
import { DepartmentModel } from '@mentor-one-ui/core/models/DepartmentModel';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'mo-department-dialog',
  template: `
    <mo-department-form *ngIf="departmentForm" [form]="departmentForm" scrollshadow></mo-department-form>
    <div class="p-dialog-footer p-dialog-footer--sticky">
      <mo-default-buttons
        [showDelete]="departmentForm.value.DepartmentId > 0"
        [isSaving]="(isSaving$ | async)!"
        [canSave]="departmentForm.valid && departmentForm.dirty"
        (delete)="openDeleteDepartmentDialog(departmentForm.value.DepartmentId)"
        (save)="saveDepartment()"
        (cancel)="cancelSaveDepartment()"
      ></mo-default-buttons>
    </div>
  `,
})
export class DepartmentDialogComponent implements OnInit {
  @Input() showDepartmentModal: boolean = false;
  @Input() department: DepartmentModel;

  isSaving$ = this.store.select(departmentSelectors.selectIsSaving);
  departmentForm: FormGroup;

  constructor(
    private store: Store,
    private config: DynamicDialogConfig,
    private confirmationService: ConfirmationService,
    private translationService: TranslationDataService
  ) {
    this.department = this.config.data.department;
  }

  ngOnInit(): void {
    this.departmentForm = new FormGroup({
      DepartmentId: new FormControl(this.department.DepartmentId),
      Name: new FormControl(this.department.Name, Validators.required),
      IsActive: new FormControl(this.department.IsActive),
      Description: new FormControl(this.department.Description),
    });
  }

  cancelSaveDepartment() {
    this.store.dispatch(DepartmentsTabActions.CloseDepartmentModal());
  }

  openDeleteDepartmentDialog(id: number) {
    if (this.department.EmployeeIds.length > 0) {
      this.confirmationService.confirm({
        header: this.translationService.translate('delete'),
        message: this.translationService.translate('delete-department-with-employees'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.translationService.translate('ok'),
        rejectVisible: false,
        acceptIcon: 'pi pi-check',
      });
    } else {
      this.confirmationService.confirm({
        message: this.translationService.translate('delete-department-question'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.translationService.translate('delete'),
        acceptButtonStyleClass: 'p-button-danger',
        rejectLabel: this.translationService.translate('cancel'),
        rejectButtonStyleClass: 'p-button-secondary',
        acceptIcon: 'pi pi-trash',
        accept: () => {
          this.store.dispatch(DepartmentsTabActions.deleteDepartment({ departmentId: id }));
        },
      });
    }
  }

  saveDepartment() {
    let department = this.departmentForm.value;
    this.store.dispatch(DepartmentsTabActions.updateDepartment({ department }));
  }
}
