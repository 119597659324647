import { createAction, props } from "@ngrx/store";

const EnterSetPasswordPage = createAction('[Create User from Email] Enter create user');

const SetPasswordRetriveToken = createAction('[Create User from Email] Retrive token', props<{ token: string }>());

const SetPasswordPostPassword = createAction('[Create User from Email] Post password', props<{ token: string, password: string }>());
const SetPasswordPostPasswordSuccess = createAction('[Create User from Email] Post password success');
const SetPasswordPostPasswordFailure = createAction('[Create User from Email] Post password failure', props<{ error: string }>());

const CheckIfTokenIsCompleted = createAction('[Create User from Email] Check if token is completed');
const CheckIfTokenIsCompletedSuccess = createAction('[Create User from Email] Check if token is completed success', props<{ status: boolean }>());
const CheckIfTokenIsCompletedFailure = createAction('[Create User from Email] Check if token is completed failure', props<{ error: string }>());

const SetServerErrorText = createAction('[Create User from Email] Set server error text', props<{ errormessage: string }>());
const SetShowServerError = createAction('[Create User from Email] Set show error text', props<{ showerror: boolean }>());


export const SetPasswordActions = {
  EnterSetPasswordPage,
  SetPasswordRetriveToken,
  SetPasswordPostPassword,
  SetPasswordPostPasswordSuccess,
  SetPasswordPostPasswordFailure,
  SetServerErrorText,
  SetShowServerError,
  CheckIfTokenIsCompleted,
  CheckIfTokenIsCompletedSuccess,
  CheckIfTokenIsCompletedFailure,
};
