import { SystemEnum } from '@mentor-one-ui/core/models/enums';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as _ from 'underscore';
import { UserState } from '../user/user.state';
import { UserRole } from '../user/user.model';
import { selectOrganizationSystemAccess } from '@mentor-one-ui/user-organization/components/about/state/organization-details.selectors';

export const selectUserState = createFeatureSelector<UserState>('user');

export const selectUserRolesForUser = createSelector(
  selectUserState,  
  userState => {
    return userState.user?.UserRoles;
  }
);

const selectIsSystemAdmin = createSelector(
  selectUserRolesForUser,   
  selectOrganizationSystemAccess,
  (userRoles, userOrganizationSystemAccess) => {

  let mentorAdminSystem = userOrganizationSystemAccess?.find((x) => x.System == SystemEnum.MentorAdmin);
  let mentorAdminRole = userRoles?.find((x) => x == UserRole.SystemAdmin);
  
  if (mentorAdminRole !== null && mentorAdminSystem !== null && mentorAdminSystem !== undefined) {
    return true;
  }

  return false;
});

export const selectIsPersonnelManager = createSelector(selectUserState, (state) => {
  return state.user?.UserRoles?.some((s) => s === UserRole.PersonnelManager);
});
export const selectIsAdministratorPersonal = createSelector(selectUserState, (state) => {
  return state.user?.UserRoles?.some((s) => s === UserRole.AdministratorMentorPersonal);
});

export const selectUserRole = createSelector(
  selectIsPersonnelManager,
  selectIsAdministratorPersonal,
  selectIsSystemAdmin,
  (isPersonnelManager, isMentorPersonalAdministrator, isSystemAdmin) => {
    return {
      isPersonnelManager,
      isMentorPersonalAdministrator,
      isSystemAdmin,
    };
    }
);


const selectIsAdminOrPersonalManager = createSelector(
  selectIsPersonnelManager,
  selectIsAdministratorPersonal,
  selectIsSystemAdmin,
  (isPersonalManager, isAdministratorPersonal) => {
    return isPersonalManager || isAdministratorPersonal;
  }
);

export const AccessSelector = {
  selectIsSystemAdmin,
  selectIsAdminOrPersonalManager,
  selectIsAdministratorPersonal,
};
