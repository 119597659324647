import { createReducer, on } from "@ngrx/store"
import { AuthState, initialState } from "./auth.state"
import * as authActions from './auth.actions';

export const authReducer = createReducer<AuthState>(
  initialState,
  on(authActions.checkAuthComplete, (state, { isLoggedIn }) => {
    return {
      ...state,      
      isAuthenticated: isLoggedIn,
    };
  }),
  on(authActions.logout, (state, {}) => {
    return initialState
  })
)
