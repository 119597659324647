import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from "../../pipes/pipes.module";
import { TopNavigationComponent } from './top-navigation.component';

@NgModule({
    declarations: [TopNavigationComponent],
    exports: [TopNavigationComponent],
    imports: [CommonModule, PipesModule]
})
export class TopNavigationModule {}
