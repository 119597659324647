import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MainNavigationComponent } from '@mentor-one-ui/core/components/main-navigation/main-navigation.component';

import { AppRoutingModule } from '@mentor-one-ui/app-routing.module';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

import { ButtonModule } from 'primeng/button'; // Hvorfor må denne importeres her også for å få ikoner?
import { MenuModule } from 'primeng/menu';
import { PanelMenuModule } from 'primeng/panelmenu';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [MainNavigationComponent],
  imports: [
    AppRoutingModule,
    StoreRouterConnectingModule,
    ButtonModule,
    FormsModule,
    MenuModule,
    PanelMenuModule,
    HttpClientModule,
    BrowserModule,
  ],
  providers: [HttpClientModule],
  exports: [MainNavigationComponent],
})
export class MainNavigationModule {}
