import { createAction, props } from "@ngrx/store";
import { SickLeaveReportFilterModel, SickLeaveReportModel } from "../models/sick-leave-reports.model";
import { CareDaysChildrenReportAggregationModel, CareDaysReportFilterModel } from "../models/sick-leave-report-children.model";

export const SickLeaveReportPageActions = {
  Enter: createAction('[SickLeaveReports Page] Enter'),
  EnterCareDays: createAction('[SickLeaveReports Page] Enter Children'),
  FilterChanged: createAction('[SickLeaveReports Page] Filter Changed', props<{ filter: SickLeaveReportFilterModel }>()),
  FilterCareDaysChanged: createAction('[SickLeaveReports Page] Filter Children Changed', props<{ filter: CareDaysReportFilterModel }>()),
  IsLoading: createAction('[SickLeaveReports Page] Is Loading', props<{ isLoading: boolean }>()),
  DownloadDetails: createAction('[SickLeaveReports Page] Download Details')
};


export const SickLeaveReportApiActions = {
    loadSickLeaveReportsSuccess: createAction('[SickLeaveReports] Load Sick Leave Report Success', props<{ report: SickLeaveReportModel }>()),
    loadSickLeaveReportsError: createAction('[SickLeaveReports] Load Sick Leave Report Error', props<{ error: string }>()),
    loadCareDaysReportsChildrenSuccess: createAction('[SickLeaveReports] Load Sick Leave Report Children Success', props<{ report: CareDaysChildrenReportAggregationModel[] }>()),
    loadCareDaysReportsChildrenError: createAction('[SickLeaveReports] Load Sick Leave Report Children Error', props<{ error: string }>())
    
};
