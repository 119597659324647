import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { PipesModule } from '@mentor-one-ui/core/pipes/pipes.module';
import { ApplicationInsightsService } from '@mentor-one-ui/core/services/application-insights.service';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { InplaceModule } from 'primeng/inplace';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mo-error-message',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    PipesModule,
    InplaceModule,
    PanelModule
  ],
  styleUrls: ['./error-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="error-container panel">
      <div class="text-icon">
        <h1>{{errorCode != null && errorCode != '' ? errorCode : '500'}}</h1>
        <div class="icon">
        🥴
        </div>
      </div>
      <h2>{{ 'could-not-load-data-header' | translate | async}}</h2>
      <p>{{ 'could-not-load-data-text' | translate | async}}</p>
      <p class="code">{{getSessionId()}}</p>
      <p-inplace *ngIf="errorMessage">
        <ng-template pTemplate="display">
            <span class="error-message-display">{{'show-details' | translate | async}}</span>
        </ng-template>
        <ng-template pTemplate="content">
          <div class="error-message" #errormessage><strong>{{'details-from-server' | translate | async}}:</strong> <br/>
            <div *ngIf="!html">{{errorMessage}}</div>
            <div *ngIf="html" [innerHTML]="html | safeHtml"></div>
          </div>
        </ng-template>
      </p-inplace>

      <a pButton href="mailto:{{getSupportEmail()}}?subject=Feilkode: {{getSessionId()}}" class="btn btn-primary">{{'contact-support' | translate | async}}</a>
    </div>
  `,
})
export class ErrorMessageComponent implements OnInit {
  @Input() errorCode: string | null = null;
  @Input() errorMessage: string | null = null;

  html: string;

  constructor(private applicationInsights: ApplicationInsightsService) { }

  ngOnInit(): void {
    this.showErrorMessage();
  }

  getSupportEmail() {
    return environment.SUPPORT_EMAIL;
  }

  getSessionId() {
    return this.applicationInsights.GetSessionId();
  }

  showErrorMessage() {
    if (this.errorMessage != null && this.isJson(this.errorMessage)) {
      this.html = '';
      this.html = this.jsonToHtmlTable(JSON.parse(this.errorMessage));
    }
  }

  isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  jsonToHtmlTable(jsonData: any[]): string {
    if (!jsonData || Object.keys(jsonData).length === 0) {
      return "<p>No data available</p>";
    }

    let tableHtml = "<table class='error-details'>";

    for (const key in jsonData) {
      if (jsonData.hasOwnProperty(key)) {
        tableHtml += "<tr>";
        tableHtml += `<td class="desc">${key}</td>`;
        tableHtml += `<td>${jsonData[key]}</td>`;
        tableHtml += "</tr>";
      }
    }

    tableHtml += "</table>";

    return tableHtml;
  }

}
