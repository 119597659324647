import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultButtonsComponent } from './default-buttons.component';
import { ButtonBarModule } from '../button-bar/button-bar.module';

@NgModule({
  declarations: [DefaultButtonsComponent],
  imports: [CommonModule, ButtonBarModule],
  exports: [DefaultButtonsComponent],
})
export class DefaultButtonsModule {}
