import { createAction, props } from '@ngrx/store';
import { SortEvent } from 'primeng/api';

// Contact Details
const openContactModal = createAction('[Contact List] Open contact modal');
const editContact = createAction('[Contact List] Edit contact', props<{ payload: number }>());

const editNewContact = createAction('[Contact List] Edit new contact');
const contactModalClosed = createAction('[Contact List] Contact modal closed');

// Contact List
const SetFilterTag = createAction('[Contact List] Set selected tag', props<{ payload: string }>());
const ToggleContactListDetails = createAction('[Contact List] Toggle contact details', props<{ contactId: number }>());
const sort = createAction('[Contact List] Sort', props<{ event: SortEvent }>());

export const contactPageActions = {
  enter: createAction('[Contact Page] Enter'),
  openContactModal,
  contactModalClosed,
  editContact,
  editNewContact,
  SetFilterTag,
  ToggleContactListDetails,
  sort,
};
