import { createAction, props } from "@ngrx/store";
import { CalendarItemModel } from "../../models/calendar.models";

export const CalendarPageActions = {
    Enter: createAction('[Calendar Page] Enter'),
    setDefaultTitle: createAction('[Calendar Page] Set Default Title', props<{ title: string }>()),
    setCalendarView: createAction('[Calendar Page] Set Calendar View', props<{ calendarView: 'month' | 'week' | 'day' }>()),
    dateChanged: createAction('[Calendar Page] Date Changed', props<{ date: string }>()),
    calendarEventClicked: createAction('[Calendar Page] Calendar Event Clicked', props<{ eventId: number }>()),
    calendarEventHide: createAction('[Calendar Page] Calendar Event Hide'),
    openCreateNewAbsenceModal: createAction('[Calendar Page] Open Create New Absence Modal'),
    closeCreateNewAbsenceModal: createAction('[Calendar Page] Close Create New Absence Modal'),
    OpenFilterDialog: createAction('[Calendar Page] Open Filter Dialog'),
    ShowRejectAbsenceDialog: createAction('[Calendar Page] Show Reject Absence Dialog', props<{ model: any }>()),
    CancelRejectAbsenceRequest: createAction('[Calendar Page] Cancel Reject Absence Request'),
};

export const CalendarPageFilterActions = {
    togglePosition: createAction('[Calendar Page] Toggle Position', props<{ position: string }>()),
    toggleDepartment: createAction('[Calendar Page] Toggle Department', props<{ departmentId: number }>()),
    filterByOvertimeType: createAction('[Calendar Page] Filter By Overtime Type', props<{ overtimeTypeIds: number[] }>()),
    filterByAbsenceType: createAction('[Calendar Page] Filter By Absence Type', props<{ absenceTypeIds: number[] }>()),
    closeFilter: createAction('[Calendar Page] Close Filter'),
    resetFilter: createAction('[Calendar Page] Reset Filter'),
    setFilter: createAction('[Calendar Page] Set Filter'),
    setFilterStatus: createAction('[Calendar Page] Set Filter Status', props<{ statuses: number[] }>()),
    SearchEmployees: createAction('[Calendar Page] Search Employees', props<{ search: string }>()),


};
