import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TranslationState } from './translation.state';

const selectTranslationState = createFeatureSelector<TranslationState>('translations');

export const selectIsLoaded = createSelector(
  selectTranslationState,
  state => state.loaded
);

export const TranslationSelectors = {
  selectIsLoaded
};
