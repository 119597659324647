import { createReducer, on } from "@ngrx/store";
import { ApplicationActions } from "./application.actions";
import { UserActions } from "../user/user.actions";

export interface State {
  activeMenuModule: string;
  darkmode: boolean;
  bootstrapped: boolean;
}

export const initialState: State = {
  activeMenuModule: '',
  darkmode: false,
  bootstrapped: false,
};

export const applicationReducer = createReducer<State>(initialState,
  on(ApplicationActions.SetActiveMenuModule, (state, action): State => {
    return {
      ...state,
      activeMenuModule: action.activeMenuModule,
    };
  }),
  on(ApplicationActions.SwitchThemeMode, (state, action): State => {
    return {
      ...state,
      darkmode: action.darkmode,
    };
  }),
  on(UserActions.bootstrapApplicationData, (state, action): State => {
    return {
      ...state,
      bootstrapped: true,
    };
  }
  )
);
