import { NgModule } from '@angular/core';
import { HasSystemAccessDirective } from './has-system-access.directive';
import { ScrollShadowDirective } from './scroll-shadow.diractive';

@NgModule({
  imports: [],
  declarations: [HasSystemAccessDirective, ScrollShadowDirective],
  exports: [HasSystemAccessDirective, ScrollShadowDirective],
})
export class DirectivesModule {}
