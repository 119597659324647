import { createReducer, on } from '@ngrx/store';
import { AdminBalanceModel, EmployeeBalanceModel, OvertimeAllocationModel } from '../models/admin-balance.model';
import { AdminBalanceFilter } from '../models/admin-balance-filter.model';
import { AdminBalancePageActions } from '../actions/admin-balance-page.actions';
import { AdminBalanceApiActions } from '../actions/admin-balance-api.actions';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { EmployeeBalanceDetailApiActions } from '../components/employee-balance-detail/actions/employee-balance-detail.api.actions';

export const featureKey = 'adminBalance';

export const generateSelectableYears = () => {
  const now = new Date().getUTCFullYear() + 1;
  let arr = Array(now - (now - 3))
    .fill('')
    .map((v, idx) => now - idx) as Array<number>;
  return arr.reverse();
};
export interface State extends EntityState<AdminBalanceModel> {
  isLoading: boolean;
  isSaving: boolean;
  selectedBalances: AdminBalanceModel[];
  overtimeAllocations: OvertimeAllocationModel[];
  selectableYears: number[];
  filter: AdminBalanceFilter;
  loadError: boolean;
}

export const adapter: EntityAdapter<AdminBalanceModel> = createEntityAdapter<AdminBalanceModel>({
  sortComparer: false,
  selectId: (s) =>
    (s.OrganizationAbsenceMapId ? s.OrganizationAbsenceMapId.toString() : s.OrganizationOvertimeMapId?.toString()) +
    s.EmployeeId?.toString() +
    s.Year.toString(),
});

export const initialState: State = adapter.getInitialState({
  selectedBalances: [],
  overtimeAllocations: [],
  isLoading: false,
  isSaving: false,
  loadError: false,
  selectableYears: generateSelectableYears(),
  filter: {
    selectedEmployeeId: -1,
    selectedDepartmentId: -1,
    selectedTimeTypeId: null,
    selectedYear: new Date().getUTCFullYear(),
  },
});

export const reducer = createReducer(
  initialState,
  on(AdminBalancePageActions.Enter, (state): State => {
    return {
      ...state,
      isLoading: true,
      loadError: false,
    };
  }),
  on(AdminBalanceApiActions.loadAdminBalanceSuccess, (state, { balances }): State => {
    return {
      ...state,
      isLoading: false,
      loadError: false,
    };
  }),
  on(AdminBalanceApiActions.loadAdminBalanceError, (state): State => {
    return {
      ...state,
      isLoading: false,
      loadError: true,
    };
  }),
  on(AdminBalanceApiActions.loadAdminBalanceSuccess, (state, { balances }): State => {
    return adapter.upsertMany(balances, state);
  }),
  on(AdminBalancePageActions.filterByEmployee, (state, { employeeId }): State => {
    return {
      ...state,
      filter: {
        ...state.filter,
        selectedEmployeeId: employeeId,
      },
    };
  }),
  on(AdminBalanceApiActions.loadEmployeeBalanceDetailsSuccess, (state, { balance }): State => {
    return {
      ...state,
      selectedBalances: balance,
    };
  }),
  on(AdminBalanceApiActions.loadEmployeeOvertimeAllocationsSuccess, (state, { allocations }): State => {
    return {
      ...state,
      overtimeAllocations: allocations,
    };
  }),
  on(AdminBalanceApiActions.addOvertimeAllocationSuccess, (state, { allocation }): State => {
    let allocations = [...state.overtimeAllocations];
    allocations.push(allocation);

    return {
      ...state,
      overtimeAllocations: allocations,
    };
  }),
  on(AdminBalanceApiActions.deleteOvertimeAllocationSuccess, (state, { allocationId }): State => {
    let allocations = [...state.overtimeAllocations].filter((x) => x.Id !== allocationId);

    return {
      ...state,
      overtimeAllocations: allocations,
    };
  }),
  on(AdminBalancePageActions.CloseEmployeeModal, (state): State => {
    return {
      ...state,
      selectedBalances: [],
    };
  }),
  on(AdminBalancePageActions.filterByTimeType, (state, { timeTypeId }): State => {
    return {
      ...state,
      isLoading: true,
      filter: {
        ...state.filter,
        selectedTimeTypeId: timeTypeId,
      },
    };
  }),
  on(AdminBalancePageActions.filterByYear, (state, { year }): State => {
    return {
      ...state,
      isLoading: true,
      filter: {
        ...state.filter,
        selectedYear: year,
      },
    };
  }),
  on(AdminBalancePageActions.filterByDepartment, (state, { departmentId }): State => {
    return {
      ...state,
      filter: {
        ...state.filter,
        selectedDepartmentId: departmentId ?? -1,
      },
    };
  }),
  on(AdminBalancePageActions.resetFilter, (state): State => {
    return {
      ...state,
      isLoading: true,
      filter: {
        ...state.filter,
        selectedDepartmentId: -1,
        selectedEmployeeId: -1,
        selectedYear: new Date().getUTCFullYear(),
      },
    };
  }),
  on(EmployeeBalanceDetailApiActions.ChangeYearSuccess, (state, { balances }): State => {
    return {
      ...state,
      selectedBalances: balances,
    };
  }),
  on(AdminBalancePageActions.updateEmployeeBalance, (state): State => {
    return {
      ...state,
      isSaving: true,
    };
  }),
  on(EmployeeBalanceDetailApiActions.updateEmployeeBalanceSuccess, (state, { balances }): State => {
    return {
      ...state,
      isSaving: false,
      selectedBalances: balances,
    };
  }),
  on(EmployeeBalanceDetailApiActions.updateEmployeeBalanceSuccess, (state, { balances }): State => {
    return adapter.upsertMany(balances, state);
  }),
  on(EmployeeBalanceDetailApiActions.updateEmployeeBalanceError, (state): State => {
    return {
      ...state,
      isSaving: false,
    };
  })
);
