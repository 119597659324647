import { Component } from '@angular/core';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent {
  title: string;
  text: string;
  linkText: string;

  constructor( 
    public translationService: TranslationDataService
    ) {
  
      this.title = translationService.translate('unauthorized-page-title');
      this.text = translationService.translate('unauthorized-page-text');
      this.linkText = translationService.translate('unauthorized-page-link-text');
}
}

