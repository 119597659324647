import { BalanceTransitionCareDaysChildrenModel, BalanceTransitionSummaryModel, BalanceTransitionVacationModel } from "../models/balance-transition.model";
import { createReducer, on } from "@ngrx/store";
import { BalanceTransitionApiActions, BalanceTransitionPageActions } from "./actions/balance-transition.actions";
import { ApplicationActions } from "@mentor-one-ui/core/state/application/application.actions";

export const featureKey = 'balanceTransition';

export interface State {
  hasFormChanges: boolean;
  isLoading: boolean;
  isSaving: boolean;
  step: number;
  loadError: boolean;
  vacationBalances: BalanceTransitionVacationModel;
  extraVacationBalances: BalanceTransitionVacationModel;
  careDaysChildrenBalances: BalanceTransitionCareDaysChildrenModel;
  summaryBalances: BalanceTransitionSummaryModel;
}

export const initialState: State = {
  hasFormChanges: false,
  step: 0,
  isLoading: false,
  isSaving: false,
  loadError: false,
  vacationBalances: {},
  extraVacationBalances: {},
  careDaysChildrenBalances: {},
  summaryBalances: {},
};

export const reducer = createReducer(
  initialState,
  on(BalanceTransitionPageActions.EnterVacation, (state): State => {
    return {
      ...state,
      isLoading: true,
      loadError: false,
    };
  }),
  on(BalanceTransitionApiActions.LoadVacationBalanceSuccess, (state, { balances }): State => {
    return {
      ...state,
      isLoading: false,
      vacationBalances: balances,
    };
  }),
  on(BalanceTransitionApiActions.LoadVacationBalanceError, (state): State => {
    return {
      ...state,
      isLoading: false,
      loadError: true,
    };
  }),
  on(BalanceTransitionPageActions.EnterExtraVacation, (state): State => {
    return {
      ...state,
      isLoading: true,
      loadError: false,
    };
  }),
  on(BalanceTransitionApiActions.LoadExtraVacationBalanceSuccess, (state, { balances }): State => {
    return {
      ...state,
      isLoading: false,
      extraVacationBalances: balances,
    };
  }),
  on(BalanceTransitionApiActions.LoadExtraVacationBalanceError, (state): State => {
    return {
      ...state,
      isLoading: false,
      loadError: true,
    };
  }),
  on(BalanceTransitionPageActions.EnterCareDaysChildren, (state): State => {
    return {
      ...state,
      isLoading: true,
      loadError: false,
    };
  }),
  on(BalanceTransitionApiActions.LoadCareDaysChildrenBalanceSuccess, (state, { balances }): State => {
    return {
      ...state,
      isLoading: false,
      careDaysChildrenBalances: balances,
    };
  }),
  on(BalanceTransitionApiActions.LoadCareDaysChildrenBalanceError, (state): State => {
    return {
      ...state,
      isLoading: false,
      loadError: true,
    };
  }),
  on(ApplicationActions.DiscardUnsavedChanges, (state) => ({
    ...state,
    hasFormChanges: false,
  })),
  on(BalanceTransitionPageActions.HasFormChanges, (state, { hasChanges }) => ({
    ...state,
    hasFormChanges: hasChanges,
  })),
  on(BalanceTransitionApiActions.SaveVacationTransition, (state, { balance }) => ({
    ...state,
    isSaving: true,
    vacationBalances: balance,
    hasFormChanges: false,
  })),
  on(BalanceTransitionApiActions.SaveVacationTransitionSuccess,
    BalanceTransitionApiActions.SaveVacationTransitionError, (state) => ({
    ...state,
    isSaving: false,
    hasFormChanges: false,
  })),
  on(BalanceTransitionApiActions.SaveExtraVacationTransition, (state, { balance }) => ({
    ...state,
    isSaving: true,
    extraVacationBalances: balance,
    hasFormChanges: false,
  })),
  on(BalanceTransitionApiActions.SaveExtraVacationTransitionSuccess,
    BalanceTransitionApiActions.SaveExtraVacationTransitionError, (state) => ({
    ...state,
    isSaving: false,
    hasFormChanges: false,
  })),
  on(BalanceTransitionApiActions.SaveCareDaysChildrenTransition, (state, { balance }) => ({
    ...state,
    isSaving: true,
    careDaysChildrenBalances: balance,
    hasFormChanges: false,
  })),
  on(BalanceTransitionApiActions.SaveCareDaysChildrenTransitionSuccess,
    BalanceTransitionApiActions.SaveCareDaysChildrenTransitionError, (state) => ({
    ...state,
    isSaving: false,
    hasFormChanges: false,
  })),
  on(BalanceTransitionPageActions.EnterSummary, (state): State => {
    return {
      ...state,
      isLoading: true,
      loadError: false,
    };
  }),
  on(BalanceTransitionApiActions.LoadSummaryBalanceSuccess, (state, { balances }): State => {
    return {
      ...state,
      isLoading: false,
      summaryBalances: balances,
    };
  }),
  on(BalanceTransitionApiActions.LoadSummaryBalanceError, (state): State => {
    return {
      ...state,
      isLoading: false,
      loadError: true,
    };
  }),
);
