import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DateOnlyConverter } from "@mentor-one-ui/core/misc/dateonly.converter";
import { AbsenceType } from "@mentor-one-ui/time/my-time/absence/models/absence-type.model";
import { TimeManagementListModel, TimeManagementModel } from "@mentor-one-ui/time/my-time/absence/models/TimeManagementModel";
import { MbscCalendarEvent } from "@mobiscroll/angular";
import { Store } from "@ngrx/store";
import { map, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CalendarModel } from "../models/calendar.models";

@Injectable({
  providedIn: 'root',
})

export class CalendarService {
  absenceTypes: AbsenceType[];

  constructor(public http: HttpClient) {
  }

  getCalenderEvents(fromDate: Date, ToDate: Date): Observable<CalendarModel> {
    let request = {
      FromDate: DateOnlyConverter.convertJsDateToDateString(fromDate),
      ToDate: DateOnlyConverter.convertJsDateToDateString(ToDate)
    };
    return this.http.post<CalendarModel>(environment.API_URL + '/Calendar/absence', request);
  }
}
