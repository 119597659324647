import { Component } from '@angular/core';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { Store } from '@ngrx/store';
import * as authActions from '@mentor-one-ui/core/state/auth/auth.actions';

@Component({
  selector: 'app-no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.scss']
})
export class NoAccessComponent {
  title: string;
  text1: string;
  text2: string;
  linkText: string;

  constructor( 
    public translationService: TranslationDataService,
    public store: Store
    ) {  
      this.title = translationService.translate('no-access-page-title');
      this.text1 = translationService.translate('no-access-page-text1');
      this.text2 = translationService.translate('no-access-page-text2');
      this.linkText = translationService.translate('no-access-page-link-text');
  }

  logout() {
    this.store.dispatch(authActions.logout());
  }

}


