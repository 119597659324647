import { Component, OnDestroy } from '@angular/core';
import { ApplicationInsightsService } from '@mentor-one-ui/core/services/application-insights.service';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signin-oidc',
  styleUrl: './signin-oidc.component.scss',
  template: `
  <div class="panel app-sleeping">
    <div class="sleepy-icon">😴</div>
    <h2>{{title}}</h2>
    <p>{{text}}</p>
    <a class="p-button" href="/">{{linkText}}</a>
  </div>`,  
})
export class SigninOidcComponent implements OnDestroy{
  public ngDestroyed$ = new Subject();
  title: string;
  text: string;
  linkText: string;

  constructor(    
    public oidcSecurityService: OidcSecurityService,
    public applicationInsights: ApplicationInsightsService,
    public translationService: TranslationDataService
  ) {

    this.title = translationService.translate('application-nap-title');
    this.text = translationService.translate('application-nap-text');
    this.linkText = translationService.translate('application-nap-link-text');

    this.oidcSecurityService.getState().pipe(takeUntil(this.ngDestroyed$)).subscribe((state) => {
      if (state === undefined || state === null || state === '') {
        this.applicationInsights.trackEvent('SignInOidc_Redirect');
        window.location.href = environment.SITE_URL;
        
      }
    });
  }

  ngOnDestroy(): void {
    this.ngDestroyed$.next(null);
    this.ngDestroyed$.complete();
  }
}
