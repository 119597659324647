import { DepartmentModel } from '@mentor-one-ui/core/models/DepartmentModel';
import { UserOrganizationModel } from '@mentor-one-ui/core/models/user-organization/user-organization.model';
import { createAction, props } from '@ngrx/store';

// Load Organization

const loadUserOrganizationSuccess = createAction(
  '[Organization API] Load organization success',
  props<{ organization: UserOrganizationModel }>()
);
const loadUserOrganizationFailure = createAction('[Organization API] Load organization error',
props<{ error: any }>());

// Save Organization

const saveUserOrganizationSuccess = createAction(
  '[Organization API] Save organization details success',
  props<{ organization: UserOrganizationModel }>()
);
const saveUserOrganizationFailure = createAction('[Organization API] Save organization failure',
props<{ error: any }>());

// Save About Image
const saveAboutImageSuccess = createAction('[Organization API] Save organization image success');
// Set About Image

// Delete About Image
const deleteAboutImageSuccess = createAction('[Organization API] Delete organization image success');
//

// OrganizationMap

const loadOrganizationMapSuccess = createAction(
  '[Organization - About | API] Load organization map success',
  props<{ imageFile: any }>()
);
const loadOrganizationMapFailure = createAction(
  '[Organization - About | API] Load organization map failure',
  props<{ error: any }>()
);

// TODO:
// Save OrgMap Image
const saveOrganizationMapSuccess = createAction('[Organization - About | API] Save organization map success');

// TODO:
// Delete OrgMap Image
const deleteOrganizationMapSuccess = createAction('[Organization - About | API] Delete organization map success');

// Organization Stats
const loadOrganizationStatsSuccess = createAction(
  '[Organization - About | API] Load organization stats success',
  props<{ stats: any }>()
);
const loadOrganizationStatsFailure = createAction(
  '[Organization - About | API] Load organization stats failure',
  props<{ error: any }>()
);

export const UserOrganizationsAboutApiActions = {
  loadUserOrganizationSuccess,
  loadUserOrganizationFailure,

  saveUserOrganizationSuccess,
  saveUserOrganizationFailure,

  saveAboutImageSuccess,
  saveAboutImageError: createAction('[Organization - About | API] Save organization image error', props<{ error: string }>()),
  deleteAboutImageSuccess,
  deleteAboutImageError: createAction('[Organization - About | API] Delete organization image error', props<{ error: string }>()),
  loadOrganizationImageFailed: createAction('[Organization - About | API] Load organization image failed', props<{ error: string }>()),

  loadOrganizationMapSuccess,
  loadOrganizationMapFailure,

  saveOrganizationMapSuccess,
  saveOrganizationMapError: createAction('[Organization - About | API] Save organization map error', props<{ error: string }>()),
  deleteOrganizationMapSuccess,
  deleteOrganizationMapError: createAction('[Organization - About | API] Delete organization map error', props<{ error: string }>()),

  loadOrganizationStatsSuccess,
  loadOrganizationStatsFailure,
};
