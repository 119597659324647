
import { ReportModel } from '@mentor-one-ui/core/models/ReportModel';
import { createAction, props } from '@ngrx/store';

export const ReportPageActions = {
    LoadPoliceCertificate: createAction('[Report] Load police certificate'),
    LoadConfidentialityDeclarations: createAction('[Report] Load confidentiality declaration'),
    LoadBirthdays: createAction('[Report] Load birthdays'),
    LoadChildrensBirthdays: createAction('[Report] Load childrens birthdays'),
    LoadNextOfKins: createAction('[Report] Load next of kins'),
    LoadPersonnelManagers: createAction('[Report] Load personnel managers'),
};

export const ReportApiActions = {
    LoadReportSuccess: createAction('[Report] Load report success', props<{ report: ReportModel }>()),
    LoadReportError: createAction('[Report] Load report error', props<{ error: string }>()),
}