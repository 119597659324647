import { createSelector } from '@ngrx/store';
import { selectAllemployees, selectMyEmployees } from '@mentor-one-ui/employees/state/selectors/employee.selectors';
import {
  selectAbsenceTypes,
  selectOvertimeTypes,
} from '@mentor-one-ui/time/state/time-common/selectors/time-common.selectors';
import { selectLeaveManagementState } from './selectors';

export const selectLeaveAdministrationState = createSelector(
  selectLeaveManagementState,
  (state) => state?.leaveAdministration
);

export const selectSelectedEmployeeIds = createSelector(
  selectLeaveAdministrationState,
  (state) => state.selectedEmployeeIds
);

export const selectFilterByEmployeeName = createSelector(
  selectLeaveAdministrationState,
  (state) => state.employeeNameFilter
);

export const selectFilteredEmployees = createSelector(
  selectMyEmployees,
  selectFilterByEmployeeName,
  (employees, filter) => {
    if (!filter) return employees;
    let words = filter.toLocaleLowerCase().split(' ');
    return employees.filter((f) => {
      if (words.length > 1) {
        return words.every(
          (word) =>
            f.ContactInformation.FirstName.toLocaleLowerCase().includes(word.trim()) ||
            f.ContactInformation.LastName.toLocaleLowerCase().includes(word.trim())
        );
      } else {
        return (
          f.ContactInformation.FirstName.toLocaleLowerCase().includes(filter.toLocaleLowerCase().trim()) ||
          f.ContactInformation.LastName.toLocaleLowerCase().includes(filter.toLocaleLowerCase().trim())
        );
      }
    });
  }
);

export const selectFilteredEmployeesSortedByFirstName = createSelector(selectFilteredEmployees, (employees) =>
  employees.sort((a, b) => a.ContactInformation.FirstName.localeCompare(b.ContactInformation.FirstName))
);

export const selectPersonnelManagers = createSelector(
  selectLeaveAdministrationState,
  (state) => state.personnelManagers
);

export const selectBalanceForSingleEmployee = createSelector(
  selectLeaveAdministrationState,
  (state) => state.balanceForSingleEmployee || null
);

export const selectAllNonArchivedEmployees = createSelector(
  selectAllemployees,
  (employees) => employees.filter((f) => !f.IsArchived)
);

export const selectSelectedEmployees = createSelector(
  selectAllNonArchivedEmployees,
  selectSelectedEmployeeIds,
  (employees, selectedEmployeeIds) =>
    employees.filter((f) =>
      f.EmployeeId &&      
      selectedEmployeeIds.includes(f.EmployeeId)
    )
);

export const selectAbsenceRegisterState = createSelector(selectLeaveAdministrationState, (state) => state.state);

export const selectIsSaving = createSelector(selectLeaveAdministrationState, (state) => state.isSaving);

export const selectSelectedAbsenceTypeId = createSelector(
  selectLeaveAdministrationState,
  (state) => state.selectedAbsenceTypeId
);

export const selectSelectedAbsenceType = createSelector(
  selectAbsenceTypes,
  selectSelectedAbsenceTypeId,
  (absenceTypes, organizationAbsenceMapId) => {
    if (!organizationAbsenceMapId) return null;
    return (
      absenceTypes?.find((absenceType) => absenceType.OrganizationAbsenceMapId === organizationAbsenceMapId) ?? null
    );
  }
);

export const selectSelectedOvertimeTypeId = createSelector(
  selectLeaveAdministrationState,
  (state) => state.SelectedOvertimeTypeId
);

export const selectSelectedOvertimeType = createSelector(
  selectOvertimeTypes,
  selectSelectedOvertimeTypeId,
  (overtimeTypes, selectedOvertimeTypeId) => {
    return (
      overtimeTypes?.find((overtimeType) => overtimeType.OrganizationOvertimeMapId === selectedOvertimeTypeId) ?? null
    );
  }
);

export const selectHasFormChanges = createSelector(selectLeaveAdministrationState, (state) => state.hasFormChanges);
