import { createReducer, on } from '@ngrx/store';
import { TranslationActions } from './translation.actions';
import { initialState, TranslationState } from './translation.state';

export const translationReducer = createReducer<TranslationState>(
  initialState,
  on(TranslationActions.loadTranslationsSuccess, (state, action): TranslationState => {
    return {
      ...state,
      loaded: true,
    };
  }),
  on(TranslationActions.selectLanguage, (state, action): TranslationState => {
    return {
      ...state,
      selectedCultureCode: action.cultureCode
    }
  })
);
