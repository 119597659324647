import { TimeManagementStatusEnum } from "@mentor-one-ui/time/my-time/absence/models/TimeManagementStatusEnum";
import { TimeState } from "@mentor-one-ui/time/state";
import { selectTimeState } from "@mentor-one-ui/time/state/selectors";
import { createSelector } from "@ngrx/store";

export const selectCalendarState = createSelector(
  selectTimeState,
  (state: TimeState) => state.calendar
);

export const selectCalendarView = createSelector(
  selectCalendarState,
  (state) => state.calendarView
);

export const selectCalendarViewType = createSelector(
  selectCalendarView,
  (calendarView) => calendarView.type
);

export const selectLastNavigationDate = createSelector(
    selectCalendarState,
    (state) => state.lastNavigationDate
);

export const selectHolidays = createSelector(
  selectCalendarState,
  (state) => state.holidays
);

export const selectActiveCalendarEventActiveStatusForButtons = createSelector(
  selectCalendarState,
  (state) => {
    return state.calendarEvents.find(event => event.AbsenceId === state.selectedCalendarEventId)?.Status == TimeManagementStatusEnum.Approved;
  }
);

export const selectSelectedCalendarEvent = createSelector(
  selectCalendarState,
  (state) => {
    let selectedEvent = state.calendarEvents.find(event => event.AbsenceId === state.selectedCalendarEventId);
    return selectedEvent;
  }
);

export const selectShowCalendarEventDetails = createSelector(
  selectCalendarState,
  (state) => state.showCalendarEventDetails
 );

 export const selectIsSaving = createSelector(
  selectCalendarState,
  (state) => state.isSaving
);

export const selectHasLoadError = createSelector(
  selectCalendarState,
  (state) => state.loadError
);

 export const selectCalendarFilter = createSelector(
  selectCalendarState,
  (state) => state.filter
);

export const selectCalendarFilterCount = createSelector(
  selectCalendarFilter,
  (filter) =>
    filter.absenceMapIds.length + 
    filter.overtimeMapdIds.length + 
    filter.selectedDepartmentIds.length + 
    filter.selectedPositions.length +
    (filter.statuses[0] !== -1 ? 1 : 0)  
);






