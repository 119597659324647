import { TimeManagementModel } from "@mentor-one-ui/time/my-time/absence/models/TimeManagementModel";
import { EntityState, createEntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { getDefaultFilter } from ".";
import { TimeFilter } from "../../../shared/time-filter.model";
import { ManageApiActions } from "../actions/manage-api.actions";
import { ManagedLeavesPageActions } from "../../containers/managed-leaves-page/actions/managed-leaves-page.actions";
import { ManagedFilterDialogActions } from "../../components/managed-filter/actions/managed-filter-dialog.actions";

export const featureKey = 'managedLeaves';

export interface State extends EntityState<TimeManagementModel> {
  dataLoaded: boolean;
  loadError: boolean;
  totalAbsences: number;
  filter: TimeFilter;  
  page: number;  
}

export const adapter = createEntityAdapter<TimeManagementModel>({
  selectId: (a) => a.Id,
  sortComparer: false,    
});
  
  
export const initialState: State = adapter.getInitialState({
  dataLoaded: false,  
  loadError: false,
  isSaving: false,
  selectedId: -1,  
  page: 0,
  totalAbsences: 0,
  filter: {...getDefaultFilter(), statuses: [-1]},
}) ;

export const reducer = createReducer(
  initialState,
  on(ManagedLeavesPageActions.Enter, (state, action): State => {
    return {
      ...state,      
      loadError: false,
      page: 0
    }
  }),
  on(ManageApiActions.loadManagedAbsencesSuccess, (state, { managedAbsences: managedAbsences, totalAbsences }): State => {
    return {
      ...adapter.setAll(managedAbsences, state),
      dataLoaded: true,
      loadError: false,
      totalAbsences: totalAbsences
    }
  }),
  on(ManageApiActions.loadManagedAbsencesError, (state, action): State => {
    return {
      ...state,
      dataLoaded: false,
      loadError: true
    }
  }),
  on(ManagedLeavesPageActions.resetFilter, (state, action): State => {
    return {
      ...state,
      filter: {...getDefaultFilter()},
    }
  }),
  on(ManagedFilterDialogActions.toggleDepartment, (state, action): State => {

    var departments = [...state.filter.selectedDepartmentIds];
    var index = departments.indexOf(action.departmentId);
    if(index > -1) {
      departments.splice(index, 1);
    } else {
      departments.push(action.departmentId);
    }

    return {
      ...state,
      filter: {
        ...state.filter,
        selectedDepartmentIds: departments
      }
    }
  }),
  on(ManagedFilterDialogActions.setOvertimeTypeFilter, (state, action): State => {


    return {
      ...state,
      filter: {
        ...state.filter,
        overtimeMapdIds: action.overtimeTypeIds
      }
    }
  }),
  on(ManagedFilterDialogActions.setFilterStatus, (state, action): State => {
    return {
      ...state,
      filter: {
        ...state.filter,
        statuses: action.statuses
      }
    }
  }),
  on(ManagedFilterDialogActions.setAbsenceTypeFilter, (state, action): State => {


    return {
      ...state,
      filter: {
        ...state.filter,
        absenceMapIds: action.absenceTypeIds
      }
    }
  }),

  on(ManagedFilterDialogActions.togglePosition, (state, action): State => {
    var positions = [...state.filter.selectedPositions];
    var index = positions.indexOf(action.position);
    if(index > -1) {
      positions.splice(index, 1);
    } else {
      positions.push(action.position);
    }

    return {
      ...state,
      filter: {
        ...state.filter,
        selectedPositions: positions
      }
    }
  }),
  on(ManagedFilterDialogActions.setFilter,
    ManagedLeavesPageActions.resetFilter,    
    ManagedLeavesPageActions.setEmployeeFilter,
    (state, action): State => {
    return {
      ...state,
      page: 0,
    } 
  } ),

  on(ManagedFilterDialogActions.setFilter,
    ManagedLeavesPageActions.resetFilter,
    ManagedLeavesPageActions.Paginate,
    ManagedLeavesPageActions.setSortOption,
    ManagedLeavesPageActions.setEmployeeFilter,
    (state, action): State => {
    return {
      ...state,
      dataLoaded: false,
    } 
  } ),

  on(ManagedLeavesPageActions.Paginate, (state, action): State => {
    return {
      ...state,
      page: action.page
    }
  }),
  on(ManagedLeavesPageActions.setSortOption, (state, action): State => {
    return {
      ...state,
      filter: {
        ...state.filter,
        selectedSortOptionId: action.sortOptionId
      }
    }
  }),
  on(ManagedLeavesPageActions.setEmployeeFilter, (state, action): State => {
    return {
      ...state,
      filter: {
        ...state.filter,
        selectedEmployeeId: action.employeeId
      }
    }
  })
);