import { createReducer, on } from '@ngrx/store';
import { contactApiActions } from './actions/contact-api.actions';
import { contactPageActions } from './actions/contact-page.actions';
import { ContactModel } from '@mentor-one-ui/core/models/user-organization/contact/ContactModel';

export const featureKey = 'contacts';

export interface State {
  name: string;
  contactModalOpen: boolean;
  selectedContactId: number | null;
  contactList: ContactModel[];
  filterTag: string;
  loadError: boolean;
  isLoading: boolean;
}
export const initialState: State = {
  name: 'contact',
  contactModalOpen: false,
  selectedContactId: null,
  contactList: [],
  filterTag: '',
  loadError: false,
  isLoading: false,
};

// TODO:! use ngrx entities

export const reducer = createReducer<State>(
  initialState,
  on(contactPageActions.enter, (state, action): State => {
    return {
      ...state,
      isLoading: true,
      loadError: false,
    };
  }),
  on(contactPageActions.openContactModal, (state, action): State => {
    return {
      ...state,
      contactModalOpen: true,
    };
  }),
  on(contactPageActions.editContact, (state, action): State => {
    return {
      ...state,
      selectedContactId: action.payload,
    };
  }),
  on(contactPageActions.editNewContact, (state, action): State => {
    return {
      ...state,
      selectedContactId: null,
    };
  }),
  on(contactApiActions.LoadContactsSuccess, (state, action): State => {
    return {
      ...state,
      contactList: action.contact,
      loadError: false,
      isLoading: false,
    };
  }),
  on(contactApiActions.LoadContactsError, (state, action): State => {
    return {
      ...state,
      loadError: true,
      isLoading: false,
    };
  }),
  on(contactApiActions.deleteContactSuccess, (state, action): State => {
    let contacts = [...state.contactList].filter((f) => f.ContactId !== action.contactId);

    return {
      ...state,
      contactList: contacts,
    };
  }),
  on(contactPageActions.ToggleContactListDetails, (state, action): State => {
    const newArray = state.contactList?.map((contact) =>
      contact.ContactId === action.contactId ? { ...contact, showInformation: !contact.showInformation } : contact
    );
    return {
      ...state,
      contactList: newArray,
    };
  }),
  on(contactApiActions.saveContactSuccess, (state, action): State => {
    let contact = JSON.parse(JSON.stringify(action.contact));
    contact.ContactId = action.id;

    if (state.selectedContactId === null) {
      return {
        ...state,
        contactList: [...state.contactList, contact],
      };
    }
    let newContactList = state.contactList.map((c, index) => {
      if (c.ContactId === state.selectedContactId) {
        return contact;
      }
      return c;
    });
    return {
      ...state,
      contactList: newContactList,
    };
  }),
  on(contactPageActions.SetFilterTag, (state, action): State => {
    return {
      ...state,
      filterTag: action.payload,
    };
  }),
  on(contactPageActions.sort, (state, action): State => {
    if (!action.event.data) return state;
    if (!action.event.field) return state;
    if (!action.event.order) return state;

    const newData = [...state.contactList];
    const sortOrder = action.event.order;

    if (action.event.field === 'FirstName') {
      newData.sort((a, b) => {
        if (a.FirstName < b.FirstName) return -sortOrder;
        if (a.FirstName > b.FirstName) return sortOrder;
        return 0;
      });
    }
    if (action.event.field === 'LastName') {
      newData.sort((a, b) => {
        if (a.LastName < b.LastName) return -sortOrder;
        if (a.LastName > b.LastName) return sortOrder;
        return 0;
      });
    }
    if (action.event.field === 'Email') {
      newData.sort((a, b) => {
        if (a.Email < b.Email) return -sortOrder;
        if (a.Email > b.Email) return sortOrder;
        return 0;
      });
    }
    if (action.event.field === 'TelephoneNumber') {
      newData.sort((a, b) => {
        if (a.TelephoneNumber < b.TelephoneNumber) return -sortOrder;
        if (a.TelephoneNumber > b.TelephoneNumber) return sortOrder;
        return 0;
      });
    }
    if (action.event.field === 'PostalAddress') {
      newData.sort((a, b) => {
        if (a.PostalAddress < b.PostalAddress) return -sortOrder;
        if (a.PostalAddress > b.PostalAddress) return sortOrder;
        return 0;
      });
    }

    return {
      ...state,
      contactList: newData,
    };
  })
);
