import { NgModule } from "@angular/core";

import { TimeFilterDesktopComponent } from "../time-filter-desktop/time-filter-desktop.component";
import { TimeFilterMobileComponent } from "../time-filter-mobile/time-filter-mobile.component";
import { CheckboxModule } from "primeng/checkbox";
import { FormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { PipesModule } from "@mentor-one-ui/core/pipes/pipes.module";
import { ButtonBarModule } from "@mentor-one-ui/core/components/button-bar/button-bar.module";
import { CommonModule } from "@angular/common";
import { SelectButtonModule } from "primeng/selectbutton";
import { ListboxModule } from "primeng/listbox";
import { TimeFilterOptionsComponent } from "./time-filter-options.component";
import { DirectivesModule } from "@mentor-one-ui/core/directives/directives.module";
import { HasPermissionDirective } from "@mentor-one-ui/core/directives/has-permission.directive";

@NgModule({
    declarations: [
      TimeFilterOptionsComponent,
      TimeFilterMobileComponent,
        TimeFilterDesktopComponent,
    ],
    imports: [
        FormsModule,
        CommonModule,
        CheckboxModule,
        ButtonModule,
        PipesModule,
        ButtonBarModule,
        SelectButtonModule,
        ListboxModule,
        DirectivesModule,
        HasPermissionDirective
    ],
    exports: [
        TimeFilterOptionsComponent
    ]
  })
  export class LeaveFilterOptionsModule { }
