import { createAction, props } from '@ngrx/store';
import { AbsenceModel } from '../models/absence.model';

export const AbsencePageActions = {
  Enter: createAction('[Absence Page] Enter'),
  GoToAbsenceOverView: createAction('[Absence Page] Go To Absence OverView'),
  SelectAbsenceType: createAction('[Absence Page] Select Absence Type', props<{ absenceTypeId: number }>()),
  resetAbsenceTypeSelection: createAction('[Absence Page] Reset Absence Type Selection'),
  LoadAbsenceTypes: createAction('[Absence Page] Load Absence Types'),
  loadAbsence: createAction('[Absence Page] Load Absence', props<{ absence: AbsenceModel }>()),
  changePage: createAction('[Absence Page] Change Page', props<{ page: number }>()),
  validateAbsence: createAction('[Absence Page] Validate Absence', props<{ absenceId: number }>()),
  filterByAbsenceType: createAction('[Absence Page] Filter By Absence Type', props<{ absenceTypeIds: number[] }>()),
  filterByStatus: createAction('[Absence Page] Filter By Absence Status', props<{ status: number }>()),
  resetFilter: createAction('[Absence Page] Reset Filter'),
  //FLYTTE TIL NEWABSENCEPAGEACTIONS
  Register: createAction('[Absence Page] Register', props<{ absence: AbsenceModel }>()),

  PreValidateAbsence: createAction(
    '[Absence Page] Pre Validate Absence',
    props<{ validateSelf: boolean; absenceTypeId: number; startDate: string; endDate: string }>()
  ),
  ExtendAbsence: createAction(
    '[Absence Page] Extend Absence',
    props<{ absenceId: number; startDate: string; endDate: string }>()
  ),
};

export const NewAbsencePageActions = {
  Enter: createAction('[New Absence Page] Enter'),
  GoBack: createAction('[New Absence Page] Go Back'),
  HasFormChanges: createAction('[New Absence Page] Update Absence Form Changes', props<{ hasChanges: boolean }>()),
  RejectCancelChanges: createAction('[New Absence Page] Reject Cancel Changes'),
};

export const EditAbsencePageActions = {
  EditAbsence: createAction('[Edit Absence Page] Edit Absence', props<{ absence: AbsenceModel }>()),
  GoBack: createAction('[Edit Absence Page] Go Back'),
  HasFormChanges: createAction('[Edit Absence Page] Update Absence Form Changes', props<{ hasChanges: boolean }>()),
  DeleteAbsence: createAction(
    '[Absence Details Page] Delete Absence',
    props<{ absenceId: number; isExported: boolean | null }>()
  ),
  DeleteAbsenceCanceled: createAction('[Absence Details Page] Delete Absence Canceled'),
  DeleteAbsenceConfirmed: createAction('[Absence Details Page] Delete Absence Confirmed'),
};
