import { createAction, props } from "@ngrx/store";
import { AdminBalanceModel, OvertimeAllocationModel } from "../models/admin-balance.model";

export const AdminBalanceApiActions = {
    loadEmployeeBalanceDetailsError: createAction('[Admin Balance/API] Load Employee Balance Error', props<{ error: string }>()),
    loadEmployeeBalanceDetailsSuccess: createAction('[Admin Balance/API] Load Employee Balance Success', props<{ balance: AdminBalanceModel[] }>()),
    loadAdminBalanceSuccess: createAction('[Admin Balance/API] Load Admin Balance Success', props<{ balances: AdminBalanceModel[] }>()),
    loadAdminBalanceError: createAction('[Admin Balance/API] Load Admin Balance Error', props<{ error: string }>()),
    loadEmployeeOvertimeAllocationsSuccess: createAction('[Admin Balance/API] Load Employee Overtime Allocations Success', props<{ allocations: OvertimeAllocationModel[] }>()),
    loadEmployeeOvertimeAllocationsError: createAction('[Admin Balance/API] Load Employee Overtime Allocations Error', props<{ error: string }>()),
    addOvertimeAllocation: createAction('[Admin Balance Page] Add Overtime Allocation', props<{ employeeId: number, minutes: number, comment: string }>()),
    addOvertimeAllocationSuccess: createAction('[Admin Balance Page] Add Overtime Allocation Success', props<{ allocation: OvertimeAllocationModel }>()),
    addOvertimeAllocationError: createAction('[Admin Balance Page] Add Overtime Allocation Error', props<{ error: string }>()),
    deleteOvertimeAllocation: createAction('[Admin Balance Page] Delete Overtime Allocation', props<{ employeeId: number, id: number }>()),
    deleteOvertimeAllocationSuccess: createAction('[Admin Balance Page] Delete Overtime Allocation Success', props<{ allocationId: number }>()),
    deleteOvertimeAllocationError: createAction('[Admin Balance Page] Delete Overtime Allocation Error', props<{ error: string }>()),
};
