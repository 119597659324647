import { createAction, props } from "@ngrx/store";

export const UnmanagedFilterDialogActions = {
    closeFilterDialog: createAction(
    '[Unmanaged Filter Dialog] Close Filter Dialog'
    ),
    setFilter: createAction(
    '[Unmanaged Filter Dialog] Set Filter'
    ),
    resetFilter: createAction(
    '[Leave Filter Dialog] Reset Filter'
    ),
    resetManagedFilter: createAction(
    '[Unmanaged Filter Dialog] Reset Managed Filter'
    ),
    toggleDepartment: createAction(
    '[Unmanaged Filter Dialog] Toggle Department',
    props<{departmentId: number}>()
    ),
    filterByOvertimeType: createAction(
    '[Unmanaged Filter Dialog] Toggle Overtime Type',
    props<{overtimeTypeIds: number[]}>()
    ),
    filterByAbsenceType: createAction(
    '[Unmanaged Filter Dialog] Toggle Absence Type',
    props<{absenceTypeIds: number[]}>()
    ),
    togglePosition: createAction(
    '[Unmanaged Filter Dialog] Toggle Position',
    props<{position: string}>()
    ),
}