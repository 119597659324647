import { createReducer, on } from '@ngrx/store';
import {
  EmployeeSystemAccessApiActions,
  EmployeeSystemAccessTabActions,
} from '@mentor-one-ui/employees/state/actions/employee-system-access.actions';
import { EmployeePageActions } from '../actions/employee-page.actions';
import { EmployeeDetailsApiActions } from '../actions/employee-details.actions';

export const featureKey = 'systemAccess';

export interface State {
  userRoleIds: number[];
  isSaving: boolean;
  isActivatingOrDeactivating: boolean;
  showModal: boolean;
  canRemoveManagerRole: boolean;
}

export const initialState: State = {
  userRoleIds: [],
  isSaving: false,
  isActivatingOrDeactivating: false,
  showModal: false,
  canRemoveManagerRole: false
};

export const reducer = createReducer(
  initialState,
  on(EmployeePageActions.Enter, (state) => initialState),
  on(EmployeeSystemAccessApiActions.LoadSystemAccessesForSelectedEmployeeSuccess, (state, { roles }) => {
    return {
      ...state,
      userRoleIds: roles,
    };
  }),
  on(EmployeeSystemAccessTabActions.SaveSystemAccess, (state) => {
    return {
      ...state,
      isSaving: true,
    };
  }),
  on(EmployeeSystemAccessApiActions.SaveSystemAccessSuccess, (state, { roleIds }): State => {
    return {
      ...state,
      userRoleIds: roleIds,
      isSaving: false,
      canRemoveManagerRole: false
    };
  }),
  on(EmployeeSystemAccessApiActions.SaveSystemAccessError, (state) => {
    return {
      ...state,
      isSaving: false,
    };
  }),
  on(
    EmployeeSystemAccessTabActions.ActivateUserAccess,
    EmployeeSystemAccessTabActions.DeactivateUserAccess,
    (state) => {
      return {
        ...state,
        isActivatingOrDeactivating: true,
      };
    }
  ),
  on(
    EmployeeDetailsApiActions.ActivateUserAccessSuccess,
    EmployeeDetailsApiActions.DeactivateUserAccessSuccess,
    EmployeeDetailsApiActions.ActivateUserAccessError,
    EmployeeDetailsApiActions.DeactivateUserAccessError,
    (state) => {
      return {
        ...state,
        isActivatingOrDeactivating: false,
      };
    }
  ),
  on(
    EmployeeSystemAccessApiActions.TransferPersonnelSuccess,
    EmployeeSystemAccessTabActions.DontTransferPersonnel,
    (state) => ({
      ...state, 
      canRemoveManagerRole: true
    })
  )
);
