import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AuthState } from "./auth.state";

const selectAuthState = createFeatureSelector<AuthState>('auth');

const isAuthenticated = createSelector(
  selectAuthState,
  state => state.isAuthenticated
)

export const AuthSelectors = {
  isAuthenticated,
}
