import { TimeManagementType } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementType';
import { createAction, props } from '@ngrx/store';

export const LeaveManagementPageActions = {
  CancelRejectAbsenceRequest: createAction('[Leave Management Page] Cancel Reject Absence Request'),
  RejectLeaveRequest: createAction(
    '[Leave Management Page] Reject Leave Request',
    props<{
      leaveId: number;
      leaveTypeId: number;
      comment: string;
    }>()
  ),
  Enter: createAction('[Leave Management Page] Enter'),
};

export const LeaveManagementDetailsPageActions = {
  ApproveLeaveRequest: createAction(
    '[Leave Management Details Page] Approve Leave Request',
    props<{ leaveType: TimeManagementType; leaveId: number }>()
  )
};
