import { PersonnelManagerModel } from '@mentor-one-ui/core/models/employee/EmploymentModel';
import { AbsenceModel } from '@mentor-one-ui/time/my-time/absence/models/absence.model';
import { TimeManagementModel } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementModel';
import { OvertimeModel } from '@mentor-one-ui/time/my-time/overtime/models/overtime.model';
import { createAction, props } from '@ngrx/store';

export const ManageAddAbsencePageActions = {
  enter: createAction('[ManageAddAbsencePage] Enter'),
  leave: createAction('[ManageAddAbsencePage] Leave'),
  resetSelection: createAction('[ManageAddAbsencePage] Reset selection'),
  selectEmployees: createAction('[ManageAddAbsencePage] Select employees', props<{ employeeIds: number[] }>()),
  startNewLeaveRegistration: createAction('[ManageAddAbsencePage] Start new absence register'),
  registerNewAbsence: createAction(
    '[ManageAddAbsencePage] Register new absence',
    props<{ absence: AbsenceModel; AutomaticApprove: boolean }>()
  ),
  registerNewOvertime: createAction(
    '[ManageAddAbsencePage] Register new overtime',
    props<{ overtime: OvertimeModel; AutomaticApprove: boolean }>()
  ),
  registerNewOvertimeCollection: createAction(
    '[ManageAddAbsencePage] Register new overtime collection',
    props<{ overtimeCollection: OvertimeModel[]; AutomaticApprove: boolean }>()
  ),
  SelectAbsenceType: createAction('[ManageAddAbsencePage] Select absence type', props<{ absenceTypeId: number }>()),
  SelectOvertimeType: createAction('[ManageAddAbsencePage] Select overtime type', props<{ overtimeTypeId: number }>()),
  filterEmployeesByName: createAction('[ManageAddAbsencePage] Filter employees by name', props<{ query: string }>()),
  setCurrentUserAsPersonnelManager: createAction(
    '[Manage Add absence Page] Set Current User As Personnel Manager',
    props<{ personnelManager: PersonnelManagerModel }>()
  ),
  openEmployeesRegistrationsModal: createAction(
    '[Manage Add absence Page] Open employees registrations modal',
    props<{ registrations: TimeManagementModel[]; continueAction: any }>()
  ),
  closeEmployeesRegistrationsModal: createAction('[Manage Add absence Page] Close employees registrations modal'),
  ExtendAbsence: createAction(
    '[Manage Add absence Page] Extend Absence',
    props<{ absenceId: number; startDate: string; endDate: string }>()
  )  
};
