import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'mo-department-form',
  template: `
    <form [formGroup]="form">
      <div class="pbl-grid-medium pbl-input-text ">
        <label for="Name">{{ 'name' | translate | async }}</label>
        <input id="Name" type="text" pInputText formControlName="Name" required maxlength="250" />
        <small *ngIf="form.get('Name')?.invalid && form.touched">{{ 'name' | translate | async }} {{ 'isrequired' | translate | async }}</small>
      </div>
      <div class="pbl-grid-medium pbl-input-text ">
        <label for="Description">{{ 'description' | translate | async }}</label>
        <textarea pInputTextarea id="Description" formControlName="Description" [rows]="5" [cols]="30" maxlength="500" ></textarea>
      </div>
    </form>
  `,
  styleUrls: ['../departments.component.scss'],
})
export class DepartmentFormComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
}
