import { ErrorHandler, Inject, Injectable, Injector } from "@angular/core";
import { LoggerService } from "@mentor-one-ui/core/services/logger.service";

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    @Inject(Injector) private readonly injector: Injector
  ) {}

  handleError(error: Error) {

    let logger = this.injector.get(LoggerService);
    logger.logException(error);
  }
}