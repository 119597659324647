import { DepartmentModel } from '@mentor-one-ui/core/models/DepartmentModel';
import { PersonnelManagerModel } from '@mentor-one-ui/core/models/employee/EmploymentModel';
import {
  DepartmentsApiActions,
  DepartmentsTabActions,
} from '@mentor-one-ui/user-organization/components/departments/state/actions/departments.actions';

import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

export const featureKey = 'departments';

export interface State extends EntityState<DepartmentModel> {
  PersonellManagers: PersonnelManagerModel[];  
  SelectedId: number | null;
  isSaving: boolean;
  loadError: boolean;
}

export const adapter: EntityAdapter<DepartmentModel> = createEntityAdapter<DepartmentModel>({
  sortComparer: false,
  selectId: (s) => s.DepartmentId,
});

export const departmentsInitialState: State = adapter.getInitialState({  
  PersonellManagers: [],
  SelectedId: null,
  isSaving: false,
  loadError: false,
});

export const reducer = createReducer(
  departmentsInitialState,
  on(DepartmentsApiActions.loadDepartmentsForOrganizationSuccess, (state, { departments }) => {
    return adapter.setAll(departments, state);
  }),
  on(DepartmentsApiActions.loadDepartmentsForOrganizationFailure, (state) => {    
    return { ...state, loadError: true };
  }),
  on(DepartmentsApiActions.loadPersonellManagersForOrganizationSuccess, (state, { personellManagers }) => {
    return { ...state, PersonellManagers: personellManagers };
  }),
  on(DepartmentsTabActions.updateDepartment, (state, { department }) => {
    return { ...state, isSaving: true };
  }),
  on(DepartmentsApiActions.updateDepartmentSuccess, (state, { department }) => {
    return { ...adapter.upsertOne(department, state), isSaving: false };
  }),
  on(DepartmentsApiActions.updateDepartmentFailure, (state, { error }) => {
    return { ...state, isSaving: false };
  }),
  on(DepartmentsTabActions.OpenNewDepartmentModal, (state) => {
    return { ...state, SelectedId: null };
  }),
  on(DepartmentsTabActions.CloseDepartmentModal, (state) => {
    return { ...state, SelectedId: null };
  }),
  on(DepartmentsTabActions.OpenEditDepartmentModal, (state, { id }) => {
    return { ...state, SelectedId: id };
  }),
  on(DepartmentsApiActions.deleteDepartmentSuccess, (state) => {
    const key = state.SelectedId ? state.SelectedId : -1;
    return adapter.removeOne(key, { ...state, SelectedId: null });
  })
);
