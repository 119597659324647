import { TranslationDataService } from 'src/app/core/services/translation-data.service';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'translate',
  pure: false,
  //TOOD: Gjøre standalone
  // standalone: true
})
export class TranslatePipe implements PipeTransform {
  constructor(private translateService: TranslationDataService) {}

  transform(term: string): Observable<string> {
    return of(this.translateService.translate(term));
  }
}
