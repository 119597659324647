import { WizardState } from '@mentor-one-ui/employees/new-employee/models/WizardState';
import { NewEmployeePageActions } from './actions/new-employee-page-actions';
import { NewEmployeeApiActions } from './actions/new-employee-api-actions';
import { createReducer, on } from '@ngrx/store';
import { EmploymentModel } from '@mentor-one-ui/core/models/employee/EmploymentModel';

export const featureKey = 'newEmployee';

export interface State {
  isSaving: boolean;
  stepIndex: number;
  wizardState: WizardState;
  createUser: boolean;
  employments: EmploymentModel[];
  selectedEmploymentId: number;
}

export const initialState: State = {
  isSaving: false,
  createUser: true,
  stepIndex: 0,
  wizardState: WizardState.IN_PROGRESS,
  employments: [],
  selectedEmploymentId: -1,
};

export const reducer = createReducer(
  initialState,

  on(NewEmployeePageActions.Enter, (state) => {
    return {
      ...state,
      isSaving: false,
    };
  }),
  on(NewEmployeePageActions.ShowEmploymentModal, (state, action) => {
    return {
      ...state,
      selectedEmploymentId: action.employmentId,
    };
  }),
  on(NewEmployeePageActions.AddEmployment, (state, action) => {
    return {
      ...state,
      employments: [...state.employments, { ...action.employment, Id: Date.now() }],
    };
  }),
  on(NewEmployeePageActions.EditEmployment, (state, action) => {
    return {
      ...state,
      employments: state.employments.map((e) => (e.Id === action.employment.Id ? action.employment : e)),
    };
  }),
  on(NewEmployeePageActions.DeleteEmployment, (state, action) => {
    return {
      ...state,
      employments: state.employments.filter((e) => e.Id !== action.employmentId),
    };
  }),
  on(NewEmployeePageActions.CreateNewEmployee, (state, action) => {
    return {
      ...state,
      isSaving: true,
    };
  }),
  on(NewEmployeeApiActions.CreateNewEmployeeSuccess, NewEmployeeApiActions.CreateNewEmployeeError, (state, action) => {
    return {
      ...state,
      isSaving: false,
    };
  }),
    
  on(NewEmployeePageActions.StartWizard, (state) => {
    return {
      ...state,
      wizardState: WizardState.IN_PROGRESS,
      employments: [],
    };
  }),
  on(NewEmployeeApiActions.validatePersonalDetailsSuccess, (state, action) => {
    return {
      ...state,
      stepIndex: state.stepIndex + 1,
    };
  }),
  on(NewEmployeePageActions.ResetWizard, (state) => {
    return {
      ...state,
      wizardState: WizardState.IN_PROGRESS,
      stepIndex: 0,
      employments: [],
    };
  }),
  
  on(NewEmployeePageActions.PreviousStep, (state) => {
    return {
      ...state,
      stepIndex: state.stepIndex - 1,
    };
  }),
  on(NewEmployeePageActions.NextStep, (state) => {
    return {
      ...state,
      stepIndex: state.stepIndex + 1,
    };
  }),

  on(NewEmployeeApiActions.CreateNewEmployeeSuccess, (state, action) => {
    return {
      ...state,
      wizardState: WizardState.COMPLETE,
    };
  }),
  on(NewEmployeePageActions.CompleteWizard, (state): State => {
    return {
      ...state,
      wizardState: WizardState.COMPLETE,
    };
  }),
  on(NewEmployeePageActions.ToggleCreateUser, (state, action) => {
    return {
      ...state,
      createUser: action.createUser,
    };
  })
);
