import { createAction, props } from "@ngrx/store";
import { BalanceTransitionCareDaysChildrenModel, BalanceTransitionSummaryModel, BalanceTransitionVacationModel } from "../../models/balance-transition.model";

export const BalanceTransitionPageActions = {
  EnterVacation: createAction('[Balance Transition Page] Enter'),
  EnterExtraVacation: createAction('[Balance Transition Page] Enter Extra Vacation'),
  EnterCareDaysChildren: createAction('[Balance Transition Page] Enter Care Days Children'),
  EnterSummary: createAction('[Balance Transition Page] Enter Summary'),
  HasFormChanges: createAction('[Balance Transition Page] Has Form Changes', props<{ hasChanges: boolean }>()),
}

export const BalanceTransitionApiActions = {
  LoadVacationBalanceSuccess: createAction('[Balance Transition API] Load Vacation Balance Success', props<{ balances: BalanceTransitionVacationModel }>()),
  LoadVacationBalanceError: createAction('[Balance Transition API] Load Vacation Balance Error', props<{ code: string, error: string }>()),
  LoadExtraVacationBalanceSuccess: createAction('[Balance Transition API] Load Extra Vacation Balance Success', props<{ balances: BalanceTransitionVacationModel }>()),
  LoadExtraVacationBalanceError: createAction('[Balance Transition API] Load Extra Vacation Balance Error', props<{ code: string, error: string }>()),
  LoadCareDaysChildrenBalanceSuccess: createAction('[Balance Transition API] Load Care Days Children Balance Success', props<{ balances: BalanceTransitionCareDaysChildrenModel }>()),
  LoadCareDaysChildrenBalanceError: createAction('[Balance Transition API] Load Care Days Children Balance Error', props<{ code: string, error: string }>()),
  SaveVacationTransition: createAction('[Balance Transition API] Save Vacation Transition', props<{ balance: BalanceTransitionVacationModel }>()),
  SaveVacationTransitionSuccess: createAction('[Balance Transition API] Save Vacation Transition Success'),
  SaveVacationTransitionError: createAction('[Balance Transition API] Save Vacation Transition Error', props<{ code: string, error: string }>()),
  SaveExtraVacationTransition: createAction('[Balance Transition API] Save Extra Vacation Transition', props<{ balance: BalanceTransitionVacationModel }>()),
  SaveExtraVacationTransitionSuccess: createAction('[Balance Transition API] Save Extra Vacation Transition Success'),
  SaveExtraVacationTransitionError: createAction('[Balance Transition API] Save Extra Vacation Transition Error', props<{ code: string, error: string }>()),
  SaveCareDaysChildrenTransition: createAction('[Balance Transition API] Save Care Days Children Transition', props<{ balance: BalanceTransitionCareDaysChildrenModel }>()),
  SaveCareDaysChildrenTransitionSuccess: createAction('[Balance Transition API] Save Care Days Children Transition Success'),
  SaveCareDaysChildrenTransitionError: createAction('[Balance Transition API] Save Care Days Children Transition Error', props<{ code: string, error: string }>()),
  LastBalanceTransitionUpdate: createAction('[Balance Transition API] Last Balance Transition Update', props<{ lastBalanceTransition: number }>()),
  LastBalanceTransitionUpdateSuccess: createAction('[Balance Transition API] Last Balance Transition Update Success'),
  LastBalanceTransitionUpdateError: createAction('[Balance Transition API] Last Balance Transition Update Error', props<{ code: string, error: string }>()),
  LoadSummaryBalanceSuccess: createAction('[Balance Transition API] Load Summary Balance Success', props<{ balances: BalanceTransitionSummaryModel }>()),
  LoadSummaryBalanceError: createAction('[Balance Transition API] Load Summary Balance Error', props<{ code: string, error: string }>()),
}
