import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';

let screenSize = 'small';

@Injectable({
  providedIn: 'root',
})
export class MediaQuery {
  public static screenSize = 'small';

  constructor(public responsive: BreakpointObserver) {}

  getScreenSize(): string {
    this.responsive
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe((result) => this.breakpointChanged());
    return screenSize;
  }

  breakpointChanged(): void {
    if (this.responsive.isMatched(Breakpoints.Large)) {
      MediaQuery.screenSize = 'large';
    } else if (this.responsive.isMatched(Breakpoints.Medium)) {
      MediaQuery.screenSize = 'medium';
    } else if (this.responsive.isMatched(Breakpoints.Small)) {
      MediaQuery.screenSize = 'small';
    } else if (this.responsive.isMatched(Breakpoints.XSmall)) {
      MediaQuery.screenSize = 'xsmall';
    } else if (this.responsive.isMatched('(min-width: 500px)')) {
      MediaQuery.screenSize = '';
    }
  }
}
