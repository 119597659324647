import { createReducer, on } from "@ngrx/store";
import { SystemMessageModel } from "@mentor-one-ui/core/models/system-messages.model";
import { FrontPageApiActions, FrontpageActions } from "./frontpage.actions";
import { TimeManagementListModel } from "@mentor-one-ui/time/my-time/absence/models/TimeManagementModel";
import { CalendarCurrentDayDepartmentModel } from "@mentor-one-ui/time/calendar/models/calendar.models";
import { SickLeaveFrontpageStatusModel } from "@mentor-one-ui/sick-leave/follow-up/models/sick-leave-follow-up.model";

export interface State {
  systemMessageList: SystemMessageModel[];
  manageList: TimeManagementListModel;
  selectedManageItemId: number | null;
  isSaving: boolean;
  dailySummary: CalendarCurrentDayDepartmentModel[];
  todayError: boolean;
  manageListError: boolean;
  manageListLoading: boolean;
  followUpList: SickLeaveFrontpageStatusModel | null;
  followUpListError: boolean;
}

export const initialState: State = {
  systemMessageList: [],
  manageList: {Count: 0, Items: []},
  selectedManageItemId: -1,
  isSaving: false,
  dailySummary: [],
  todayError: false,
  manageListError: false,
  manageListLoading: true,
  followUpList: null,
  followUpListError: false
};

export const frontpageReducer = createReducer<State>(initialState,
  on(FrontpageActions.Enter, (state): State => {
    return {
      ...state,
      manageListError: false,
      todayError: false,
      followUpListError: false,
    };
  }),
  on(FrontPageApiActions.LoadSystemMessageListSuccess, (state, action): State => {
    const messageList = action.systemMessageList.map((m: any) => {
      let existing = state.systemMessageList.find((sm) => sm.Id === m.Id);
      if (existing) {
        return { ...m, IsClosed: existing.IsClosed };
      }
      return {...m, IsClosed: false};
    });

    return {
      ...state,
      systemMessageList: messageList,
    };
  }),
  on(FrontpageActions.ManageTimeItemClicked, (state, action): State => {
    return {
      ...state,
      selectedManageItemId: action.eventId,
    };
  }),
  on(FrontpageActions.SetSystemMessageAsClosed, (state, action): State => {
    return {
      ...state,
      systemMessageList: state.systemMessageList.map((m) => {
        let mC = { ...m };
        if (mC.Id === action.id) {
          mC.IsClosed = true;
        }
        return mC;
      }),
    };
  }),
  on(FrontPageApiActions.LoadManageListFrontpageSuccess, (state, action): State => {
    return {
      ...state,
      manageList: action.manageList,
      manageListError: false,
      manageListLoading: false
    };
  }),
  on(FrontPageApiActions.LoadManageListFrontpageError, (state, action): State => {
    return {
      ...state,
      manageList: {Count: 0, Items: []},
      manageListError: true,
      manageListLoading: false
    };
  }),
  on(FrontpageActions.ApproveLeaveRequest, (state): State => {
    return {
      ...state,
      isSaving: true,
    };
  }),
  on(
    FrontPageApiActions.ApproveAbsenceSuccess, FrontPageApiActions.ApproveAbsenceError, 
    FrontPageApiActions.ApproveOvertimeSuccess, FrontPageApiActions.ApproveOvertimeError, 
    FrontPageApiActions.RejectAbsenceSuccess, FrontPageApiActions.RejectAbsenceError,
    FrontPageApiActions.RejectOvertimeSuccess, FrontPageApiActions.RejectOvertimeError, 
    (state): State => {
    return {
      ...state,
      isSaving: false,
      manageListLoading: true
    };
  }),
  on(FrontpageActions.HideManageItem, FrontPageApiActions.ApproveAbsenceSuccess, (state, action): State => {
    return {
      ...state,
      selectedManageItemId: -1,
    };
  }),
  on(FrontpageActions.LoadDailySummarySuccess, (state, action): State => {
    return {
      ...state,
      dailySummary: action.summary,
      todayError: false
    };
  }),
  on(FrontpageActions.LoadDailySummaryError, (state, action): State => {
    return {
      ...state,
      dailySummary: [],
      todayError: true
    };
  }),
  on(FrontPageApiActions.LoadFollowUpListFrontpageSuccess, (state, action): State => {
    return {
      ...state,
      followUpList: action.result,
      followUpListError: false
    };
  }),
  on(FrontPageApiActions.LoadFollowUpListFrontpageError, (state, action): State => {
    return {
      ...state,
      followUpList: null,
      followUpListError: true
    };
  }),
);
