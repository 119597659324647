import { SystemAccessRole } from '@mentor-one-ui/core/models/employee/SystemAccessRole';
import { createAction, props } from '@ngrx/store';

export const EmployeeSystemAccessTabActions = {
  EnterSystemAccessTab: createAction('[Employee - SystemAccess Tab] Enter'),
  OpenModal: createAction('[Employee - SystemAccess Tab] System Access Modal Open'),
  CloseModal: createAction('[Employee - SystemAccess Tab] Modal Close'),
  ModalWasClosed: createAction('[Employee - SystemAccess Tab] Modal Was Closed'),
  ActivateUserAccess: createAction('[Employee - SystemAccess Tab] Activate User Access'),
  DeactivateUserAccess: createAction('[Employee - SystemAccess Tab] Deactivate User Access'),
  SaveSystemAccess: createAction('[Employee - SystemAccess Tab] Save System Access', props<{ roledIds: number[] }>()),
  CreateUserAndSystemAccess: createAction(
    '[Employee - SystemAccess Tab] Create User and Create User Access',
    props<{ roledIds: number[] }>()
  ),
  ResendCreateSignInRequest: createAction(
    '[Employee - SystemAccess Tab] Resend SignIn request',
    props<{ employeeId: number }>()
  ),
  DeleteUserAccess: createAction('[Employee - SystemAccess Tab] Delete User Access'),
  DeleteUserAccessSuccess: createAction(
    '[Employee - SystemAccess Tab] Delete User Access Success',
    props<{ employeeId: number }>()
  ),
  DeleteUserAccessError: createAction(
    '[Employee - SystemAccess Tab] Delete User Access Error',
    props<{ error: string }>()
  ),
  OpenTransferEmployeesModal: createAction(
    '[Employee - SystemAccess Tab] Open transfer employees modal',
  ),
  TransferPersonnel: createAction(
     '[Employee - SystemAccess Tab] Start Transfer Personnel',
     props<{currentPersonnelManagerId: number, newPersonnelManagerId: number}>()
  ),
  DontTransferPersonnel: createAction(
     '[Employee - SystemAccess Tab] Dont transfer personnel'
  )
};

export const EmployeeSystemAccessApiActions = {
  LoadSystemAccessesForOrganizationSuccess: createAction(
    '[Employee - SystemAccess API] Load Organization System Accesses Success',
    props<{ roles: SystemAccessRole[] }>()
  ),
  LoadSystemAccessesForOrganizationError: createAction(
    '[Employee - SystemAccess API] Load Organization System Accesses Error',
    props<{ error: any }>()
  ),
  LoadSystemAccessesForSelectedEmployeeSuccess: createAction(
    '[Employee - SystemAccess API] Load Selected Employee System Accesses Success',
    props<{ roles: number[] }>()
  ),
  CreateUserAndSystemAccessSuccess: createAction(
    '[Employee - SystemAccess Tab] Create User and Create User Access Success',
    props<{ userLoginId: number; employeeId: number }>()
  ),
  CreateUserAndSystemAccessError: createAction(
    '[Employee - SystemAccess Tab] Create User and Create User Access Error',
    props<{ error: any }>()
  ),
  ResendCreateSignInRequestError: createAction(
    '[Employee - SystemAccess Tab] Resend SignIn request Error',
    props<{ error: any }>()
  ),
  SaveSystemAccessSuccess: createAction(
    '[Employee - SystemAccess API] Save System Access Success',
    props<{ employeeId: number; roleIds: number[] }>()
  ),
  SaveSystemAccessError: createAction(
    '[Employee - SystemAccess API] Save System Access Error',
    props<{ error: any }>()
  ),
  TransferPersonnelSuccess: createAction(
     '[Employee - SystemAccess API] Transfer personnel success',
    props<{ oldPersonnelManagerId: number; newPersonnelManagerId: number }>()
  ),
  TransferPersonnelError: createAction(
    '[Employee - SystemAccess API] Transfer personnel error',
    props<{ error: any }>()
  ),
};
