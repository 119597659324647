<div class="contact" scrollshadow>
  <div class="form-container">
    <form [formGroup]="form" id="validator">
      <fieldset class="pbl-grid">
        <div class="pbl-input-text pbl-grid-large">
          <label for="FirstName">{{'name-or-company'|translate|async}}</label>
          <input id="firstName" type="text" pInputText formControlName="FirstName" required maxlength="200" >
          <small *ngIf="form.hasError('required', 'FirstName') && form.get('FirstName')?.touched"
            class="p-error block">{{'name-required'|translate|async}}</small>
        </div>
        <div class="pbl-input-text pbl-grid-large">
          <label for="LastName">{{'contact-person'|translate|async}}</label>
          <input id="lastName" type="text" pInputText formControlName="LastName" maxlength="200" >
        </div>
        <div class="pbl-input-text pbl-grid-small">
          <label for="TelephoneNumber">{{'phone'|translate|async}}</label>
          <input id="TelephoneNumber" type="tel" pInputText formControlName="TelephoneNumber" maxlength="50" >
        </div>
        <div class="pbl-input-text pbl-grid-medium">
          <label for="email">{{'email'|translate|async}}</label>
          <input id="email" type="email" pInputText formControlName="Email" maxlength="250" >
          <small *ngIf="form.hasError('Email') && form.get('Email')?.touched"
            class="p-error block">{{'email-required'|translate|async}}</small>
        </div>
        <div class="pbl-input-text pbl-grid-large">
          <label for="postalAddress">{{'address'|translate|async}}</label>
          <input id="postalAddress" type="text" pInputText formControlName="PostalAddress" maxlength="250" >
        </div>
        <div class="pbl-input-text pbl-grid-large">
          <label for="usefulInformation">{{'useful-information'|translate|async}}</label>
          <textarea id="usefulInformation" type="text" pInputText formControlName="UsefulInformation" maxlength="1000" ></textarea>
        </div>
      </fieldset>
    </form>
  </div>
  <div>
    <div class="contactTags">
      <div class="pbl-input-text">
        <label for="inputId">{{'labels'|translate|async}}</label>
        <div class="pbl-grid">
          <div class="pbl-grid-medium">
            <p-autoComplete id="search-tag" [(ngModel)]="tag" [suggestions]="results"
              (completeMethod)="getSearchResults($event)" appendTo="body" placeholder="" styleClass="fullwidth"
              (onSelect)="addToSelected($event)" (keydown.enter)="addCurrentValueTag()" autocomplete="off"
              inputId="inputId" appendTo="body">
            </p-autoComplete>
          </div>
          <div class="pbl-grid-medium">
            <button pButton class="p-button-secondary" icon="fal fa-plus" label="{{'add'|translate|async}}" type="button"
              (click)="addCurrentValueTag()">
            </button>
          </div>
        </div>
      </div>

      <div class="tags">
        <div *ngFor="let tag of selectedTags; let i = index">
          <p-chip label="{{tag.TagValue}}" [removable]="true" (onRemove)="removeTag(i)"
            styleClass="custom-chip"></p-chip>
        </div>
      </div>
    </div>

  </div>
  <div class="p-dialog-footer p-dialog-footer--sticky">
    <mo-default-buttons [showDelete]="false" [canSave]="formHasChanges" (cancel)="cancel()"
      (save)="postContact()"></mo-default-buttons>
  </div>
</div>
