import { TerminateEmploymentModel } from '@mentor-one-ui/core/models/employee/EmploymentModel';
import { createAction, props } from '@ngrx/store';

export const EmployeeTerminateEmploymentPageActions = {
  Enter: createAction('[Employee - TerminateEmployment Page] Enter'),
  TerminateEmployment: createAction(
    '[Employee - TerminateEmployment Page] Terminate Employment',
    props<{ model: TerminateEmploymentModel }>()
  ),
  AbortTerminateEmployment: createAction(
    '[Employee - TerminateEmployment Page] AbortTerminateEmployment',
    props<{ employeeId: number }>()
  ),
};

export const EmployeeTerminateEmploymentApiActions = {
  TerminateEmploymentSuccess: createAction(
    '[Employee - TerminateEmployment API] TerminateEmploymentSuccess',
    props<{ employeeId: number }>()
  ),
  TerminateEmploymentError: createAction(
    '[Employee - TerminateEmployment API] Terminate Employment Error',
    props<{ error: string }>()
  ),
  AbortTerminateEmploymentSuccess: createAction(
    '[Employee - TerminateEmployment API] AbortTerminateEmploymentSuccess',
    props<{ employeeId: number }>()
  ),
  AbortTerminateEmploymentError: createAction(
    '[Employee - TerminateEmployment API] AbortTerminateEmploymentError',
    props<{ error: string }>()
  ),
};
