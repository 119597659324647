import { createReducer, on } from '@ngrx/store';
import { EmployeeEmploymentApiActions } from '../actions/employee-employment.actions';
import {
  EmployeeTerminateEmploymentApiActions,
  EmployeeTerminateEmploymentPageActions,
} from '../actions/employee-terminate-employment.actions';

export const featureKey = 'terminateEmployment';

export interface State {
  error: boolean;
  isSaving: boolean;
}

export const initialState: State = {
  error: false,
  isSaving: false,
};

export const reducer = createReducer(
  initialState,
  on(EmployeeEmploymentApiActions.LoadEmploymentHistoryError, (state) => {
    return {
      ...state,
      error: true,
    };
  }),
  on(EmployeeTerminateEmploymentPageActions.TerminateEmployment, (state) => {
    return {
      ...state,
      isSaving: true,
    };
  }),
  on(EmployeeTerminateEmploymentApiActions.TerminateEmploymentSuccess, (state) => {
    return {
      ...state,
      isSaving: false,
    };
  }),
  on(EmployeeTerminateEmploymentApiActions.TerminateEmploymentError, (state) => {
    return {
      ...state,
      isSaving: false,
    };
  })
);
