import { createAction, props } from "@ngrx/store";
import { TimeManagementListModel, TimeManagementModel } from "@mentor-one-ui/time/my-time/absence/models/TimeManagementModel";
import { ReportsFilter } from "../../models/reports-filter.model";
import { TimeManagementStatusEnum } from "@mentor-one-ui/time/my-time/absence/models/TimeManagementStatusEnum";

export const ReportsApiActions = {
    loadReportsOverviewSuccess: createAction('[Reports API] Load Reports Overview Success', props<{ result: TimeManagementListModel }>()),
    loadReportsOverviewFailure: createAction('[Reports API] Load Reports Overview Failure', props<{ error: string }>()),
    exportReportsSuccess: createAction('[Reports API] Export Reports Success'),
    exportReportsFailure: createAction('[Reports API] Export Reports Failure', props<{ error: string }>())
};

export const ReportsPageActions = {
    Enter: createAction('[Reports Page] Enter'),
    FilterChanged: createAction('[Reports Page] Filter Changed', props<{filter: ReportsFilter}>() ),
    ExportReports: createAction('[Reports Page] Export Reports'),
};

export const ReportFilterDialogActions = {
    openFilterDialog: createAction('[Report Filter Dialog] Open Filter Dialog'),
    closeFilterDialog: createAction('[Report Filter Dialog] Close Filter Dialog'),
    setFilter: createAction('[Report Filter Dialog] Set Filter'),
    resetFilter: createAction('[Report Filter Dialog] Reset Filter'),
    toggleDepartment: createAction('[Report Filter Dialog] Toggle Department', props<{departmentId: number}>()),
    toggleOvertimeType: createAction('[Report Filter Dialog] Toggle Overtime Type', props<{overtimeTypeIds: number[]}>()),
    toggleAbsenceType: createAction('[Report Filter Dialog] Toggle Absence Type', props<{absenceTypeIds: number[]}>()),
    togglePosition: createAction('[Report Filter Dialog] Toggle Position', props<{position: string}>()),
    toggleStatus: createAction('[Report Filter Dialog] Toggle Status', props<{statuses: number[]}>()),
    fromDateChanged: createAction('[Report Filter Dialog] From Date Changed', props<{fromDate: string}>()),
    toDateChanged: createAction('[Report Filter Dialog] To Date Changed', props<{toDate: string}>()),
};