import { Action, combineReducers } from '@ngrx/store';
import * as fromAbsence from './absence.reducer';
import * as fromOvertime from './overtime.reducer';
import * as fromBalance from './balance.reducer';

export const featureKey = 'myTime';

export interface State {
  [fromAbsence.featureKey]: fromAbsence.State;
  [fromOvertime.featureKey]: fromOvertime.State;
  [fromBalance.featureKey]: fromBalance.State;
}

export function reducers(state: State | undefined, action: Action) {
  return combineReducers({
    [fromAbsence.featureKey]: fromAbsence.reducer,
    [fromOvertime.featureKey]: fromOvertime.reducer,
    [fromBalance.featureKey]: fromBalance.reducer,
  })(state, action);
}




