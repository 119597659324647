import { NgModule } from '@angular/core';
import { TimeTypeInBalancePipe } from './time-type-balance.pipe';
import { AbsenceTypeNamePipe, AbsenceTypePipe } from './absence-type.pipe';
import { DisallowBalanceAllocationPipe } from './allow-balance-allocation.pipe';
import { OvertimeTypeNamePipe, OvertimeTypePipe } from './overtime-type.pipe';
import { MinutesToHoursPipe } from './minutes-to-hours.pipe';
import { HoursAndMinutesPipe } from './hours-and-minutes.pipe';
import { MinutesToHoursShortPipe } from '@mentor-one-ui/time/pipes/minutes-to-hours-short.pipe';


@NgModule({
  declarations: [AbsenceTypePipe, TimeTypeInBalancePipe, OvertimeTypePipe, DisallowBalanceAllocationPipe, MinutesToHoursPipe, HoursAndMinutesPipe, MinutesToHoursShortPipe, AbsenceTypeNamePipe, OvertimeTypeNamePipe],
  imports: [],
  exports: [AbsenceTypePipe, TimeTypeInBalancePipe, OvertimeTypePipe, DisallowBalanceAllocationPipe, MinutesToHoursPipe, HoursAndMinutesPipe, MinutesToHoursShortPipe, AbsenceTypeNamePipe, OvertimeTypeNamePipe],
})
export class LeaveTypePipesModule { }

