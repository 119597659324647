import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ButtonBarModule } from "@mentor-one-ui/core/components/button-bar/button-bar.module";
import { ButtonAction, ButtonBarButton } from "@mentor-one-ui/core/models/ButtonBar";
import { DepartmentLightModel } from "@mentor-one-ui/core/models/DepartmentModel";
import { EmployeeModel } from "@mentor-one-ui/core/models/employee/EmployeeModel";
import { PipesModule } from "@mentor-one-ui/core/pipes/pipes.module";
import { TranslationDataService } from "@mentor-one-ui/core/services/translation-data.service";
import { EmployeeListPageActions } from "@mentor-one-ui/employees/state/actions/employee-list-page.actions";
import { EmployeeListSelectors } from "@mentor-one-ui/employees/state/selectors/employee-list.selectors";
import { departmentSelectors } from "@mentor-one-ui/user-organization/components/departments/state/departments.selectors";
import { Store } from "@ngrx/store";
import { CheckboxModule } from "primeng/checkbox";
import { Observable, tap } from "rxjs";

@Component({
  selector: 'app-employee-list-mobile-filter-modal',
  styleUrls: ['./employee-list-mobile-filter-modal.component.scss'],
  template: `
  <p>{{ 'select-department' | translate | async }}</p>
  <div class="filter-detail">
    <p-checkbox *ngFor="let dept of (departments$ | async)"
      (onChange)="toggleDepartment(dept.DepartmentId)"
      [ngModel]="selectedDepartmentIds.includes(dept.DepartmentId)"
      label="{{dept.Name}}"
      [binary]="true"></p-checkbox>
  </div>
  <div class="p-dialog-footer--sticky">
    <mo-button-bar
      [buttonList]="buttons"
      (buttonBarClick)="handleButtonClick($event)">
    </mo-button-bar>
  </div>
  `,
  standalone: true,
  imports: [
    CommonModule,
    ButtonBarModule,
    CheckboxModule,
    FormsModule,
    PipesModule
  ],
})
export class EmployeeListMobileFilterModalComponent implements OnInit {

  buttons: ButtonBarButton[];

  filteredEmployees$: Observable<EmployeeModel[]> = this.store.select(EmployeeListSelectors.selectFilteredEmployees);
  initialNumberOfEmployees$: Observable<number> = this.store.select(EmployeeListSelectors.initialNumberOfEmployees);
  departments$: Observable<DepartmentLightModel[]> = this.store.select(departmentSelectors.selectDepartmentsForDropDown);
  selectedDepartmentIds: number[] = [];

  constructor(private translationService: TranslationDataService, private store: Store) {
    this.setUpButtons();
  }
  ngOnInit(): void {
    this.store.select(EmployeeListSelectors.selectFilter).pipe(
      tap((filter) => {
        this.selectedDepartmentIds = filter.departments;
        this.setUpButtons();
      }
      )).subscribe();
  }

  toggleDepartment(departmentId: number) {
    let tempDepartments = [...this.selectedDepartmentIds];

    if (this.selectedDepartmentIds.includes(departmentId)) {
      tempDepartments = this.selectedDepartmentIds.filter((id) => id !== departmentId);
    } else {
      tempDepartments.push(departmentId);
    }

    this.selectedDepartmentIds = tempDepartments;
    this.setUpButtons();
  }

  handleButtonClick(button: ButtonBarButton) {
    if (button.action == ButtonAction.Cancel) {
      this.store.dispatch(EmployeeListPageActions.CloseMobileFilter());
    } else if (button.action == ButtonAction.Add) {
      this.store.dispatch(EmployeeListPageActions.SetFilterDepartments({departments: this.selectedDepartmentIds}));
      this.store.dispatch(EmployeeListPageActions.CloseMobileFilter());
    } else if (button.action == ButtonAction.Reset) {
      this.store.dispatch(EmployeeListPageActions.SetFilterDepartments({ departments: [] }));
      this.store.dispatch(EmployeeListPageActions.SetFilterIsActive({ isActive: false }));
      this.store.dispatch(EmployeeListPageActions.SetFilterSearch({ search: '' }));
    }
  }

  setUpButtons() {
    this.buttons = [
      {
        label: this.translationService.translate('clearfilter'),
        routerLink: null,
        action: ButtonAction.Reset,
        visible: true,
        disabled: this.selectedDepartmentIds.length === 0,
        class: 'p-button p-button-secondary',
      } as ButtonBarButton,
      {
        label: this.translationService.translate('cancel'),
        routerLink: null,
        action: ButtonAction.Cancel,
        visible: true,
        disabled: false,
        class: 'p-button p-button-secondary',

      } as ButtonBarButton,
      {
        label: this.translationService.translate('save'),
        action: ButtonAction.Add,
        disabled: false,
        visible: true,
        isSaveButton: true,
        class: 'p-button',

      } as ButtonBarButton,
    ];
  }
}


