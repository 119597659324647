import { EmploymentModel, PersonnelManagerModel } from '@mentor-one-ui/core/models/employee/EmploymentModel';
import { createAction, props } from '@ngrx/store';

export const EmployeeEmploymentTabActions = {
  EnterEmploymentTab: createAction('[Employee - Employment Tab] Enter'),
  OpenEmploymentModal: createAction('[Employee - Employment Tab] Employment Modal Open', props<{ employmentId: number }>()),
  SaveEmployment: createAction('[Employee - Employment Tab] Save EmploymentHistory', props<{ employment: EmploymentModel }>()),
  DeleteEmployment: createAction('[Employee - Employment Tab] Delete Employment', props<{ employmentId: number }>()),
  OpenEmploymentDepartmentModal: createAction('[Employee - Employment Tab] Employment Department Modal Open', props<{ employmentId: number }>()),
  CloseEmploymentDepartmentModal: createAction('[Employee - Employment Tab] Employment Department Modal Close'),
};

export const EmployeeEmploymentApiActions = {
  LoadEmploymentHistorySuccess: createAction(
    '[Employee - Employment API] Load EmploymentHistory Success',
    props<{ employeeId: number; employmentHistory: EmploymentModel[] }>()
  ),
  LoadEmploymentHistoryError: createAction(
    '[Employee - Employment API] Load Employment History Error',
    props<{ error: string }>()
  ),

  SaveEmploymentSuccess: createAction(
    '[Employee - Employment API] Save EmploymentHistory success',
    props<{ employeeId: number; employment: EmploymentModel }>()
  ),
  SaveEmploymentError: createAction(
    '[Employee - Employment API] Save EmploymentHistory error',
    props<{ error: string }>()
  ),

  DeleteEmploymentSuccess: createAction(
    '[Employee - Employment API] Delete EmploymentHistory success',
    props<{ employeeId: number; employmentId: number }>()
  ),
  DeleteEmploymentError: createAction(
    '[Employee - Employment API] Delete EmploymentHistory error',
    props<{ error: string }>()
  ),
  ChangeDepartmentOnEmployment: createAction(
    '[Employee - Employment API] Change Department on Employment',
    props<{ employmentId: number; departmentId: number; changeDate: string }>()
  ),
  ChangeDepartmentOnEmploymentSuccess: createAction(
    '[Employee - Employment API] Change Department on Employment Success',
    props<{ employeeId: number; employment: EmploymentModel; }>()
  ),
  ChangeDepartmentOnEmploymentError: createAction(
    '[Employee - Employment API] Change Department on Employment Error',
    props<{ error: string }>()
  ),
  DeleteEmploymentDepartment: createAction(
    '[Employee - Employment API] Delete Employment Department',
    props<{ employmentId: number, employmentDepartmentMapId: number }>()
  ),
  DeleteEmploymentDepartmentSuccess: createAction(
    '[Employee - Employment API] Delete Employment Department Success',
    props<{ employeeId: number, employment: EmploymentModel }>()
  ),
  DeleteEmploymentDepartmentError: createAction(
    '[Employee - Employment API] Delete Employment Department Error',
    props<{ error: string }>()
  ),
};
