import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { organizationDetailsSelectors } from '../../../about/state/organization-details.selectors';
import {
  UserOrganizationModel,
  combineHrSettingsAndOrgModel,
} from '@mentor-one-ui/core/models/user-organization/user-organization.model';
import { SettingsModalActions, SettingsPageActions } from '../../state/settings.actions';
import { Observable, map, tap } from 'rxjs';
import { OrganizationSettingsSelectors } from '../../state/settings.selectors';

@Component({
  selector: 'mo-formality',
  template: `
    <div class="formality-modal" *ngrxLet="vm$ | async as vm" scrollshadow>
      <form [formGroup]="form" *ngIf="form">
        <p class="ingress">
          {{ 'formality-ingress' | translate | async }}<mo-info-mini infoText="{{'formality-info-text' | translate | async}}"></mo-info-mini>
        </p>

        <div class="switch-span">
          <p-inputSwitch formControlName="PoliceCertificateIsRequired"></p-inputSwitch>
          <span>{{ 'police-switch-description' | translate | async }}</span>
        </div>
        <div class="switch-span">
          <p-inputSwitch formControlName="ConfidentialityDeclaration"></p-inputSwitch>
          <span>{{ 'confidentiality-switch-description' | translate | async }}</span>
        </div>

        <div class="p-dialog-footer p-dialog-footer--sticky">
          <mo-default-buttons *ngrxLet="hasChanges$ | async as hasChanges"
            [showDelete]="false"
            [canSave]="hasChanges!"
            [isSaving]="(isSaving$ | async)!"
            (cancel)="cancel(hasChanges!)"
            (save)="save(vm!)"
          ></mo-default-buttons>
        </div>
      </form>
    </div>
  `,
  styleUrls: ['./documentation-settings-modal.component.scss'],
})
export class DocumentationSettingsModalComponent implements OnInit {
  vm$: Observable<UserOrganizationModel | null> | undefined;
  isSaving$ = this.store.select(OrganizationSettingsSelectors.selectSettingsPageIsSaving);
  hasChanges$: Observable<boolean> | undefined;
  form: FormGroup = new FormGroup({});
  initialFormValues: string = '';

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.vm$ = this.store.select(organizationDetailsSelectors.selectSelectedUserOrganization)
    .pipe(tap(org => {
      this.form = this.createForm(org!);
      this.initialFormValues = JSON.stringify(this.form.getRawValue());
      this.hasChanges$ = this.form.valueChanges.pipe(map(() => this.initialFormValues !== JSON.stringify(this.form.getRawValue())));
    }));
  }

  createForm(orgModel: UserOrganizationModel): FormGroup {
    return new FormGroup({
      PoliceCertificateIsRequired: new FormControl(orgModel.HrSettings.PoliceCertificateIsRequired),
      ConfidentialityDeclaration: new FormControl(orgModel.HrSettings.ConfidentialityDeclaration),
    });
  }

  save(orgModel: UserOrganizationModel) {
    const dto = combineHrSettingsAndOrgModel(this.form, orgModel!);
    this.store.dispatch(SettingsPageActions.saveSettings({ organization: dto }));
  }
  cancel(hasChanges: boolean) {
    this.store.dispatch(SettingsModalActions.CancelModal({ hasChanges: hasChanges }));
  }
}
