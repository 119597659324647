import { EmployeePageActions } from '@mentor-one-ui/employees/state/actions/employee-page.actions';
import { createReducer, on } from '@ngrx/store';
import {
  EmployeeContactInformationActions,
  EmployeeDetailsApiActions,
  EmployeeDetailsTabActions,
  EmployeeFormalityActions,
  EmployeeModalActions,
  EmployeePersonaliaActions,
} from '../actions/employee-details.actions';
import { EmployeeDetailsModalTypes } from '../../../core/models/employee/EmployeeDetailsModalTypes';

export const featureKey = 'details';

export interface State {
  activeModal: EmployeeDetailsModalTypes | null;
  isSaving: boolean;
  imageTempUri: string | undefined;
}

export const initialState: State = {
  isSaving: false,
  activeModal: null,
  imageTempUri: undefined
};

export const reducer = createReducer(
  initialState,
  on(EmployeePageActions.Enter, (state) => initialState),
  on(EmployeeDetailsTabActions.OpenDetailsModal, (state, { modalType }) => ({
    ...state,
    activeModal: modalType,
  })),
  on(EmployeeModalActions.CloseDetailsModal, (state) => ({
    ...state,
    activeModal: null,
  })),
  on(
    EmployeeFormalityActions.SaveFormality,
    EmployeePersonaliaActions.SaveEmployeePersonalia,
    EmployeeContactInformationActions.SaveContactInformation,
    (state) => ({
      ...state,
      isSaving: true,
    })
  ),
  on(
    EmployeeDetailsApiActions.SaveEmployeeFormalitySuccess,
    EmployeeDetailsApiActions.SaveEmployeePersonaliaSuccess,
    EmployeeDetailsApiActions.SaveEmployeeContactInformationSuccess,
    (state) => ({
      ...state,
      isSaving: false,
      activeModal: null,
    })
  ),
  on(
    EmployeeDetailsApiActions.SaveEmployeeFormalityError,
    EmployeeDetailsApiActions.SaveEmployeeContactInformationError,
    EmployeeDetailsApiActions.SaveEmployeePersonaliaError,
    (state) => ({
      ...state,
      isSaving: false,
    })
  ),
  on(EmployeePageActions.SetProfilePicture, (state, { image }) => ({
    ...state,
    imageTempUri: image,
  })),
  on(
    EmployeePageActions.Leave,
    (state) => ({
      ...state,
      imageTempUri: undefined
    }))
);
