import { Directive, OnInit, OnDestroy, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { AppPermissionEnum } from '../permissions/app-permissions';
import { selectUserRole } from '../state/access/access.selector';

@Directive({
  selector: '[hasPermission]',
  standalone: true,
})
export class HasPermissionDirective implements OnInit, OnDestroy {
  private _permission: AppPermissionEnum;
  @Input()
  set hasPermission(userPermission: AppPermissionEnum) {
    this._permission = userPermission;
  }

  destroy$ = new Subject<void>();

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly store: Store
  ) {}

  ngOnInit() {
    this.store
      .select(selectUserRole)
      .pipe(takeUntil(this.destroy$))
      .subscribe((role) => {
        let result = false;

        if (this._permission === AppPermissionEnum.IsSystemAdmin) {
          if (role.isSystemAdmin) {
            result = true;
          }
        }
        if (this._permission === AppPermissionEnum.IsMentorPersonalAdmin) {
          if (role.isMentorPersonalAdministrator) {
            result = true;
          }
        }
        if (this._permission === AppPermissionEnum.IsMentorPersonalAdminOrPersonnelManager) {
          if (role.isMentorPersonalAdministrator || role.isPersonnelManager) {
            result = true;
          }
        }

        if (result) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
