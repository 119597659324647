import { createReducer, on } from '@ngrx/store';
import {
  CalendarItemModel,
  CALENDAR_VIEW_DAY,
  CALENDAR_VIEW_MONTH,
  CALENDAR_VIEW_WEEK,
  CalendarView,
  PublicHolidayModel,
} from '../models/calendar.models';
import { CalendarApiActions } from './actions/calendar-api.actions';
import { CalendarPageActions, CalendarPageFilterActions } from './actions/calendar-page.actions';
import { DateOnlyConverter } from '@mentor-one-ui/core/misc/dateonly.converter';
import { getDefaultFilter } from '@mentor-one-ui/time/leave-management/state/reducers';
import { TimeFilter } from '@mentor-one-ui/time/shared/time-filter.model';

export const calendarFeatureKey = 'calendar';

export interface State {
  calendarEvents: CalendarItemModel[];
  calendarView: CalendarView;
  lastNavigationDate: string;
  holidays: PublicHolidayModel[];
  defaultTitle: string;
  showCalendarEventDetails: boolean;
  selectedCalendarEventId: number | null;
  filter: TimeFilter;
  isSaving: boolean;
  loadError: boolean;
}

const getCalendarView = (calendarView: 'month' | 'week' | 'day'): CalendarView => {
  switch (calendarView) {
    case 'month':
      return CALENDAR_VIEW_MONTH;
    case 'week':
      return CALENDAR_VIEW_WEEK;
    case 'day':
      return CALENDAR_VIEW_DAY;
  }
};

export const initialState: State = {
  lastNavigationDate: DateOnlyConverter.convertJsDateToDateString(new Date()),
  calendarEvents: [],
  calendarView: CALENDAR_VIEW_MONTH,
  holidays: [],
  defaultTitle: '',
  showCalendarEventDetails: false,
  selectedCalendarEventId: null,
  filter: getDefaultFilter(),
  isSaving: false,
  loadError: false,
};

export const reducer = createReducer(
  initialState,
  on(CalendarPageActions.Enter, (state): State => {
    return {
      ...state,
      isSaving: false,
      loadError: false,
    };
  }),
  on(CalendarApiActions.loadCalendarsSuccess, (state, { calendarData, date }): State => {
    let d = new Date(date);
    if (state.calendarView.type != 'day' && isDateInThisWeek(d)) {
      d = new Date();
    } else if (state.calendarView.type === 'month' && isDateInThisMonth(d)) {
      d = new Date();
    }

    return {
      ...state,
      calendarEvents: calendarData.Items,
      holidays: calendarData.PublicHolidays,
      loadError: false,
      lastNavigationDate: DateOnlyConverter.convertJsDateToDateString(d),
    };
  }),
  on(CalendarApiActions.loadCalendarDataError, (state): State => {
    return {
      ...state,
      loadError: true,
    };
  }),
  on(CalendarPageActions.setDefaultTitle, (state, { title }): State => {
    return {
      ...state,
      defaultTitle: title,
    };
  }),
  on(CalendarPageActions.setCalendarView, (state, { calendarView }): State => {
    return {
      ...state,
      calendarView: getCalendarView(calendarView),
    };
  }),
  on(CalendarPageActions.dateChanged, (state, { date }): State => {
    return {
      ...state,
      lastNavigationDate: date,
    };
  }),
  on(CalendarPageActions.calendarEventClicked, (state, { eventId }): State => {
    return {
      ...state,
      selectedCalendarEventId: eventId,
      showCalendarEventDetails: true,
    };
  }),
  on(CalendarPageActions.calendarEventHide, (state): State => {
    return {
      ...state,
      showCalendarEventDetails: false,
      selectedCalendarEventId: null,
    };
  }),
  on(CalendarApiActions.loadCalendarEventDetailsSuccess, (state, { model }): State => {
    let list = [...state.calendarEvents].map((e) => {
      if (e.AbsenceId === model.AbsenceId) {
        return {
          ...e,
          Comment: model.Comment,
          HasValidationErrors: model.HasValidationErrors,
          DaysCalculated: model.DaysCalculated,
          HoursCalculated: model.HoursCalculated,
        };
      }
      return e;
    });

    return {
      ...state,
      calendarEvents: list,
    };
  }),
  on(CalendarPageFilterActions.resetFilter, (state, action): State => {
    return {
      ...state,
      filter: getDefaultFilter(),
    };
  }),
  on(CalendarPageFilterActions.SearchEmployees, (state, action): State => {
    return {
      ...state,
      filter: {
        ...state.filter,
        employeeSearch: action.search,
      },
    };
  }),
  on(CalendarPageFilterActions.setFilterStatus, (state, action): State => {
    return {
      ...state,
      filter: {
        ...state.filter,
        statuses: action.statuses,
      },
    };
  }),
  on(CalendarPageFilterActions.toggleDepartment, (state, action): State => {
    var departments = [...state.filter.selectedDepartmentIds];
    var index = departments.indexOf(action.departmentId);
    if (index > -1) {
      departments.splice(index, 1);
    } else {
      departments.push(action.departmentId);
    }

    return {
      ...state,
      filter: {
        ...state.filter,
        selectedDepartmentIds: departments,
      },
    };
  }),
  on(CalendarPageFilterActions.togglePosition, (state, action): State => {
    var positions = [...state.filter.selectedPositions];
    var index = positions.indexOf(action.position);
    if (index > -1) {
      positions.splice(index, 1);
    } else {
      positions.push(action.position);
    }

    return {
      ...state,
      filter: {
        ...state.filter,
        selectedPositions: positions,
      },
    };
  }),
  on(CalendarPageFilterActions.filterByAbsenceType, (state, action): State => {
    return {
      ...state,
      filter: {
        ...state.filter,
        absenceMapIds: action.absenceTypeIds,
      },
    };
  }),
  on(CalendarApiActions.ApproveLeaveRequest, (state): State => {
    return {
      ...state,
      isSaving: true,
    };
  }),
  on(CalendarApiActions.ApproveAbsenceError, (state): State => {
    return {
      ...state,
      isSaving: false,
    };
  }),
  on(CalendarApiActions.ApproveAbsenceSuccess, (state, { absence }): State => {
    let list = [...state.calendarEvents].map((e) => {
      if (e.AbsenceId === absence.AbsenceId) {
        return {
          ...e,
          Status: absence.Status,
        };
      }
      return e;
    });

    return {
      ...state,
      calendarEvents: list,
      isSaving: false,
    };
  }),
  on(CalendarApiActions.RejectAbsenceSuccess, (state, { id }): State => {
    let list = [...state.calendarEvents].filter((e) => e.AbsenceId !== id);
    return {
      ...state,
      calendarEvents: list,
    };
  })
);

function isDateInThisWeek(date: Date) {
  const todayObj = new Date();
  const todayDate = todayObj.getDate();
  const todayDay = todayObj.getDay();

  // get first date of week
  const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

  // get last date of week
  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

  // if date is equal or within the first and last dates of the week
  return date >= firstDayOfWeek && date <= lastDayOfWeek;
}

function isDateInThisMonth(date: Date) {
  if (new Date().getFullYear() === date.getFullYear() && new Date().getMonth() === date.getMonth()) {
    return true;
  }
  return false;
}
