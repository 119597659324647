import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, filter, map, of, pipe, switchMap } from "rxjs";
import * as fromAuthActions from './auth.actions';
import { AuthService } from "./services/auth.service";
import { UserActions } from "../user/user.actions";
import { LoggerService } from "@mentor-one-ui/core/services/logger.service";

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private logger: LoggerService
  ) { }


  checkauth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.checkAuth),
      switchMap(() =>
        this.authService
          .checkAuth()
          .pipe(
            catchError((error) => {              
              return of(fromAuthActions.checkAuthCompleteError( {error: error.message}));
            }),
            map((loginResponse: any) =>
              fromAuthActions.checkAuthComplete({ isLoggedIn: loginResponse.isAuthenticated })
            )
          )
      )
    )
  );

  bootstrapApplicationData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.checkAuthComplete),
      filter(({ isLoggedIn }) => isLoggedIn),
      map(() => {        
          return UserActions.bootstrapApplicationData();        
      })
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.logout),
      switchMap(() => {
        return this.authService.signOut();
      }),
      map(() => {
       return  fromAuthActions.logoutComplete()
      }),
      catchError((error) => {
        this.logger.logException(error);
        return of(fromAuthActions.logoutCompleteError( {error: error.message}));
      })
    )    
  );
}
