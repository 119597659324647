import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { DepartmentLightModel, DepartmentModel } from '@mentor-one-ui/core/models/DepartmentModel';

@Injectable({
  providedIn: 'root',
})
export class DepartmentService {
  constructor(public http: HttpClient) {}

  // Org dependent data

  getDepartments(): Observable<DepartmentLightModel[]> {
    return this.http.get<DepartmentLightModel[]>(environment.API_URL + '/department/list/');
  }

  updateDepartment(department: DepartmentModel): Observable<DepartmentModel> {
    return this.http.post<DepartmentModel>(environment.API_URL + '/department/update/', department);
  }
  deleteDepartment(departmentId: number): Observable<DepartmentModel[]> {
    return this.http.delete<DepartmentModel[]>(environment.API_URL + '/department/' + departmentId);
  }
}
