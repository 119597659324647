import { Component, Input, OnInit } from '@angular/core';
import { TimeManagementModel } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementModel';
import { AbsenceModel } from '@mentor-one-ui/time/my-time/absence/models/absence.model';
import { OvertimeModel } from '@mentor-one-ui/time/my-time/overtime/models/overtime.model';
import { CalendarItemModel } from '@mentor-one-ui/time/calendar/models/calendar.models';
interface TimeDisplay {
  DaysCalculated: number | null;
  HoursCalculated: string | null;
  FromDate: string | null;
  ToDate: string | null;
  FromTime: string | null;
  ToTime: string | null;
}
@Component({
  selector: 'mo-timespan-element',
  styleUrls: ['./timespan-element.component.scss'],
  template: `<small class="timespan-element">
    <span *ngIf="showCalculatedHours && (time.DaysCalculated || time.HoursCalculated)" class="timespan-element__span mobile-hidden">
      <strong *ngIf="time.DaysCalculated">
        {{ time.DaysCalculated }}
        <span *ngIf="time.DaysCalculated === 1; else multipledays">{{ 'day' | translate | async }}</span>
        <ng-template #multipledays>{{ 'days' | translate | async }}</ng-template>
      </strong>
      <strong *ngIf="time.HoursCalculated">{{ calculateHours(time.HoursCalculated) }} </strong>
      <span>•</span>
    </span>
    <span *ngIf="!time.ToDate">
      <span>{{ time.FromDate | date : 'dd. MMM yyyy' }}</span>
    </span>
    <span *ngIf="time.ToDate" class="timespan-element__span">
      <ng-container template *ngIf="time.ToDate && time.ToDate !== time.FromDate; else onlyFromTime">
        <span>{{ time.FromDate | date : isSameMonth }}</span>
        <i class="fal fa-arrow-right fal-sm" style="font-size: .75rem"></i>
        <span class="event-list-item__time">{{ time.ToDate | date : 'dd. MMM yyyy' }}</span>
      </ng-container>
      <ng-template #onlyFromTime>
        <span>{{ time.FromDate | date : 'dd. MMM yyyy' }}</span>
      </ng-template>
    </span>
    <span *ngIf="time.FromTime" class="timespan-element__span time"> <i class="fal fa-clock"></i> <span>{{ time.FromTime }}</span> - <span>{{ time.ToTime }}</span> </span>
  </small> `,
})
export class TimespanElementComponent implements OnInit {
  @Input() absence: TimeManagementModel | AbsenceModel | OvertimeModel | CalendarItemModel;
  @Input() showCalculatedHours: boolean = false;
  time: TimeDisplay = {} as TimeDisplay;
  isSameMonth: string = 'dd.';

  constructor() { }

  ngOnInit(): void {
    this.generateTimeDisplay(this.absence);
    this.sameMonth(new Date(this.time.FromDate!), new Date(this.time.ToDate!));
  }

  calculateHours(time: String | null) {
    if (time === null) {
      return;
    } else {
      const parts = time.split(':');
      const hours = parseInt(parts[0]);
      const minutes = parseInt(parts[1]);
      if (minutes) {
        return `${hours} t ${minutes} m`;
      }
      return `${hours} t`;
    }
  }
  generateTimeDisplay(time: any) {
    this.time = {
      DaysCalculated: time.DaysCalculated ? time.DaysCalculated : null,
      HoursCalculated: time.HoursCalculated ? time.HoursCalculated : null,
      FromDate: time.FromDate ? time.FromDate : time.Date,
      ToDate: time.ToDate ? time.ToDate : null,
      FromTime: time.FromTime ? time.FromTime : null,
      ToTime: time.ToTime ? time.ToTime : null,
    };
  }
  sameMonth(date1: Date, date2: Date) {
    this.isSameMonth = date1.getMonth() === date2.getMonth() ? 'dd. MMM' : 'dd. MMMM';
  }
}
