import { EmployeeModel } from '@mentor-one-ui/core/models/employee/EmployeeModel';
import { NewEmployeeScenario } from '@mentor-one-ui/core/models/employee/NewEmployeeScenario';
import { createAction, props } from '@ngrx/store';

export const NewEmployeeApiActions = {
  
  validatePersonalDetailsSuccess: createAction(
    '[Employee API] Validate Personal Details Success',
    props<{ employee: EmployeeModel; scenario: NewEmployeeScenario }>()
  ),
  validatePersonalDetailsError: createAction(
    '[Employee API] Validate Personal Details Error',
    props<{ message: string }>()
  ),    
    
  CreateNewEmployeeSuccess: createAction(
    '[Employee API] Create New Employee Success',
    props<{ employee: EmployeeModel; createUser: boolean }>()
  ),
  CreateNewEmployeeError: createAction('[Employee API] Create New Employee Error', props<{ message: string }>()),    
};
