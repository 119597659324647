
import { createSelector } from "@ngrx/store";
import { selectLeaveManagementState } from ".";

export const selectLeaveApprovalState = createSelector(
  selectLeaveManagementState,
  (state) => state.leaveApproval
);

export const selectApprovalPageState = createSelector(
    selectLeaveApprovalState,
    (state) => state.approvalPage
  );

export const selectIsSaving = createSelector(
    selectApprovalPageState,
    (state) => state.isSaving
  );

  export const selectSelectedId = createSelector(
    selectApprovalPageState,
    (state) => state.selectedId
  );
