import { AfterViewInit, Directive, HostListener, OnDestroy } from "@angular/core";

@Directive({
  selector: "[scrollshadow]",
})
export class ScrollShadowDirective implements AfterViewInit, OnDestroy {

  @HostListener('scroll', ['$event']) onScroll(event: Event) {
    this.checkIfShadowIsNecessary();
  }

  constructor() { }

  ngOnDestroy(): void {
    let dialog = document.querySelector('.p-dialog-content') as Element;
    dialog.removeEventListener('scroll', () => { });
    dialog.removeEventListener('click', () => { });
  }

  ngAfterViewInit() {
    this.checkIfShadowIsNecessary();
  }

  checkIfShadowIsNecessary() {
    let dialog = document.querySelector('.p-dialog-content') as Element;

    dialog.addEventListener('scroll', () => {
      this.addOrRemoveShadow(dialog);
    });

    dialog.addEventListener('click', () => {
      setTimeout(() => {
        this.addOrRemoveShadow(dialog);
      }, 500);
    });

    this.addOrRemoveShadow(dialog);
  }

  addOrRemoveShadow(dialog: Element) {
    if (dialog && dialog.scrollHeight > dialog.clientHeight) {
      let footer = dialog.querySelector('.p-dialog-footer') as Element;
      if (footer) {
        footer.classList.add('add-shadow');
      }
    }

    if (dialog && dialog.scrollHeight == dialog.clientHeight) {
      let footer = dialog.querySelector('.p-dialog-footer') as Element;
      if (footer) {
        footer.classList.remove('add-shadow');
      }
    }
  }
}
