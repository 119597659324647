import { createAction, props } from "@ngrx/store";

export const ManagedLeavesPageActions = {
    Enter: createAction(
        '[Managed Leaves page] Enter'
    ),
    OpenFilterModal: createAction(
        '[Managed Leaves page] Open Filter Modal'
    ),
    resetFilter: createAction(
        '[Managed Leaves page] Reset Filter'
    ),
    Paginate: createAction(
        '[Managed Leaves page] Paginate',
        props<{ page: number }>()
    ),
    setEmployeeFilter: createAction(
        '[Managed Leaves page] Set Employee Filter',
        props<{ employeeId: number }>()
    ),
    OpenSortModal: createAction(
        '[Managed Leaves page] Open Sort Modal'
    ),
    setSortOption: createAction(
        '[Managed Leaves page] Set Sort Option',
        props<{ sortOptionId: number }>()
    ),
    optionDialogCanceled: createAction(
        '[Managed Leaves page] Option Dialog Canceled'
    ),

};