import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeService } from '@mentor-one-ui/core/services/api/employee.service';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { EmployeeListMobileFilterModalComponent } from '@mentor-one-ui/employees/components/employee-list-mobile-filter-modal/employee-list-mobile-filter-modal.component';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, catchError, filter, forkJoin, map, mergeMap, of, switchMap } from 'rxjs';
import { EmployeeListPageActions } from '../actions/employee-list-page.actions';
import { ApplicationActions } from '@mentor-one-ui/core/state/application/application.actions';
import { UserActions } from '@mentor-one-ui/core/state/user/user.actions';
import { NewEmployeePageActions } from '@mentor-one-ui/employees/new-employee/state/actions/new-employee-page-actions';
import { EmployeeListApiActions } from '../actions/employee-list-api.actions';
import { EmployeeListSelectors } from '../selectors/employee-list.selectors';
import { selectHasPermission } from '@mentor-one-ui/core/state/user/user.selector';
import { AppPermissionEnum } from '@mentor-one-ui/core/permissions/app-permissions';
import { PayrollPageActions } from '@mentor-one-ui/time/payroll/state/actions/payroll-page.actions';
import { ReportsPageActions } from '@mentor-one-ui/time/reports/state/actions/reports.actions';
import { FrontpageActions } from '@mentor-one-ui/core/state/frontpage/frontpage.actions';
import { ArchivedEmployeesListComponent } from '@mentor-one-ui/employees/components/archived-employees-list/archived-employees-list.component';
import { EmployeePageActions } from '../actions/employee-page.actions';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class EmployeesListEffects {
  constructor(
    private actions$: Actions,
    private employeeService: EmployeeService,
    private store: Store,
    private messageService: MessageService,
    private dialogService: DialogService,
    private translationService: TranslationDataService,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {}

  dialogRef: DynamicDialogRef;

  openEmployeeMobileFilter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          EmployeeListPageActions.OpenMobileFilter),
        switchMap((action) => {
          this.dialogRef = this.dialogService.open(EmployeeListMobileFilterModalComponent, {
            header: this.translationService.translate('filter'),
            closable: true,
          });

          return this.dialogRef.onClose;
        })
      ),
    { dispatch: false }
  );

  dialogClosed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          EmployeePageActions.Enter,
          EmployeeListPageActions.CloseMobileFilter),
        map((payload) => {
          if (this.dialogRef) {
            this.dialogRef.close();
          }
        })
      ),
    { dispatch: false }
  );

  loadEmployeeListData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.bootstrapApplicationData, EmployeeListPageActions.Enter, NewEmployeePageActions.Enter),
      mergeMap(() =>
        this.employeeService.getEmployees().pipe(
          map((result) => {
            return EmployeeListApiActions.LoadEmployeesSuccess({ employees: result });
          }),
          catchError((error: HttpErrorResponse) => {
              let code = '';
              let message = this.translationService.translate('could-not-load-employees');

              if(error.status) code = error.status.toString();
              if(error.error) {
                message = error.error;
              }

              return of(EmployeeListApiActions.LoadEmployeesError({ code: code, error: message }),
              ApplicationActions.ErrorMessage({ title: this.translationService.translate('could-not-load-employees'), message: ''})
            )}
          )
        )
      )
    );
  });

  loadEmployeeListError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EmployeeListApiActions.LoadArchivedEmployeesError),
      map((action) => {
        return ApplicationActions.ErrorMessage({
          title: action.error,
          message: '',
        });
      })
    );
  });

  loadArchivedEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserActions.loadUserDataSuccess,
      ),
      concatLatestFrom(() => this.store.select(selectHasPermission(AppPermissionEnum.IsMentorPersonalAdmin))),
      filter(([action, hasPermission]) => {
        return hasPermission;
      }),
      switchMap(([a, b]) =>
        this.employeeService.getArchivedEmployees().pipe(
          map((result) => {
            return EmployeeListApiActions.LoadArchivedEmployeesSuccess({ employees: result });
          }),
          catchError((err) =>
            of(
              EmployeeListApiActions.LoadArchivedEmployeesError({
                error: this.translationService.translate('could-not-load-archived-employees'),
              })
            )
          )
        )
      )
    )
  );

  archiveEmployee$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(EmployeeListPageActions.ArchiveEmployee),
        concatLatestFrom(() => this.store.select(EmployeeListSelectors.selectSelectedEmployee)),
        filter(([action, employee]) => !employee?.HasActiveEmployments!),
        mergeMap(() => {
          const confirmationResult = new Subject<boolean>();

          let confirm = this.confirmationService.confirm({
            message: this.translationService.translate('archive-description'),
            header: this.translationService.translate('archive'),
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: this.translationService.translate('archive'),
            rejectButtonStyleClass: 'p-button-secondary',
            acceptIcon: 'fal fa-archive',
            rejectLabel: this.translationService.translate('cancel'),
            accept: () => {
              confirmationResult.next(true);
              confirmationResult.complete();
            },
            reject: () => {
              confirmationResult.next(false);
              confirmationResult.complete();
            },
          });

          return confirmationResult.asObservable();
        })
      )
      .pipe(
        map((confirmationResult: boolean) => {
          if (confirmationResult) {
            return EmployeeListPageActions.ConfirmArchiveEmployee();
          } else {
            return EmployeeListPageActions.RejectArchiveEmployee();
          }
        })
      );
  });

  cannotArchiveActiveEmployeeDialog$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(EmployeeListPageActions.ArchiveEmployee),
        concatLatestFrom(() => this.store.select(EmployeeListSelectors.selectSelectedEmployee)),
        filter(([action, employee]) => employee?.HasActiveEmployments!),
        mergeMap(([action, employee]) => {
          const confirmationResult = new Subject<boolean>();

          let message = this.translationService.translate('cannot-archive-active-employee-explanation');
          message = message.replace(
            '{name}',
            employee?.ContactInformation?.FirstName.toUpperCase() +
              ' ' +
              employee?.ContactInformation?.LastName.toUpperCase()
          );
          this.confirmationService.confirm({
            header: this.translationService.translate('cannot-archive-active-employee'),
            message: message,
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Gå til arbeidsforhold',
            acceptIcon: 'fal fa-briefcase',
            rejectButtonStyleClass: 'p-button-secondary',
            rejectLabel: this.translationService.translate('cancel'),
            accept: () => {
              confirmationResult.next(true);
              confirmationResult.complete();
            },
            reject: () => {
              confirmationResult.next(false);
              confirmationResult.complete();
            },
          });
          return confirmationResult.asObservable();
        })
      )
      .pipe(
        map((confirmationResult: boolean) => {
          if (confirmationResult) {
            return EmployeeListPageActions.NavigateToEmployment();
          } else {
            return EmployeeListPageActions.RejectArchiveEmployee();
          }
        })
      );
  });

  navigateToEmployment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EmployeeListPageActions.NavigateToEmployment),
        concatLatestFrom(() => this.store.select(EmployeeListSelectors.selectSelectedEmployee)),
        mergeMap(([action, employee]) => this.router.navigate(['/employees/all', employee?.EmployeeId, 'employement']))
      ),
    { dispatch: false }
  );

  deleteEmployee$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EmployeeListPageActions.DeleteEmployee),
      mergeMap((action) =>
        this.employeeService.deleteEmployee(action.employee.EmployeeId).pipe(
          map(() => {
            this.messageService.add({
              severity: 'success',
              summary: `${this.translationService.translate('deleted')} ${
                action.employee.ContactInformation.FirstName
              } ${action.employee.ContactInformation.LastName}`,
            });
            return EmployeeListApiActions.DeleteEmployeeSuccess({ employeeId: action.employee.EmployeeId.valueOf() });
          }),
          catchError((err) => of(EmployeeListApiActions.DeleteEmployeeError(err.message)))
        )
      )
    );
  });
  // TODO: FIX
  activateEmployee$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EmployeeListPageActions.ActivateToggleEmployee),
      mergeMap((action) =>
        this.employeeService
          .updateEmployeeContactInformation(action.employee.EmployeeId, action.employee.ContactInformation)
          .pipe(
            map(() => {
              this.messageService.add({
                severity: 'success',
                summary: `${
                  action.employee.ContactInformation.IsActive
                    ? this.translationService.translate('activated')
                    : this.translationService.translate('deactivated')
                } ${action.employee.ContactInformation.FirstName} ${action.employee.ContactInformation.LastName}`,
              });
              return EmployeeListApiActions.ActivateToggleEmployeeSuccess({
                employeeId: action.employee.EmployeeId,
                isActive: action.employee.ContactInformation.IsActive,
              });
            }),
            catchError((err) => of(EmployeeListApiActions.ActivateToggleEmployeeError(err.message)))
          )
      )
    );
  });

  confirmArchiveEmployee$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EmployeeListPageActions.ConfirmArchiveEmployee),
      concatLatestFrom(() => this.store.select(EmployeeListSelectors.selectSelectedEmployee)),
      mergeMap(([action, employee]) =>
        this.employeeService.archiveEmployee(employee?.EmployeeId!).pipe(
          map(() => {
            this.messageService.add({
              severity: 'success',
              summary: `${this.translationService.translate('archived')} ${employee?.ContactInformation?.FirstName} ${
                employee?.ContactInformation?.LastName
              }`,
            });
            return EmployeeListApiActions.ArchiveEmployeeSuccess({ employeeId: employee?.EmployeeId! });
          }),
          catchError((err) => of(EmployeeListApiActions.ArchiveEmployeeError(err.message)))
        )
      )
    );
  });
  unarchiveEmployee$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EmployeeListPageActions.UnarchiveEmployee),
      mergeMap((action) =>
        this.employeeService.unarchiveEmployee(action.employee.EmployeeId).pipe(
          map(() => {
            this.messageService.add({
              severity: 'success',
              summary: `${this.translationService.translate('unarchived')} ${
                action.employee.ContactInformation?.FirstName
              } ${action.employee.ContactInformation?.LastName}`,
            });
            return EmployeeListApiActions.UnarchiveEmployeeSuccess({ id: action.employee.EmployeeId.valueOf() });
          }),
          catchError((err) => of(EmployeeListApiActions.UnarchiveEmployeeError(err.message)))
        )
      )
    );
  });

  singleEmployeeActionError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        EmployeeListApiActions.ArchiveEmployeeError,
        EmployeeListApiActions.UnarchiveEmployeeError,
        EmployeeListApiActions.DeleteEmployeeError,
        EmployeeListApiActions.ActivateToggleEmployeeError
      ),
      map((action) => {
        return ApplicationActions.ErrorMessage({
          title: this.translationService.translate('could-not-update-user'),
          message: action.error,
        });
      })
    );
  });

  ShowArchivedEmployees$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EmployeeListPageActions.ShowArchivedEmployees),
        switchMap((action) => {
          let header = this.translationService.translate('former-employees');

          this.dialogRef = this.dialogService.open(ArchivedEmployeesListComponent, {
            header,
            closeOnEscape: true,
            closable: true,
          });

          return this.dialogRef.onClose;
        })
      ),
    { dispatch: false }
  );

  archiveEmployeeList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EmployeeListPageActions.ArchiveEmployeeList),
      switchMap((action) => {
        const achiveEmployeeObservables = action.employees.map((employee) => {
          return this.employeeService.archiveEmployee(employee.EmployeeId);
        });
        return forkJoin(achiveEmployeeObservables).pipe(
          map(() => {
            return EmployeeListApiActions.ArchiveEmployeeListSuccess({
              employees: action.employees,
            });
          }),
          // TODO: partial success?
          catchError((e) => of(EmployeeListApiActions.ArchiveEmployeeListError(e.message)))
        );
      })
    );
  });
  // for each successfull archive, trigger the EmployeeListPageActions.ArchiveEmployee action
  archiveEmployeeListSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EmployeeListApiActions.ArchiveEmployeeListSuccess),
      mergeMap((action) => {
        this.messageService.add({
          severity: 'success',
          summary: `${this.translationService.translate('archived')} ${
            action.employees.length
          } ${this.translationService.translate('employees')}`,
        });
        return action.employees.map((employee) => {
          return EmployeeListApiActions.ArchiveEmployeeSuccess({ employeeId: employee.EmployeeId });
        });
      })
    );
  });

  // TODO: add message service
  ActivateToggleEmployeeList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EmployeeListPageActions.ActivateToggleEmployeeList),

      switchMap((action) => {
        const activateEmployeeObservables = action.employees.map((employee) => {
          return this.employeeService.updateEmployeeContactInformation(employee.EmployeeId, {
            ...employee.ContactInformation,
            IsActive: action.isActive,
          });
        });
        return forkJoin([activateEmployeeObservables]).pipe(
          map((result) => {
            return EmployeeListApiActions.ActivateToggleEmployeeListSuccess({
              employees: action.employees,
              isActive: action.isActive,
            });
          }),
          // TODO: partial success?
          catchError((e) => of(EmployeeListApiActions.ActivateToggleEmployeeListError(e.message)))
        );
      })
    );
  });
  // for each successfull activation, trigger the EmployeeListPageActions.ActivateToggleEmployeeSuccess action
  activateToggleEmployeeListSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EmployeeListApiActions.ActivateToggleEmployeeListSuccess),
      mergeMap((action) => {
        this.messageService.add({
          severity: 'success',
          summary: `${this.translationService.translate('activated')} ${
            action.employees.length
          } ${this.translationService.translate('employees')}`,
        });
        return action.employees.map((employee) => {
          return EmployeeListApiActions.ActivateToggleEmployeeSuccess({
            employeeId: employee.EmployeeId,
            isActive: action.isActive,
          });
        });
      })
    );
  });

  multipleEmployeeActionError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EmployeeListApiActions.ArchiveEmployeeListError, EmployeeListApiActions.ActivateToggleEmployeeListError),
      map((action) => {
        return ApplicationActions.ErrorMessage({
          title: this.translationService.translate('could-not-update-users'),
          message: action.error,
        });
      })
    );
  });
}
