import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTimeCommon from './time-common';
import * as fromMyTime from '../my-time/state';
import * as fromLeaveManagement from '../leave-management/state';
import * as fromAdminBalance from '../admin-balance/state';
import * as fromCalendar from '../calendar/state';
import * as fromPayroll from '../payroll/state';
import * as fromReports from '../reports/state';
import * as fromBalanceTransition from '../balance-transition/state';

export const timeFeatureKey = 'time';

export interface TimeState {
  [fromTimeCommon.timeCommonFeatureKey]: fromTimeCommon.State;
  [fromMyTime.featureKey]: fromMyTime.State;
  [fromLeaveManagement.leaveManagementFeatureKey]: fromLeaveManagement.State;
  [fromAdminBalance.featureKey]: fromAdminBalance.State;
  [fromCalendar.calendarFeatureKey]: fromCalendar.State;
  [fromPayroll.payrollFeatureKey]: fromPayroll.State;
  [fromReports.reportsFeatureKey]: fromReports.State;
  [fromBalanceTransition.featureKey]: fromBalanceTransition.State;
}

export function reducers(state: TimeState | undefined, action: Action) {
  return combineReducers({
    [fromTimeCommon.timeCommonFeatureKey]: fromTimeCommon.reducer,
    [fromMyTime.featureKey]: fromMyTime.reducers,
    [fromLeaveManagement.leaveManagementFeatureKey]: fromLeaveManagement.reducers,
    [fromAdminBalance.featureKey]: fromAdminBalance.reducer,
    [fromCalendar.calendarFeatureKey]: fromCalendar.reducer,
    [fromPayroll.payrollFeatureKey]: fromPayroll.reducer,
    [fromReports.reportsFeatureKey]: fromReports.reducer,
    [fromBalanceTransition.featureKey]: fromBalanceTransition.reducer,
  })(state, action);
}








