export interface TimeManagementListSorting {
  title: string;
  id: number;
  direction: SortDirection;
}

export enum SortDirection {
  Ascending,
  Descending,
}

export enum TimeManagementListSortingEnum {
  FromDateAscending = 0,
  FromDateDescending = 1,
  RegistrationDateAscending = 2,
  RegistrationDateDescending = 3,
  EmployeeNameAscending = 4,
  EmployeeNameDescending = 5,
}
