import { Injectable } from '@angular/core';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { TimeManagementTypeCode } from '@mentor-one-ui/time/my-time/absence/models/time-management-type-code.enum';
import { TimeManagementType } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementType';

@Injectable({
  providedIn: 'root',
})
export class AbsenceInfoService {
  constructor(private translationService: TranslationDataService) {}

  showAbsenceInfoText(type: TimeManagementType, code: string | null): boolean {
    if (code === null) return false;

    if (type === TimeManagementType.Absence) {
      switch (code) {
        case TimeManagementTypeCode.SelfReport:
        case TimeManagementTypeCode.TimeOff:
        case TimeManagementTypeCode.MaternityPay:
        case TimeManagementTypeCode.AbsenceWithoutPay:
        case TimeManagementTypeCode.Service:
        case TimeManagementTypeCode.ExtraVacation60:
          return true;
        default:
          return false;
      }
    } else {
      switch (code) {
        case 'OVERTIME-50-TIME-OFF':
        case 'OVERTIME-100-TIME-OFF':
        case 'OVERTIME-133-TIME-OFF':
        case 'TIME-FOR-TIME-OFF':
        case 'STAGGERED-HOURS':
        case 'TIME-REGISTRATION':
        case 'TIME-REGISTRATION-SUBSTITUTE':
        case 'EVENING-SURCHARGE':
          return true;
        default:
          return false;
      }
    }
  }

  getAbsenceInfoText(type: TimeManagementType, code: string | null): string {
    if (!this.showAbsenceInfoText(type, code)) return '';

    if (type === TimeManagementType.Absence)
      return this.translationService.translate('absence-codes-' + code + '-info-text');

    if (type === TimeManagementType.Overtime)
      return this.translationService.translate('overtime-codes-' + code + '-info-text');

    return '';
  }
}
