
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApplicationInsightsService } from './application-insights.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private isProd = environment.production;
  constructor(
    private applicationInsights: ApplicationInsightsService) { }

  public logError(errorMsg: string) {
    if (!this.isProd) {
      console.error(errorMsg);
    }

    this.applicationInsights.logError(errorMsg);
  }

  public logException(exception: Error) {
    if (!this.isProd) {
      console.error(exception.message);
    }

    this.applicationInsights.logException(exception);
  }

  public logHttpErrorResponse(error: HttpErrorResponse) {
    if (!this.isProd) {
      console.error(error.message);
    }

    this.applicationInsights.logHttpErrorResponse(error);
  }
}
