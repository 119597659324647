import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ButtonAction, ButtonBarButton } from '@mentor-one-ui/core/models/ButtonBar';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';

@Component({
  selector: 'mo-default-buttons',
  template: ` <mo-button-bar
    [buttonList]="buttons"
    [isSaving]="isSaving"
    [canSave]="canSave"
    (buttonBarClick)="handleButtonClick($event)"
  >
  </mo-button-bar>`,
})
export class DefaultButtonsComponent implements OnInit, OnChanges {
  @Input() showDelete: boolean;
  @Input() showCancel: boolean = true;
  @Input() showSave: boolean = true;
  @Input() isSaving: boolean;
  @Input() canSave: boolean = true;
  @Input() canDelete: boolean = true;
  @Input() saveLabel: string = this.translateService.translate('save');
  @Input() saveIcon: string = 'fal fa-save';
  @Input() cancelLabel: string = this.translateService.translate('close');
  @Output() delete: EventEmitter<ButtonBarButton> = new EventEmitter<ButtonBarButton>();
  @Output() save: EventEmitter<ButtonBarButton> = new EventEmitter<ButtonBarButton>();
  @Output() cancel: EventEmitter<ButtonBarButton> = new EventEmitter<ButtonBarButton>();
  buttons: ButtonBarButton[];
  constructor(private translateService: TranslationDataService) {}

  ngOnInit(): void {
    this.defaultButtons();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isSaving) {
      this.buttons = this.buttons?.map((button) => {
        if (button.isSaveButton) {
          button.label = changes.isSaving.currentValue
          ? this.translateService.translate('saving') + '...'
          : this.translateService.translate('save');
        }
        return button;
      });
    }

    if(changes.canSave) {
      this.buttons = this.buttons?.map((button) => {
        if (button.isCancelButton) {
          button.label = changes.canSave.currentValue
            ? this.translateService.translate('cancel')
            : this.translateService.translate('close');
        }
        return button;
      });
    }
    if (changes.showCancel) {
      this.showCancel = changes.showCancel.currentValue;
      this.defaultButtons();
    }

    if(changes.showDelete) {
      this.showDelete = changes.showDelete.currentValue;
      this.defaultButtons();
    }
    if(changes.showSave) {
      this.showSave = changes.showSave.currentValue;
      this.defaultButtons();
    }
    if(changes.saveLabel) {
      this.saveLabel = changes.saveLabel.currentValue;
      this.defaultButtons();
    }
  }

  defaultButtons() {
    this.buttons = [
      {
        label: this.cancelLabel,
        routerLink: null,
        visible: this.showCancel,
        disabled: false,
        action: ButtonAction.Cancel,
        class: 'p-button p-button-secondary',
        icon: 'fal fa-times',
        isSaveButton: false,
        isCancelButton: true,
      },
      {
        label: this.translateService.translate('delete'),
        routerLink: null,
        visible: this.showDelete,
        disabled: !this.canDelete,
        action: ButtonAction.Delete,
        class: 'p-button p-button-danger',
        icon: 'fal fa-trash-alt',
        isSaveButton: false,
        isCancelButton: false,
      },
      {
        label: this.saveLabel,
        routerLink: null,
        visible: this.showSave,
        disabled: false,
        action: ButtonAction.Save,
        class: null,
        icon: this.saveIcon,
        isSaveButton: true,
        isCancelButton: false,
      },
    ];
  }
  handleButtonClick(button: ButtonBarButton) {
    if (button.action === ButtonAction.Delete) {
      this.delete.emit();
    }
    if (button.action === ButtonAction.Cancel) {
      this.cancel.emit();
    }
    if (button.action === ButtonAction.Save) {
      this.save.emit();
    }
  }
}
