import { getRouterSelectors, RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';
import { Params } from '@angular/router';

const selectRouterState = createFeatureSelector<RouterReducerState>('router');
export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = fromRouter.getRouterSelectors(selectRouterState);

const routerSelectors = getRouterSelectors();

//Bruk denne dersom du trenger å hente ut en parameter fra en nested route
export const selectRouteNestedParams = createSelector(selectRouterState, (router) => {
  let currentRoute = router?.state?.root;
  let params: Params = {};
  while (currentRoute?.firstChild) {
    currentRoute = currentRoute.firstChild;
    params = {
      ...params,
      ...currentRoute.params,
    };
  }
  return params;
});

export const selectRouteNestedParam = (param: string) =>
  createSelector(selectRouteNestedParams, (params) => params && params[param]);

export const selectRoutedOrganizationId = routerSelectors.selectRouteParam('organizationId');
export const selectRoutedEmployeeId = selectRouteNestedParam('employeeId');
export const selectRoutedAbsenceId = routerSelectors.selectRouteParam('absenceId');
export const selectRoutedOvertimeId = routerSelectors.selectRouteParam('overtimeId');
export const selectSystemMessageId = routerSelectors.selectRouteParam('mentoradminsystemmessageid');
export const selectQueryToken = routerSelectors.selectQueryParam('t');
export const selectIsMyProfile = routerSelectors.selectRouteDataParam('myprofile');
export const selectRoutedFollowupId = routerSelectors.selectRouteParam('followupId');
