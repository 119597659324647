import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { mergeMap, map, tap, filter, switchMap, catchError, timer, of } from 'rxjs';
import { UserService } from '@mentor-one-ui/core/services/api/user.service';
import { UserApiActions, UserActions } from './user.actions';
import { UserModel } from './user.model';
import { UserSelectors } from './user.selector';
import { AccessControlSystemEditModel } from '@mentor-one-ui/core/models/frontpage.model';
import { FrontpageService } from '@mentor-one-ui/frontpage/frontpage.service';
import { UserOrganizationModel } from '@mentor-one-ui/core/models/user-organization/user-organization.model';
import { Router } from '@angular/router';
import { DropdownModel } from '@mentor-one-ui/core/models/DropdownModel';
import {
  EmployeeSystemAccessApiActions,
  EmployeeSystemAccessTabActions,
} from '@mentor-one-ui/employees/state/actions/employee-system-access.actions';
import { RoleService } from '@mentor-one-ui/core/services/api/role.service';
import { NewEmployeePageActions } from '@mentor-one-ui/employees/new-employee/state/actions/new-employee-page-actions';
import { ApplicationActions } from '../application/application.actions';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { OrganizationDetailsApiActions } from '@mentor-one-ui/mentor-admin/admin-organizations/state/actions/organization-details-api.actions';
import { AuthService } from '../auth/services/auth.service';
import { EmployeeDetailsApiActions } from '@mentor-one-ui/employees/state/actions/employee-details.actions';
import { EmployeeEmploymentApiActions } from '@mentor-one-ui/employees/state/actions/employee-employment.actions';

const TOKEN_EXPIRE_TRESHOLD_IN_MINUTES = 1;
@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private frontpageService: FrontpageService,
    private userService: UserService,
    private router: Router,
    private roleService: RoleService,
    private store: Store,
    private authService: AuthService,
    private translationService: TranslationDataService
  ) {}
  loadUserData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.bootstrapApplicationData),
      mergeMap(() =>
        this.userService.GetUserData().pipe(
          map((data: UserModel) => {
            return UserActions.loadUserDataSuccess({ payload: data });
          })
        )
      )
    );
  });

  loadSectorData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.bootstrapApplicationData),
      mergeMap(() =>
        this.userService.GetSectorData().pipe(
          map((data: DropdownModel[]) => {
            return UserActions.loadSectorDataSuccess({ sectors: data });
          })
        )
      )
    );
  });

  loadAvailableOrganizationsData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.bootstrapApplicationData, OrganizationDetailsApiActions.SupportLogoutRequestSuccess),
      mergeMap(() =>
        this.userService.GetAvailableOrganizations().pipe(
          map((data: UserOrganizationModel[]) => {
            return UserActions.loadAvailableOrganzationSuccess({ payload: data });
          })
        )
      )
    );
  });

  loadManageableEmployees$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.bootstrapApplicationData),
      mergeMap(() =>
        this.userService.getManageableEmployees().pipe(
          map((employeeIds: number[]) => {
            return UserActions.loadManageableEmployeesSuccess({ employeeIds: employeeIds });
          })
        )
      )
    );
  });

  loadPersonellManagerError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.LoadPersonnelManagersError),
      map((action) => {
        return ApplicationActions.ErrorMessage({
          title: this.translationService.translate('error'),
          message: action.error,
        });
      })
    );
  });

  loadOrganizationSystemAccesses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        EmployeeSystemAccessTabActions.EnterSystemAccessTab,
        UserActions.bootstrapApplicationData,
        NewEmployeePageActions.Enter
      ),
      switchMap(() => {
        return this.roleService.getAvailiableRoles().pipe(
          map((result) => {
            return EmployeeSystemAccessApiActions.LoadSystemAccessesForOrganizationSuccess({ roles: result });
          })
        );
      })
    );
  });

  loadJobTitles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.bootstrapApplicationData),
      mergeMap(() =>
        this.userService.getJobTitles().pipe(
          map((jobTitles: string[]) => {
            return UserActions.loadJobTitlesSuccess({ jobTitles: jobTitles });
          })
        )
      )
    );
  });

  switchOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.switchOrganzation),
      mergeMap((action) =>
        this.userService.SwitchOrganization(action.organizationId).pipe(
          map(() => {
            return UserActions.resetStateAfterOrganizationSwitch();
          })
        )
      )
    );
  });

  setCultureCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.setCultureCode),
      mergeMap((action) =>
        this.userService.SetCultureCode(action.cultureCode).pipe(
          map(() => {
            location.reload();
            return UserActions.setCultureCodeSuccess();
          })
        )
      )
    );
  });

  refershCurrentUserAfterOrganizationSwitch$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserActions.resetStateAfterOrganizationSwitch),
        tap(() => {
          this.router.navigate(['/']).then(() => {
            location.reload();
          });
        })
      );
    },
    { dispatch: false }
  );

  loadModuleAccessData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.bootstrapApplicationData),
      mergeMap(() =>
        this.frontpageService.GetModuleAccess().pipe(
          map((data: AccessControlSystemEditModel[]) => {
            return UserActions.loadAccessControlSuccess({ payload: data });
          })
        )
      )
    );
  });

  refreshTokenTimer$ = createEffect(() => {
    return timer(5000, 60000).pipe(
      concatLatestFrom(() => [this.store.select(UserSelectors.selectUserToken), this.authService.isLoggedIn]),
      filter(([, token, authResult]) => {
        if (!authResult.isAuthenticated) {
          return false;
        }

        if (!token || token.Token == null) {
          return true;
        }
        const now = new Date();
        const expires = new Date(token!.Expires);
        const diff = expires.getTime() - now.getTime();
        return diff < 0 || diff < TOKEN_EXPIRE_TRESHOLD_IN_MINUTES * 60 * 1000;
      }),
      switchMap(() =>
        this.userService.GetUserToken().pipe(
          map((data) => UserApiActions.loadUserTokenSuccess({ userToken: data })),
          catchError((error) => of(UserApiActions.loadUserTokenError({ error: error.message })))
        )
      )
    );
  });

  loadMissingInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        UserActions.bootstrapApplicationData,
        EmployeeDetailsApiActions.SaveEmployeePersonaliaSuccess,
        EmployeeEmploymentApiActions.SaveEmploymentSuccess
      ),
      switchMap((action) => {
        return this.userService.GetMissingUserInfo().pipe(
          map((result) => {
            return UserActions.LoadMissingUserInfoSuccess({ info: result });
          }),
          catchError((e) => of(UserActions.LoadMissingUserInfoError(e.message)))
        );
      })
    );
  });
}
