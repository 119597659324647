import { AccessControlSystemEditModel } from '@mentor-one-ui/core/models/module-access';
import { DropdownModel } from '@mentor-one-ui/core/models/DropdownModel';

export interface MentorAdminOrganizationModel {
  Id: number;
  Name: string;
  OrganizationNumber: string;
  BusinessNumber: string;
  Country: string;
  EmailAddress: string;
  Municipality: string;
  PostalAddress: string;
  PostalZipCode: string;
  PostalCity: string;
  VisitAddress: string;
  VisitZipCode: string;
  VisitCity: string;
  Sector: SectorEnum;
  PhoneNumber: string;
  CultureCode: string;
  IsActive: boolean;
  IsInternalOrganization: boolean;
  ModuleAccess: AccessControlSystemEditModel[];
  CreateAdministrator: boolean;
  Administrator?: MentorAdminCreateAdministratorModel;
}
// Sector enum
export enum SectorEnum {
  Kindergarden = 1,
  School = 2,
  General = 3,
  HotelAndRestaurant = 4,
}
// Model form form
export class OrganizationModelForForm {
  Id: number;
  Name: string;
  OrganizationNumber: string;
  BusinessNumber: string;
  Country: string;
  EmailAddress: string;
  Municipality: string;
  PostalAddress: string;
  PostalZipCode: string;
  PostalCity: string;
  VisitAddress: string;
  VisitZipCode: string;
  VisitCity: string;
  DemoExpirationDate: string;
  DemoActive: string;
  Sector: DropdownModel;

  PhoneNumber: string;
  CultureCode: string;
  IsActive: boolean;
  IsInternalOrganization: boolean;
  CreateAdministrator: boolean;
  Administrator?: MentorAdminCreateAdministratorModel;

  constructor(dto: MentorAdminOrganizationModel) {
    this.Id = dto.Id;
    this.Name = dto.Name;
    this.OrganizationNumber = dto.OrganizationNumber;
    this.BusinessNumber = dto.BusinessNumber;
    this.Country = dto.Country;
    this.EmailAddress = dto.EmailAddress;
    this.Municipality = dto.Municipality;
    this.PostalAddress = dto.PostalAddress;
    this.PostalZipCode = dto.PostalZipCode;
    this.PostalCity = dto.PostalCity;
    this.VisitAddress = dto.VisitAddress;
    this.VisitZipCode = dto.VisitZipCode;
    this.VisitCity = dto.VisitCity;
    if (dto.Sector === 1) this.Sector = { name: 'Barnehage', value: '1' } as DropdownModel;
    if (dto.Sector === 2) this.Sector = { name: 'Skole', value: '2' } as DropdownModel;
    if (dto.Sector === 3) this.Sector = { name: 'Generell', value: '3' } as DropdownModel;
    if (dto.Sector === 4) this.Sector = { name: 'Hotell og restaurant', value: '4' } as DropdownModel;
    this.PhoneNumber = dto.PhoneNumber;
    this.CultureCode = dto.CultureCode;
    this.IsActive = dto.IsActive;
    this.IsInternalOrganization = dto.IsInternalOrganization;
  }
}

export interface ValidateOrganizationNumberRequest {
  OrganizationId: number;
  SectorId: SectorEnum;
  OrganizationNumber: string | null;
  BusinessNumber: string | null;
  IsInternalOrganization: boolean;
}

// Response from server
export interface ValidateOrganizationModel {
  OrganizationNumberIsRequiredHasError: boolean;
  OrganizationNumberMustBeUniqueHasError: boolean;
  BusinessNumberIsRequiredHasError: boolean;
  BusinessNumberMustBeUniqueHasError: boolean;
}

export interface MentorAdminCreateAdministratorModel {
  FirstName: string;
  LastName: string;
  MobilePhoneNumber: string;
  Email: string;
  SendEmailToAdmin: boolean;
}

export class MentorAdminOrganizationFilterOptionsModel {
  Sectors: DropdownModel[];
  OrganizationSettings: OrganizationSettingsModel;
}

export class OrganizationSettingsModel {
  HasPblPayroll: boolean;
  IsActive: boolean;
  IsInactive: boolean;
  IsInternal: boolean;
  IsPersonalDemo: boolean;
  constructor() {
    this.HasPblPayroll = false;
    this.IsActive = true;
    this.IsInactive = true;
    this.IsInternal = false;
    this.IsPersonalDemo = false;
  }
}

export class MentorAdminOrganizationFilterModel {
  selectedSector: number = -1;
  organizationSettings: OrganizationSettingsModel = new OrganizationSettingsModel();
}

export interface MentorAdminCreateInternalUser {
  FirstName: string;
  LastName: string;
  MobilePhoneNumber: string;
  Email: string;
}

export interface OrganizationCountModel {
  Total: number;
  Active: number;
  Internal: number;
}

export enum OrganizationFilterEnum {
  PblPayroll = 1,
  Active = 2,
  Internal = 3,
  PersonalDemo = 4,
  Inactive = 5,
}
