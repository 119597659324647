export enum TimeManagementTypeCode {
  SelfReport = 'SELF-REPORT',
  TimeOff = 'TIME-OFF',
  MaternityPay = 'MATERNITY-PAY',
  AbsenceWithoutPay = 'ABSENCE-WITHOUT-PAY',
  Service = 'SERVICE',
  ExtraVacation60 = 'EXTRA-VACATION-60',
  CaredaysSickChild = 'CAREDAYS-SICKCHILD',
  WorkAssessmentAllowance = 'WORK-ASSESSMENT-ALLOWANCE',
  MedicalCertification = 'MEDICALCERTIFICATION',
  Vacation = "VACATION",
}
