import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SystemEnum } from '@mentor-one-ui/core/models/enums';
import { selectUserRolesForUser } from '@mentor-one-ui/core/state/access/access.selector';
import { UserRole } from '@mentor-one-ui/core/state/user/user.model';
import { selectOrganizationSystemAccess } from '@mentor-one-ui/user-organization/components/about/state/organization-details.selectors';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MentorAdminGuard  {
  constructor(private store: Store) {}
  organizationSystemAccess$ = this.store.select(selectOrganizationSystemAccess);
  userState$ = this.store.select(selectUserRolesForUser);
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    
    return combineLatest([
      this.organizationSystemAccess$,
      this.userState$,
    ]).pipe(
      filter(([orgSystemAccess, userRoles]) => orgSystemAccess != null && userRoles != null), 
      map(([orgSystemAccess, userRoles]) => {        
        let mentorAdminSystem = orgSystemAccess?.find((x) => x.System == SystemEnum.MentorAdmin);
        let mentorAdminRole = userRoles?.find((x) => x == UserRole.SystemAdmin);              
        if (mentorAdminRole !== null && mentorAdminSystem !== null && mentorAdminSystem !== undefined) {
          return true;
        }
        return false;
     })
    );
  }
}
