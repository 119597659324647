import { createReducer, on } from "@ngrx/store";
import { OvertimeApiActions } from "../overtime/actions/overtime-api.actions";
import { OvertimePageActions, EditOvertimePageActions } from "../overtime/actions/overtime-page.actions";
import { NewOvertimePageActions } from "../overtime/containers/new-overtime-page/actions/new-overtime-page.actions";
import { OvertimeModel } from "../overtime/models/overtime.model";
import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";
import { ApplicationActions } from "@mentor-one-ui/core/state/application/application.actions";
import { TimeManagementModel } from "../absence/models/TimeManagementModel";


export const featureKey = 'overtime';


export interface State extends EntityState<OvertimeModel>{  
  isSaving: boolean;
  selectedOvertimeTypeId: number;
  hasFormChanges: boolean;
  overtimeLoaded: boolean;
  overtimeLoadError: boolean;
  totalItems: number;
  page: number;
  pageSize: number;

  filteredOvertimeTypes: number[];
  filterByStatus: number;  
  conflictingRegistrations: TimeManagementModel[] | undefined;
}

export const adapter: EntityAdapter<OvertimeModel> = createEntityAdapter<OvertimeModel>({
  selectId: (a) => a.OvertimeId,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  isSaving: false,
  selectedOvertimeTypeId: -1,
  hasFormChanges: false,
  overtimeLoaded: false,
  totalItems: 0,
  pageSize: 10,
  page: 0,
  overtimeLoadError: false,  
  filteredOvertimeTypes: [],
  filterByStatus: -1,
  conflictingRegistrations: undefined
});

export const reducer = createReducer(
  initialState,
  on(NewOvertimePageActions.Enter, OvertimePageActions.Enter, (state) => {
    return {
      ...state,
      selectedOvertimeTypeId: -1,
    }
  }),
  on(OvertimePageActions.LoadOvertime, (state, { overtime }): State => {
    return {
      ...adapter.addOne(overtime, state),
      overtimeLoadError: false
    }
  }
  ),
  on(OvertimeApiActions.loadOvertimesSuccess, (state, { overtimes, totalItems }): State => {
    return {
      ...adapter.setAll(overtimes, state),
      overtimeLoaded: true,
      totalItems: totalItems,
      overtimeLoadError: false
    }
  }),
  on(OvertimeApiActions.loadOvertimesError, (state): State => {
    return {
      ...state,
      overtimeLoadError: true
    }
  }),

  on(NewOvertimePageActions.SelectOvertimeType, (state, { overtimeTypeId }): State => {
    return {
      ...state,
      selectedOvertimeTypeId: overtimeTypeId
    }
  }),
  on(NewOvertimePageActions.ResetOvertimeTypeSelection, (state): State => {
    return {
      ...state,
      selectedOvertimeTypeId: -1
    }
  }),
  on(NewOvertimePageActions.Register, EditOvertimePageActions.UpdateOvertime, (state) => ({
    ...state,
    isSaving: true
  })),
  on(OvertimeApiActions.registerOvertimeSuccess, (state, { overtime }) => ({
    ...adapter.addOne(overtime, state),
    isSaving: false,
    hasFormChanges: false
  })),
  on(OvertimeApiActions.DeleteOvertimeSuccess, (state, { overtimeId }) => ({
    ...adapter.removeOne(overtimeId, state),
    isSaving: false,
    hasFormChanges: false
  })),
  on(OvertimeApiActions.UpdateOvertimeError, OvertimeApiActions.registerOvertimeError, (state) => ({
    ...state,
    isSaving: false
  })),
  on(OvertimeApiActions.UpdateOvertimeSuccess, (state, { overtime }) => ({
    ...adapter.updateOne({ id: overtime.OvertimeId, changes: overtime }, state),
    isSaving: false,
    hasFormChanges: false
  })),
  on(EditOvertimePageActions.HasFormChanges,
    (state, { hasChanges }) => ({
      ...state,
      hasFormChanges: hasChanges,
  })),
  on(OvertimePageActions.changePage, (state, { page }) => ({
    ...state,
    page: page
  })),  
  on(ApplicationActions.DiscardUnsavedChanges, (state) => ({
    ...state,
    hasFormChanges: false,
  })),
  on(OvertimePageActions.filterByOvertimeType, (state, { overtimeTypeIds }) => ({
    ...state,
    filteredOvertimeTypes: overtimeTypeIds
  })),
  on(OvertimePageActions.filterByStatus, (state, { status }) => ({
    ...state,
    filterByStatus: status
  })),
  on(OvertimePageActions.resetFilter, (state) => ({
    ...state,
    filteredOvertimeTypes: [],
    filterByStatus: -1
  })),
  on(OvertimeApiActions.loadConflictingRegistationsSuccess, (state, { registrations })=> ({
    ...state,
    conflictingRegistrations: registrations
  }))
  );
