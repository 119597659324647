import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { FormsModule } from '@angular/forms';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DefaultButtonsModule } from '@mentor-one-ui/core/components/default-buttons/default-buttons.module';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TimeManagementTemplate } from '../../models/time-management-template.model';
import { Store } from '@ngrx/store';
import { TimeManagementType } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementType';
import { TimeManagementTypesModalActions } from './actions/time-management-types-modal.actions';
import { selectIsSaving } from '../../state/settings.selectors';
import { Observable } from 'rxjs';
import { InfoBoxComponent } from '@mentor-one-ui/core/components/info-box/info-box.component';
import { PipesModule } from '@mentor-one-ui/core/pipes/pipes.module';
import { InfoMiniComponent } from '@mentor-one-ui/core/components/info-mini/info-mini.component';
import { AbsenceInfoService } from '../absence-info.service';
import { DirectivesModule } from '@mentor-one-ui/core/directives/directives.module';
import { CalendarPageModule } from "../../../../../time/calendar/calendar-page.module";

@Component({
  selector: 'app-time-management-types-modal',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ToggleButtonModule,
    SelectButtonModule,
    DefaultButtonsModule,
    InputSwitchModule,
    InfoBoxComponent,
    PipesModule,
    InfoMiniComponent,
    DirectivesModule,
    CalendarPageModule
],
  styleUrls: ['./time-management-types-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
  <div class="time-management-types" scrollshadow>
    <mo-info-box info="{{'info-locked-time-management-types' | translate | async}}"></mo-info-box>
    <div *ngFor="let item of timeManagementTypes">
      <div class="grid">
        <div class="left">
          <div class="top">
            <strong>{{item.Title}}</strong>
            <mo-info-mini *ngIf="absenceInfoService.showAbsenceInfoText(type, item.Code)" infoText="{{absenceInfoService.getAbsenceInfoText(type, item.Code)}}"></mo-info-mini>
          </div>
        </div>
        <div class="right">
          <p-inputSwitch
            [(ngModel)]="item.IsActive"
            [disabled]="item.IsMandatory"
            (onChange)="isActiveChange($event, item)">
          </p-inputSwitch>
        </div>

        <div class="warning-box" >
          <div *ngIf="item.ShowTimeOffWarning">
            <small>
              <div class="warning-info">
                <i class="fal fa-info-circle"></i>
                <span>{{ 'time-management-edit-time-off-warning' | translate | async }}</ span>
              </div>
            </small>
          </div>      
          <div *ngIf="item.ShowTimeForTimeOffWarning">
            <small>
              <div class="warning-info">
                <i class="fal fa-info-circle"></i>
                <span>{{ 'time-management-edit-time-for-time-off-warning' | translate | async }}</span>
              </div>
            </small>            
          </div>      
        </div>
      </div>
    </div>

    <div class="p-dialog-footer p-dialog-footer--sticky">
      <mo-default-buttons
        [showDelete]="false"
        [isSaving]="(isSaving$ | async)!"
        (cancel)="cancel()"
        (save)="save()"
      ></mo-default-buttons>
    </div>
  `,
})
export class TimeManagementTypesModalComponent implements OnInit {
  timeManagementTypes: TimeManagementTemplate[];
  originalTimeManagementTypes: TimeManagementTemplate[];
  stateOptions: any;
  type: TimeManagementType;

  isSaving$: Observable<boolean> = this.store.select(selectIsSaving);

  constructor(
    private store: Store,
    public absenceInfoService: AbsenceInfoService,
    private config: DynamicDialogConfig)  {
   }

  isActiveChange(event: any, item: TimeManagementTemplate): void {
    var orgValue = this.originalTimeManagementTypes.find(x => x.Id == item.Id);

    if (orgValue == null || orgValue == undefined) {
      return;
    }

    item.ShowTimeOffWarning = item.Code == 'TIME-OFF' && orgValue.IsActive != item.IsActive;
    item.ShowTimeForTimeOffWarning = item.Code == 'TIME-FOR-TIME-OFF' && orgValue.IsActive != item.IsActive;
  }

  ngOnInit(): void {
    this.timeManagementTypes = JSON.parse(JSON.stringify(this.config.data.timeManagementTypes))
    this.originalTimeManagementTypes = JSON.parse(JSON.stringify(this.config.data.timeManagementTypes));

    this.type = this.config.data.type;
  }

  save() {
    this.store.dispatch(TimeManagementTypesModalActions.save({
      timeManagementTypes: this.timeManagementTypes,
      timeManagementType: this.type
    }));
  }

  cancel() {
    this.store.dispatch(TimeManagementTypesModalActions.cancel());
  }
}
