import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { ReportModel } from '@mentor-one-ui/core/models/ReportModel';

@Injectable({
    providedIn: 'root',
  })
  export class ReportService {
    constructor(public http: HttpClient) {}
  
    getPoliceCertificates(): Observable<ReportModel> {
      return this.http.get<ReportModel>(environment.API_URL + '/reports/employees/PoliceCertificates');
    }

    getConfidentialityDeclarations(): Observable<ReportModel> {
      return this.http.get<ReportModel>(environment.API_URL + '/reports/employees/ConfidentialityDeclarations');
    }

    getBirthdays(): Observable<ReportModel> {
      return this.http.get<ReportModel>(environment.API_URL + '/reports/employees/Birthdays');
    }

    getChildrensBirthdays(): Observable<ReportModel> {
      return this.http.get<ReportModel>(environment.API_URL + '/reports/employees/ChildrensBirthdays');
    }

    getNextOfKins(): Observable<ReportModel> {
      return this.http.get<ReportModel>(environment.API_URL + '/reports/employees/NextOfKins');
    }

    getPersonnelManagers(): Observable<ReportModel> {
      return this.http.get<ReportModel>(environment.API_URL + '/reports/employees/PersonnelManagers');
    }
}