import { createAction, props } from "@ngrx/store";

export const ManagedFilterDialogActions = {
    closeFilterDialog: createAction(
    '[Managed Filter Dialog] Close Filter Dialog'
    ),
    setFilter: createAction(
    '[Managed Filter Dialog] Set Filter'
    ),
    setFilterStatus: createAction(
    '[Managed Filter Dialog] Set Filter Status',
    props<{statuses: number[]}>()
    ),    
    resetManagedFilter: createAction(
    '[Managed Filter Dialog] Reset Managed Filter'
    ),
    toggleDepartment: createAction(
    '[Managed Filter Dialog] Toggle Department',
    props<{departmentId: number}>()
    ),
    setOvertimeTypeFilter: createAction(
    '[Managed Filter Dialog] Toggle Overtime Type',
    props<{overtimeTypeIds: number[]}>()
    ),
    setAbsenceTypeFilter: createAction(
    '[Managed Filter Dialog] Toggle Absence Type',
    props<{absenceTypeIds: number[]}>()
    ),
    togglePosition: createAction(
    '[Managed Filter Dialog] Toggle Position',
    props<{position: string}>()
    ),
}