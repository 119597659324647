import { AbsenceApiActions } from '@mentor-one-ui/time/my-time/absence/actions/absence-api.actions';
import { AbsenceType } from '@mentor-one-ui/time/my-time/absence/models/absence-type.model';
import { OvertimeApiActions } from '@mentor-one-ui/time/my-time/overtime/actions/overtime-api.actions';
import { OvertimeType } from '@mentor-one-ui/time/my-time/overtime/models/overtime-type.model';
import { createReducer, on } from '@ngrx/store';

export const timeCommonFeatureKey = 'timeCommon';

export interface State {
    absenceTypes: AbsenceType[];
    overtimeTypes: OvertimeType[];
    absenceTypesLoadError: boolean;
    overtimeTypesLoadError: boolean;
    absenceTypesLoaded: boolean;
}

export const initialState: State = {
    absenceTypes: [],
    overtimeTypes: [],
    absenceTypesLoadError: false,
    overtimeTypesLoadError: false,
    absenceTypesLoaded: false

}

export const reducer = createReducer(
initialState,
on(AbsenceApiActions.loadAbsenceTypesSuccess, (state, { absenceTypes }): State => {
    return {
      ...state,
      absenceTypes: absenceTypes,
      absenceTypesLoadError: false,
      absenceTypesLoaded: true
    }
  }),
  on(AbsenceApiActions.loadAbsenceTypesError, (state): State => {
    return {
      ...state,
      absenceTypesLoadError: true
    }
  }),
on(OvertimeApiActions.loadOvertimeTypesSuccess, (state, { overtimeTypes }): State => {
    return {
      ...state,
      overtimeTypes: overtimeTypes,
      overtimeTypesLoadError: false
    }
  }),
  on(OvertimeApiActions.loadOvertimeTypesError, (state): State => {
    return {
      ...state,
      overtimeTypesLoadError: true
    }
  }),
)
