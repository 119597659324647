import { DateValidator } from './date.validator';

export class DateOnlyConverter {
  constructor() {}

  static convertJsDateToDateString(jsDate: Date): string {
    if (jsDate !== null && jsDate instanceof Date && DateValidator.isValidDate(jsDate)) {
      const month = (jsDate.getMonth() + 1).toString().padStart(2, '0');
      const day = jsDate.getDate().toString().padStart(2, '0');
      const year = jsDate.getFullYear().toString();
      const dateString = `${year}-${month}-${day}`;

      return dateString;
    }
    return '';
  }

  static convertDateOnlyStringToJsDate(dateString: string): Date {
    if (dateString !== null && dateString !== '') {
      const dateParts = dateString.split('-');
      const year = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) - 1;
      const day = parseInt(dateParts[2], 10);
      const jsDate = new Date(year, month, day);

      return jsDate;
    }
    return new Date();
  }
}
