import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, of } from 'rxjs';
import {
  DepartmentsApiActions,
  DepartmentsTabActions,
} from '@mentor-one-ui/user-organization/components/departments/state/actions/departments.actions';
import { DepartmentService } from '@mentor-one-ui/core/services/api/department.service';
import { UserActions } from '@mentor-one-ui/core/state/user/user.actions';
import { OrganizationService } from '@mentor-one-ui/core/services/api/organization.service';
import { DialogService } from 'primeng/dynamicdialog';
import { DepartmentDialogComponent } from '../department-dialog/department-dialog.component';
import { departmentSelectors } from './departments.selectors';
import { Store } from '@ngrx/store';
import { createEmptyDepartmentModel } from '@mentor-one-ui/core/models/DepartmentModel';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { ApplicationActions } from '@mentor-one-ui/core/state/application/application.actions';
import { MessageService } from 'primeng/api';

@Injectable()
export class DepartmentsEffects {
  dialogRef: any;
  constructor(
    private actions$: Actions,
    private organizationService: OrganizationService,
    private departmentService: DepartmentService,
    private dialogService: DialogService,
    private store: Store,
    private translationService: TranslationDataService,
    private messageService: MessageService
  ) { }

  // Enter loads Poersonel Managers and Departments for User Organization

  organizationDepartmentsEnter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DepartmentsTabActions.Enter),
      mergeMap(() => [
        DepartmentsTabActions.loadDepartmentsForOrganization(),
        DepartmentsTabActions.loadPersonellManagersForOrganization(),
      ])
    );
  });

  // Load Departments for User Organization

  loadDepartmentsForOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DepartmentsTabActions.loadDepartmentsForOrganization, UserActions.bootstrapApplicationData),
      mergeMap(() =>
        this.departmentService.getDepartments().pipe(
          map((data: any) => {
            return DepartmentsApiActions.loadDepartmentsForOrganizationSuccess({ departments: data });
          }),
          catchError((err) => of(DepartmentsApiActions.loadDepartmentsForOrganizationFailure({ error: err.message}))
        ))
      )
    );
  });

  // Load Personell Managers for User Organization

  loadPersonellManagersForOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DepartmentsTabActions.loadPersonellManagersForOrganization),
      mergeMap(() =>
        this.organizationService.getPersonnelManagers().pipe(
          map((data: any) => {
            return DepartmentsApiActions.loadPersonellManagersForOrganizationSuccess({
              personellManagers: data,
            });
          })
        )
      )
    );
  });

  // Save Department

  saveDepartment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DepartmentsTabActions.updateDepartment),
      mergeMap((action) =>
        this.departmentService.updateDepartment(action.department).pipe(
          map((data: any) => {
            return DepartmentsApiActions.updateDepartmentSuccess({ department: data });
          }),
          catchError((err) => of(DepartmentsApiActions.updateDepartmentFailure({ error: err.message }))
          )
        )
      )
    );
  });

  rejectAbsenceErrorToUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DepartmentsApiActions.updateDepartmentFailure,
        DepartmentsApiActions.deleteDepartmentFailure
        ),
      map((action) => {
        return ApplicationActions.ErrorMessage({
          title: this.translationService.translate('something-went-wrong'),
          message: '',
        });
      })
    );
  });


  showToastOnSave$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DepartmentsApiActions.updateDepartmentSuccess),
        map((action) => {
          this.messageService.add({ severity: 'success', summary: this.translationService.translate('success'), detail: this.translationService.translate('department-saved') });
        })
      );
    },
    { dispatch: false }
  );

  // Delete Department

  deleteDepartment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DepartmentsTabActions.deleteDepartment),
      mergeMap((action) =>
        this.departmentService.deleteDepartment(action.departmentId).pipe(
          map(() => {
            return DepartmentsApiActions.deleteDepartmentSuccess();
          }),
          catchError((err) => of(DepartmentsApiActions.deleteDepartmentFailure({ error: err.message })))
        )
      )
    );
  });

  showToastOndelete$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DepartmentsApiActions.deleteDepartmentSuccess),
        map((action) => {
          this.messageService.add({ severity: 'success', summary: this.translationService.translate('success'), detail: this.translationService.translate('department-deleted') });
        })
      );
    },
    { dispatch: false }
  );

  addDepartment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DepartmentsTabActions.OpenNewDepartmentModal,
        DepartmentsTabActions.OpenEditDepartmentModal),
      concatLatestFrom(() => [
        this.store.select(departmentSelectors.selectSelectedDepartment),
        this.store.select(departmentSelectors.selectPersonellManagers)]),
      mergeMap(([action, selectedDepartment, personnel]) => {
        let header = this.translationService.translate('edit-department');
        if (!selectedDepartment) {
          selectedDepartment = createEmptyDepartmentModel();
          header = this.translationService.translate('new-department');
        }
        this.dialogRef = this.dialogService.open(DepartmentDialogComponent, {
          header: header,
          data: { managers: personnel, department: selectedDepartment },
        });

        return this.dialogRef.onClose;
      }),
    );
  }, { dispatch: false });

  dialogClosed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DepartmentsTabActions.CloseDepartmentModal
        ),
        map((payload) => {
          if (this.dialogRef) {
            this.dialogRef.close();
          }
        })
      ),
    { dispatch: false }
  );





  // Close Department form on Success

  closeDepartmentModalOnUpdateOrDeleteSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DepartmentsApiActions.updateDepartmentSuccess,
        DepartmentsApiActions.deleteDepartmentSuccess),
      map(() => DepartmentsTabActions.CloseDepartmentModal())
    );
  });
}
