import { CultureCode, Language } from './translation.state';
import { createAction, props } from '@ngrx/store';

const load = createAction('[Translation] Load translations');

const loadTranslationsSuccess = createAction('[Translation] Load translations success');
const loadPrimeTranslationsSuccess = createAction('[Translation] Load Prime translations success');

const selectLanguage = createAction('[Translation] Select language',
props<{ cultureCode: CultureCode }>());

export const TranslationActions = {
  load,
  loadTranslationsSuccess,
  loadPrimeTranslationsSuccess,
  selectLanguage
};
