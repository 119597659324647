import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactTagModel } from '@mentor-one-ui/core/models/user-organization/contact/ContactTagModel';
import { Store } from '@ngrx/store';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ContactModel } from '@mentor-one-ui/core/models/user-organization/contact/ContactModel';
import { contactsSelectors } from '@mentor-one-ui/user-organization/components/contacts/state/contacts.selector';
import { contactApiActions } from '@mentor-one-ui/user-organization/components/contacts/state/actions/contact-api.actions';

@Component({
  selector: 'mo-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss'],
})
export class ContactModalComponent implements OnInit {
  canDeleteTag = true; // TODO get user-privelege
  tag = '';

  contact$ = this.store.select(contactsSelectors.selectContact);
  contactBeforeEdit: ContactModel | undefined;
  formHasChanges = false;

  form: FormGroup = new FormGroup({
    FirstName: new FormControl('', Validators.required),
    LastName: new FormControl(''),
    Email: new FormControl('', [Validators.email]),
    TelephoneNumber: new FormControl('', Validators.pattern(/^[0-9]*$/)),
    PostalAddress: new FormControl(''),
    UsefulInformation: new FormControl(''),

    ContactId: new FormControl(''),
    OrganizationId: new FormControl(''),
  });

  allTagsForOrganization: string[];
  suggestions: string[] = [];
  selectedTags: ContactTagModel[] = [];
  results: any;

  contactId: number | undefined;

  constructor(private store: Store, private ref: DynamicDialogRef) {}
  ngOnInit(): void {
    this.contact$.subscribe((contact) => {
      this.form.patchValue({ FirstName: contact?.FirstName ?? '' });
      this.form.patchValue({ LastName: contact?.LastName ?? '' });
      this.form.patchValue({ Email: contact?.Email ?? '' });
      this.form.patchValue({ TelephoneNumber: contact?.TelephoneNumber ?? '' });
      this.form.patchValue({ PostalAddress: contact?.PostalAddress ?? '' });
      this.form.patchValue({ UsefulInformation: contact?.UsefulInformation ?? '' });
      this.form.patchValue({ ContactId: contact?.ContactId ?? '' });

      this.selectedTags = contact?.ContactTags ?? [];
      this.contactId = contact?.ContactId;

      this.contactBeforeEdit = contact;
    });
    this.store.select(contactsSelectors.selectContactTags).subscribe((tags) => {
      this.allTagsForOrganization = tags;
    });

    this.form.valueChanges.subscribe((value) => {
      this.checkIfFormHasChanges();
    });
  }

  checkIfFormHasChanges() {
    this.formHasChanges = !this.compare(this.form.value, this.contactBeforeEdit);
  }

  compare(a: ContactModel, b: ContactModel | undefined) {
    return (
      a.Email === b?.Email &&
      a.FirstName === b?.FirstName &&
      a.LastName === b?.LastName &&
      a.PostalAddress === b?.PostalAddress &&
      a.TelephoneNumber === b?.TelephoneNumber &&
      a.UsefulInformation === b?.UsefulInformation &&
      this.selectedTags === b?.ContactTags
    );
  }

  postContact() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      let form = document.getElementById('validator');

      if (form) {
        let firstInvalidControl = form.querySelectorAll('input.ng-invalid')[0];

        if (firstInvalidControl) {
          firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
          (firstInvalidControl as HTMLElement).focus({ preventScroll: true });
        }
      }
      return;
    }

    if (this.tag.length > 0) {
      this.addCurrentValueTag();
    }

    const updatedContact = this.form.value as ContactModel;
    updatedContact.ContactTags = this.selectedTags;
    this.store.dispatch(contactApiActions.postContact({ contact: updatedContact }));    
  }

  getSearchResults(event: any) {
    const query = event.query;
    const searchResult = this.allTagsForOrganization.filter(
      (tag) => tag.toLowerCase().indexOf(query.toLowerCase()) === 0
    );
    this.results = searchResult;
  }

  addCurrentValueTag() {
    this.addToSuggestions(this.tag);
    this.checkIfFormHasChanges();
  }

  addToSelected(event: any) {
    this.addToSuggestions(event.value);
    this.checkIfFormHasChanges();
  }

  addToSuggestions(tag: string) {
    if (this.contactId === undefined) return;
    if (tag === '') return;
    // if array of selected tags contains tag, return
    if (this.selectedTags.map((t) => t.TagValue === tag).includes(true)) return;
    this.selectedTags = [...this.selectedTags, new ContactTagModel(-1, this.contactId, tag)];
    this.tag = '';
  }
  removeTag(index: number) {
    this.selectedTags = this.selectedTags.filter((_, i) => i !== index);
    this.checkIfFormHasChanges();
  }
  haveAllTagsForOrganization() {
    return this.allTagsForOrganization.length > 0;
  }
  cancel() {
    this.ref.close();
  }
}
