import { OvertimeModel } from "@mentor-one-ui/time/my-time/overtime/models/overtime.model";
import { createAction, props } from "@ngrx/store";

export const ManageEditOvertimePageActions = {
    DeleteOvertime: createAction(
        '[ManageEditOvertimePage] Delete Overtime',
        props<{ id: number, isExported: boolean | null }>()
    ),    

    DeleteOvertimeCanceled: createAction(
        '[ManageEditOvertimePage] Delete Overtime canceled'
    ),

    DeleteOvertimeConfirmed: createAction(
        '[ManageEditOvertimePage] Delete Overtime confirmed'
    ),
    EditOvertime: createAction(
        '[ManageEditOvertimePage] Edit Overtime',
        props<{ overtime: OvertimeModel }>()
    ),
    }