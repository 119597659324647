import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRouteSnapshot, RouterStateSnapshot, TitleStrategy } from "@angular/router";

@Injectable({providedIn: 'root'})
export class ConcatTitleStrategy extends TitleStrategy {
    public postFix = "Mentor Pluss";
    public separator = " - ";

    constructor(
        private readonly title: Title    ) {
        super();
    }

    override updateTitle(routerState: RouterStateSnapshot): void {
        const title = this.concatTitle(routerState.root, [], this.separator);        
        this.title.setTitle(title.join(this.separator) + this.separator + this.postFix);
    }

    private concatTitle(route: ActivatedRouteSnapshot, titles: string[], separator: string): string[] {
        if (!route)
        return titles;

        const sub = route.data ? this.getResolvedTitleForRoute(route) : undefined;

        if(sub) {
            if(titles[0] != sub) { // if the title is not already in the array(child routes will inherit the title from the parent if not explicitly set)
                titles.unshift(sub); 
            }
        }        
        titles = this.concatTitle(route?.children[0], titles, separator);        
        
        return titles;
  }
}