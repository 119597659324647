import { Pipe, PipeTransform } from "@angular/core";
import { Store } from "@ngrx/store";
import { OvertimeType } from "../my-time/overtime/models/overtime-type.model";
import { selectAllOvertimeTypes } from "../state/time-common/selectors/time-common.selectors";



@Pipe({
  name: 'overtimeType',
  pure: false
})
export class OvertimeTypePipe implements PipeTransform {
  overtimeTypes: OvertimeType[];

  constructor(private store: Store) {
    this.store.select(selectAllOvertimeTypes).subscribe((overtimeTypes) => this.overtimeTypes = overtimeTypes);
  }

  transform(overtimeTypeId: number): string {
    var overtimeType = this.overtimeTypes?.find(f => f.OrganizationOvertimeMapId === overtimeTypeId);
    return `${overtimeType?.EmojiCode} ${overtimeType?.Title}`;
  }
}

@Pipe({
  name: 'overtimeTypeName',
  pure: false,
})
export class OvertimeTypeNamePipe implements PipeTransform {
  overtimeTypes: OvertimeType[];

  constructor(private store: Store) {
    this.store.select(selectAllOvertimeTypes).subscribe((overtimeTypes) => (this.overtimeTypes = overtimeTypes));
  }

  transform(abscenceTypeId: number): string {
    var overtimeType = this.overtimeTypes?.find((f) => f.OrganizationOvertimeMapId === abscenceTypeId);
    if (!overtimeType) return '';
    return `${overtimeType?.Title}`;
  }
}
