import { Injectable } from "@angular/core";
import { TranslationDataService } from "@mentor-one-ui/core/services/translation-data.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { switchMap, map, catchError, of, Subject, exhaustMap, filter } from "rxjs";
import { selectSelectedEmployeeIds } from "../leave-administration.selectors";
import { ManageApiActions } from "../actions/manage-api.actions";
import { ApplicationActions } from "@mentor-one-ui/core/state/application/application.actions";
import { Router } from "@angular/router";
import { ManageEditAbsencePageActions } from "../../containers/manage-edit-absence-page/actions/manage-edit-absence-page.actions";
import { ConfirmationService, MessageService } from "primeng/api";
import { selectRoutedAbsenceId, selectRoutedOvertimeId, selectUrl } from "@mentor-one-ui/core/state/router/router.selectors";
import { ManageEditOvertimePageActions } from "../../containers/manage-edit-overtime-page/actions/manage-edit-overtime-page.actions";
import { ManageAddAbsencePageActions } from "../../containers/manage-add-absence-page/actions/manage-add-absence-page.actions";
import { AbsenceManageService } from "@mentor-one-ui/time/my-time/absence/absence-manage.service";
import { AbsenceService } from "@mentor-one-ui/time/my-time/absence/absence.service";
import { OvertimeService } from "@mentor-one-ui/time/my-time/overtime/overtime.service";
import { EmployeeService } from "@mentor-one-ui/core/services/api/employee.service";
import { PersonnelManagerModel } from "@mentor-one-ui/core/models/employee/EmploymentModel";
import { EmployeeDetailsApiActions } from "@mentor-one-ui/employees/state/actions/employee-details.actions";
import { UserSelectors } from "@mentor-one-ui/core/state/user/user.selector";
import { BalanceService } from "@mentor-one-ui/time/my-time/balance/services/balance.service";
import { BalanceModel } from "@mentor-one-ui/time/my-time/balance/models/balance.model";
import { OvertimeApiActions } from "@mentor-one-ui/time/my-time/overtime/actions/overtime-api.actions";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { EmployeesRegistrationsModalComponent } from "../../components/employees-registrations/employees-registrations-modal.component";
import { selectSelectedFollowUpId } from "@mentor-one-ui/sick-leave/follow-up/state/selectors";
import { SickLeaveFollowUpPageActions } from "@mentor-one-ui/sick-leave/follow-up/state/follow-up.actions";

@Injectable()
export class LeaveManagementsEffects {
  constructor(
    private actions$: Actions,
    private absenceManageService: AbsenceManageService,
    private translationService: TranslationDataService,
    private absenceServie: AbsenceService,
    private balanceService: BalanceService,
    private employeeService: EmployeeService,
    private overtimeService: OvertimeService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private store: Store,
    private messageService: MessageService,
    private dialogService: DialogService
  ) {}
  dialogRef: DynamicDialogRef;

  getBalanceForSingleEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageAddAbsencePageActions.startNewLeaveRegistration),
      concatLatestFrom(() => this.store.select(selectSelectedEmployeeIds)),
      filter(([action, employeeIds]) => employeeIds.length == 1),
      switchMap(([action, employeeIds]) =>
        this.balanceService.getBalance(employeeIds[0]).pipe(
          map((balance: BalanceModel[]) => {
            return EmployeeDetailsApiActions.getBalanceForSingleEmployeeSuccess({ balance });
          }),
          catchError((e) => of(EmployeeDetailsApiActions.getBalanceForSingleEmployeeError(e.message)))
        )
      )
    )
  );

  getPersonnelManagersForSelectedEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageAddAbsencePageActions.startNewLeaveRegistration,
        SickLeaveFollowUpPageActions.ExtendAbsence),
      concatLatestFrom(() => this.store.select(selectSelectedEmployeeIds)),
      filter(([action, employeeIds]) => employeeIds.length == 1),
      switchMap(([action, employeeIds]) =>
        this.employeeService.getEmployeePersonalManagers(employeeIds[0]).pipe(
          map((personnelManagers: PersonnelManagerModel[]) => {
            return EmployeeDetailsApiActions.getEmployeePersonnelManagersSuccess({
              personnelManagers: personnelManagers,
            });
          }),
          catchError((e) => of(EmployeeDetailsApiActions.getEmployeePersonnelManagersError(e.message)))
        )
      )
    )
  );

  setCurrentUserAsPersonnelManager$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageAddAbsencePageActions.startNewLeaveRegistration),
      concatLatestFrom(() => [
        this.store.select(selectSelectedEmployeeIds),
        this.store.select(UserSelectors.selectUser),
      ]),
      filter(([action, employeeIds, user]) => employeeIds.length > 1),
      map(([action, employeeIds, user]) => {
        let personnelManager: PersonnelManagerModel = {
          Id: user?.EmployeeId!,
          Name: user?.EmployeeFullName!,
        };

        return ManageAddAbsencePageActions.setCurrentUserAsPersonnelManager({ personnelManager: personnelManager });
      })
    )
  );

  registerBulkAbsence$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageAddAbsencePageActions.registerNewAbsence),
      concatLatestFrom((action) => this.store.select(selectSelectedEmployeeIds)),

      switchMap(([action, employeeIds]) =>
        this.absenceManageService.registerBulkAbsence(employeeIds, action.absence, action.AutomaticApprove).pipe(
          map(() => {
            return ManageApiActions.RegisterBulkLeaveSuccess();
          }),
          catchError((e) =>
            of(
              ManageApiActions.RegisterBulkLeaveError({
                error: this.translationService.translate('could-not-save-absence'),
              })
            )
          )
        )
      )
    )
  );

  registerBulkOvertime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageAddAbsencePageActions.registerNewOvertime),
      concatLatestFrom((action) => this.store.select(selectSelectedEmployeeIds)),
      switchMap(([action, employeeIds]) =>
        this.absenceManageService.registerBulkOvertime(employeeIds, action.overtime, action.AutomaticApprove).pipe(
          map(() => {
            return ManageApiActions.RegisterBulkLeaveSuccess();
          }),
          catchError((e) =>
            of(
              ManageApiActions.RegisterBulkLeaveError({
                error: this.translationService.translate('could-not-save-overtime'),
              })
            )
          )
        )
      )
    )
  );

  registerBulkOvertimeCollection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageAddAbsencePageActions.registerNewOvertimeCollection),
      concatLatestFrom((action) => this.store.select(selectSelectedEmployeeIds)),
      switchMap(([action, employeeIds]) =>
        this.absenceManageService
          .registerBulkOvertimeCollection(employeeIds, action.overtimeCollection, action.AutomaticApprove)
          .pipe(
            map(() => {
              return ManageApiActions.RegisterBulkLeaveSuccess();
            }),
            catchError((e) =>
              of(
                ManageApiActions.RegisterBulkLeaveError({
                  error: this.translationService.translate('could-not-save-overtime'),
                })
              )
            )
          )
      )
    )
  );

  registerLeaveeErrorToUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ManageApiActions.RegisterBulkLeaveError,
        ManageApiActions.EditAbsenceError,
        ManageApiActions.EditOvertimeError,
        ManageApiActions.DeleteLeaveError,
        OvertimeApiActions.DeleteOvertimeError
      ),
      map((action) => {
        return ApplicationActions.ErrorMessage({
          title: this.translationService.translate('something-went-wrong'),
          message: action.error,
        });
      })
    );
  });

  RegisterLeaveSuccessConfirmation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ManageApiActions.RegisterBulkLeaveSuccess),
      map((action) => {
        return ApplicationActions.SuccessMessage({
          title: this.translationService.translate('success'),
          message: this.translationService.translate('registration-complete'),
        });
      })
    );
  });

  deleteAbsencePromt$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ManageEditAbsencePageActions.DeleteAbsence),
      exhaustMap((action) => {
        const confirmationResult = new Subject<boolean>();

        let message = this.translationService.translate('delete-description');
        if (action.isExported)
          message += '<br><br>' + this.translationService.translate('timeManagement-item-is-exported');

        this.confirmationService.confirm({
          header: this.translationService.translate('delete'),
          message: message,
          icon: 'pi pi-exclamation-triangle',
          acceptIcon: 'fal fa-trash-alt',
          acceptButtonStyleClass: 'p-button-danger',
          rejectButtonStyleClass: 'p-button-secondary',
          accept: () => {
            confirmationResult.next(true);
            confirmationResult.complete();
          },
          reject: () => {
            confirmationResult.next(false);
            confirmationResult.complete();
          },
        });
        return confirmationResult.asObservable();
      }),
      map((result: boolean) => {
        if (result === false) {
          return ManageEditAbsencePageActions.DeleteAbsenceCanceled();
        }
        return ManageEditAbsencePageActions.DeleteAbsenceConfirmed();
      })
    );
  });

  DeleteAbsence$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageEditAbsencePageActions.DeleteAbsenceConfirmed),
      concatLatestFrom(() => this.store.select(selectRoutedAbsenceId)),
      filter(([action, absenceId]) => {
        return absenceId != null;
      }),
      switchMap(([action, absenceId]) =>
        this.absenceServie.deleteAbsence(+absenceId!).pipe(
          map(() => {
            return ManageApiActions.DeleteLeaveSuccess();
          }),
          catchError((e) =>
            of(
              ManageApiActions.DeleteLeaveError({
                error: this.translationService.translate('could-not-delete-absence'),
              })
            )
          )
        )
      )
    )
  );

  navigateToList$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ManageApiActions.DeleteLeaveSuccess, ManageApiActions.RegisterBulkLeaveSuccess),
        concatLatestFrom(() => this.store.select(selectSelectedFollowUpId)),
        map(([action, followUpId]) => {
          if (followUpId) {
            this.router.navigate(['/sick-leave/follow-up', followUpId]);
          } else {
            this.router.navigate(['/time/manage']);
          }
        })
      );
    },
    { dispatch: false }
  );

  deleteAbsenceSuccessToUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ManageApiActions.DeleteLeaveSuccess),
      map((action) => {
        return ApplicationActions.SuccessMessage({
          title: this.translationService.translate('absence-deleted'),
          message: '',
        });
      })
    );
  });

  editAbsence$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageEditAbsencePageActions.EditAbsence),
      switchMap((action) =>
        this.absenceServie.updateAbsence(action.absence).pipe(
          map(() => {
            return ManageApiActions.EditAbsenceSuccess();
          }),
          catchError((e) =>
            of(
              ManageApiActions.EditAbsenceError({ error: this.translationService.translate('could-not-save-absence') })
            )
          )
        )
      )
    )
  );

  navigateToAbsenceDetail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ManageApiActions.EditAbsenceSuccess),
        concatLatestFrom(() => this.store.select(selectRoutedAbsenceId)),
        map(([action, absenceId]) => {
          this.router.navigate(['/time/manage/absence/', absenceId]);
        })
      );
    },
    { dispatch: false }
  );

  /* Flytte til egen effect fil?*/
  deleteOvertimPromt$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ManageEditOvertimePageActions.DeleteOvertime),
      exhaustMap((action) => {
        const confirmationResult = new Subject<boolean>();

        let message = this.translationService.translate('delete-description');
        if (action.isExported)
          message += '<br><br>' + this.translationService.translate('timeManagement-item-is-exported');

        this.confirmationService.confirm({
          header: this.translationService.translate('delete'),
          message: message,
          icon: 'pi pi-exclamation-triangle',
          acceptIcon: 'fal fa-trash-alt',
          acceptButtonStyleClass: 'p-button-danger',
          rejectButtonStyleClass: 'p-button-secondary',
          accept: () => {
            confirmationResult.next(true);
            confirmationResult.complete();
          },
          reject: () => {
            confirmationResult.next(false);
            confirmationResult.complete();
          },
        });
        return confirmationResult.asObservable();
      }),
      map((result: boolean) => {
        if (result === false) {
          return ManageEditOvertimePageActions.DeleteOvertimeCanceled();
        }
        return ManageEditOvertimePageActions.DeleteOvertimeConfirmed();
      })
    );
  });

  DeleteOvertime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageEditOvertimePageActions.DeleteOvertimeConfirmed),
      concatLatestFrom(() => this.store.select(selectRoutedOvertimeId)),
      filter(([action, overtimeId]) => {
        return overtimeId != null;
      }),
      switchMap(([action, overtimeId]) =>
        this.overtimeService.deleteOvertime(+overtimeId!).pipe(
          map(() => {
            return OvertimeApiActions.DeleteOvertimeSuccess({ overtimeId: +overtimeId! });
          }),
          catchError((e) =>
            of(
              OvertimeApiActions.DeleteOvertimeError({
                error: this.translationService.translate('could-not-delete-overtime'),
              })
            )
          )
        )
      )
    )
  );

  editOvertime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageEditOvertimePageActions.EditOvertime),
      switchMap((action) =>
        this.overtimeService.updateOvertime(action.overtime).pipe(
          map(() => {
            return ManageApiActions.EditOvertimeSuccess();
          }),
          catchError((e) =>
            of(
              ManageApiActions.EditOvertimeError({
                error: this.translationService.translate('could-not-save-overtime'),
              })
            )
          )
        )
      )
    )
  );

  navigateToOvertimeList$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OvertimeApiActions.DeleteOvertimeSuccess),
        concatLatestFrom(() => this.store.select(selectUrl)),
        filter(([action, url]) => url.includes('manage/overtime')),
        map(([action, overtimeId]) => {
          this.router.navigate(['/time/manage/unmanaged']);
        })
      );
    },
    { dispatch: false }
  );

  navigateToOvertimeDetail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ManageApiActions.EditOvertimeSuccess),
        concatLatestFrom(() => this.store.select(selectRoutedOvertimeId)),
        map(([action, overtimeId]) => {
          this.router.navigate(['/time/manage/overtime/', overtimeId]);
        })
      );
    },
    { dispatch: false }
  );

  showSuccessToastOnOvertimeEdit$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ManageApiActions.EditOvertimeSuccess),
        map((action) => {
          this.messageService.add({
            severity: 'success',
            summary: this.translationService.translate('success'),
            detail: this.translationService.translate('overtime-updated'),
          });
        })
      );
    },
    { dispatch: false }
  );

  showSuccessToastOnAbsenceEdit$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ManageApiActions.EditAbsenceSuccess),
        map((action) => {
          this.messageService.add({
            severity: 'success',
            summary: this.translationService.translate('success'),
            detail: this.translationService.translate('absence-updated'),
          });
        })
      );
    },
    { dispatch: false }
  );

  showConflictingRegistrationsDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ManageAddAbsencePageActions.openEmployeesRegistrationsModal),
        map((action) => {
          this.dialogRef = this.dialogService.open(EmployeesRegistrationsModalComponent, {
            data: { absences: action.registrations, continueAction: action.continueAction },
            header: this.translationService.translate('concurrent-registrations-info-title'),
            width: '700px',
            closeOnEscape: true,
            closable: true,
          });
          return this.dialogRef.onClose;
        })
      ),
    { dispatch: false }
  );

  closeConflictingRegistrationsDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ManageAddAbsencePageActions.closeEmployeesRegistrationsModal),
        map((payload) => {
          if (this.dialogRef) {
            this.dialogRef.close();
          }
        })
      ),
    { dispatch: false }
  );
}
