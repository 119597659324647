import { TimeManagementModel } from "@mentor-one-ui/time/my-time/absence/models/TimeManagementModel";
import { TimeManagementType } from "@mentor-one-ui/time/my-time/absence/models/TimeManagementType";
import { createAction, props } from "@ngrx/store";

export const ManageApiActions = {
  loadUnmanagedAbsencesSuccess: createAction(
    '[Manage API] Load Unmanaged Absences Success',
    props<{ unmanagedAbsences: TimeManagementModel[]; totalAbsences: number }>()
  ),
  loadUnmanagedAbsencesError: createAction(
    '[Manage API] Load Unmanaged Absences Error',
    props<{ error: string; }>()
  ),
  loadManagedAbsencesSuccess: createAction(
    '[Manage API] Load Managed Absences Success',
    props<{ managedAbsences: TimeManagementModel[]; totalAbsences: number }>()
  ),
  loadManagedAbsencesError: createAction(
    '[Manage API] Load Managed Absences Error',
    props<{ error: string; }>()
  ),
  ApproveAbsenceSuccess: createAction(
    '[Manage API] Approve Absence Success',
    props<{ id: number; }>()
  ),
  ApproveAbsenceError: createAction(
    '[Manage API] Approve Absence Error',
    props<{ error: string; }>()
  ),
  RejectAbsenceSuccess: createAction(
    '[Manage API] Reject Absence Success',
    props<{ id: number; leaveType: TimeManagementType }>()
  ),
  RejectAbsenceError: createAction(
    '[Manage API] Reject Absence Error',
    props<{ error: string; }>()
  ),
  RegisterBulkLeaveSuccess: createAction(
    '[Manage API] Register Bulk Leave Success'
  ),
  RegisterBulkLeaveError: createAction(
    '[Manage API] Register Bulk Leave Error',
    props<{ error: string; }>()
  ),
  EditAbsenceSuccess: createAction(
    '[Manage API] Edit Absence Success'
  ),
  EditAbsenceError: createAction(
    '[Manage API] Edit Absence Error',
    props<{ error: string; }>()
  ),

  DeleteLeaveSuccess: createAction(
    '[Manage API] Delete Leave Success'
  ),
  DeleteLeaveError: createAction(
    '[Manage API] Delete Leave Error',
    props<{ error: string; }>()
  ),

  EditOvertimeSuccess: createAction(
    '[Manage API] Edit Overtime Success'
  ),
  EditOvertimeError: createAction(
    '[Manage API] Edit Overtime Error',
    props<{ error: string; }>()
  ),
};
