import { createReducer, on } from "@ngrx/store";
import { ReportsFilter } from "../models/reports-filter.model";
import { TimeManagementModel } from "@mentor-one-ui/time/my-time/absence/models/TimeManagementModel";
import { SortDirection } from "@mentor-one-ui/time/leave-management/models/time-management-list-sorting.model";
import { ReportFilterDialogActions, ReportsApiActions, ReportsPageActions } from "./actions/reports.actions";
import { DateOnlyConverter } from "@mentor-one-ui/core/misc/dateonly.converter";

export const reportsFeatureKey = 'reports';

export const getDefaultFilter = () => {
  var today = new Date();
  var lastDay = new Date(today.getFullYear(), today.getMonth()+1, 0);;
  var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);;
  return {
    absenceMapIds: [],
    overtimeMapdIds: [],
    selectedDepartmentIds: [],
    selectedPositions: [],
    selectedSortOptionId: 0,
    selectedEmployeeId: -1,
    statuses: [],
    employeeSearch: '',
    fromDate: DateOnlyConverter.convertJsDateToDateString(firstDay),
    toDate: DateOnlyConverter.convertJsDateToDateString(lastDay),
    rows: 10,
    first: 0,
    sortOptions: [       
    {
      id: 0,
      title: 'date',
      direction: SortDirection.Ascending
    },
    {
      id: 1,
      title: 'date',
      direction: SortDirection.Descending
    },
    {
      id: 2,
      title: 'RegistrationDate',
      direction: SortDirection.Ascending
    },
    {
      id: 3,
      title: 'RegistrationDate',
      direction: SortDirection.Descending
    }, 
    {
      id: 4,
      title: 'EmployeeName',
      direction: SortDirection.Ascending
    },{
      id: 5,
      title: 'EmployeeName',
      direction: SortDirection.Descending
    } 
  ]
  } as ReportsFilter
}

export interface State {
    reportsList: TimeManagementModel[];
    isLoading: boolean;
    filter: ReportsFilter;
    totalReports: number;
}

export const initialState: State = {
    reportsList: [],
    isLoading: false,
    filter: getDefaultFilter(),
    totalReports: 0
}

export const reducer = createReducer(
    initialState,
    on(ReportsPageActions.Enter, (state): State => {
        return {
          ...state,
          isLoading: state.reportsList.length == 0,
          filter: getDefaultFilter(),
        }
    }),
    on(ReportsPageActions.FilterChanged, (state, { filter }): State => {
        return {
            ...state,
            isLoading: true,
            reportsList: [],
            filter: filter
        }
    }),
    on(ReportsApiActions.loadReportsOverviewSuccess, (state, { result }): State => {
        return {
            ...state,
            reportsList: result.Items,
            isLoading: false,
            totalReports: result.Count
        }
    }),    
    on(ReportsApiActions.loadReportsOverviewFailure, (state): State => {
        return {
            ...state,
            isLoading: false
        }
    }),
    on(ReportsApiActions.exportReportsSuccess, (state): State => {
        return {
            ...state
        }
    }),
    on(ReportsApiActions.exportReportsFailure, (state): State => {
        return {
            ...state
        }
    }),
    on(ReportFilterDialogActions.resetFilter, (state, action): State => {
      return {
        ...state,
        filter: {...getDefaultFilter()},
      }
    }),
    on(ReportFilterDialogActions.toggleDepartment, (state, action): State => {
  
      var departmentIds = [...state.filter.selectedDepartmentIds];
      var index = departmentIds.indexOf(action.departmentId);
      if(index > -1) {
        departmentIds.splice(index, 1);
      } else {
        departmentIds.push(action.departmentId);
      }
  
      return {
        ...state,
        filter: {
          ...state.filter,
          selectedDepartmentIds: departmentIds
        }
      }
    }),
    on(ReportFilterDialogActions.toggleOvertimeType, (state, action): State => {
      return {
        ...state,
        filter: {
          ...state.filter,
          overtimeMapdIds: action.overtimeTypeIds
        }
      }
    }),
    on(ReportFilterDialogActions.toggleAbsenceType, (state, action): State => {
      return {
        ...state,
        filter: {
          ...state.filter,
          absenceMapIds: action.absenceTypeIds
        }
      }
    }),
  
    on(ReportFilterDialogActions.togglePosition, (state, action): State => {
      var positions = [...state.filter.selectedPositions];
      var index = positions.indexOf(action.position);
      if(index > -1) {
        positions.splice(index, 1);
      } else {
        positions.push(action.position);
      }
  
      return {
        ...state,
        filter: {
          ...state.filter,
          selectedPositions: positions
        }
      }
    }),
    on(ReportFilterDialogActions.toggleStatus, (state, action): State => {
      return {
        ...state,
        filter: {
          ...state.filter,
          statuses: action.statuses
        }
      }
    }),
    on(ReportFilterDialogActions.fromDateChanged, (state, action): State => {
      return {
        ...state,
        filter: {
          ...state.filter,
          fromDate: action.fromDate
        }
      }
    }),
    on(ReportFilterDialogActions.toDateChanged, (state, action): State => {
      return {
        ...state,
        filter: {
          ...state.filter,
          toDate: action.toDate
        }
      }
    }),
);