import { Injectable } from '@angular/core';
import { catchError, of } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private oidcSecurityService: OidcSecurityService) {}

  get isLoggedIn() {
    return this.oidcSecurityService.isAuthenticated$;
  }

  get token() {
    return this.oidcSecurityService.getAccessToken();
  }

  getRefreshToken() {
    return this.oidcSecurityService.getRefreshToken();
  }

  get userData() {
    return this.oidcSecurityService.userData$;
  }

  checkAuth() {
    return this.oidcSecurityService.checkAuth();
  }

  doLogin() {
    this.oidcSecurityService.authorize();
  }

  signOut() {    
    return this.oidcSecurityService.logoff().pipe(
      catchError((error) => {
        return of(error);
      })
    );   
  }
}